import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { Box, Paper, TableRow, TableCell, Typography, CircularProgress } from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';

import { getAllClubIncomeTransactions } from 'src/server/api/transaction';

import Scrollbar from 'src/components/scrollbar';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import ClubIncomeTransactionToolbar from 'src/sections/clubIncomeTransaction/ClubIncomeTransactionToolbar';
import ClubIncomeTransactionTableRow from 'src/sections/clubIncomeTransaction/ClubIncomeTransactionTableRow';

// ----------------------------------------------------------------------

export default function ClubIncomeTransaction({ member, disabled }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const router = useRouter();
  const table = useTable({ defaultOrderBy: 'createdAt', defaultRowsPerPage: 25 });
  const confirm = useBoolean();

  const { loading, clubIncomeTransactionList } = useSelector((state) => ({
    loading: state.transaction.loading,
    clubIncomeTransactionList: state.transaction.ClubIncomeTransactions,
  }));
  console.log(disabled);
  const identifier = 'club-income-transaction';
  const storedRowsPerPage = sessionStorage.getItem(`rowsPerPage_${identifier}`);

  const [searchText, setSearchText] = useState();
  const [date, setDate] = useState('');
  // const [sort, setSort] = useState('');
  // console.log(setSort);
  const [tableData, setTableData] = useState([]);
  const totalPage = clubIncomeTransactionList?.total;

  const TABLE_HEAD = [
    { id: 'date', label: 'Date' },
    { id: 'description', label: 'Description' },
    // { id: 'club', label: 'Club' },
    { id: 'credit', label: 'Credit' },
    { id: 'debit', label: 'Debit' },
  ];

  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      limit: 6,
      sort: '',
      date,
      dispatch,
    };
    dispatch(getAllClubIncomeTransactions(credentials));
  }, [date, dispatch, searchText, storedRowsPerPage, table.page, table.rowsPerPage]);

  useEffect(() => {
    setTableData(clubIncomeTransactionList?.clubIncomeTransactions);
  }, [clubIncomeTransactionList]);

  const dataInPage = [];

  const canReset = false;

  const notFound = (!tableData?.length && canReset) || !tableData?.length;

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData?.filter((row) => row.id !== id);
      enqueueSnackbar('Delete success!');
      setTableData(deleteRow);
      table.onUpdatePageDeleteRow(dataInPage?.length);
    },
    [dataInPage?.length, enqueueSnackbar, table, tableData]
  );

  const handleDeleteRows = () => {};

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.edit(id));
    },
    [router]
  );

  const handleViewRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.details(id));
    },
    [router]
  );

  const handleSearch = (value) => {
    setSearchText(value);
  };
  const setPage = () => {
    table.setPage(0);
  };

  return (
    <>
      <Stack height="100%">
        <Card
          variant="outlined"
          sx={{
            // height: '100%',
            justifyContent: 'space-between',
            boxShadow: 'unset',
            display: 'flex',
            flexDirection: 'column',
            pb: 0.5,
          }}
        >
          <Stack>
            <Stack p={2}>
              <Typography variant="h6">Club Income Transactions </Typography>
            </Stack>
            {/* <ClubIncomeTransactionSort
            sort={sort}
            setSort={setSort}
            clubIncomeTransactionList={clubIncomeTransactionList}
          /> */}

            <Box mx={2} my={3}>
              <ClubIncomeTransactionToolbar
                setPage={setPage}
                onSearch={handleSearch}
                date={date}
                setDate={setDate}
                member={member}
                disabled={disabled}
              />
            </Box>
            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <TableSelectedAction
                dense={table.dense}
                numSelected={table.selected?.length}
                rowCount={tableData?.length}
                onSelectAllRows={(checked) => {
                  table.onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id)
                  );
                }}
              />
              <Scrollbar>
                <Table size={table.dense ? 'small' : 'medium'}>
                  <TableHeadCustom
                    order={table.order}
                    orderBy={table.orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData?.length}
                    numSelected={table.selected?.length}
                    onSort={table.onSort}
                  />

                  {loading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <CircularProgress color="inherit" />
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}

                  <TableBody>
                    {(tableData || []).map((row, index) => (
                      <ClubIncomeTransactionTableRow
                        index={index}
                        page={table.page + 1}
                        rowsPerPage={storedRowsPerPage || table.rowsPerPage || 5}
                        key={row.id}
                        row={row}
                        selected={table?.selected?.includes(row.id)}
                        onSelectRow={() => table.onSelectRow(row.id)}
                        onViewRow={() => handleViewRow(row.id)}
                        onEditRow={() => handleEditRow(row.id)}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                        loading={loading}
                        colSpan={TABLE_HEAD?.length}
                        members={member}
                      />
                    ))}
                  </TableBody>

                  {/* <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData?.length)}
                />  */}

                  <TableNoData notFound={notFound} />
                </Table>
              </Scrollbar>
            </TableContainer>
          </Stack>

          <TablePaginationCustom
            count={totalPage || 0}
            customRowsPerPage={25}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
            identifier={identifier}
            disabled={disabled}
          />
        </Card>
      </Stack>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

ClubIncomeTransaction.propTypes = {
  member: PropTypes.any,
  disabled: PropTypes.any,
};

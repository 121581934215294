/* eslint-disable import/no-unresolved */
import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
// import { createEnquiry, updateEnquiryDetails } from 'src/server/api/enquiry';
import { useForm } from 'react-hook-form';
import { useMemo, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
// components
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// @mui
import { Stack, Dialog, Button, Divider, DialogTitle, DialogContent } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import { createFaq, updateFaqDetails } from 'src/server/api/notification';

import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';

// import CrudModalContent from './CrudModalContent';

FaqModalDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  isUpdate: PropTypes.func,
};

export default function FaqModalDialog({ open, setOpen, isUpdate }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useResponsive('down', 'md');
  // const { role } = useGetRoles();
  const { loading } = useSelector((state) => ({
    loading: state.notification.faqLoading,
  }));
  // console.log(loading);

  const handleClose = () => {
    setOpen(false);
  };

  const notificationSchema = Yup.object().shape({
    question: Yup.string()?.required('question is required'),
    answer: Yup.string(),
    priority: Yup.number(),
  });

  const defaultValues = useMemo(
    () => ({
      question: isUpdate?.question || '',
      answer: isUpdate?.answer || '',
      priority: isUpdate?.priority || 0,
    }),
    [isUpdate?.question, isUpdate?.answer, isUpdate?.priority]
  );

  // const defaultValues = useMemo(() => {
  //   if (isUpdate?._id) {
  //     return {
  //       title: isUpdate?.title || '',
  //       description: isUpdate?.description || '',
  //     };
  //   }
  //   return {
  //     title: '',
  //     description: '',
  //   };
  // }, [isUpdate]);

  const methods = useForm({
    resolver: yupResolver(notificationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    // watch,
  } = methods;

  useEffect(() => {
    if (isUpdate?._id) {
      reset(defaultValues);
    }
  }, [defaultValues, reset, isUpdate?._id]);

  // const watchedUserName = watch();
  // console.log('>>>>>>>', watchedUserName);

  const onSubmit = handleSubmit(async (data) => {
    const credentials = {
      state: {
        ...data,
      },
      dispatch,
      handleClose,
      reset,
    };
    const updateCredentials = {
      state: {
        ...data,
      },
      dispatch,
      handleClose,
      reset,
      faqId: isUpdate?._id,
    };
    if (isUpdate?._id) {
      dispatch(updateFaqDetails(updateCredentials));
    } else {
      dispatch(createFaq(credentials));
    }
  });

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" sx={{ pb: 0 }}>
          {/* {isUpdate?._id ? 'Update your payment' : 'Complete your payment'} */}
          {isUpdate?._id ? 'Update' : 'Create'} FAQ
        </DialogTitle>
        <DialogContent
          className="crud-modal"
          sx={{ minWidth: isMobile ? '200px' : '450px', padding: '0px' }}
        >
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack p={3}>
              <Stack gap={3}>
                <RHFTextField
                  name="question"
                  size="small"
                  label="Question"
                  // InputLabelProps={{ shrink: true }}
                  // placeholder="Some random question goes here"
                  multiline
                  required
                  rows={3}
                />
              </Stack>
              <Stack pt={2}>
                <RHFTextField
                  name="answer"
                  size="small"
                  label="Answer"
                  required
                  // InputLabelProps={{ shrink: true }}
                  // placeholder="Some random answer goes here "
                  multiline
                  rows={5}
                />
              </Stack>
              <Stack pt={2} width="30%">
                <RHFTextField
                  name="priority"
                  size="small"
                  label="Priority"
                  type="number"

                  // InputLabelProps={{ shrink: true }}
                  // placeholder="Enter Priority "
                />
              </Stack>
            </Stack>
            <Divider
              sx={{
                width: '100%',
                borderStyle: 'dashed',
              }}
            />

            <Stack p={2} direction="row" alignItems="center" justifyContent="end" spacing={1}>
              <LoadingButton
                loading={loading}
                variant="contained"
                sx={{
                  backgroundColor: '#007867',
                  color: 'white',
                }}
                type="submit"
              >
                {isUpdate?._id ? 'Update' : 'Create'}
              </LoadingButton>
              <Button color="inherit" variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}

/* eslint-disable import/no-unresolved */
import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
// import { createEnquiry, updateEnquiryDetails } from 'src/server/api/enquiry';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// components
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
// @mui
import { Stack, Dialog, Button, IconButton, DialogTitle, DialogContent } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import { CreateEnquiryApi } from 'src/server/api/auth';

import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';

// import CrudModalContent from './CrudModalContent';

EnquiryModalDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default function EnquiryModalDialog({ open, setOpen }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  // console.log(isUpdate);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useResponsive('down', 'md');

  // const { role } = useGetRoles();
  // const { loading } = useSelector((state) => ({
  //   settingsDetails: state.settings.settingsDetails,
  //   loading: state.settings.loading,
  // }));
  // console.log(loading);

  const handleClose = () => {
    setOpen(false);
  };

  const memberCreateSchema = Yup.object().shape({
    fname: Yup.string(),
    lname: Yup.string(),
    // email: Yup.string().required('email is required'),
    email: Yup.string(),
    subject: Yup.string(),
    message: Yup.string(),
  });

  const defaultValues = React.useMemo(
    () => ({
      fname: '',
      lname: '',
      email: '',
      subject: '',
      message: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(memberCreateSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    // setValue,
    // watch,
  } = methods;

  // const watchedUserName = watch();
  // console.log('>>>>>>>', watchedUserName);

  const onSubmit = handleSubmit(async (data) => {
    const credentials = {
      state: {
        // ...data,
        first_name: data?.fname,
        last_name: data?.lname,
        name: `${data?.fname} ${data?.lname}`,
        subject: data?.subject,
        message: data?.message,
        email: data?.email,
      },
      dispatch,
      reset,
      setOpen,
    };
    dispatch(CreateEnquiryApi(credentials));
  });

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', top: 0, right: 0, m: 1 }}
        >
          <CloseIcon sx={{ fontSize: '20px' }} />
        </IconButton>
        <DialogTitle id="responsive-dialog-title" sx={{ pb: 0 }}>
          {/* {isUpdate?._id ? 'Update your payment' : 'Complete your payment'} */}
          Create Enquiry
        </DialogTitle>
        <DialogContent
          className="crud-modal"
          sx={{ minWidth: isMobile ? '200px' : '450px', padding: '0px' }}
        >
          {/* <Stack p={3} pt={0}>
            <Stack textAlign="start">
              <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                <AccountCircleIcon sx={{ fontSize: '20px' }} />
                <Typography variant="subtitle1">{isUpdate?.first_name}</Typography>
              </Stack>
              <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                <EmailIcon sx={{ fontSize: '20px' }} />
                <Typography variant="subtitle1">{isUpdate?.email}</Typography>
              </Stack>
            </Stack>
            <Stack alignItems="flex-end">
              <Stack className="all-list-select-ui" my={2} width="150px">
                <FormControl width="150px" name="status" size="small">
                  <Select
                    value={statusValue}
                    onChange={handleTypeChange}
                    sx={{
                      backgroundColor: statusOptions.find((option) => option.value === statusValue)
                        ?.color,
                      color: 'black',
                    }}
                  >
                    {statusOptions.map((option) => (
                      <MenuItem key={option.value} value={option?.value} sx={{ color: 'black' }}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Stack>
            <Typography variant="subtitle1" mb={1}>
              Subject
            </Typography>
            <Typography
              sx={{
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                textAlign: 'start',
              }}
              variant="caption"
            >
              {isUpdate?.subject}
            </Typography>
            <Typography variant="subtitle1" mt={2} mb={1}>
              Message
            </Typography>
            <Typography
              sx={{
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                textAlign: 'start',
              }}
              variant="caption"
            >
              {isUpdate?.message}
            </Typography>
          </Stack> */}

          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack p={3} flexDirection="column">
              <Stack flex={7} flexDirection="row" gap={3}>
                <RHFTextField
                  name="fname"
                  size="small"
                  label="First Name"
                  InputLabelProps={{ shrink: true }}
                  placeholder="Enter First Name"
                  required
                />
                <RHFTextField
                  name="lname"
                  size="small"
                  label="Last Name"
                  InputLabelProps={{ shrink: true }}
                  placeholder="Enter Last Name"
                  required
                />
              </Stack>
              {/* <Stack flex={7} flexDirection="row" gap={3} mt={3}>
                <Stack width="100%">
                  <RHFTextField
                    sx={{ width: '100%' }}
                    name="name"
                    size="small"
                    label="Name"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter Name"
                    required
                  />
                </Stack>
              </Stack> */}

              <Stack flex={7} flexDirection="row" gap={3} mt={3}>
                <Stack width="100%">
                  <RHFTextField
                    name="email"
                    size="small"
                    label="Email"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Email"
                    required
                  />
                </Stack>

                {/* <Stack width="100%">
                <Stack className="all-list-select-ui">
                  <FormControl width="150px" name="status" size="small">
                    <InputLabel
                      sx={{
                        backgroundColor: 'white',
                        textAlign: 'center',
                        alignItems: 'center',
                        marginBottom: '5px',
                      }}
                    >
                      Status
                    </InputLabel>
                    <Select
                      value={statusValue}
                      onChange={handleTypeChange}
                      sx={{
                        backgroundColor: statusOptions.find(
                          (option) => option.value === statusValue
                        )?.color,
                        color: 'black',
                      }}
                    >
                      {statusOptions.map((option) => (
                        <MenuItem key={option.value} value={option?.value} sx={{ color: 'black' }}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Stack> */}
              </Stack>
              <Stack flex={7} flexDirection="row" gap={3} mt={3}>
                <Stack width="100%">
                  <RHFTextField
                    name="subject"
                    size="small"
                    label="Subject"
                    rows={2}
                    multiline
                    InputLabelProps={{ shrink: true }}
                    placeholder="Subject"
                  />
                </Stack>
              </Stack>
              <Stack flex={7} flexDirection="row" gap={3} mt={3}>
                <Stack width="100%">
                  <RHFTextField
                    name="message"
                    size="small"
                    label="Message"
                    rows={4}
                    multiline
                    InputLabelProps={{ shrink: true }}
                    placeholder="Message"
                  />
                </Stack>
              </Stack>

              <Button
                variant="contained"
                type="submit"
                sx={{
                  mt: 2,
                }}
              >
                Create Enquiry
              </Button>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { Box, Paper, CardMedia, Typography } from '@mui/material';

import timerImg from 'src/assets/other/timer-org.png';
import expiredImg from 'src/assets/other/expired.png';
import achievedImg from 'src/assets/other/achieved.png';

const IncentiveTimer = ({ verifiedDate, days, levelId, transactionList }) => {
  const [timeLeft, setTimeLeft] = useState('');

  const isRankAchieved = transactionList?.some((item) => item?.incentive_level?._id === levelId);

  //   const dateValue = '2024-11-24T04:07:12.509Z';

  useEffect(() => {
    const calculateRemainingTime = () => {
      const endDate = new Date(verifiedDate);
      endDate.setDate(endDate.getDate() + days);

      const now = new Date();
      const diff = endDate - now;

      if (diff <= 0) {
        setTimeLeft('Expired');
        return;
      }

      const remainingDays = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      setTimeLeft(`${remainingDays} d ${hours} h ${minutes} m ${seconds} s`);
    };

    // Initial calculation
    calculateRemainingTime();

    // Update the timer every second
    const timer = setInterval(calculateRemainingTime, 1000);

    // Cleanup on component unmount
    return () => clearInterval(timer);
  }, [verifiedDate, days]);

  const returnData = () => {
    if (isRankAchieved) {
      return (
        <Paper
          sx={{
            backgroundColor: '#F2F8F6',
            color: '#007867',
            px: 2,
            py: 2.5,
            borderRadius: 0.5,
          }}
        >
          <Box display="flex" alignItems="center" gap={1}>
            <CardMedia
              component="img"
              sx={{
                height: 17,
                width: 17,
              }}
              src={achievedImg}
            />
            <Typography variant="subtitle2" display="flex" alignItems="center">
              Achieved
            </Typography>
          </Box>
        </Paper>
      );
    }
    if (timeLeft === 'Expired' && !isRankAchieved) {
      return (
        <Paper
          sx={{
            backgroundColor: '#F0F0F0',
            color: '#454F5B',
            px: 2,
            py: 2.5,
            borderRadius: 0.5,
          }}
        >
          <Box display="flex" alignItems="center" gap={1}>
            <CardMedia
              component="img"
              sx={{
                height: 17,
                width: 17,
              }}
              src={expiredImg}
            />
            <Typography variant="subtitle2" display="flex" alignItems="center">
              Expired
            </Typography>
          </Box>
        </Paper>
      );
    }
    return (
      <Paper
        sx={{
          backgroundColor: '#F8F6F2',
          color: '#7A4100',
          p: 1,
          borderRadius: 0.5,
        }}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <CardMedia
            component="img"
            sx={{
              height: 17,
              width: 17,
            }}
            src={timerImg}
          />
          <Typography variant="body2">
            Hurry! Only{' '}
            <Typography variant="body2" component="span" fontWeight={700}>
              {timeLeft}
            </Typography>{' '}
            left to achieve this rank
          </Typography>
        </Box>
      </Paper>
    );
  };

  return returnData();
};

export default IncentiveTimer;

IncentiveTimer.propTypes = {
  days: PropTypes.any,
  verifiedDate: PropTypes.any,
  levelId: PropTypes.any,
  transactionList: PropTypes.any,
};

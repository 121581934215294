import { matchPath, useLocation } from 'react-router-dom';

import { useSearchParams } from './use-search-params';

// ----------------------------------------------------------------------

export function useActiveLink(path, deep = true) {
  const { pathname } = useLocation();
  const search = useSearchParams();

  const parsedPath = path.split('?search=')[1];
  const searchValue = search.get('search');

  const normalActive = path ? !!matchPath({ path, end: true }, pathname) : false;

  const deepActive = path ? !!matchPath({ path, end: false }, pathname) : false;

  if (searchValue === parsedPath) {
    return true;
  }

  return deep ? deepActive : normalActive;
}

/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import { toPng } from 'html-to-image';
import React, { useRef, useState } from 'react';

import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import DownloadIcon from '@mui/icons-material/Download';
import { Tooltip, Typography, IconButton } from '@mui/material';

// import { useGetRoles } from 'src/hooks/useHandleSessions';

import { useResponsive } from 'src/hooks/use-responsive';

import { formatDate } from 'src/utils/format-time';

import { bgGradient } from 'src/theme/css';
import victor from 'src/assets/cards/VICTOR.png';
import vigour from 'src/assets/cards/VIgour.png';
import vibron from 'src/assets/cards/VIbron.png';
import vivian from 'src/assets/cards/vivian.png';
import standard from 'src/assets/cards/standard.png';

export default function MemberCard({
  memberDetails,
  createdAt,
  name,
  username,
  cardImg,
  expiry_date,
}) {
  const cardRef = useRef(null);
  const downloadIconRef = useRef(null);
  const isMobile = useResponsive('down', 'md');
  // const role = useGetRoles()?.role;

  const [isDownloadIcon, setIsDownloadIcon] = useState(false);

  // getImageUrl
  const getImageUrl = (cardName) => {
    if (cardName === '' || cardName === undefined) return standard;
    if (cardName === 'VICTOR') return victor;
    if (cardName === 'VIBRON') return vibron;
    if (cardName === 'VIGOUR') return vigour;
    if (cardName === 'VIVIAN') return vivian;
    return 0;
  };

  // handleDownloadImage
  const handleDownloadImage = () => {
    if (cardRef.current === null) {
      return;
    }

    // Hide the download icon before taking the screenshot
    if (downloadIconRef.current) {
      downloadIconRef.current.style.display = 'none';
    }

    // Convert the element to PNG with high quality
    toPng(cardRef.current, { pixelRatio: 2 })
      .then((dataUrl) => {
        // Download the generated PNG
        saveAs(dataUrl, `${name}-card.png`);

        // Restore the download icon visibility after capturing
        if (downloadIconRef.current) {
          downloadIconRef.current.style.display = 'block';
        }
      })
      .catch((error) => {
        console.error('Failed to download image:', error);

        // Restore download icon visibility in case of an error
        if (downloadIconRef.current) {
          downloadIconRef.current.style.display = 'block';
        }
      });
  };

  return (
    <Stack
      direction={{ xs: 'column', md: 'column' }}
      // gap={isMobile ? 0.5 : 1}
      sx={{
        ...bgGradient({
          // color: alpha('#0a0909cc', 0.8),
          // imgUrl: coverUrl,
          color: alpha('#0a0909cc', 0.1),
          imgUrl: getImageUrl(cardImg),
          // imgUrl: 'https://res.osperb.com/winbuks/image/69b1020ee69346b1a2f4e5aeea4ae76c'
        }),
        // backgroundColor: '#FFE0A3',
        // height: isMobile ? '436px' : '230px',
        aspectRatio: '16/9',
        width: '100%',
        height: '100%',
        color: 'common.white',
        position: 'relative',
        borderRadius: '15px',
        // pt: isMobile ? 11 : 16,
        // border: '2px solid orange',
      }}
      onMouseEnter={() => setIsDownloadIcon(true)}
      onMouseLeave={() => setIsDownloadIcon(false)}
      ref={cardRef}
    >
      <Stack
        sx={{
          position: 'absolute',

          bottom: 0,
          width: '100%',
        }}
      >
        <Typography
          p={2}
          pl={isMobile ? 4 : 3.5}
          pb={0}
          sx={{
            color: 'common.white',
          }}
          variant="h6"
        >
          {name?.toUpperCase()}
        </Typography>
        <Stack p={isMobile ? 2 : 1} px={2.5} justifyContent="space-between" direction="row">
          <Stack width="auto">
            <Typography variant="caption" color="#ffd37f">
              ID NO:
            </Typography>
            <Typography
              sx={{
                color: 'common.white',
                typography: 'body2',
                fontWeight: 'bold',
              }}
            >
              {memberDetails?.member_id || '--'}
            </Typography>
          </Stack>
          {createdAt && (
            <Stack width="auto">
              <Typography variant="caption" color="#ffd37f">
                JOIN DATE:
              </Typography>
              <Typography
                sx={{
                  color: 'common.white',
                  typography: 'body2',
                  fontWeight: 'bold',
                }}
              >
                {createdAt ? formatDate(createdAt) : 'NA'}
              </Typography>
            </Stack>
          )}
          <Stack width="auto">
            <Typography variant="caption" color="#ffd37f">
              EXP DATE:
            </Typography>
            <Typography
              sx={{
                color: 'common.white',
                typography: 'body2',
                fontWeight: 'bold',
              }}
            >
              {expiry_date ? formatDate(expiry_date) : 'NA'}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      {isDownloadIcon && (
        <IconButton
          className="download-icon"
          sx={{
            position: 'absolute',
            right: 6,
            top: 6,
            color: 'common.white',
          }}
          onClick={handleDownloadImage}
          ref={downloadIconRef}
        >
          <Tooltip title="Download">
            <DownloadIcon />
          </Tooltip>
        </IconButton>
      )}
    </Stack>
  );
}

MemberCard.propTypes = {
  memberDetails: PropTypes.string,
  createdAt: PropTypes.string,
  name: PropTypes.string,
  username: PropTypes.string,
  cardImg: PropTypes.string,
  expiry_date: PropTypes.string,
};

/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { Box, Paper, TableRow, TableCell, CircularProgress } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import { getAllNotifications } from 'src/server/api/notification';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import NotificationTableRow from 'src/sections/notificarion/NotificationTableRow';
import NotificationModalDialog from 'src/sections/notificarion/createNotification';

// ----------------------------------------------------------------------

export default function AllNotification({ disable }) {
  const dispatch = useDispatch();
  const table = useTable({ defaultOrderBy: 'createdAt', defaultRowsPerPage: 25 });
  const confirm = useBoolean();
  const navigate = useNavigate();
  const role = useGetRoles()?.role;
  const isMobile = useResponsive('down', 'md');

  const { loading, allNotification } = useSelector((state) => ({
    loading: state.notification.loading,
    allNotification: state?.notification?.notificationList,
  }));
  console.log(allNotification);
  const identifier = 'notification';
  const storedRowsPerPage = sessionStorage.getItem(`rowsPerPage_${identifier}`);

  // const [searchText, setSearchText] = useState();
  // const [sort, setSort] = useState('');
  // const [date, setDate] = useState('');
  // console.log(setSort);
  const totalPage = allNotification?.total;
  const [openNotificationDialog, setOpenNotificationDialog] = useState({ status: false, data: {} });
  // console.log(total);

  const TABLE_HEAD = [
    { id: 'title', label: 'Title' },
    { id: 'message', label: 'Message' },
    { id: 'date', label: 'Posted On' },
    { id: 'action', label: 'Actions' },
  ];

  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      limit: disable ? 5 : storedRowsPerPage || table.rowsPerPage,
      dispatch,
    };
    dispatch(getAllNotifications(credentials));
  }, [disable, dispatch, role, storedRowsPerPage, table.page, table.rowsPerPage]);

  // useEffect(() => {
  //   setallNotification?.list(allNotification);
  // }, [allNotification]);

  const canReset = false;

  const notFound = (!allNotification?.list?.length && canReset) || !allNotification?.list?.length;

  // const handleDeleteRow = useCallback(
  //   (id) => {
  //     const deleteRow = allNotification?.list?.filter((row) => row.id !== id);
  //     enqueueSnackbar('Delete success!');
  //     setallNotification?.list(deleteRow);
  //     table.onUpdatePageDeleteRow(dataInPage?.length);
  //   },
  //   [dataInPage?.length, enqueueSnackbar, table, allNotification?.list]
  // );

  const handleDeleteRows = () => {};

  const handleEditRow = (notificationData) => {
    setOpenNotificationDialog({
      status: true,
      data: notificationData,
    });
  };

  const HandlePage = () => {
    navigate(paths.dashboard.transaction, {});
  };

  return (
    <>
      {!disable && (
        <CustomBreadcrumbs
          heading="Notifications"
          links={[
            {
              name: 'Dashboard',
              href: paths.dashboard.root,
            },
            {
              name: 'Notification',
            },
            {
              name: 'List',
            },
          ]}
          sx={{
            mb: { xs: 3, md: 2 },
            mt: 4,
            width: '100%',
          }}
          action={
            <Button
              variant="contained"
              onClick={() => setOpenNotificationDialog({ status: true })}
              sx={{
                backgroundColor: '#007867',
                color: 'white',
              }}
            >
              <AddIcon sx={{ fontSize: '20px' }} />
              Create Notification
            </Button>
          }
        />
      )}

      {openNotificationDialog && (
        <NotificationModalDialog
          setOpen={setOpenNotificationDialog}
          open={openNotificationDialog?.status}
          isUpdate={openNotificationDialog?.data}
        />
      )}

      <Stack
        width={isMobile ? '100%' : '100%'}
        // border='2px solid orange'
      >
        <Card>
          {/* <Stack p={2} direction="row" alignContent="center" justifyContent="space-between">
            <Typography variant="h6">All Notifications</Typography>

            <Button variant="contained" onClick={() => setOpenNotificationDialog({ status: true })}>
              Create Notification
            </Button>
          </Stack> */}
          {/* {!disable && ( */}
          {/* <>
            <EnquirySort setPage={setPage} sort={sort} setSort={setSort} allList={total} />
            <Stack mx={2} my={3}>
              <EnquiryToolbar
                setPage={setPage}
                role={role}
                onSearch={handleSearch}
                date={date}
                setDate={setDate}
              />
            </Stack>
          </> */}
          {/* )} */}
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected?.length}
              rowCount={allNotification?.list?.length}
              onSelectAllRows={(checked) => {
                table.onSelectAllRows(
                  checked,
                  allNotification?.list.map((row) => row.id)
                );
              }}
            />
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={allNotification?.list?.length}
                  numSelected={table.selected?.length}
                  onSort={table.onSort}
                  sx={{
                    height: 50,
                  }}
                />

                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <CircularProgress color="inherit" />
                        </Paper>
                      </TableCell>
                    </TableRow>
                  ) : allNotification?.list?.length > 0 ? (
                    (allNotification?.list || []).map((row, index) => (
                      <NotificationTableRow
                        key={index}
                        row={row}
                        selected={table?.selected?.includes(row.id)}
                        onEditRow={() => handleEditRow(row)}
                        loading={loading}
                      />
                    ))
                  ) : (
                    <TableNoData notFound={notFound} />
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
          {disable && (
            <Box sx={{ p: 2, textAlign: 'right' }}>
              <Button
                size="small"
                color="inherit"
                onClick={HandlePage}
                endIcon={<Iconify icon="eva:arrow-ios-forward-fill" width={18} sx={{ ml: -0.5 }} />}
              >
                View All
              </Button>
            </Box>
          )}
          {!disable && (
            <TablePaginationCustom
              count={totalPage || 0}
              customRowsPerPage={25}
              page={table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
              dense={table.dense}
              onChangeDense={table.onChangeDense}
              identifier={identifier}
            />
          )}
        </Card>
      </Stack>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete Notification"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          // <Button
          //   fullWidth
          //   variant="contained"
          //   sx={{
          //     backgroundColor: '#7A0916',
          //   }}
          //   onClick={() => {
          //     handleDeleteRows();
          //     confirm.onFalse();
          //   }}
          // >
          //   Delete
          // </Button>
          <Button
            fullWidth
            variant="contained"
            sx={{
              backgroundColor: '#7A0916',
            }}
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

AllNotification.propTypes = {
  disable: PropTypes.string,
};

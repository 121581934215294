/* eslint-disable import/no-unresolved */
import React from 'react';

import { Container } from '@mui/material';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import { useSettingsContext } from 'src/components/settings';

import IncentivePage from './IncentivePage';

function Incentive() {
  // const { MemberProfileDetails } = useSelector((state) => ({
  //   MemberProfileDetails: state.member.memberProfileDetails.member,
  // }));
  // console.log(MemberProfileDetails);
  const settings = useSettingsContext();
  return (
    <>
      <MetaHelmet title="Winbuks" miniDescription="Ranks" />
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <IncentivePage />
      </Container>
    </>
  );
}

export default Incentive;

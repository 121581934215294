import React from 'react';
import { useLocation } from 'react-router';

import { Container } from '@mui/material';

import { useSettingsContext } from 'src/components/settings';

import AllClubPage from './ClubPage';
import ClubMemberLists from '../ClubMember/ClubMembersPage';

function AllClub() {
  const settings = useSettingsContext();
  const location = useLocation();

  function getValueFromSearch(searchString, parameterName) {
    const searchParams = new URLSearchParams(searchString);
    return searchParams.get(parameterName);
  }
  const searchValue = getValueFromSearch(location.search, 'search');
  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      {searchValue === 'club' && <AllClubPage />}
      {searchValue === 'club-members' && <ClubMemberLists />}
    </Container>
  );
}

export default AllClub;

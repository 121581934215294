/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

// import { createEnquiry, u // console.log(isUpdate);
// const fullScreen = useMediaQuery(theme.breakpoints.down('md')); pdateEnquiryDetails } from 'src/server/api/enquiry';
import { LoadingButton } from '@mui/lab';
// components
import CloseIcon from '@mui/icons-material/Close';
// @mui
import {
  Stack,
  Dialog,
  Button,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
} from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import { memberSuspend } from 'src/server/api/member';

SuspendDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  isSuspented: PropTypes.string,
};

export default function SuspendDialog({ open, setOpen, isSuspented }) {
  const dispatch = useDispatch();

  const isMobile = useResponsive('down', 'md');
  const params = useParams();
  // console.log(isSuspented);
  const { loading } = useSelector((state) => ({
    loading: state.member.loading,
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const Submit = () => {
    const credentials = {
      dispatch,
      memberId: params?.id,
      state: {
        is_withdrawal_suspended: !isSuspented,
      },
      handleClose,
    };
    dispatch(memberSuspend(credentials));
  };

  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        // open='true'

        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', top: 0, right: 0, m: 1 }}
        >
          <CloseIcon sx={{ fontSize: '20px' }} />
        </IconButton>
        <DialogTitle id="responsive-dialog-title" sx={{ pb: 2 }}>
          {/* {isUpdate?._id ? 'Update your payment' : 'Complete your payment'} */}
          {isSuspented ? 'Unsuspend Member' : 'Suspend Member'}
        </DialogTitle>

        <DialogContent
          className="crud-modal"
          sx={{ minWidth: isMobile ? '300px' : '450px', padding: '0px' }}
        >
          <Typography variant="body2" sx={{ px: 3, color: '' }}>
            Are you sure to {isSuspented ? 'unsuspend' : 'suspend'}?
          </Typography>
          <Stack alignItems="end">
            <Stack
              mx={2}
              py={2}
              mb=""
              direction="row"
              alignItems="center"
              width="fit-content"
              gap={1}
            >
              <Button variant="outlined" color="inherit" onClick={handleClose}>
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                onClick={Submit}
                variant="contained"
                loading={loading}
                sx={{ ml: 'auto' }}
                color="success"
                size="medium"
              >
                {isSuspented ? 'Unsuspend ' : 'Suspend '}
              </LoadingButton>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}

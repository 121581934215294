import * as React from 'react';
import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack';
import CopyToClipboard from 'react-copy-to-clipboard';

// components
import CloseIcon from '@mui/icons-material/Close';
// @mui
import {
  Box,
  Card,
  Stack,
  Dialog,
  Button,
  CardMedia,
  IconButton,
  Typography,
  DialogContent,
} from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

import { frontEndUrl } from 'src/server/api/http';
import referralImg from 'src/assets/other/referral-img.png';

import { useSettingsContext } from 'src/components/settings';

// import { createEnquiry, updateEnquiryDetails } from 'src/server/api/enquiry';

ReferralModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  member: PropTypes.any,
};

export default function ReferralModal({ open, setOpen, member }) {
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useResponsive('down', 'md');
  const { themeMode } = useSettingsContext();

  // const { role } = useGetRoles();
  // console.log(isUpdate);

  const handleClose = () => {
    setOpen(false);
  };
  // const router = useRouter();

  const handleCopy = () => {
    enqueueSnackbar('Link copied!', { variant: 'success' });
  };

  const truncateText = (text, maxLength) =>
    text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            backgroundColor: themeMode === 'dark' ? '' : '#DFE3E8',
            '&:hover': {
              backgroundColor: themeMode === 'dark' ? '' : 'rgba(223, 227, 232, 1)',
            },
          }}
        >
          <CloseIcon sx={{ fontSize: '18px' }} />
        </IconButton>

        <DialogContent sx={{ minWidth: isMobile ? '250px' : '480px', p: 4 }}>
          <Box pb={2}>
            <CardMedia
              src={referralImg}
              component="img"
              height={148}
              width={185}
              sx={{
                objectFit: 'contain',
              }}
            />
          </Box>
          <Stack textAlign="center">
            <Typography
              fontWeight={700}
              fontSize={14}
              color={themeMode === 'dark' ? '' : 'rgba(33, 43, 54, 1)'}
              lineHeight="22px"
              pb={1}
            >
              Refer friends and start earning.
            </Typography>
            <Typography
              pb={2.5}
              fontSize={12}
              fontWeight={400}
              lineHeight="18px"
              color="text.secondary"
            >
              Refer{' '}
              <b
                style={{
                  color: themeMode === 'dark' ? '' : 'rgba(33, 43, 54, 1)',
                  fontWeight: 500,
                }}
              >
                3{' '}
              </b>{' '}
              members to become club member and earn <br /> up to{' '}
              <b
                style={{
                  color: themeMode === 'dark' ? '' : 'rgba(33, 43, 54, 1)',
                  fontWeight: 500,
                }}
              >
                32100
              </b>{' '}
              USDT.
            </Typography>
          </Stack>
          <Stack px={3}>
            <Card
              variant="outlined"
              sx={{
                backgroundColor: themeMode === 'dark' ? '' : 'rgba(245, 245, 245, 1)',
                color: 'rgba(99, 115, 129, 1)',
                borderRadius: '9px',
                px: 1,
                py: 0.5,
                boxShadow: 'none',
              }}
            >
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography
                  fontSize={14}
                  color="rgba(99, 115, 129, 1)"
                  lineHeight="22px"
                  fontWeight={400}
                >
                  {truncateText(`${frontEndUrl}${paths.auth.register}?ref_id=${member}`, 42)}
                </Typography>
                <CopyToClipboard
                  text={`${frontEndUrl}${paths.auth.register}?ref_id=${member}`}
                  onCopy={handleCopy}
                  sx={{
                    backgroundColor: 'rgba(0, 120, 103, 1)',
                    borderRadius: '48px',
                    color: themeMode === 'dark' && 'white',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 120, 103, 1)',
                    },
                  }}
                >
                  <Button variant="contained" size="small">
                    Copy
                  </Button>
                </CopyToClipboard>
              </Box>
            </Card>
            <Typography
              variant="caption"
              color="text.disabled"
              fontWeight={400}
              lineHeight="18px"
              py={1.1}
            >
              Or share on{' '}
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(`${frontEndUrl}${paths.auth.register}?ref_id=${member}`)}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: 'none',
                }}
              >
                <Button
                  sx={{
                    backgroundColor: 'rgba(53, 102, 165, 1)',
                    width: '175px',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'rgba(53, 102, 165, 1)',
                    },
                    borderRadius: '9px',
                  }}
                >
                  Facebook
                </Button>
              </a>
              <a
                style={{
                  textDecoration: 'none',
                }}
                target="_blank"
                rel="noopener noreferrer"
                href={`https://api.whatsapp.com/send?text=${`${frontEndUrl}${paths.auth.register}?ref_id=${member}`}`}
              >
                <Button
                  sx={{
                    backgroundColor: 'rgba(42, 163, 86, 1)',
                    width: '175px',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'rgba(42, 163, 86, 1)',
                    },
                    borderRadius: '9px',
                  }}
                >
                  Whatsapp
                </Button>
              </a>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import { createSlice } from '@reduxjs/toolkit';

import { getAllEnquiry, getAllReferrals, updateEnquiryStatus } from '../api/referrals';

export const referralSlice = createSlice({
  name: 'referral',
  initialState: {
    loading: false,
    error: {},
    referrals: {},
    allEnquiry: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // get all referrals
    [getAllReferrals.pending]: (state) => {
      state.loading = true;
    },
    [getAllReferrals.fulfilled]: (state, action) => {
      state.loading = false;
      state.referrals = action.payload;
      state.error = {};
    },
    [getAllReferrals.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // enquiry

    [getAllEnquiry.pending]: (state) => {
      state.loading = true;
    },
    [getAllEnquiry.fulfilled]: (state, action) => {
      state.loading = false;
      state.allEnquiry = action.payload;
      state.error = {};
    },
    [getAllEnquiry.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateEnquiryStatus.pending]: (state) => {
      state.loading = true;
    },
    [updateEnquiryStatus.fulfilled]: (state, action) => {
      state.loading = false;

      state.allEnquiry = action?.payload;
      state.error = {};
    },
    // [updateEnquiryStatus.fulfilled]: (state, action) => {
    //   const jsonState = covertToJSON(state)?.walletAddresses;
    //   console.log(jsonState);
    //   const updateNewList = jsonState?.wallet_address;
    //   const modifiedList = {
    //     ...updateNewList,
    //     walletAddresses: updateNewList?.map((i) =>
    //       i._id === action.payload._id ? action?.payload : i
    //     ),
    //   };
    //   console.log(modifiedList);
    //   state.loading = false;
    //   // state.walletAddresses = modifiedList;
    //   state.walletAddressDetails = action.payload;
    //   state.walletAddresses.wallet_address = modifiedList?.walletAddresses;
    //   state.error = {};
    // },
    [updateEnquiryStatus.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = referralSlice.actions;

export default referralSlice.reducer;

import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Typography, ListItemText } from '@mui/material';

import { USDT } from 'src/hooks/use-USDT';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import { fDate, fTime } from 'src/utils/format-time';

import Label from 'src/components/label/label';
import TextMaxLine from 'src/components/text-max-line/text-max-line';

// ----------------------------------------------------------------------

export default function TransactionTableRowAdmin({ row, selected }) {
  const role = useGetRoles()?.role;
  // const settings = useSettingsContext();

  const { createdAt, amount, description, wallet_type, transaction_type } = row;

  const handleTransactionWalletType = (wallet_type_item) => {
    switch (wallet_type_item) {
      case 'CATALYST':
        return {
          color: 'info',
          label: 'Catalyst',
        };
      case 'COMPANY':
        return {
          color: 'primary',
          label: 'Company',
        };
      case 'CLUB':
        return {
          color: 'secondary',
          label: 'Club',
        };
      case 'CONNECT':
        return {
          color: 'success',
          label: 'Connect',
        };
      case 'MEMBER':
        return {
          color: 'warning',
          label: 'Member',
        };
      case 'MAINTENANCE':
        return {
          color: 'info',
          label: 'Maintenance',
        };
      case 'FUTURE_PROJECTS':
        return {
          color: 'success',
          label: 'Future Projects',
        };
      case 'ACTUAL':
        return {
          color: 'primary',
          label: 'Actual',
        };
      case 'FOURTH':
        return {
          color: 'error',
          label: 'Fourth',
        };
      case 'INCENTIVE':
        return {
          color: 'error',
          label: 'Incentive',
        };
      case 'BUKS':
        return {
          color: 'success',
          label: 'Buks',
        };
      case 'W2W':
        return {
          color: 'info',
          label: 'W2W',
        };
      case 'LEVERAGE':
        return {
          color: 'info',
          label: 'Leverage',
        };
      case 'DIRECT':
        return {
          color: 'info',
          label: 'Direct',
        };

      default:
        return {
          color: 'default',
          label: '',
        };
    }
  };

  // console.log(settings);
  return (
    <TableRow
      hover
      selected={selected}
      sx={{
        // backgroundColor:
        //   transaction_type === 'CREDIT' && settings?.themeMode !== 'dark' ? '#ebf8f3' : '',
        height: 2,
      }}
    >
      <TableCell>
        <ListItemText
          primary={fDate(createdAt)}
          secondary={fTime(createdAt)}
          primaryTypographyProps={{ variant: 'caption', noWrap: true }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            variant: 'caption',
          }}
        />
      </TableCell>
      <TableCell sx={{ minWidth: '250px' }}>
        <TextMaxLine line={2}>
          <Typography variant="caption">{description || '---'}</Typography>
        </TextMaxLine>
      </TableCell>
      {/* <TableCell>
        <Typography fontWeight="600" fontSize="14px">{`${amount?.toFixed(2)} ${USDT}`}</Typography>
      </TableCell> */}

      {role === 'admin' && (
        <TableCell>
          <Label
            // variant="outlined"
            variant="soft"
            color={handleTransactionWalletType(wallet_type)?.color}
          >
            {handleTransactionWalletType(wallet_type)?.label}
          </Label>
        </TableCell>
      )}
      {/* <TableCell>
        <Label
          variant="soft"
          color={getColorByTransactionType()}
          sx={{ textTransform: 'camelcase' }}
        >
          {handleTransactionType()}
        </Label>
      </TableCell> */}
      <TableCell>
        <Typography variant="caption" color="#007867">
          {transaction_type === 'CREDIT' && `${amount?.toFixed(2)} ${USDT}`}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="caption" color="error">
          {transaction_type === 'DEBIT' && `${amount?.toFixed(2)} ${USDT}`}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

TransactionTableRowAdmin.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
};

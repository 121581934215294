/* eslint-disable import/no-unresolved */
import * as React from 'react';
import PropTypes from 'prop-types';
// import { createEnquiry, updateEnquiryDetails } from 'src/server/api/enquiry';

// components
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
// @mui
import { Stack, Dialog, CardMedia, IconButton, DialogTitle, DialogContent } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

// import CrudModalContent from './CrudModalContent';

ImageShowDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  image: PropTypes.any,
};

export default function ImageShowDialog({ open, setOpen, image }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useResponsive('down', 'md');

  // console.log(loading);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" sx={{}}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', top: 2, right: 5, mt: 2 }}
          >
            <CloseIcon sx={{ fontSize: '20px' }} />
          </IconButton>
          Image
        </DialogTitle>
        <DialogContent
          className="crud-modal"
          sx={{ minWidth: isMobile ? '200px' : '450px', padding: '0px' }}
        >
          <Stack>
            <CardMedia component="img" src={image} height={400} width={400} />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}

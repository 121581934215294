/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import SavingsIcon from '@mui/icons-material/Savings';
import TableContainer from '@mui/material/TableContainer';
import {
  Box,
  Grid,
  Paper,
  Avatar,
  Divider,
  TableRow,
  Skeleton,
  TableCell,
  CardMedia,
  TextField,
  Typography,
  InputAdornment,
  CircularProgress,
} from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import cup2 from 'src/assets/other/cup.png';
import cup1 from 'src/assets/other/cup-2.png';
import cup3 from 'src/assets/other/cup-3.png';
import cupOther from 'src/assets/other/other.png';
import { decimalLimit } from 'src/server/api/http';
import trophy1 from 'src/assets/other/trophy-1.png';
import trophy2 from 'src/assets/other/trophy-2.png';
import trophy3 from 'src/assets/other/trophy-3.png';
import { getAllMemberLeaderboard, getAllMemberLeaderboardStatic } from 'src/server/api/member';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import LeaderboardTableRow from 'src/sections/leaderboard/LeaderboardTableRow';

// ----------------------------------------------------------------------

export default function AllLeaderboard({ disable }) {
  const dispatch = useDispatch();
  const table = useTable({ defaultOrderBy: 'createdAt', defaultRowsPerPage: 25 });
  const confirm = useBoolean();
  const navigate = useNavigate();
  const { role } = useGetRoles();
  const [searchText, setSearchText] = useState('');
  const isMobile = useResponsive('down', 'md');

  const { staticLoading, allLeaderboardListStatic, loading, allLeaderboardList, memberDetails } =
    useSelector((state) => ({
      loading: state.member.leaderBoardLoading,
      staticLoading: state.member.leaderBoardStaticLoading,
      allLeaderboardList: state?.member?.memberLeaderboard,
      allLeaderboardListStatic: state?.member?.memberLeaderboardStatic,
      memberDetails: state?.member?.memberProfileDetails?.member,
    }));
  console.log(allLeaderboardList);
  const identifier = 'leaderboard';
  const storedRowsPerPage = sessionStorage.getItem(`rowsPerPage_${identifier}`);

  // const [searchText, setSearchText] = useState();
  // const [sort, setSort] = useState('');
  // const [date, setDate] = useState('');
  // console.log(setSort);
  const totalPage = allLeaderboardList?.total;
  // console.log(total);

  const TABLE_HEAD = [
    { id: 'rank', label: 'Rank' },
    { id: 'name', label: 'Name' },
    { id: 'memberId', label: 'Member ID' },
    { id: 'total_earnings', label: 'Total Earnings' },
  ];

  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      limit: disable ? 5 : storedRowsPerPage || table.rowsPerPage,
      dispatch,
      search: searchText,
    };
    dispatch(getAllMemberLeaderboard(credentials));
  }, [disable, dispatch, role, searchText, storedRowsPerPage, table.page, table.rowsPerPage]);

  useEffect(() => {
    const credentials = {
      dispatch,
      limit: totalPage,
    };
    dispatch(getAllMemberLeaderboardStatic(credentials));
  }, [dispatch, totalPage]);

  // useEffect(() => {
  //   setallNotification?.list(allNotification);
  // }, [allNotification]);

  const canReset = false;

  const notFound =
    (!allLeaderboardList?.list?.length && canReset) || !allLeaderboardList?.list?.length;

  // const handleDeleteRow = useCallback(
  //   (id) => {
  //     const deleteRow = allNotification?.list?.filter((row) => row.id !== id);
  //     enqueueSnackbar('Delete success!');
  //     setallNotification?.list(deleteRow);
  //     table.onUpdatePageDeleteRow(dataInPage?.length);
  //   },
  //   [dataInPage?.length, enqueueSnackbar, table, allNotification?.list]
  // );

  const handleDeleteRows = () => {};

  const HandlePage = () => {
    navigate(paths.dashboard.transaction, {});
  };

  const getInitials = (title) => {
    const names = title?.split(' ');
    return names?.map((n) => n.charAt(0).toUpperCase()).join('');
  };

  const handleChangelist = () => {
    const trophyImages = [trophy1, trophy2, trophy3];

    const newData = allLeaderboardListStatic?.members?.slice(0, 3)?.map((member, index) => ({
      ...member,
      trophyImg: trophyImages[index], // Add trophy image based on rank
    }));

    console.log(newData); // Verify the updated array
    return newData;
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleFilterMemberRankAndEarnings = () =>
    allLeaderboardListStatic?.members?.filter(
      (member) => member?._id === memberDetails?._id && member
    );

  const memberRank = handleFilterMemberRankAndEarnings()?.[0];

  console.log(allLeaderboardListStatic);
  console.log(memberDetails);
  console.log(handleFilterMemberRankAndEarnings());
  console.log(memberRank);

  const handleRankImg = () => {
    switch (memberRank?.rank) {
      case 1:
        return cup1;
      case 2:
        return cup2;
      case 3:
        return cup3;
      default:
        return '';
    }
  };

  return (
    <>
      {!disable && (
        <CustomBreadcrumbs
          heading="Leaderboard"
          links={[
            {
              name: 'Dashboard',
              href: paths.dashboard.root,
            },
            {
              name: 'Leaderboard',
            },
            {
              name: 'List',
            },
          ]}
          sx={{
            mb: { xs: 3, md: 7 },
            mt: 4,
            width: '80%',
          }}
        />
      )}

      <Stack width={isMobile ? '100%' : '80%'} px={isMobile ? 2 : ''}>
        {staticLoading ? (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Skeleton
              variant="rectangular"
              width="33%"
              height={200}
              sx={{ borderRadius: 2, mt: 4 }}
            />
            <Skeleton
              variant="rectangular"
              width="33%"
              height={200}
              sx={{ borderRadius: 2, mt: 4 }}
            />
            <Skeleton
              variant="rectangular"
              width="33%"
              height={200}
              sx={{ borderRadius: 2, mt: 4 }}
            />
          </Stack>
        ) : (
          <MemberLeaderboardList
            handleChangelist={handleChangelist}
            loading={staticLoading}
            getInitials={getInitials}
            isMobile={isMobile}
          />
        )}

        <Card
          sx={{
            mt: 2,
          }}
        >
          {staticLoading ? (
            <Skeleton
              variant="rectangular"
              width="96%"
              height={150}
              sx={{ borderRadius: 2, marginLeft: 2, marginRight: 3, mt: 2 }}
            />
          ) : (
            <>
              {memberRank?.rank > 3 ? (
                // after rank 3
                <MemberLeaderBoardRanking memberRank={memberRank} isMobile={isMobile} />
              ) : (
                <HighRankingLeaderboard
                  memberRank={memberRank}
                  handleRankImg={handleRankImg}
                  isMobile={isMobile}
                />
              )}
            </>
          )}

          <Stack px={2} py={2}>
            <TextField
              sx={{ width: '100%' }}
              onChange={(e) => {
                handleSearch(e.target.value);
                table.setPage(0);
              }}
              placeholder="Search "
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ ml: 1, color: 'text.disabled' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected?.length}
              rowCount={allLeaderboardList?.members?.length}
              onSelectAllRows={(checked) => {
                table.onSelectAllRows(
                  checked,
                  allLeaderboardList?.members.map((row) => row.id)
                );
              }}
            />
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={allLeaderboardList?.members?.length}
                  numSelected={table.selected?.length}
                  onSort={table.onSort}
                  sx={{
                    height: 50,
                  }}
                />

                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <CircularProgress color="inherit" />
                        </Paper>
                      </TableCell>
                    </TableRow>
                  ) : allLeaderboardList?.members?.length > 0 ? (
                    (allLeaderboardList?.members?.slice(0, 100) || []).map((row, index) => (
                      <LeaderboardTableRow
                        key={index}
                        row={row}
                        selected={table?.selected?.includes(row.id)}
                        // onEditRow={() => handleEditRow(row)}
                        loading={loading}
                        markPosition={
                          handleFilterMemberRankAndEarnings()?.length > 0 &&
                          handleFilterMemberRankAndEarnings()[0]?._id
                        }
                      />
                    ))
                  ) : (
                    <TableNoData notFound={notFound} />
                  )}
                </TableBody>

                {/* <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, allNotification?.list?.length)}
                />  */}
              </Table>
            </Scrollbar>
          </TableContainer>
          {disable && (
            <Box sx={{ p: 2, textAlign: 'right' }}>
              <Button
                size="small"
                color="inherit"
                onClick={HandlePage}
                endIcon={<Iconify icon="eva:arrow-ios-forward-fill" width={18} sx={{ ml: -0.5 }} />}
              >
                View All
              </Button>
            </Box>
          )}
          {!disable && (
            <TablePaginationCustom
              count={totalPage || 0}
              customRowsPerPage={25}
              page={table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
              dense={table.dense}
              onChangeDense={table.onChangeDense}
              identifier={identifier}
            />
          )}
        </Card>
      </Stack>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

AllLeaderboard.propTypes = {
  disable: PropTypes.string,
};

const MemberLeaderboardList = ({ loading, handleChangelist, getInitials, isMobile }) => (
  <Stack spacing={3} alignItems="center">
    <Grid container justifyContent="center" spacing={2}>
      {handleChangelist()?.map((item, key) => (
        <Grid item md={4} sm={6} xs={12} key={key} mt={isMobile && 2}>
          <Stack
            sx={{
              border: '1px solid #DFE3E8',
              borderRadius: 2,
              // padding: 3,
              position: 'relative',
              alignItems: 'center',
              textAlign: 'center',
              pt: 3,
            }}
          >
            {loading ? (
              <Skeleton variant="rectangular" width="100%" height={200} sx={{ borderRadius: 2 }} />
            ) : (
              <>
                <CardMedia
                  component="img"
                  src={item?.trophyImg}
                  sx={{
                    height: 60,
                    width: 60,
                    position: 'absolute',
                    top: -30,
                    left: -28,
                  }}
                />

                <Avatar
                  sx={{
                    width: 60,
                    height: 60,
                    backgroundColor: key === 1 ? '#8C63FF' : key === 2 ? '#FF3333' : '#00A76F',
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: 18,
                    marginBottom: 1.5,
                    position: 'absolute',
                    borderRadius: 1.5,
                    top: -20,
                    border: '3px solid #DFE3E8',
                  }}
                >
                  {getInitials(item?.name)}
                </Avatar>

                <Typography
                  variant="h6"
                  fontWeight="bold"
                  pt={7}
                  sx={{
                    textTransform: 'capitalize',
                  }}
                >
                  {item?.name}
                </Typography>

                <Divider
                  sx={{
                    width: '100%',
                    borderColor: '#DFE3E8', // Adjust color if needed
                    py: 1.5,
                  }}
                />

                <Stack direction="row" justifyContent="space-between" width="100%" sx={{ my: 3 }}>
                  <Stack>
                    <Typography variant="body2" color="text.secondary">
                      Member ID
                    </Typography>
                    <Typography variant="body2">{item?.member_id}</Typography>
                  </Stack>
                  <Stack>
                    <Typography variant="body2" color="text.secondary">
                      Earnings
                    </Typography>
                    <Typography
                      variant="body2"
                      // fontWeight="bold" // Set a specific fontWeight here
                      sx={{
                        color: '#00A76F',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <SavingsIcon fontSize="small" />
                      &nbsp;
                      {item?.earnings_total?.toFixed(decimalLimit)} USDT
                    </Typography>
                  </Stack>
                </Stack>
              </>
            )}
          </Stack>
        </Grid>
      ))}
    </Grid>
  </Stack>
);

MemberLeaderboardList.propTypes = {
  loading: PropTypes.any,
  handleChangelist: PropTypes.any,
  getInitials: PropTypes.any,
  isMobile: PropTypes.any,
};

const MemberLeaderBoardRanking = ({ memberRank, isMobile }) => (
  <Box
    sx={{
      backgroundColor: '#3B8545',
      borderRadius: '12px',
      color: '#fff',
      height: isMobile ? 100 : '150px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      pl: 3,
      py: 2,
      gap: 2,
      m: 2,
    }}
  >
    <Box sx={{ flex: 1 }}>
      <Typography variant="subtitle2">Your Rank & Earnings</Typography>
      <Typography variant="h3" fontWeight="bold" sx={{ margin: '8px 0', fontSize: '36px' }}>
        #{memberRank?.rank}
      </Typography>
      <Box
        sx={{
          backgroundColor: '#367A40',
          borderRadius: '8px',
          px: 2,
          py: 1,
          display: 'inline-block',
        }}
      >
        <Typography variant="body2" fontWeight="bold" py={0}>
          Total Earnings : {memberRank?.earnings_total?.toFixed(decimalLimit)} USDT
        </Typography>
      </Box>
    </Box>

    <Box
      sx={{
        flexShrink: 0,
        display: 'flex',
        justifyContent: '',
        alignItems: 'center',
      }}
    >
      <CardMedia
        component="img"
        src={cupOther}
        alt="Trophy"
        sx={{
          height: isMobile ? '80px' : '150px',
          width: isMobile ? '50px' : 'auto',
          objectFit: 'contain',
        }}
      />
    </Box>
  </Box>
);
MemberLeaderBoardRanking.propTypes = {
  memberRank: PropTypes.any,
  isMobile: PropTypes.any,
};

const HighRankingLeaderboard = ({ memberRank, handleRankImg, isMobile }) => (
  <Box
    sx={{
      backgroundColor: '#316DEF',
      borderRadius: '12px',
      color: '#fff',
      height: { xs: 'auto', sm: '150px' },
      display: 'flex',
      flexDirection: { xs: 'column', sm: 'row' },
      alignItems: 'center',
      px: { xs: 2, sm: 3 },
      py: 2,
      gap: { xs: 2, sm: 2 },
      m: 2,
    }}
  >
    {/* Rank and Earnings Section */}
    <Box sx={{ flex: 1, textAlign: { xs: 'center', sm: 'left' } }}>
      <Typography variant="subtitle2">Your Rank & Earnings</Typography>
      <Typography
        variant="h3"
        fontWeight="bold"
        sx={{
          margin: '8px 0',
          fontSize: { xs: '25px', sm: '36px' },
        }}
      >
        #{memberRank?.rank}
      </Typography>
      <Box
        sx={{
          backgroundColor: '#2B61D4',
          borderRadius: '8px',
          px: 2,
          py: 1,
          display: 'inline-block',
        }}
      >
        <Typography fontSize={{ xs: '10px', sm: '13px' }} fontWeight="bold">
          Total Earnings : {memberRank?.earnings_total.toFixed(decimalLimit)} USDT
        </Typography>
      </Box>
    </Box>

    {/* Image Section */}
    <Box
      sx={{
        flexShrink: 0,
        display: 'flex',
        justifyContent: { xs: 'center', sm: 'start' },
        alignItems: 'center',
        pl: { sm: 3.5 },
      }}
    >
      <CardMedia
        component="img"
        src={handleRankImg()}
        alt="Trophy"
        sx={{
          height: { xs: 100, sm: '150px' },
          width: { xs: 100, sm: '190px' },
        }}
      />
    </Box>

    {/* Congratulations Section */}
    <Box
      sx={{
        flex: 1,
        px: { xs: 0, sm: 0 },
        pl: { xs: 0, sm: 14 },
        pr: { xs: 2, sm: 2 },
        textAlign: { xs: 'center', sm: 'left' },
      }}
    >
      <Typography
        fontWeight="bold"
        sx={{
          marginBottom: '0px',
          fontSize: { xs: '20px', sm: '24px' },
        }}
      >
        Congratulations
      </Typography>
      <Typography
        sx={{
          lineHeight: 1.2,
          fontSize: { xs: '12px', sm: '14px' },
        }}
      >
        You`re leading the leaderboard, but don`t stop now, stay active to keep your top rank!
      </Typography>
    </Box>
  </Box>
);

HighRankingLeaderboard.propTypes = {
  memberRank: PropTypes.any,
  handleRankImg: PropTypes.func,
  isMobile: PropTypes.any,
};

import * as React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// components
import CloseIcon from '@mui/icons-material/Close';
// @mui
import { Stack, Dialog, CardMedia, IconButton, Typography, DialogContent } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import success from 'src/assets/other/success.png';

// import { createEnquiry, updateEnquiryDetails } from 'src/server/api/enquiry';

UpdateMemberEmailSuccessDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default function UpdateMemberEmailSuccessDialog({ open, setOpen }) {
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useResponsive('down', 'md');

  // const { role } = useGetRoles();
  // console.log(isUpdate);

  const { verifyNewEmail } = useSelector((state) => ({
    verifyNewEmail: state.auth.verifyNewEmail,
  }));
  const handleClose = () => {
    setOpen(false);
  };
  // const router = useRouter();

  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', top: 0, right: 0, m: 1 }}
        >
          <CloseIcon sx={{ fontSize: '20px' }} />
        </IconButton>
        {/* <DialogTitle id="responsive-dialog-title" sx={{ textAlign: 'center', color: '#22C55E' }}>
          Your withdrawal request has been sent successfully
        </DialogTitle> */}
        <DialogContent sx={{ maxWidth: isMobile ? 'auto' : '400px', padding: '0px', p: 3 }}>
          <Stack p={3} alignItems="center" textAlign="center" gap={2}>
            <CardMedia component="img" src={success} alt="qr-code" sx={{ width: '84px', mr: 1 }} />
            <Typography variant="h6">Email updated successfully!</Typography>
            <Stack>
              <Typography variant="caption" color="#637381">
                The email address for the user has been changed to{' '}
                <Typography variant="caption" color="black">
                  {' '}
                  {verifyNewEmail?.findMember?.email}{' '}
                </Typography>
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import * as React from 'react';
import PropTypes from 'prop-types';
import { useMemo, useEffect } from 'react';

// components
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// @mui
import { Box, Grid, Dialog, Button, Typography, DialogTitle, DialogContent } from '@mui/material';

// import { createEnquiry, updateEnquiryDetails } from 'src/server/api/enquiry';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import { LoadingButton } from '@mui/lab';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

import { useResponsive } from 'src/hooks/use-responsive';

import { updateClubDetails } from 'src/server/api/club';

import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';

EditClubDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  isUpdate: PropTypes.object,
};

export default function EditClubDialog({ open, setOpen, isUpdate }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useResponsive('down', 'md');

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // const isMobile = useResponsive('down', 'md');

  // const { role } = useGetRoles();
  // console.log(isUpdate);

  const UpdateSchema = Yup.object().shape({
    limit_amount: Yup.number(),
    title: Yup.string(),
  });

  const defaultValues = useMemo(
    () => ({
      title: isUpdate?.club?.title || '',
      limit_amount: isUpdate?.club?.limit_amount || '',
    }),
    [isUpdate]
  );
  const methods = useForm({
    resolver: yupResolver(UpdateSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = handleSubmit(async (data) => {
    // console.log(data);
    const credentialsUpdate = {
      state: {
        ...data,
        // username: userNameCheck,
      },
      dispatch,
      handleClose,
      clubId: isUpdate?.club?._id,

      // handleClear,
    };
    // if () {
    //   const credentialsUpdate = {
    //     state: {
    //       ...data,
    //       is_active: isActive,s
    //       is_verified: isVerified,
    //       is_paid: isPaid,
    //     },
    //     dispatch,

    //     navigate,
    //   };
    // dispatch(updateMemberDetails(credentialsUpdate));
    dispatch(updateClubDetails(credentialsUpdate));
  });

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Update club details</DialogTitle>
        <DialogContent sx={{ minWidth: isMobile ? 'auto' : '580px', padding: '30px' }}>
          <Typography
            color="#637381"
            display="flex"
            alignItems="center"
            fontSize="12px"
            paddingBottom={3}
            px={1}
          >
            <InfoRoundedIcon sx={{ fontSize: 14 }} /> &nbsp; Title of this club can be changed, and
            it will automatically update across all panels.
          </Typography>
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Grid container>
              <Grid item md={6} xs={12}>
                <RHFTextField name="title" label="Title" type="text" pattern="^[A-Za-z\s]+$" />
              </Grid>
              <Grid item md={6} pl={isMobile ? 0 : 1} xs={12} pt={isMobile && 2}>
                <RHFTextField
                  name="limit_amount"
                  label="Limit amount"
                  type="text"
                  pattern="^[A-Za-z\s]+$"
                  disabled
                />
              </Grid>
            </Grid>
            <Box display="flex" alignItems="center" justifyContent="end" pt={3}>
              <Button
                variant="outlined"
                onClick={handleClose}
                sx={{
                  mr: 1,
                  px: isMobile ? 0 : 2,
                  backgroundColor: '#fff',
                  '&:hover': {
                    backgroundColor: '#fff',
                  },
                  color: '#000',
                }}
              >
                Close
              </Button>
              <LoadingButton
                variant="contained"
                color="success"
                type="submit"
                sx={{ px: 3, mr: 1 }}
                loading={isSubmitting}
              >
                Save
              </LoadingButton>
            </Box>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}

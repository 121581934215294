import * as Yup from 'yup';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

// import { createEnquiry, updateEnquiryDetails } from 'src/server/api/enquiry';
import { LoadingButton } from '@mui/lab';
// components
import CloseIcon from '@mui/icons-material/Close';
import { DateRangeIcon } from '@mui/x-date-pickers';
// @mui
import {
  Stack,
  Dialog,
  TextField,
  IconButton,
  DialogTitle,
  DialogContent,
  InputAdornment,
} from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import { updateMemberDetails } from 'src/server/api/member';

import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';

// import CrudModalContent from './CrudModalContent';

UpdateAdminModalDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  isUpdate: PropTypes.object,
  setIsUpdate: PropTypes.any,
  setTab: PropTypes.any,
  memberDetails: PropTypes.object,
};

export default function UpdateAdminModalDialog({
  open,
  setOpen,
  isUpdate,
  memberDetails,
  setIsUpdate,
  setTab,
}) {
  // const theme = useTheme();
  const dispatch = useDispatch();
  // console.log(isUpdate);
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useResponsive('down', 'md');
  const params = useParams();

  const { loading } = useSelector((state) => ({
    loading: state.member.loading,
  }));
  // console.log(error);

  const memberCreateSchema = Yup.object().shape({
    name: Yup.string().required('name is required'),
    date_of_birth: Yup.string(),
  });
  const formattedDob = memberDetails?.date_of_birth
    ? moment(memberDetails?.date_of_birth).format('YYYY-MM-DD')
    : '';

  // console.log(formattedDob);
  // console.log(memberDetails);
  const defaultValues = React.useMemo(
    () => ({
      name: memberDetails?.name || '',
      profile_photo: null,
      date_of_birth: formattedDob,
    }),
    [memberDetails?.name, formattedDob]
  );
  const methods = useForm({
    resolver: yupResolver(memberCreateSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    reset,
    register,
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);
  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = handleSubmit(async (data) => {
    // console.log(data);
    const credentialsCreate = {
      state: {
        ...data,
        id: params?.id,
      },
      handleClose,
      dispatch,
    };

    dispatch(updateMemberDetails(credentialsCreate));
  });

  // console.log(loading);

  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', top: 0, right: 0, m: 1 }}
        >
          <CloseIcon sx={{ fontSize: '20px' }} />
        </IconButton>
        <DialogTitle id="responsive-dialog-title">
          {/* {isUpdate?._id ? 'Update your payment' : 'Complete your payment'} */}
          Update Info
        </DialogTitle>
        <DialogContent
          className="crud-modal"
          sx={{ minWidth: isMobile ? '200px' : '450px', padding: '0px' }}
        >
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack p={2}>
              <RHFTextField
                name="name"
                label="Name"
                type="text"
                required
                placeholder="Enter Full name"
                InputLabelProps={{ shrink: true }}
              />
            </Stack>
            <Stack px={2} py={1}>
              <TextField
                name="date_of_birth"
                label="Date of birth"
                type="date"
                sx={{ width: '100%' }}
                {...register('date_of_birth', {
                  required: {
                    value: false,
                  },
                })}
                error={!!errors?.expiry_date}
                helperText={errors?.expiry_date?.message}
                size="medium"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DateRangeIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Stack mx={2} py={2} mb="">
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting || loading}
                sx={{ ml: 'auto' }}
                color="success"
                size="small"
              >
                Update Changes
              </LoadingButton>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}

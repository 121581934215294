/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import { Card, Grid, Typography } from '@mui/material';

import { USDT } from 'src/hooks/use-USDT';
import { useResponsive } from 'src/hooks/use-responsive';

import wallet from 'src/assets/other/wallet.png';

export default function EarningsCardMember({ MemberProfileEarnings }) {
  // console.log(MemberProfileEarnings);

  const isMobile = useResponsive('down', 'md');

  const earnings = [
    {
      label: 'Catalyst Earnings',
      value: MemberProfileEarnings?.member?.earnings_statistics?.catalyst_total,
    },
    {
      label: 'Connect Earnings',
      value: MemberProfileEarnings?.member?.earnings_statistics?.line_total,
    },
    {
      label: 'Fourth Earnings',
      value: MemberProfileEarnings?.member?.earnings_statistics?.fourth_total,
    },
    {
      label: 'Club Earnings',
      value: MemberProfileEarnings?.member?.earnings_statistics?.club_total,
    },
    {
      label: 'Incentive Earnings',
      value: MemberProfileEarnings?.member?.earnings_statistics?.incentive_total,
    },
  ];

  return (
    <>
      <Stack mt={4} mb={2}>
        <Typography variant="h6">Your Earnings</Typography>
      </Stack>
      <Stack width="100%" direction={isMobile ? 'column' : 'row'} gap={2}>
        <Card variant="outlined" p={2} sx={{ flex: 3, boxShadow: 'unset', borderRadius: '15px' }}>
          <Stack
            direction="column"
            gap={2}
            sx={{
              p: 3,
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Stack
              p={2}
              backgroundColor="#f0f0f0"
              borderRadius="50%"
              justifyContent="center"
              alignItems="center"
              width="60px"
              height="60px"
            >
              <img
                src={wallet}
                alt="wallet"
                style={{ width: '40px', height: '40px', objectFit: 'cover' }}
              />
            </Stack>
            <Stack alignItems="baseline" justifyContent="center">
              <Typography variant="body2" fontWeight="bold">
                {MemberProfileEarnings?.member?.earnings_statistics?.net_earnings_total?.toFixed(
                  2
                ) || 0}{' '}
                {USDT}
              </Typography>
              <Typography
                sx={{
                  typography: 'caption',
                  opacity: 0.68,
                }}
              >
                Net Earnings{' '}
              </Typography>
            </Stack>
          </Stack>
        </Card>
        <Card
          variant="outlined"
          direction={{ xs: 'row', md: 'row' }}
          justifyContent="space-between"
          sx={{
            flex: 8,
            p: 2,
            width: '100%',
            boxShadow: 'unset',
            borderRadius: '15px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Grid container spacing={2}>
            {earnings.map((item, index) => (
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                key={index}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Stack alignItems="center" justifyContent="center">
                  <Typography variant="body2" fontWeight="bold">
                    {item.value?.toFixed(2) || 0} {USDT}
                  </Typography>
                  <Typography sx={{ typography: 'caption', opacity: 0.68 }}>
                    {item.label}
                  </Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Card>
      </Stack>
    </>
  );
}

EarningsCardMember.propTypes = {
  MemberProfileEarnings: PropTypes.string,
};

/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';

import { Box, Grid, Stack, TextField, Autocomplete } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

// ----------------------------------------------------------------------

export default function TransactionToolbar({
  role,
  walletTypeSelect,
  walletTypeOption,
  memberOption,
  onChange,
  onSearchMember,
  date,
  setDate,
  isShow,
  memberSingle,
  setPage,
  type,
}) {
  const isMobile = useResponsive('down', 'md');

  return (
    <>
      {type ? (
        <Box
          direction={isMobile ? 'column' : 'row'}
          // alignItems={isMobile ? 'start' : 'center'}
          // spacing={2}
        >
          <Stack direction="row" pl={isMobile && 1}>
            <TextField
              name="date"
              label="Start Date"
              sx={{ width: '100%', minWidth: isMobile && 120, mr: 2 }}
              size="medium"
              type="date"
              variant="outlined"
              inputProps={{ maxLength: 4 }}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setDate({
                  ...date,
                  from: e.target.value,
                });
                setPage();
              }}
            />
            <TextField
              name="date"
              label="End Date"
              sx={{ width: '100%', minWidth: isMobile && 120 }}
              size="medium"
              type="date"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setDate({
                  ...date,
                  to: e.target.value,
                });
                setPage();
              }}
            />
          </Stack>
        </Box>
      ) : (
        <Stack
          direction={isMobile ? 'column' : 'row'}
          alignItems={isMobile ? 'start' : 'center'}
          spacing={2}
        >
          <Stack direction="row" pl={isMobile && 1}>
            <TextField
              name="date"
              label="Start Date"
              sx={{ width: '100%', minWidth: isMobile && 120, mr: 2 }}
              size="medium"
              type="date"
              variant="outlined"
              inputProps={{ maxLength: 4 }}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setDate({
                  ...date,
                  from: e.target.value,
                });
                setPage();
              }}
            />
            <TextField
              name="date"
              label="End Date"
              sx={{ width: '100%', minWidth: isMobile && 120 }}
              size="medium"
              type="date"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setDate({
                  ...date,
                  to: e.target.value,
                });
                setPage();
              }}
            />
          </Stack>

          {role === 'admin' && (
            <Grid container>
              {!memberSingle && (
                <Grid item pl={1} width="25%" xs={6} md={4}>
                  <Autocomplete
                    id="country-select-demo"
                    // sx={{ width: '350%' }}
                    options={memberOption}
                    autoHighlight
                    size="medium"
                    // className="small-select"
                    getOptionLabel={(option) => option.label}
                    onChange={onChange}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.label}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Members"
                        onChange={onSearchMember}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                      />
                    )}
                  />
                </Grid>
              )}

              {isShow === undefined && (
                <Grid item pl={1} width="25%" xs={12} md={6} pt={isMobile && 1}>
                  <Autocomplete
                    id="country-select-demo"
                    // sx={{ width: '350%' }}
                    options={walletTypeOption}
                    autoHighlight
                    size="medium"
                    // className="small-select"
                    getOptionLabel={(option) => option.label}
                    onChange={walletTypeSelect}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.label}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Wallet Type"
                        placeholder="Select Wallet Type"
                        // onChange={}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          )}
          {/* <TextField
        onChange={(e) => onSearch(e.target.value)}
        placeholder="Search..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ ml: 1, color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
      /> */}
        </Stack>
      )}
    </>
  );
}

TransactionToolbar.propTypes = {
  walletTypeOption: PropTypes.array,
  walletTypeSelect: PropTypes.func,
  onSearch: PropTypes.any,
  date: PropTypes.any,
  setDate: PropTypes.any,
  onSearchMember: PropTypes.string,
  onChange: PropTypes.func,
  memberOption: PropTypes.array,
  role: PropTypes.string,
  isShow: PropTypes.string,
  memberSingle: PropTypes.string,
  setPage: PropTypes.any,
  type: PropTypes.any,
};

import { paths } from 'src/routes/routes/paths';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: paths?.public?.landing,
  },
  {
    title: 'Plan',
    icon: <Iconify icon="solar:atom-bold-duotone" />,
    path: paths.public.plan,
  },
  {
    title: 'Contact',
    icon: <Iconify icon="solar:file-bold-duotone" />,
    path: paths.public.contact,
  },
];

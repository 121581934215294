import PropTypes from 'prop-types';

import { Stack, TextField, InputAdornment } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function EnquiryToolbar({ onSearch, date, setDate, setPage }) {
  const isMobile = useResponsive('down', 'md');

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Stack direction="row">
          <TextField
            name="date"
            label="Start Date"
            sx={{ width: '100%', minWidth: isMobile && 150, mr: 2 }}
            size="medium"
            type="date"
            variant="outlined"
            inputProps={{ maxLength: 4 }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setDate({
                ...date,
                from: e.target.value,
              });
              setPage();
            }}
          />
          <TextField
            name="date"
            label="End Date"
            sx={{ width: '100%', minWidth: isMobile && 145 }}
            size="medium"
            type="date"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setDate({
                ...date,
                to: e.target.value,
              });
              setPage();
            }}
          />
        </Stack>
        {!isMobile && (
          <TextField
            sx={{ width: '100%' }}
            onChange={(e) => {
              onSearch(e.target.value);
              setPage();
            }}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ ml: 1, color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />
        )}
      </Stack>
      {isMobile && (
        <TextField
          sx={{ width: '100%', pt: isMobile && 1 }}
          onChange={(e) => {
            onSearch(e.target.value);
            setPage();
          }}
          placeholder="Search..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ ml: 1, color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      )}
    </>
  );
}

EnquiryToolbar.propTypes = {
  onSearch: PropTypes.func,
  date: PropTypes.any,
  setDate: PropTypes.any,
  setPage: PropTypes.any,
};

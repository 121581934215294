import React from 'react';
import PropTypes from 'prop-types';

import { Box, Tooltip, Typography } from '@mui/material';

function TextTruncate({ text, width, color }) {
  return (
    <Box sx={{ maxWidth: width }}>
      <Tooltip title={text}>
        <Typography
          variant="subtitle4"
          noWrap
          component="div"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            color,
          }}
        >
          {text}
        </Typography>
      </Tooltip>
    </Box>
  );
}

export default TextTruncate;
TextTruncate.propTypes = {
  text: PropTypes.any,
  width: PropTypes.any,
  color: PropTypes.any,
};

/* eslint-disable import/no-unresolved */
import React from 'react';

import { Container } from '@mui/material';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import { useSettingsContext } from 'src/components/settings';

import AllNotification from './NotificationPage';

function Notification() {
  const settings = useSettingsContext();
  return (
    <>
      <MetaHelmet title="Winbuks" miniDescription="Notification" />
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <AllNotification />
      </Container>
    </>
  );
}

export default Notification;

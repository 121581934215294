/* eslint-disable import/no-unresolved */
import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import { useGetRoles } from 'src/hooks/useHandleSessions';

import AuthGuard from 'src/auth/guard/auth-guard';
import DashboardLayout from 'src/layouts/dashboard';
import DashboardAdminApp from 'src/pages/Common/Dashboard/DashboardAdminApp';

import { LoadingScreen } from 'src/components/loading-screen';

// import { paths } from './paths';
import { authRoutes, adminRoutes, memberRoutes, publicRoutes } from './routes';

const Page404 = lazy(() => import('src/pages/Common/Static/404'));
const DashboardMemberApp = lazy(() => import('src/pages/Common/Dashboard/DashboardMemberApp'));

// ----------------------------------------------------------------------

export default function Router() {
  const { role } = useGetRoles();

  const handleDashboardApp = () => {
    switch (role) {
      case 'admin':
        return <DashboardAdminApp />;
      case 'member':
        return <DashboardMemberApp />;
      default:
        return '';
    }
  };

  const mainRouteContainer = [
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        {
          path: 'app',
          element: handleDashboardApp(),
        },

        // other routes are listed here with their roles
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/dashboard/404" /> },
      ],
    },

    // handle notfound routes
    {
      path: '/',
      element: <Navigate to="/dashboard/app" replace />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ];

  const handleRouteByRoles = () => {
    switch (role) {
      case 'admin': {
        const modifiedRoute = mainRouteContainer?.map((route) =>
          route.path === '/dashboard'
            ? {
                ...route,
                children: [...route.children, ...adminRoutes],
              }
            : route
        );
        return modifiedRoute;
      }

      case 'member': {
        const modifiedRoute = mainRouteContainer?.map((route) =>
          route.path === '/dashboard'
            ? {
                ...route,
                children: [...route.children, ...memberRoutes],
              }
            : route
        );
        return modifiedRoute;
      }

      default:
        return mainRouteContainer;
    }
  };

  const routes = useRoutes([
    {
      path: '/',
      element: <Navigate to="/home" replace />,
    },

    ...handleRouteByRoles(),
    ...publicRoutes,
    ...authRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);

  return routes;
}

// const modifiedRoutes = () => {
//   if (role === 'member') {
//     if (MemberProfileDetails && MemberProfileDetails && isVerified) {
//       return handleRouteByRoles()
//     }

//     if (MemberProfileDetails && MemberProfileDetails && !isVerified) {
//       const unVerifiedRoutes = handleRouteByRoles()?.map((i) => i.path === '/dashboard' ? {
//         ...i,
//         children: i.children?.filter(c => c.path === 'app')
//       } : i)
//       return unVerifiedRoutes
//     }

//     return 'loading...'
//   }
//   return handleRouteByRoles()
// }

// console.log(routes);

// switch (role) {
//   case 'member':
//     console.log('one', MemberProfileDetails);
//     if (MemberProfileDetails && isVerified) {
//       return routes;
//     }
//     if (!isVerified) {
//       return routes;
//     }
//     return 'loading...';
//   case 'admin':
//     return routes;

//   default:
//     return routes;
// }
// MemberProfileDetails && isVerified
// ? [...route.children, ...memberRoutes]
// : [
//     ...route.children,
//     route.children?.filter((item) => item.path === paths.dashboard.root),
//   ],

import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

// import { createEnquiry, updateEnquiryDetails } from 'src/server/api/enquiry';
import { LoadingButton } from '@mui/lab';
// components
import CloseIcon from '@mui/icons-material/Close';
// @mui
import {
  Box,
  Card,
  Stack,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  InputAdornment,
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { updateMemberPassword } from 'src/server/api/member';

import Iconify from 'src/components/iconify';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';

// import CrudModalContent from './CrudModalContent';

UpdateMemberPasswordModalDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  isUpdate: PropTypes.object,
};

export default function UpdateMemberPasswordModalDialog({ open, setOpen, isUpdate }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  // const theme = useTheme();

  // console.log(isUpdate);
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useResponsive('down', 'md');

  // const { error } = useSelector((state) => ({
  //   loading: state.member.loading,
  // }));
  // console.log(error);
  const password = useBoolean();

  const UpdatePasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    oldPassword: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
  });
  // console.log(MemberProfileDetails);

  const methods = useForm({
    resolver: yupResolver(UpdatePasswordSchema),
    // defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const credentialsPasswordChange = {
      state: {
        newPassword: data?.newPassword,
        oldPassword: data?.oldPassword,
      },
      enqueueSnackbar,
      handleClose,
    };

    dispatch(updateMemberPassword(credentialsPasswordChange));
  });
  // console.log(imagePreview);

  // useEffect(() => {
  //   if (MemberProfileDetails?._id) {
  //     setImagePreview(MemberProfileDetails?.profile_photo);
  //   }
  // }, [MemberProfileDetails?._id, MemberProfileDetails?.profile_photo]);
  // useEffect(() => {
  //   if (MemberProfileDetails?._id) {
  //     setImagePreview(
  //       [
  //         {
  //           public_id: MemberProfileDetails?.profile_photo?.public_id,
  //           url: MemberProfileDetails?.profile_photo?.url,
  //         },
  //       ] || []
  //     );
  //   }
  // }, [

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="dialog-is-mobile">
      <Dialog
        // sx={{ height: isMobile && 'auto', width: isMobile && 'auto' }}
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', top: 0, right: 0, m: 1 }}
        >
          <CloseIcon sx={{ fontSize: '20px' }} />
        </IconButton>
        <DialogTitle id="responsive-dialog-title" sx={{ pb: 0 }}>
          {/* {isUpdate?._id ? 'Update your payment' : 'Complete your payment'} */}
          Update Password
        </DialogTitle>
        <DialogContent
          className="crud-modal"
          sx={{ minWidth: isMobile ? '200px' : '450px', padding: '0px' }}
        >
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack flexDirection={isMobile ? 'column' : 'row'} spacing={3}>
              <Stack>
                <Card sx={{ p: 3, display: 'flex', spacing: 2, flexDirection: 'column' }}>
                  <Stack flexDirection="column">
                    <Stack flexDirection={isMobile ? 'column' : 'row'} spacing={3}>
                      <Stack flex={2.3}>
                        <Box
                          rowGap={3}
                          columnGap={2}
                          display="grid"
                          gridTemplateColumns={{
                            xs: 'repeat(1, 1fr)',
                            sm: 'repeat(1, 1fr)',
                          }}
                        >
                          <RHFTextField
                            name="oldPassword"
                            label="Old Password"
                            placeholder="Password"
                            type={password.value ? 'text' : 'password'}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={password.onToggle} edge="end">
                                    <Iconify
                                      icon={
                                        password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'
                                      }
                                    />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <RHFTextField
                            name="newPassword"
                            label="New Password"
                            placeholder="Confirm Password"
                            type={password.value ? 'text' : 'password'}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={password.onToggle} edge="end">
                                    <Iconify
                                      icon={
                                        password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'
                                      }
                                    />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />{' '}
                        </Box>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                      sx={{
                        color: 'white',
                        width: 'fit-content',
                        marginLeft: 'auto',
                        backgroundColor: '#22C55E',
                        '&:hover': {
                          backgroundColor: '#0b4e3e',
                        },
                      }}
                    >
                      Update Changes{' '}
                    </LoadingButton>
                  </Stack>
                </Card>
              </Stack>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import { Stack, CardMedia, Typography } from '@mui/material';

// import { useBoolean } from 'src/hooks/use-boolean';
import useResponsive from 'src/hooks/useResponsive';

import newEmail from 'src/assets/other/newEmail.png';
import { SentOtpNewEmailApi } from 'src/server/api/auth';

import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
// import { FileUploadCustom } from 'src/components/CustomFields/ImageUpload';

export default function UpdateMemberEmailContent({
  activeStep,
  handleNextStep,
  handleClose,
  setOpenUpdateMemberEmailSuccess,
  handlePreviousStep,
  setActiveStep,
  memberDetails,
}) {
  const isMobile = useResponsive('down', 'md');
  const { enqueueSnackbar } = useSnackbar();
  // console.log(memberDetails);
  // const password = useBoolean();
  // const navigate = useNavigate();

  // const { token } = useGetToken(TOKEN_PREFIX);
  // console.log(token);
  // const [selectedCountryCode, setSelectedCountryCode] = useState('');
  // const [userNameCheck, setUserNameCheck] = useState('');
  const { loading } = useSelector((state) => ({
    userNamesCheck: state.auth.CheckUserNames,
    user: state.auth.User,
    loading: state.auth.loading,
    paymentLoading: state.auth.loading,
    settingsDetails: state.settings.settingsDetails,
    MemberProfileDetails: state.member.memberProfileDetails.member,
    memberWalletAddress: state.wallet.walletAddress,
    NewEmailOTP: state.auth.NewEmailOTP,
  }));
  // console.log('NewEmailOTP', NewEmailOTP);
  // const [timeLeft, setTimeLeft] = useState(15 * 60);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
  //   }, 1000);

  //   // Cleanup interval on component unmount
  //   return () => clearInterval(interval);
  // }, []);
  // const minutes = Math.floor(timeLeft / 60);
  // const seconds = timeLeft % 60;
  // const [neTimeLeft, setNewTimeLeft] = useState(15 * 60);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setNewTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
  //   }, 1000);

  //   // Cleanup interval on component unmount
  //   return () => clearInterval(interval);
  // }, []);
  // const newMinutes = Math.floor(neTimeLeft / 60);
  // const newSeconds = neTimeLeft % 60;

  const dispatch = useDispatch();

  const NewEmailSchema = Yup.object().shape({
    new_email: Yup.string().email('Email must be a valid email address'),
  });

  const OtpSchema = Yup.object().shape({
    otp: Yup.number().min(4, 'OTP must be at least 4 digits').required('OTP is required'),
  });
  const OtpNewEmailSchema = Yup.object().shape({
    new_otp: Yup.number().min(4, 'OTP must be at least 4 digits').required('OTP is required'),
  });

  const defaultValues = {
    // name: account?.name || '',
    // email: account?.email || '',
    // phone: account?.phone || '',
  };
  // console.log(selectedCountryCode);
  const methods = useForm({
    resolver: yupResolver(NewEmailSchema || OtpSchema || OtpNewEmailSchema),
    defaultValues,
  });

  const {
    // setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // const handleReset = () => {
  //   if (memberDetails?.user?.email) {
  //     const otpResetCrud = {
  //       state: {
  //         email: memberDetails?.email,
  //       },
  //       type: 'mail_update_otp',
  //       dispatch,
  //     };
  //     dispatch(ResentOtpApi(otpResetCrud));
  //   }
  // };
  // const onSubmitVerifyEmail = handleSubmit(async (loginFormData) => {
  //   // handleNextStep();

  //   if (memberDetails?.email) {
  //     const otpResetCrud = {
  //       state: {
  //         email: memberDetails?.email,
  //       },
  //       handleNextStep,
  //       type: 'mail_update_otp',
  //       dispatch,
  //     };

  //     dispatch(ResentOtpApi(otpResetCrud));
  //   }
  //   // };
  // });
  // const renderVerifyEmail = (
  //   <>
  //     <Stack p={3} alignItems="center" textAlign="center" gap={2}>
  //       <CardMedia
  //         component="img"
  //         src={emailVerification}
  //         alt="qr-code"
  //         sx={{ width: '84px', mr: 1 }}
  //       />
  //       <Typography variant="h6">Verify current email</Typography>
  //       <Stack>
  //         <Typography variant="caption" color="#637381">
  //           To update the email, we need to verify your current email address. Click &#39;Send
  //           OTP&#39; to receive an OTP at
  //         </Typography>
  //         <Typography variant="caption">{memberDetails?.email}</Typography>
  //       </Stack>
  //     </Stack>
  //     <Stack p={3} flexDirection="row" gap={2} justifyContent="end">
  //       <LoadingButton
  //         fullWidth
  //         size="medium"
  //         type="submit"
  //         variant="contained"
  //         color="success"
  //         sx={{ backgroundColor: '#007867', width: '100%', height: '' }}
  //         loading={isSubmitting || loading}
  //       >
  //         Send OTP
  //       </LoadingButton>
  //     </Stack>
  //   </>
  // );
  // const onSubmitOTP = handleSubmit(async (loginFormData) => {
  //   // handleNextStep();

  //   const otpCrud = {
  //     state: {
  //       otp: loginFormData?.otp,
  //       userId: memberDetails?.user?._id,
  //     },
  //     dispatch,
  //     enqueueSnackbar,
  //     handleNextStep,
  //     // reset:setValue({
  //     //   otp:''
  //     // })
  //     reset: () => setValue('otp', ''),
  //   };
  //   dispatch(NewMailUpdateVerifyOtpApi(otpCrud));
  // });
  // const renderFormOtp = (
  //   <>
  //     <Stack p={3} pb={2}>
  //       <Stack p={3} alignItems="center" textAlign="center" gap={2}>
  //         <CardMedia component="img" src={sent} alt="qr-code" sx={{ width: '84px', mr: 1 }} />
  //         <Typography variant="h6">We’ve sent an OTP </Typography>
  //         <Stack>
  //           <Typography variant="caption" color="#637381">
  //             An OTP has been sent to{' '}
  //             <Typography variant="caption" color="black">
  //               {' '}
  //               {memberDetails?.email}
  //             </Typography>
  //             . Please enter it below.{' '}
  //           </Typography>
  //         </Stack>
  //       </Stack>
  //       <RHFCode name="otp" mb={3} />
  //       <Typography variant="caption" textAlign="center">
  //         Didn&apos;t receive an OTP? Resend in{' '}
  //         <Typography variant="caption" fontWeight="bold" component="span">
  //           {`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`}{' '}
  //         </Typography>{' '}
  //       </Typography>
  //     </Stack>
  //     <Stack p={3} pt={0} flexDirection="column" gap={2} justifyContent="end">
  //       <LoadingButton
  //         fullWidth
  //         size="medium"
  //         type="submit"
  //         variant="contained"
  //         sx={{ backgroundColor: '#007867', width: '100%', height: '' }}
  //         loading={isSubmitting || loading}
  //       >
  //         Verify OTP
  //       </LoadingButton>
  //       <Button variant="outlined" sx={{ width: '100%' }} onClick={handleReset}>
  //         Resend OTP
  //       </Button>
  //     </Stack>
  //   </>
  // );
  // const onSubmitNewEmail = handleSubmit(async (data) => {
  //   handleNextStep();

  //   // const credentials = {
  //   //   state: {
  //   //     // ...data,
  //   //     // wallet_address_id: selectedWalletAddress || data?.wallet_address,
  //   //     wallet_address_id: selectedWalletAddress,
  //   //     amount: parseInt(amount),
  //   //   },
  //   //   dispatch,
  //   //   handleClose,
  //   //   setOpenUpdateMemberEmailSuccess,
  //   //   setActiveStep,

  //   //   // handleNextStep,
  //   // };
  //   // dispatch(createWithdrawalRequest(credentials));
  // });
  const onSubmitNewEmail = handleSubmit(async (data) => {
    // handleNextStep();
    console.log(data);
    const otpCrud = {
      state: {
        new_email: data?.new_email,
      },
      memberId: memberDetails?._id,
      dispatch,
      enqueueSnackbar,
      handleClose,
    };
    dispatch(SentOtpNewEmailApi(otpCrud));
  });
  const renderNewEmailOtp = (
    <>
      <Stack p={3} alignItems="center" textAlign="center" gap={2}>
        <CardMedia component="img" src={newEmail} alt="qr-code" sx={{ width: '84px', mr: 1 }} />
        <Typography variant="h6">Update Email</Typography>
        <Stack>
          <Typography mb={1} variant="caption" color="#637381">
            Previous email :
            <Typography variant="caption" color="black" component="span" ml={0.5}>
              {memberDetails?.email}
            </Typography>
          </Typography>

          <Typography variant="caption" color="#637381">
            Enter the new email you&#39;d like to change to.
          </Typography>
        </Stack>
        <Stack width="100%">
          <RHFTextField
            sx={{ width: '100%' }}
            name="new_email"
            type="email"
            label="New Email"
            placeholder="Enter New Email.."
            InputLabelProps={{ shrink: true }}
            required
          />
        </Stack>
      </Stack>
      {/* <Stack px={3}>
        <Alert
          icon={<Iconify icon="material-symbols:info" />}
          sx={{
            bgcolor: '#f0faf5',
            color: '#00A76F',
            borderRadius: '10px',
            display: 'inline-flex',
            alignItems: 'center',
            p: 0.5,
            px: 1,
            mb: 1,
            width: '100%',
          }}
        >
          <Typography variant="body2" color="#004B50" sx={{ fontWeight: 500 }}>
            You&#39;ll receive an OTP at the new email address you provide for confirmation.
          </Typography>{' '}
        </Alert>
      </Stack> */}
      <Stack p={3} flexDirection="row" justifyContent="end">
        <LoadingButton
          fullWidth
          size="medium"
          type="submit"
          variant="contained"
          color="success"
          sx={{ backgroundColor: '#007867', width: '100%', height: '' }}
          loading={isSubmitting || loading}
        >
          Submit{' '}
        </LoadingButton>
      </Stack>
    </>
  );
  // const handleResetNewEmail = () => {
  //   if (NewEmailOTP?.new_email) {
  //     const otpResetCrud = {
  //       state: {
  //         email: NewEmailOTP?.new_email,
  //       },
  //       type: 'mail_update_otp',
  //       dispatch,
  //     };
  //     dispatch(ResentOtpApi(otpResetCrud));
  //   }
  // };
  // const onSubmit = handleSubmit(async (loginFormData) => {
  //   // handleNextStep();

  //   const otpCrud = {
  //     state: {
  //       otp: loginFormData?.new_otp,
  //     },
  //     dispatch,
  //     enqueueSnackbar,
  //     setOpenUpdateMemberEmailSuccess,
  //     memberId: memberDetails?._id,
  //     handleClose,
  //     setActiveStep,
  //   };
  //   dispatch(VerifyNewEmailApi(otpCrud));
  // });
  // const renderFormNewOtp = (
  //   <>
  //     <Stack p={3} pb={2}>
  //       <Stack p={3} alignItems="center" textAlign="center" gap={2}>
  //         <CardMedia component="img" src={sent} alt="qr-code" sx={{ width: '84px', mr: 1 }} />
  //         <Typography variant="h6">We’ve sent an OTP </Typography>
  //         <Stack>
  //           <Typography variant="caption" color="#637381">
  //             An OTP has been sent to{' '}
  //             <Typography variant="caption" color="black">
  //               {' '}
  //               {NewEmailOTP?.new_email}
  //             </Typography>
  //             . Please enter it below.
  //           </Typography>
  //         </Stack>
  //       </Stack>
  //       <RHFCode name="new_otp" mb={3} />
  //       <Typography variant="caption" textAlign="center">
  //         Didn&apos;t receive an OTP? Resend in{' '}
  //         <Typography variant="caption" fontWeight="bold" component="span">
  //           {`${newMinutes}:${newSeconds < 10 ? '0' : ''}${newSeconds}`}{' '}
  //         </Typography>{' '}
  //       </Typography>
  //     </Stack>
  //     <Stack p={3} pt={0} flexDirection="column" gap={2} justifyContent="end">
  //       <LoadingButton
  //         fullWidth
  //         size="medium"
  //         type="submit"
  //         variant="contained"
  //         sx={{ backgroundColor: '#007867', width: '100%', height: '' }}
  //         loading={isSubmitting || loading}
  //       >
  //         Verify OTP
  //       </LoadingButton>
  //       <Button variant="outlined" sx={{ width: '100%' }} onClick={handleResetNewEmail}>
  //         Resend OTP
  //       </Button>
  //     </Stack>
  //   </>
  // );

  return (
    <>
      {/* {activeStep === 0 && (
        <Stack flexDirection={isMobile ? 'column' : 'column'} spacing={2}>
          <FormProvider methods={methods} onSubmit={onSubmitVerifyEmail}>
            {renderVerifyEmail}
          </FormProvider>
        </Stack>
      )}
      {activeStep === 1 && (
        <Stack flexDirection={isMobile ? 'column' : 'column'} spacing={2}>
          <FormProvider methods={methods} onSubmit={onSubmitOTP}>
            {renderFormOtp}
          </FormProvider>
        </Stack>
      )} */}
      {activeStep === 0 && (
        <Stack flexDirection={isMobile ? 'column' : 'column'} spacing={2}>
          <FormProvider methods={methods} onSubmit={onSubmitNewEmail}>
            {renderNewEmailOtp}
          </FormProvider>
        </Stack>
      )}
      {/* {activeStep === 3 && (
        <Stack flexDirection={isMobile ? 'column' : 'column'} spacing={2}>
          <FormProvider methods={methods} onSubmit={onSubmit}>
            {renderFormNewOtp}
          </FormProvider>
        </Stack>
      )} */}
    </>
  );
}

UpdateMemberEmailContent.propTypes = {
  activeStep: PropTypes.any,
  handleNextStep: PropTypes.any,
  handleClose: PropTypes.any,
  setOpenUpdateMemberEmailSuccess: PropTypes.func,
  handlePreviousStep: PropTypes.any,
  setActiveStep: PropTypes.any,
  memberDetails: PropTypes.any,
};

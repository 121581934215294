import * as React from 'react';
import PropTypes from 'prop-types';

// components
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
// @mui
import { Box, Stack, Dialog, Button, Typography, DialogTitle, DialogContent } from '@mui/material';

// import { createEnquiry, updateEnquiryDetails } from 'src/server/api/enquiry';

import { useDispatch, useSelector } from 'react-redux';

import { useResponsive } from 'src/hooks/use-responsive';

import { ResentOtpApi } from 'src/server/api/auth';

WalletRequestsConfirmDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  memberProfileDetails: PropTypes.object,
  createDialog: PropTypes.bool,
  setCreateDialog: PropTypes.func,
  setOpenWalletTransferDialog: PropTypes.bool,
};

export default function WalletRequestsConfirmDialog({
  open,
  setOpen,
  memberProfileDetails,
  createDialog,
  setCreateDialog,
  setOpenWalletTransferDialog,
}) {
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useResponsive('down', 'md');
  const { MemberProfileDetails } = useSelector((state) => ({
    loading: state.referrals.loading,
    MemberProfileDetails: state.member.memberProfileDetails.member,
  }));
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };
  const handleReset = () => {
    // Assuming loginData is accessible here
    if (MemberProfileDetails?.user?.email) {
      const otpResetCrud = {
        state: {
          email: MemberProfileDetails?.user?.email,
        },
        type: 'club_income_transfer',
        // dispatch,
        // navigate,
      };
      dispatch(ResentOtpApi(otpResetCrud));
    }
  };
  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box
          textAlign={isMobile && 'center'}
          // sx={{ marginTop: isMobile && '50vh', transform: isMobile && 'translateY(-50%)' }}
        >
          <DialogTitle sx={{ pb: 1 }}> Confirm</DialogTitle>
          <DialogContent
            sx={{
              minWidth: isMobile ? 'auto' : '380px',
              padding: isMobile ? '15px' : '25px',
              // pb: isMobile ? '' : '25px',
              spacing: 2,
            }}
          >
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="start"
              color="warning"
              padding={2}
            >
              <InfoRoundedIcon sx={{ fontSize: 16 }} color="warning" /> &nbsp;{' '}
              <Typography variant="subtitle2">
                Are you confirm to Transfer club balance to personal wallet?
              </Typography>
            </Stack>
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent={isMobile ? 'center' : 'end'}
            >
              <Button variant="outlined" color="inherit" size="small" onClick={() => handleClose()}>
                Cancel
              </Button>
              <Button
                sx={{ mx: 1 }}
                variant="contained"
                color="success"
                size="small"
                onClick={() => {
                  setOpenWalletTransferDialog(true);
                  handleClose();
                  handleReset();
                }}
                // onClick={() => handleReset()}
              >
                Confirm
              </Button>
            </Stack>
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
}

import { createSlice } from '@reduxjs/toolkit';

import {
  LoginApi,
  logoutApi,
  EnquiryApi,
  ResentOtpApi,
  verifyOtpApi,
  CheckUserName,
  forgotPassword,
  updatePassword,
  memberLoginApi,
  getMemberProfile,
  memberRegisterApi,
  VerifyNewEmailApi,
  SentOtpNewEmailApi,
  updateMemberProfile,
  ResentRegisterOtpApi,
  verifyRegisterOtpApi,
  memberRegisterProfileApi,
  NewMailUpdateVerifyOtpApi,
} from '../api/auth';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    passwordLoading: false,
    forgottLoading: false,
    loadingRegister: false,
    loading: false,
    loginLoading: false,
    error: {},
    ResentRegisterOTP: {},
    verifyRegisterOTP: {},
    CheckUserNames: {},
    user: {
      email: '',
      username: '',
      isActive: false,
    },
    User: {},
    payment: {},
    memberProfileDetails: {},
    loginData: {},
    ResentOTP: {},
    verifyOTP: {},
    admin: {},
    enquiry: {},
    NewEmailOTP: {},
    verifyNewEmail: {},
  },
  reducers: {},

  extraReducers: {
    // login
    [memberLoginApi.pending]: (state) => {
      state.loginLoading = true;
    },
    [memberLoginApi.fulfilled]: (state, action) => {
      state.loginLoading = false;
      state.user = action.payload;
      state.error = {};
    },
    [memberLoginApi.rejected]: (state, action) => {
      state.loginLoading = false;
      state.error = action.error;
    },
    /// admin login
    [LoginApi.pending]: (state) => {
      state.loginLoading = true;
    },
    [LoginApi.fulfilled]: (state, action) => {
      state.loginLoading = false;
      state.loginData = action.payload;
      state.error = {};
    },
    [LoginApi.rejected]: (state, action) => {
      state.loginLoading = false;
      state.error = action.error;
    },
    // update profile

    // register
    [memberRegisterApi.pending]: (state) => {
      state.loginLoading = true;
    },
    [memberRegisterApi.fulfilled]: (state, action) => {
      state.loginLoading = false;
      state.User = action.payload;
      state.error = {};
    },
    [memberRegisterApi.rejected]: (state, action) => {
      state.loginLoading = false;
      state.error = action.error;
    },

    // profile
    [getMemberProfile.pending]: (state) => {
      state.loading = true;
    },
    [getMemberProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.admin = action.payload;
      state.error = {};
    },
    [getMemberProfile.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update profile
    [updateMemberProfile.pending]: (state) => {
      state.loading = true;
    },
    [updateMemberProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = {};
    },
    [updateMemberProfile.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // logout
    [logoutApi.fulfilled]: (state) => {
      state.user = {};
      state.error = {};
    },
    [logoutApi.rejected]: (state, action) => {
      state.error = action.error;
    },

    [forgotPassword.pending]: (state) => {
      state.forgottLoading = true;
    },
    [forgotPassword.fulfilled]: (state) => {
      state.forgottLoading = false;
      state.error = {};
    },
    [forgotPassword.rejected]: (state, action) => {
      state.forgottLoading = false;
      state.error = action.error;
    },

    [updatePassword.pending]: (state) => {
      state.loading = true;
    },
    [updatePassword.fulfilled]: (state) => {
      state.loading = false;
      state.error = {};
    },
    [updatePassword.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    //  check username
    [CheckUserName.pending]: (state) => {
      state.loading = true;
    },
    [CheckUserName.fulfilled]: (state, action) => {
      // console.log('username', action.payload);
      state.loading = false;
      state.CheckUserNames = action.payload;
      state.error = {};
    },
    [CheckUserName.rejected]: (state, action) => {
      // console.log('errr', action.error);
      state.loading = false;
      state.error = action.error;
    },
    // resent
    [ResentOtpApi.pending]: (state) => {
      state.loading = true;
    },
    [ResentOtpApi.fulfilled]: (state, action) => {
      // console.log('username', action.payload);
      state.loading = false;
      state.ResentOTP = action.payload;
      state.error = {};
    },
    [ResentOtpApi.rejected]: (state, action) => {
      // console.log('errr', action.error);
      state.loading = false;
      state.error = action.error;
    },

    [verifyOtpApi.pending]: (state) => {
      state.loading = true;
    },
    [verifyOtpApi.fulfilled]: (state, action) => {
      // console.log('username', action.payload);
      state.loading = false;
      state.verifyOTP = action.payload;
      state.error = {};
    },
    [verifyOtpApi.rejected]: (state, action) => {
      // console.log('errr', action.error);
      state.loading = false;
      state.error = action.error;
    },
    // enquiry
    [EnquiryApi.pending]: (state) => {
      state.loginLoading = true;
    },
    [EnquiryApi.fulfilled]: (state, action) => {
      state.loginLoading = false;
      state.enquiry = action.payload;
      state.error = {};
    },
    [EnquiryApi.rejected]: (state, action) => {
      state.loginLoading = false;
      state.error = action.error;
    },
    // OTP TO NEW MAIL
    [SentOtpNewEmailApi.pending]: (state) => {
      state.loading = true;
    },
    [SentOtpNewEmailApi.fulfilled]: (state, action) => {
      state.loading = false;
      state.NewEmailOTP = action.payload;
      state.error = {};
    },
    [SentOtpNewEmailApi.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // verify TO NEW MAIL
    [VerifyNewEmailApi.pending]: (state) => {
      state.loading = true;
    },
    [VerifyNewEmailApi.fulfilled]: (state, action) => {
      state.loading = false;
      state.verifyNewEmail = action.payload;
      state.error = {};
    },
    [VerifyNewEmailApi.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [NewMailUpdateVerifyOtpApi.pending]: (state) => {
      state.loading = true;
    },
    [NewMailUpdateVerifyOtpApi.fulfilled]: (state, action) => {
      // console.log('username', action.payload);
      state.loading = false;
      state.verifyOTP = action.payload;
      state.error = {};
    },
    [NewMailUpdateVerifyOtpApi.rejected]: (state, action) => {
      // console.log('errr', action.error);
      state.loading = false;
      state.error = action.error;
    },
    // resent
    [ResentRegisterOtpApi.pending]: (state) => {
      state.loading = true;
    },
    [ResentRegisterOtpApi.fulfilled]: (state, action) => {
      // console.log('username', action.payload);
      state.loading = false;
      state.ResentRegisterOTP = action.payload;
      state.error = {};
    },
    [ResentRegisterOtpApi.rejected]: (state, action) => {
      // console.log('errr', action.error);
      state.loading = false;
      state.error = action.error;
    },
    [verifyRegisterOtpApi.pending]: (state) => {
      state.loadingRegister = true;
    },
    [verifyRegisterOtpApi.fulfilled]: (state, action) => {
      // console.log('username', action.payload);
      state.loadingRegister = false;
      state.verifyRegisterOTP = action.payload;
      state.error = {};
    },
    [verifyRegisterOtpApi.rejected]: (state, action) => {
      // console.log('errr', action.error);
      state.loadingRegister = false;
      state.error = action.error;
    },
    // register
    [memberRegisterProfileApi.pending]: (state) => {
      state.loginLoading = true;
    },
    [memberRegisterProfileApi.fulfilled]: (state, action) => {
      state.loginLoading = false;
      state.User = action.payload;
      state.error = {};
    },
    [memberRegisterProfileApi.rejected]: (state, action) => {
      state.loginLoading = false;
      state.error = action.error;
    },
  },
});
// export const {} = authSlice.actions;

export default authSlice.reducer;

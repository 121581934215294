import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import React, { useMemo, useState, useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import parsePhoneNumberFromString, { getCountryCallingCode } from 'libphonenumber-js';

import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// @mui
import {
  Box,
  Card,
  Stack,
  Dialog,
  Button,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
} from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

// import { countryCodes } from 'src/assets/data/countryCodes';
import { referralIdCheckApi, createMemberAccountMember } from 'src/server/api/member';

import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { RHFPhoneInput } from 'src/components/hook-form/rhf-phone-input';

CreateMemberAccountModalDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default function CreateMemberAccountModalDialog({ open, setOpen }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const isMobile = useResponsive('down', 'md');

  const { loadingAccount, ReferralId, MemberProfileDetails } = useSelector((state) => ({
    ReferralId: state.member.ReferralId,
    loadingAccount: state.member.loadingAccount,
    MemberProfileDetails: state.member.memberProfileDetails.member,
  }));
  // console.log(loadingAccount);
  const [referralIdCheck, setReferralIdCheck] = useState('');
  const [selectedCode, setSelectedCode] = useState('91');

  // const [selectedCountryCode, setSelectedCountryCode] = useState('');
  console.log(MemberProfileDetails);
  const UpdateUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    referral_id: Yup.string(),
    phone: Yup.string(),
  });

  const defaultValues = useMemo(
    () => ({
      referral_id: MemberProfileDetails?.member_id || '',
    }),
    [MemberProfileDetails?.member_id]
  );
  console.log(defaultValues);
  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = methods;
  // const optionCountry = [
  //   ...(countryCodes || []).map((results) => ({
  //     label: `${results?.code} ${results?.name}`,
  //     // label: results?.code,
  //     value: results?.code,
  //   })),
  // ];
  // const handleAutocompleteChangePhone = (e, newValue) => {
  //   setSelectedCountryCode(newValue?.value || '');
  // };
  const onSubmit = handleSubmit(async (data) => {
    const memberDataCredential = {
      state: {
        // ...data,
        name: data?.name,
        referral_id: referralIdCheck,
        phone: parsePhoneNumberFromString(data?.phone)?.nationalNumber,

        country_code: selectedCode || '',
      },
      dispatch,
      enqueueSnackbar,
      handleClose,
    };

    dispatch(createMemberAccountMember(memberDataCredential));
  });

  const handleClose = () => {
    setOpen(false);
  };
  const handleReferralIdChange = (event) => {
    const { value } = event.target;
    // console.log('User Name Value:', value);
    setReferralIdCheck(value);
  };
  console.log(referralIdCheck);
  useEffect(() => {
    if (MemberProfileDetails?.member_id) {
      setReferralIdCheck(MemberProfileDetails?.member_id);
    }
  }, [MemberProfileDetails?.member_id]);
  useEffect(() => {
    if (referralIdCheck && referralIdCheck?.length >= 6) {
      const credential = {
        state: {
          referral_id: referralIdCheck.toUpperCase(),
        },
      };
      dispatch(referralIdCheckApi(credential));
    }
  }, [dispatch, referralIdCheck]);
  const handlePhoneInputChange = (value, inputName) => {
    const country_code = getCountryCallingCode(value);
    setSelectedCode(country_code);
  };
  const phone = watch('phone');
  const phoneNumberCheck = phone && parsePhoneNumberFromString(phone)?.nationalNumber;
  // console.log('phone', phone&& parsePhoneNumberFromString(phone)?.nationalNumber)
  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', top: 0, right: 0, m: 1 }}
        >
          <CloseIcon sx={{ fontSize: '20px' }} />
        </IconButton>
        <DialogTitle id="responsive-dialog-title" sx={{ pb: 0 }}>
          {/* {isUpdate?._id ? 'Update your payment' : 'Complete your payment'} */}
          Create Account{' '}
        </DialogTitle>
        <DialogContent
          className="crud-modal"
          sx={{ minWidth: isMobile ? '200px' : '450px', padding: '0px' }}
        >
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack flexDirection={isMobile ? 'column' : 'row'} spacing={3}>
              <Stack>
                <Card sx={{ p: 3, display: 'flex', spacing: 2, flexDirection: 'column' }}>
                  <Stack flexDirection="column">
                    <Stack flexDirection={isMobile ? 'column' : 'column'} spacing={3}>
                      <RHFTextField
                        name="name"
                        label="Name"
                        required
                        placeholder="Enter full name"
                        InputLabelProps
                      />
                      <Stack>
                        <RHFTextField
                          name="referral_id"
                          label="Referral Id"
                          placeholder="Enter Referral Id"
                          InputLabelProps
                          required
                          onChange={handleReferralIdChange}
                          value={referralIdCheck}
                          inputProps={{ style: { textTransform: 'uppercase' } }}
                        />
                        {referralIdCheck && (
                          <>
                            {ReferralId === false && referralIdCheck?.length >= 6 && (
                              <Typography
                                color="#FF5630"
                                fontSize={12}
                                fontWeight={400}
                                display="flex"
                                alignItems="center"
                                ml={2}
                              >
                                {/* <Iconify icon="solid/ic-eva:alert-triangle-fill" />  */}
                                <WarningIcon
                                  sx={{ fontSize: '14px', marginRight: '4px', color: '#FF5630' }}
                                />{' '}
                                Invalid Referral ID{' '}
                              </Typography>
                            )}
                            {ReferralId === true && referralIdCheck?.length >= 6 && (
                              <Typography
                                sx={{
                                  color: 'success.main',
                                  fontSize: '10px',
                                  fontWeight: '600',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                Verified{' '}
                                <CheckCircleIcon sx={{ fontSize: '14px', marginLeft: '4px' }} />
                              </Typography>
                            )}
                          </>
                        )}
                      </Stack>
                      {/* <Stack spacing={1} flexDirection="row">
                        <Autocomplete
                          id="country-select-demo"
                          sx={{ width: '50%' }}
                          options={optionCountry}
                          autoHighlight
                          size="medium"
                          required
                          // className="small-select"
                          getOptionLabel={(option) => option.label}
                          onChange={handleAutocompleteChangePhone}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              {option.label}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Country"
                              required
                              // onChange={handleTextFieldChangeCourse}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                              }}
                            />
                          )}
                        />
                        <Stack width="100%">
                          <RHFTextField
                            sx={{ width: '100%' }}
                            name="phone"
                            type="number"
                            label="Phone"
                            placeholder="Enter phone number"
                            onInput={(e) => {
                              const input = e.target.value.replace(/\D/g, '');
                              const limitedInput = input.slice(0, 10);
                              e.target.value = limitedInput;
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Stack>
                      </Stack> */}
                      <RHFPhoneInput
                        name="phone"
                        label="Phone"
                        placeholder="Enter phone number"
                        required
                        setSelectedCode={(value) => handlePhoneInputChange(value, 'phone')}
                      />
                    </Stack>
                  </Stack>

                  <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                    <Box display="flex" alignItems="center" justifyContent="end" pt={3}>
                      <Button
                        variant="outlined"
                        onClick={handleClose}
                        sx={{
                          mr: 1,
                          px: isMobile ? 0 : 2,
                          backgroundColor: '#fff',
                          '&:hover': {
                            backgroundColor: '#fff',
                          },
                          color: '#000',
                        }}
                      >
                        Close
                      </Button>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        disabled={!phoneNumberCheck}
                        loading={isSubmitting || loadingAccount}
                        sx={{
                          color: 'white',
                          width: 'fit-content',
                          marginLeft: 'auto',
                          backgroundColor: '#007867',
                          '&:hover': {
                            backgroundColor: '#08443b',
                          },
                        }}
                      >
                        Add Member{' '}
                      </LoadingButton>
                    </Box>
                  </Stack>
                </Card>
              </Stack>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createNotification = createAsyncThunk(
  'notification/create',
  async ({ state, dispatch, handleClose, reset }) => {
    try {
      const URL = `notification/admin/new`;
      const response = await post(URL, state);
      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'Notification Created successfully' }));
        handleClose();
        reset();
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      console.log(error);

      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const updateNotificationDetails = createAsyncThunk(
  'notification/update',
  async ({ state, notificationId, dispatch, handleClose, reset }) => {
    try {
      const URL = `notification/admin/${notificationId}`;

      const response = await put(URL, state);
      if (response) {
        handleClose();
        reset();
        dispatch(activeSnack({ type: 'success', message: 'Notification Updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getAllNotifications = createAsyncThunk(
  'notification/list',
  async ({ page, search, dispatch, limit }) => {
    try {
      const URL = `/notification/admin/all?page=${page && page}&search=${search || ''}&limit=${
        limit && limit
      }`;

      const URL_DROPDOWN = `/wallet/admin/all`;

      const response = await get(page ? URL : URL_DROPDOWN);
      if (response) {
        return {
          list: response?.notifications,
          total: response?.total,
        };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteNotification = createAsyncThunk(
  'notification/delete',
  async ({ notificationId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/notification/admin/${notificationId}`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Notification Deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// faq

export const createFaq = createAsyncThunk(
  'faq/create',
  async ({ state, dispatch, handleClose, reset }) => {
    try {
      const URL = `faq/admin/new`;
      const response = await post(URL, state);
      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'FAQ Created successfully' }));
        handleClose();
        reset();
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      console.log(error);

      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const updateFaqDetails = createAsyncThunk(
  'faq/update',
  async ({ state, faqId, dispatch, handleClose, reset }) => {
    try {
      const URL = `faq/admin/${faqId}`;

      const response = await put(URL, state);
      if (response) {
        handleClose();
        reset();
        dispatch(activeSnack({ type: 'success', message: 'FAQ Updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getAllFaqs = createAsyncThunk(
  'faq/list',
  async ({ page, search, dispatch, limit }) => {
    try {
      const URL = `/faq/admin/all?page=${page && page}&search=${search || ''}&limit=${
        limit && limit
      }`;

      const URL_DROPDOWN = `/wallet/admin/all`;

      const response = await get(page ? URL : URL_DROPDOWN);
      if (response) {
        return {
          list: response?.faqs,
          total: response?.total,
        };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteFaq = createAsyncThunk(
  'faq/delete',
  async ({ faqId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/faq/admin/${faqId}`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'FAQ Deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// notification for member

export const getAllNotificationByMember = createAsyncThunk(
  'notification/member/list',
  async ({ dispatch }) => {
    try {
      const URL = `/notification/member/all`;

      const response = await get(URL);
      if (response) {
        return {
          list: response?.notifications,
          total: response?.total,
        };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fTime(date, newFormat) {
  const fm = newFormat || 'p';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTimeSimple(date, newFormat) {
  const fm = newFormat || 'dd/MM/yy p';

  return date ? format(new Date(date), fm) : '';
}
export function fDateSimple(date, newFormat) {
  const fm = newFormat || 'dd/MM/yy ';

  return date ? format(new Date(date), fm) : '';
}
export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export const formatDate = (dateString) => {
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
};

export function isBetween(inputDate, startDate, endDate) {
  const date = new Date(inputDate);

  const results =
    new Date(date.toDateString()) >= new Date(startDate.toDateString()) &&
    new Date(date.toDateString()) <= new Date(endDate.toDateString());

  return results;
}

export function isAfter(startDate, endDate) {
  const results =
    startDate && endDate ? new Date(startDate).getTime() > new Date(endDate).getTime() : false;

  return results;
}

export const formatTime = (date) => {
  const now = new Date();
  const givenDate = new Date(date);
  const differenceInSeconds = Math.floor((now - givenDate) / 1000);
  const differenceInMinutes = Math.floor(differenceInSeconds / 60);
  const differenceInHours = Math.floor(differenceInMinutes / 60);
  const differenceInDays = Math.floor(differenceInHours / 24);

  if (differenceInDays > 5) {
    return givenDate.toLocaleDateString();
  }

  if (differenceInDays > 0) {
    return `${differenceInDays} day${differenceInDays > 1 ? 's' : ''} ago`;
  }

  if (differenceInHours > 0) {
    return `${differenceInHours} hour${differenceInHours > 1 ? 's' : ''} ago`;
  }

  if (differenceInMinutes > 0) {
    return `${differenceInMinutes} minute${differenceInMinutes > 1 ? 's' : ''} ago`;
  }
  return `${differenceInSeconds} second${differenceInSeconds > 1 ? 's' : ''} ago`;
};

export const formatRemainingTime = (date) => {
  const now = new Date();
  const givenDate = new Date(date);

  // Calculate the difference in milliseconds (future date - current date)
  const timeDiff = now - givenDate * -1;

  // If the difference is less than 0, it means the time has passed
  if (timeDiff <= 0) {
    return false; // Time has already passed
  }

  // Calculate the remaining time in hours, minutes, and seconds
  const hoursLeft = Math.floor(timeDiff / (1000 * 60 * 60)) % 24;
  const minutesLeft = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
  const secondsLeft = Math.floor((timeDiff % (1000 * 60)) / 1000);

  return `${hoursLeft}h ${minutesLeft}m ${secondsLeft}s`;
};

// export const formatRemainingTime = () => {
//   const now = new Date();
//   const targetTime = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours from now

//     const currentTime = new Date();
//     const timeDiff = targetTime - currentTime;

//     // If the time difference is less than or equal to 0, the countdown is over
//     if (timeDiff <= 0) {
//       return "Time is up!";
//     }

//     // Calculate the remaining time in hours, minutes, and seconds
//     const hoursLeft = Math.floor(timeDiff / (1000 * 60 * 60)) % 24;
//     const minutesLeft = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
//     const secondsLeft = Math.floor((timeDiff % (1000 * 60)) / 1000);

//     return `${hoursLeft}h ${minutesLeft}m ${secondsLeft}s`;
// };
export const getRemainingTime = (targetDate) => {
  const now = new Date();
  const target = new Date(targetDate);

  // Calculate the time difference in milliseconds
  const timeDiff = target.getTime() + 24 * 60 * 60 * 1000 - now.getTime();

  // If timeDiff is negative, the timer has expired
  if (timeDiff <= 0) {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  // Convert the time difference to hours, minutes, and seconds
  const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
  const seconds = Math.floor((timeDiff / 1000) % 60);

  return { hours, minutes, seconds };
};

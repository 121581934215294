import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { Box, Paper, TableRow, TableCell, Typography, CircularProgress } from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import { getAllTransactions, getAllTransactionsAdmin } from 'src/server/api/transaction';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useTable, TableNoData, TableHeadCustom, TableSelectedAction } from 'src/components/table';

import TransactionTableRowAdmin from './TransactionTableRow';

// ----------------------------------------------------------------------

export default function DashboardTransaction({ disable, count }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const isMobile = useResponsive('down', 'md');

  const router = useRouter();
  const table = useTable({ defaultOrderBy: 'createdAt' });
  const confirm = useBoolean();
  const navigate = useNavigate();
  const role = useGetRoles()?.role;

  const { loading, transactionList } = useSelector((state) => ({
    loading: state.transaction.loading,
    transactionList: state.transaction.transactions,
    membersList: state.member.members?.members,
  }));
  // console.log(transactionList);
  const identifier = 'transaction';
  const storedRowsPerPage = sessionStorage.getItem(`rowsPerPage_${identifier}`);

  const [tableData, setTableData] = useState([]);

  const TABLE_HEAD = [
    { id: 'date', label: 'Date' },
    { id: 'description', label: 'Description' },
    // { id: 'amount', label: 'Amount' },
    { id: 'wallet_type', label: 'Wallet Type' },
    { id: 'credit', label: 'Credit' },
    { id: 'debit', label: 'Debit' },
  ];

  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      limit: !disable ? 5 : count || storedRowsPerPage || table.rowsPerPage,
      transaction_type: '',
      date: '',
      dispatch,
      member: '',
      wallet_type: '',
      selectedCategory: '',
    };
    if (role === 'admin') {
      dispatch(getAllTransactionsAdmin(credentials));
    } else {
      dispatch(getAllTransactions(credentials));
    }
  }, [dispatch, role, storedRowsPerPage, table.page, table.rowsPerPage, disable, count]);

  useEffect(() => {
    setTableData(transactionList?.walletTransactions);
  }, [transactionList]);

  const dataInPage = [];

  const canReset = false;

  const notFound = (!tableData?.length && canReset) || !tableData?.length;

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData?.filter((row) => row.id !== id);
      enqueueSnackbar('Delete success!');
      setTableData(deleteRow);
      table.onUpdatePageDeleteRow(dataInPage?.length);
    },
    [dataInPage?.length, enqueueSnackbar, table, tableData]
  );

  const handleDeleteRows = () => {};

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.edit(id));
    },
    [router]
  );

  const handleViewRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.details(id));
    },
    [router]
  );

  const HandlePage = () => {
    navigate(paths.dashboard.transaction, {});
  };
  // useEffect(() => {
  //   const credentials = {
  //     page: '',
  //     search: '',
  //     limit: '',
  //     sort: '',
  //     date: '',
  //   };
  //   if (role === 'admin') {
  //     dispatch(getAllMembers(credentials));
  //   }
  // }, [role, dispatch]);

  // console.log(table);
  let widthValue;
  if (disable) {
    widthValue = '100%';
  } else if (isMobile) {
    widthValue = '100%';
  } else {
    widthValue = '75%';
  }

  return (
    <>
      <Stack width={widthValue} height="100%">
        <Card sx={{ height: '100%' }}>
          <Stack p={2} flexDirection="row">
            <ChangeCircleIcon sx={{ marginRight: '5px' }} />
            <Typography variant="h6">Transaction History</Typography>
          </Stack>

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense
              numSelected={table.selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) => {
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                );
              }}
            />
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={table.selected?.length}
                  onSort={table.onSort}
                />

                {loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <CircularProgress color="inherit" />
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                <TableBody>
                  {(tableData || []).map((row, index) => (
                    <TransactionTableRowAdmin
                      index={index}
                      page={table.page + 1}
                      rowsPerPage={storedRowsPerPage || table.rowsPerPage || 5}
                      key={row.id}
                      row={row}
                      selected={table?.selected?.includes(row.id)}
                      onSelectRow={() => table.onSelectRow(row.id)}
                      onViewRow={() => handleViewRow(row.id)}
                      onEditRow={() => handleEditRow(row.id)}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                      loading={loading}
                      colSpan={TABLE_HEAD?.length}
                    />
                  ))}
                </TableBody>

                {/* <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData?.length)}
                />  */}

                <TableNoData notFound={notFound} />
              </Table>
            </Scrollbar>
          </TableContainer>
          {disable && (
            <Box sx={{ p: 2, textAlign: 'right' }}>
              <Button
                size="small"
                color="inherit"
                onClick={HandlePage}
                endIcon={<Iconify icon="eva:arrow-ios-forward-fill" width={18} sx={{ ml: -0.5 }} />}
              >
                View All
              </Button>
            </Box>
          )}
        </Card>
      </Stack>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

DashboardTransaction.propTypes = {
  disable: PropTypes.string,
  count: PropTypes.number,
};

import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack';
import CopyToClipboard from 'react-copy-to-clipboard';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Divider from '@mui/material/Divider';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { Stack, Button, CardMedia, Typography, ListItemText } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

import { fDate, fTime } from 'src/utils/format-time';

import { frontEndUrl } from 'src/server/api/http';
import lightGreen from 'src/assets/other/lighGreen.png';

import Scrollbar from 'src/components/scrollbar';
import TextMaxLine from 'src/components/text-max-line';
import { useSettingsContext } from 'src/components/settings';
import TextTruncate from 'src/components/Typography/TextTruncate';
import { TableNoData, TableHeadCustom, TablePaginationCustom } from 'src/components/table';

import RefMemberSearch from '../referralMemberSearch';

// ----------------------------------------------------------------------

export default function ReferralMembers({
  search,
  title,
  subheader,
  tableData,
  table,
  setSearchText,
  tableLabels,
  memberID,
  ...other
}) {
  const settings = useSettingsContext();
  const totalPage = tableData?.length;
  const notFound = !tableData?.length || !tableData?.length;
  const handleCopy = () => {
    enqueueSnackbar('Link copied!', { variant: 'success' });
  };
  const isMobile = useResponsive('down', 'md');

  const handleSearch = (value) => {
    // setSearchText(value);
    search(value);
  };

  // console.log(search);

  const identifier = 'referralMember';
  return (
    <Card {...other}>
      {/* <Typography color="#212B36" py={2} pt={4} px={3} sx={{ fontSize: 16, fontWeight: 700 }}>
        Referral Members &nbsp;<Tooltip title="Total Referred Count">({totalPage})</Tooltip>
      </Typography> */}

      <Stack px={1} py={1} direction={isMobile ? 'column' : 'row'} gap={2}>
        <RefMemberSearch onSearch={handleSearch} />
        <Stack>
          <Card
            sx={{
              borderRadius: '10px',
              boxShadow: 'unset',
              border: settings?.themeMode === 'dark' ? '1px solid #616161' : '1px solid #e3e7ea',
              textAlign: 'start',
              gap: 1.8,
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Stack sx={{ px: 1, py: 0.5 }}>
              <Typography variant="caption">Member Referral Link</Typography>
              <TextMaxLine line={1}>
                <Typography
                  sx={{
                    wordWrap: 'break-word',
                    overflowWrap: 'anywhere',
                    opacity: 0.7,
                    textAlign: 'start',
                  }}
                  variant="caption"
                >
                  {`${frontEndUrl}${paths.auth.register}?ref_id=${memberID}`}
                </Typography>
              </TextMaxLine>
            </Stack>
            <Stack
              sx={{
                borderLeft: '1px solid #e3e7ea',
                px: 1,
                justifyContent: 'center',
                backgroundColor: '#e1efea',
              }}
            >
              <CopyToClipboard
                // text={`${paths.auth.register}?ref='${MemberProfileDetails?.member_id}`}
                text={`${frontEndUrl}${paths.auth.register}?ref_id=${memberID}`}
                onCopy={handleCopy}
                sx={
                  {
                    // cursor: 'pointer',
                    // position: 'absolute',
                    // bottom: 16,
                    // left: 16,
                    // '&:hover': {
                    //   color: 'primary.main',
                    // },
                  }
                }
              >
                <Button
                  size="small"
                  borderRadius="25px"
                  className="light-green-copy--btn"
                  startIcon={
                    <CardMedia component="img" src={lightGreen} alt="copy" sx={{ width: '25px' }} />
                  }
                >
                  Copy
                </Button>
              </CopyToClipboard>
            </Stack>
          </Card>
        </Stack>
      </Stack>
      <TableContainer sx={{ overflow: 'unset' }}>
        <Scrollbar>
          <Table sx={{ minWidth: 680 }}>
            <TableHeadCustom headLabel={tableLabels} />

            <TableBody>
              {tableData?.map((row) => (
                <AppNewInvoiceRow key={row.id} row={row} />
              ))}
            </TableBody>
            <TableNoData notFound={notFound} />
          </Table>
        </Scrollbar>
      </TableContainer>
      <TablePaginationCustom
        count={totalPage || 0}
        page={table.page}
        rowsPerPage={table.rowsPerPage}
        onPageChange={table.onChangePage}
        onRowsPerPageChange={table.onChangeRowsPerPage}
        dense={table.dense}
        onChangeDense={table.onChangeDense}
        identifier={identifier}
      />

      <Divider sx={{ borderStyle: 'dashed' }} />
    </Card>
  );
}

ReferralMembers.propTypes = {
  subheader: PropTypes.string,
  tableData: PropTypes.array,
  tableLabels: PropTypes.array,
  title: PropTypes.string,
  table: PropTypes.string,
  setSearchText: PropTypes.any,
  search: PropTypes.any,
  memberID: PropTypes.any,
};

// ----------------------------------------------------------------------

function AppNewInvoiceRow({ row }) {
  return (
    <TableRow>
      <TableCell sx={{ width: 150 }}>
        <ListItemText
          primary={fDate(row?.createdAt)}
          secondary={fTime(row?.createdAt)}
          primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
          }}
        />
      </TableCell>

      <TableCell sx={{ width: 150 }}>
        <ListItemText
          primary={row.member_id}
          secondary={row?.name}
          primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
          }}
        />
      </TableCell>

      <TableCell sx={{}}>
        <ListItemText
          primary={<Typography variant="caption">{row.phone}</Typography>}
          secondary={<TextTruncate text={row?.email} />}
          primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
          }}
        />
      </TableCell>
    </TableRow>
  );
}

AppNewInvoiceRow.propTypes = {
  row: PropTypes.object,
};

/* eslint-disable import/no-unresolved */
import { useState } from 'react';
import { useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import { Card, Grid } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

// import coverImg from 'src/assets/other/set-role.jpg';
// import memberProfileImg from 'src/assets/other/profile.png';

// import MemberCover from 'src/sections/Member/MemberSingleView/member-cover';
// eslint-disable-next-line import/no-duplicates
// import WalletAddressMember from 'src/sections/Member/wallet-address-member';
import MemberAccountsMember from 'src/sections/Member/MemberAccountsMember';
// import MemberCards from 'src/sections/Member/MemberSingleView/Small.Widgets';
// import WalletAddressDetailsAdmin from 'src/sections/Member/wallet-address-details';
// eslint-disable-next-line import/no-duplicates
import WalletAddressDetailsMember from 'src/sections/Member/wallet-address-member';
// import ReferredViewMember from 'src/sections/Member/MemberSingleView/ReferredViewMember';
import UpdateMemberModalDialog from 'src/sections/Member/MemberSingleView/updatememberModal';
import UpdateMemberPasswordModalDialog from 'src/sections/Member/MemberSingleView/updatememberPassWordModal';

import MemberCard from './MemberCard';
// import EarningsCard from './EarningsCards';
// // ----------------------------------------------------------------------
// import JoinedAndReferral from './jointedAndReferal';
// import MemberRefferedCard from './MemberRefferedCard';
import EarningsCardMember from './EarningsCards Member';
import MemberRefiredCardNew from './MemberRefferedCardNew';

// const TABS = [
//   {
//     value: 'general',
//     label: 'General',
//     icon: <Iconify icon="solar:user-id-bold" width={24} />,
//   },

//   {
//     value: 'security',
//     label: 'Security',
//     icon: <Iconify icon="ic:round-vpn-key" width={24} />,
//   },
// ];

// ----------------------------------------------------------------------

export default function AccountView() {
  // const location = useLocation();
  // const settings = useSettingsContext();
  const isMobile = useResponsive('down', 'md');

  const { MemberProfileDetails, MemberProfileEarnings } = useSelector((state) => ({
    loading: state.member.loading,
    MemberProfileDetails: state.member.memberProfileDetails.member,
    MemberProfileEarnings: state.member.memberProfileDetails,
  }));
  const [openModalDialog, setOpenModalDialog] = useState(false);
  const [openModalPasswordDialog, setOpenModalPasswordDialog] = useState(false);

  // console.log('<<<<ooo', MemberProfileEarnings);

  return (
    <>
      {openModalDialog && (
        <UpdateMemberModalDialog
          setOpen={setOpenModalDialog}
          open={openModalDialog}
          isUpdate={MemberProfileDetails}
          MemberProfileDetails={MemberProfileDetails}
          // setIsUpdate={setInvestorData}
        />
      )}
      {openModalPasswordDialog && (
        <UpdateMemberPasswordModalDialog
          setOpen={setOpenModalPasswordDialog}
          open={openModalPasswordDialog}
          isUpdate={MemberProfileDetails}
          memberDetails={MemberProfileDetails}
          // setIsUpdate={setInvestorData}
        />
      )}
      <Stack>
        <Stack
          flexDirection={isMobile ? 'column' : 'row'}
          spacing={2}
          // border='2px solid orange'
        >
          <Card
            sx={{
              flex: 4.5,
              borderRadius: '15px',
              // border:'2px solid orange'
            }}
          >
            <MemberCard
              memberDetails={MemberProfileDetails}
              createdAt={MemberProfileDetails?.createdAt}
              expiry_date={MemberProfileDetails?.expiry_date}
              name={MemberProfileDetails?.user?.name}
              username={MemberProfileDetails?.user?.username}
              cardImg={MemberProfileDetails?.current_member_incentive?.incentive_level?.rank_name}
            />
          </Card>

          <Card
            variant="outlined"
            sx={{
              flex: 7.8,
              // border: '1px solid #e3e7ea',
              boxShadow: 'unset',
              borderRadius: '15px',
            }}
          >
            <MemberRefiredCardNew
              memberDetails={MemberProfileDetails}
              setOpenModalPasswordDialog={setOpenModalPasswordDialog}
              setOpenModalDialog={setOpenModalDialog}
              data={MemberProfileDetails}
            />
          </Card>
        </Stack>

        {/* <Stack flexDirection={isMobile ? 'column' : 'row'} spacing={3}
          border='2px solid orange'
        >
          <Card
            sx={{ flex: 8.1 }}
          >
            <MemberCover
              setOpenModalDialog={setOpenModalDialog}
              createdAt={MemberProfileDetails?.createdAt}
              setOpenModalPasswordDialog={setOpenModalPasswordDialog}
              name={MemberProfileDetails?.user?.name}
              username={MemberProfileDetails?.user?.username}
              phone={MemberProfileDetails?.phone}
              countryCode={MemberProfileDetails?.country_code}
              email={MemberProfileDetails?.user?.email}
              avatarUrl={MemberProfileDetails?.profile_photo?.url || memberProfileImg}
              coverUrl={MemberProfileDetails?.coverUrl || coverImg}
              verified={MemberProfileDetails?.is_verified}
              paid={MemberProfileDetails?.is_paid}
              memberDetails={MemberProfileDetails}
            />

          </Card>
          <Stack flex={3.9}>
            <ReferredViewMember data={MemberProfileDetails} />
          </Stack>
        </Stack> */}

        {/* <Stack flexDirection={isMobile ? 'column' : 'row'} spacing={3} my={3}>
          <Stack flex={4}>
            <MemberCards title={MemberProfileDetails?.member_id} name="memberId" />
          </Stack>
          <Stack flex={4}>
            <MemberCards
              title={MemberProfileDetails?.is_club_member}
              MemberProfileDetails={MemberProfileDetails}
              name="club"
            />
          </Stack>
          <Stack flex={4}>
            <MemberCards title={MemberProfileDetails?.wallet_balance} name="wallet" />
          </Stack>
        </Stack> */}

        {/* {isMobile === true && (
          <Stack
            flexDirection={isMobile ? 'column' : 'row'}
            flexWrap="wrap"
            my={3}
            // border="2px solid orange"
            width="100%"
          >
            <Stack flex="1 1 10%" minWidth="250px" className="netCard3">
              <MemberCards title={MemberProfileEarnings?.netEarningsTotal} name="Net Earnings" />
            </Stack>
            <Stack flex="1 1 10%" minWidth="250px" className="netCard3">
              <MemberCards
                title={MemberProfileEarnings?.catalystTotal}
                MemberProfileDetails={MemberProfileDetails}
                name="Catalyst Earnings"
              />
            </Stack>
            <Stack flex="1 1 10%" minWidth="250px" className="netCard3">
              <MemberCards title={MemberProfileEarnings?.lineTotal} name="Connect Earnings" />
            </Stack>

            <Stack flex="1 1 10%" minWidth="250px" className="netCard3">
              <MemberCards title={MemberProfileEarnings?.fourthTotal} name="Fourth Earnings" />
            </Stack>

            <Stack flex="1 1 10%" minWidth="250px" className="netCard3">
              <MemberCards title={MemberProfileEarnings?.clubTotal} name="Club Earnings" />
            </Stack>

            <Stack flex="1 1 10%" minWidth="250px" className="netCard3">
              <MemberCards
                title={MemberProfileEarnings?.incentiveTotal}
                name="Incentive Earnings"
                className="netCard3"
              />
            </Stack>
          </Stack>
        )} */}

        {/* {isMobile === false && (
          <Stack
            flexDirection={isMobile ? 'column' : 'row'}
            flexWrap="wrap"
            width="100%"
            my={2}
            // justifyContent="flex-start"
            justifyContent='flex-start'
            border='2px solid orange'
          >
            <Stack flex="1 1 250px" maxWidth="280px"
              sx={{
                border:"2px solid orange",
              }}
            >
              <MemberCards
                title={MemberProfileDetails?.earnings_statistics?.net_earnings_total}
                name="Net Earnings"
              />
            </Stack>
            <Stack flex="1 1 250px" maxWidth="280px">
              <MemberCards
                title={MemberProfileDetails?.earnings_statistics?.catalyst_total}
                MemberProfileDetails={MemberProfileDetails}
                name="Catalyst Earnings"
              />
            </Stack>
            <Stack flex="1 1 250px" maxWidth="280px">
              <MemberCards
                title={MemberProfileDetails?.earnings_statistics?.line_total}
                name="Connect Earnings"
              />
            </Stack>
            <Stack flex="1 1 250px" maxWidth="280px">
              <MemberCards
                title={MemberProfileDetails?.earnings_statistics?.fourth_total}
                name="Fourth Earnings"
              />
            </Stack>
            <Stack flex="1 1 250px" maxWidth="280px">
              <MemberCards
                title={MemberProfileDetails?.earnings_statistics?.club_total}
                name="Club Earnings"
              />
            </Stack>
            <Stack flex="1 1 250px" maxWidth="280px">
              <MemberCards
                title={MemberProfileDetails?.earnings_statistics?.incentive_total}
                name="Incentive Earnings"
              />
            </Stack>
          </Stack>
        )} */}

        {/* {isMobile === false && (
          <Stack
            flexDirection="row"
            flexWrap="wrap"
            width="100%"
            my={2}
            justifyContent="flex-start"
            className="netcard"
          >
            <Stack flex="1 1 23%" maxWidth="30%" className="netCard1">
              <MemberCards title={MemberProfileEarnings?.netEarningsTotal} name="Net Earnings" />
            </Stack>
            <Stack flex="1 1 23%" maxWidth="30%">
              <MemberCards title={MemberProfileEarnings?.catalystTotal} name="Catalyst Earnings" />
            </Stack>
            <Stack flex="1 1 23%" maxWidth="30%">
              <MemberCards title={MemberProfileEarnings?.lineTotal} name="Connect Earnings" />
            </Stack>
            <Stack flex="1 1 23%" maxWidth="30%">
              <MemberCards title={MemberProfileEarnings?.fourthTotal} name="Fourth Earnings" />
            </Stack>

            <Stack flex="1 1 23%" maxWidth="25%" className="netCard1">
              <MemberCards title={MemberProfileEarnings?.clubTotal} name="Club Earnings" />
            </Stack>
            <Stack flex="1 1 23%" maxWidth="25%">
              <MemberCards
                title={MemberProfileEarnings?.incentiveTotal}
                name="Incentive Earnings"
              />
            </Stack>
          </Stack>
        )} */}
        <EarningsCardMember MemberProfileEarnings={MemberProfileEarnings} />

        {/* <Stack flexDirection={isMobile ? 'column' : 'row'} gap={3} mb={3}>
          <Stack flex={8.1}>
            <WalletAddressMember data={MemberProfileDetails} />
          </Stack>
          <Stack flex={3.9}>
            <JoinedAndReferral />
          </Stack>
        </Stack> */}
        <Grid
          md={12}
          py={3}
          xs={12}
          gap={3}
          sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}
        >
          <Stack flex={5}>
            <MemberAccountsMember memberDetails={MemberProfileDetails} />
          </Stack>
          <Stack flex={7}>
            <WalletAddressDetailsMember data={MemberProfileDetails} />

            {/* {memberDetails?.is_verified === true ? (
              <VerifiedDetails memberDetails={memberDetails} />
            ) : (
              <>
                {memberDetails?.referral_member?.name && (
                  <Stack pb={2} height="100%">
                    <ReferredByDetails memberDetails={memberDetails} />
                  </Stack>
                )}
                <Stack height="100%">
                  <JoinedOnDetails memberDetails={memberDetails} />
                </Stack>{' '}
              </>
            )} */}
          </Stack>
        </Grid>
      </Stack>
    </>
  );
}

import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import parser from 'html-react-parser';
import { React, useState } from 'react';
import { useDispatch } from 'react-redux';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Box, Stack, Button, Tooltip, CardMedia, Typography, IconButton } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { fDateTime } from 'src/utils/format-time';

import noImg from 'src/assets/other/default-img.png';
import { deleteNotification } from 'src/server/api/notification';

import Iconify from 'src/components/iconify';
import TextMaxLine from 'src/components/text-max-line';
import { ConfirmDialog } from 'src/components/custom-dialog';

import ImageShowDialog from './imageShowDialog';

export default function NotificationTableRow({
  row,
  selected,
  onEditRow,
  handleCloseDeleteDialog,
}) {
  const { createdAt, title, description, image } = row;
  const [openModalDialog, setOpenModalDialog] = useState(false);
  const dispatch = useDispatch();
  const confirm = useBoolean();
  // console.log(message)
  // console.log(subject)

  // const handleTypeChange = (event, id) => {
  //   const newStatus = event.target.value;
  //   setStatusValue(newStatus);
  //   // console.log(newStatus);
  //   // console.log('id =>', id);
  //   const updateCredentials = {
  //     state: {
  //       status: newStatus,
  //       id: _id,
  //     },
  //     dispatch,
  //   };

  //   dispatch(updateEnquiryStatus(updateCredentials));
  // };
  const handleClose = () => {
    confirm.onFalse();
  };

  const handleDelete = () => {
    confirm.onTrue();
  };

  const handleDeleteNotificationConfirmClick = () => {
    const credentials = {
      dispatch,
      notificationId: row?._id,
      handleCloseDeleteDialog: handleClose,
    };
    dispatch(deleteNotification(credentials));
  };

  return (
    <>
      {openModalDialog && (
        <ImageShowDialog setOpen={setOpenModalDialog} open={openModalDialog} image={image?.url} />
      )}
      <TableRow hover selected={selected}>
        <TableCell>
          <Box display="flex" alignItems="center" justifyContent="start" gap={1}>
            {image?.url ? (
              <Tooltip title="View ">
                <CardMedia
                  onClick={() => setOpenModalDialog(true)}
                  sx={{
                    height: 60,
                    width: 60,
                    borderRadius: '12px',
                    objectFit: 'contain',
                    cursor: 'pointer',
                  }}
                  component="img"
                  src={image?.url || noImg}
                />
              </Tooltip>
            ) : (
              <CardMedia
                sx={{
                  height: 60,
                  width: 60,
                  borderRadius: '12px',
                  objectFit: 'contain',
                }}
                component="img"
                src={noImg}
              />
            )}
            <Stack>
              <Typography variant="body2" sx={{ cursor: 'pointer' }}>
                {title || '--'}
              </Typography>
            </Stack>
          </Box>
        </TableCell>
        <TableCell
          sx={{
            maxWidth: '300px',
          }}
        >
          <TextMaxLine line={2}>
            <Typography variant="body2">{parser(description) || '--'}</Typography>
          </TextMaxLine>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{fDateTime(createdAt)}</Typography>
        </TableCell>
        {/* <TableCell>
        <TextMaxLine line={2}>
          <Typography variant="body2">{message}</Typography>
        </TextMaxLine>
      </TableCell>
      <TableCell>
        <TextMaxLine line={2}>
          <Typography variant="body2">{subject}</Typography>
        </TextMaxLine>
      </TableCell> */}
        <TableCell>
          <Stack direction="row" alignItems="center">
            <>
              <Tooltip title="Edit">
                <Stack direction="row" alignItems="center">
                  <IconButton
                    color="text.secondary"
                    onClick={() => onEditRow(row)}
                    sx={{
                      borderRadius: '50%',
                    }}
                  >
                    <Iconify icon="solar:pen-bold" />
                  </IconButton>
                </Stack>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  sx={{
                    borderRadius: '50%',
                    color: '#B71D18',
                  }}
                  color="error"
                  onClick={handleDelete}
                >
                  <Iconify icon="solar:trash-bin-trash-bold" />
                </IconButton>
              </Tooltip>
            </>
          </Stack>
        </TableCell>
      </TableRow>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete Notification"
        content="Are you sure you want to delete this notification?"
        action={
          <Button
            fullWidth
            variant="contained"
            sx={{
              backgroundColor: '#7A0916',
            }}
            onClick={handleDeleteNotificationConfirmClick}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

NotificationTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.any,
  handleCloseDeleteDialog: PropTypes.any,
};

import * as Yup from 'yup';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';

import { Box } from '@mui/material';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { LoginApi } from 'src/server/api/auth';

import Iconify from '../../components/iconify';
import { paths } from '../../routes/routes/paths';
// eslint-disable-next-line import/no-unresolved

// import { useAuthContext } from '../../hooks/use-auth-context';
import FormProvider, { RHFTextField } from '../../components/hook-form';

// ----------------------------------------------------------------------

export default function JwtLoginView({ role }) {
  // const { login } = useAuthContext();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useResponsive('down', 'md');

  // const router = useRouter();

  const [errorMsg] = useState('');

  const password = useBoolean();

  const LoginSchema = Yup.object().shape({
    loginIdentifier: Yup.string().required('Email or Username is required'),
    password: Yup.string().required('Password is required'),
  });
  // const { loginData } = useSelector((state) => ({
  //   loginData: state.auth.user,
  //   loading: state.auth.loginLoading,
  // }));
  // console.log(loginData);
  // const defaultValues = {
  //   email: 'demo@minimals.cc',
  //   password: 'demo1234',
  // };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    // defaultValues,
  });

  const {
    // reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (loginFormData) => {
    const credentials = {
      state: loginFormData,
      dispatch,
      navigate,
    };

    dispatch(LoginApi(credentials));
  });

  const renderForm = (
    <Stack height="100vh" flexDirection="column" justifyContent="center" px={isMobile && 2}>
      <Stack spacing={2} sx={{ mb: 3 }} justifyContent="center">
        <Typography variant="h5">Sign in to Winbuks</Typography>
      </Stack>
      <Box>
        <Stack spacing={2.5}>
          <RHFTextField name="loginIdentifier" label="Email" fullWidth />

          <RHFTextField
            name="password"
            label="Password"
            type={password.value ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={password.onToggle} edge="end">
                    <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Link
            href={`${paths.auth.forgot_password}?next=admin_login`}
            variant="body2"
            color="inherit"
            underline="always"
            sx={{ alignSelf: 'flex-end' }}
          >
            Forgot password?
          </Link>

          <LoadingButton
            fullWidth
            color="inherit"
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Login
          </LoadingButton>
        </Stack>
      </Box>
    </Stack>
  );

  return (
    <>
      {/* <Grid paddingY={2} display="flex" alignItems="center" justifyContent="center">
        <Label style={{ width: '50%', height: 35, backgroundColor: '#dddfe2' }}>
          Login With email
        </Label>{' '}
        <Label style={{ width: '50%', height: 35, backgroundColor: '#dddfe2', marginLeft: 2 }}>
          Login With username
        </Label>{' '}
      </Grid> */}

      {/* <Alert severity="info" sx={{ mb: 3 }}>
        Use email : <strong>demo@minimals.cc</strong> / password :<strong> demo1234</strong>
      </Alert> */}

      {!!errorMsg && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {errorMsg}
        </Alert>
      )}

      <FormProvider methods={methods} onSubmit={onSubmit}>
        {renderForm}
      </FormProvider>
    </>
  );
}

JwtLoginView.propTypes = {
  role: PropTypes.string,
};

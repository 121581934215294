import * as React from 'react';
import PropTypes from 'prop-types';

// components
import CloseIcon from '@mui/icons-material/Close';
// @mui
import {
  Stack,
  Dialog,
  Button,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
} from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

// import { createEnquiry, updateEnquiryDetails } from 'src/server/api/enquiry';

SuccessDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  isUpdate: PropTypes.object,
};

export default function SuccessDialog({ open, setOpen, isUpdate }) {
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useResponsive('down', 'md');

  // const { role } = useGetRoles();
  // console.log(isUpdate);

  const handleClose = () => {
    setOpen(false);
  };
  // const router = useRouter();

  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', top: 0, right: 0 }}
        >
          <CloseIcon sx={{ fontSize: '20px' }} />
        </IconButton>
        <DialogTitle id="responsive-dialog-title" sx={{ textAlign: 'center', color: '#22C55E' }}>
          Transfer to personal wallet completed successfully{' '}
        </DialogTitle>
        <DialogContent sx={{ minWidth: isMobile ? '250px' : '480px', padding: '0px', p: 3 }}>
          <Typography variant="body2" color="#637381">
            Your transfer to your personal wallet has been completed successfully. Feel free to
            check your profile to confirm it. If you have any questions about this transaction,
            please reach out to the administrator.
          </Typography>

          <Stack alignItems="flex-end" mt={3}>
            <Button
              onClick={handleClose}
              variant="outlined"
              sx={{
                backgroundColor: 'success.main',
                color: 'white',
                width: '60px',
                '&:hover': {
                  backgroundColor: '#0f7133',
                },
              }}
            >
              Done
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { Box, Stack, Button, CardMedia, Typography, ListItemText } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

import image from 'src/assets/other/person1.jpg';
import { getAllMembers } from 'src/server/api/member';
import AllVerified from 'src/assets/other/AllVerified.webp';

import Iconify from 'src/components/iconify';

import ScreenshotView from 'src/sections/Member/ScreenshotView';

export default function AdminDashboardVerifiedList({ chart, ...other }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState({ status: false, data: {} });
  const isMobile = useResponsive('down', 'md');

  const { membersList } = useSelector((state) => ({
    membersList: state.member.members?.members,
  }));

  // console.log(membersList);
  const HandlePage = () => {
    navigate(paths.dashboard.member, {});
  };

  useEffect(() => {
    const credentials = {
      page: '',
      search: '',
      dispatch,
      limit: 5,
      sort: 'UNVERIFIED',
      date: '',
    };
    dispatch(getAllMembers(credentials));
  }, [dispatch]);

  return (
    <>
      <ScreenshotView open={isOpen?.status} setOpen={setIsOpen} data={isOpen?.data} />

      <Card sx={{ padding: 2, height: '100%' }}>
        <Stack>
          <Stack flexDirection="row" alignItems="center">
            <NewReleasesIcon
              sx={{
                height: 20,
                width: 20,
                color: '#FF5630',
                marginRight: '5px',
              }}
            />
            <Typography variant="h6" py={0.5}>
              Unverified Members
            </Typography>
          </Stack>

          <Box pt={1} mb={2}>
            {membersList && membersList?.length > 0 ? (
              <>
                {membersList?.map((item) => (
                  <Box pb={2} px={1}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <CardMedia
                        component="img"
                        src={image}
                        sx={{ height: 40, width: 40, mr: 1.5, borderRadius: '50%' }}
                      />
                      <ListItemText
                        primary={item?.name}
                        secondary={`ID: ${item?.member_id}`}
                        primaryTypographyProps={{ typography: 'subtitle1', noWrap: true }}
                        secondaryTypographyProps={{
                          mt: 0.5,
                          component: 'span',
                          typography: 'caption',
                          color: '#637381',
                        }}
                      />
                      <Button
                        color="info"
                        variant="outlined"
                        onClick={() => setIsOpen({ status: true, data: item })}
                      >
                        Verify
                      </Button>
                    </Box>
                  </Box>
                ))}
              </>
            ) : (
              <Stack
                mt={isMobile ? '50px' : '127px'}
                alignItems="center"
                justifyContent="center"
                // position="absolute"
                // backgroundColor="pink"
                height="100%"
                width="100%"
                // sx={{
                //   bottom: '50%',
                //   right: '40%',
                // }}
              >
                <CardMedia
                  component="img"
                  src={AllVerified}
                  sx={{ height: 180, width: 150, mr: 1.5 }}
                />
                <Typography variant="caption">All members are verified!</Typography>
              </Stack>
            )}
          </Box>
        </Stack>
        {membersList && membersList?.length > 0 && (
          <Stack>
            <Box
              sx={{ p: 2, pb: 1, textAlign: '' }}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              position="absolute"
              bottom={5}
              right={0}
            >
              <Button
                size="small"
                color="inherit"
                onClick={HandlePage}
                endIcon={<Iconify icon="eva:arrow-ios-forward-fill" width={18} sx={{ ml: -0.5 }} />}
              >
                View All
              </Button>
            </Box>
          </Stack>
        )}
      </Card>
    </>
  );
}

AdminDashboardVerifiedList.propTypes = {
  chart: PropTypes.object,
};

import PropTypes from 'prop-types';

import { Box, Grid, Stack, TextField, Autocomplete } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';
import { useGetRoles } from 'src/hooks/useHandleSessions';

// import BasicDateRangePicker from 'src/pages/Common/Static/calender';

// ----------------------------------------------------------------------

export default function MemberIncentiveToolbar({
  // handleTextFieldChangeClub,
  handleAutocompleteChangeClub,
  optionClub,
  date,
  setDate,
  handleTextFieldChangeMember,
  handleAutocompleteChangeMember,
  optionMembers,
  setPage,
}) {
  const role = useGetRoles()?.role;
  const isMobile = useResponsive('down', 'md');

  // console.log(optionMembers);
  return (
    <>
      {role === 'admin' ? (
        <Stack direction="row" alignItems="center" spacing={2}>
          <Grid container direction="row" alignItems="center">
            <Grid md={3} mt={1} xs={6}>
              <Autocomplete
                id="country-select-demo"
                sx={{ width: '100%' }}
                options={optionClub}
                autoHighlight
                // size="small"
                // className="small-select"
                getOptionLabel={(option) => option.label}
                onChange={handleAutocompleteChangeClub}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.label}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Rank Level"
                    // onChange={handleTextFieldChangeClub}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )}
              />
            </Grid>
            <Grid md={5} mt={1} px={1} xs={6}>
              <Autocomplete
                id="country-select-demo"
                sx={{ width: '100%' }}
                options={optionMembers}
                autoHighlight
                // size="small"
                // className="small-select"
                getOptionLabel={(option) =>
                  `${option?.member_id} - ${option?.name} - ${option?.username ?? ''}`
                }
                onChange={handleAutocompleteChangeMember}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.label}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Member"
                    onChange={handleTextFieldChangeMember}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-member',
                    }}
                  />
                )}
              />
            </Grid>
            <Grid md={4} mt={1} className="club_transaction" width="" xs={6}>
              {/* <BasicDateRangePicker selectedRange={date} setSelectedRange={setDate} /> */}
              <Stack direction="row" lg={5} md={5} padding="0px 0px 0px 0px">
                <TextField
                  name="date"
                  label="Start Date"
                  sx={{ width: '100%', minWidth: isMobile && 160, mr: 2 }}
                  size="medium"
                  type="date"
                  variant="filled"
                  inputProps={{ maxLength: 4 }}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    setDate({
                      ...date,
                      start_date: e.target.value,
                    });
                    setPage();
                  }}
                />
                <TextField
                  name="date"
                  label="End Date"
                  sx={{ width: '100%', minWidth: isMobile && 165 }}
                  size="medium"
                  type="date"
                  variant="filled"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    setDate({
                      ...date,
                      end_date: e.target.value,
                    });
                    setPage();
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      ) : (
        <Stack direction="row" width="50%">
          <TextField
            name="date"
            label="Start Date"
            sx={{ width: '100%', minWidth: isMobile && 150, mr: 2 }}
            size="medium"
            type="date"
            variant="outlined"
            inputProps={{ maxLength: 4 }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setDate({
                ...date,
                from: e.target.value,
              });
              setPage();
            }}
          />
          <TextField
            name="date"
            label="End Date"
            sx={{ width: '100%', minWidth: isMobile && 150 }}
            size="medium"
            type="date"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setDate({
                ...date,
                to: e.target.value,
              });
              setPage();
            }}
          />
        </Stack>
      )}
    </>
  );
}

MemberIncentiveToolbar.propTypes = {
  date: PropTypes.string,
  setDate: PropTypes.func,
  optionClub: PropTypes.array,
  // handleTextFieldChangeClub: PropTypes.func,
  handleAutocompleteChangeClub: PropTypes.func,
  optionMembers: PropTypes.array,
  handleTextFieldChangeMember: PropTypes.func,
  handleAutocompleteChangeMember: PropTypes.func,
  setPage: PropTypes.any,
};

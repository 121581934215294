import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import { Card, Divider, CardMedia, Typography } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import vivan from 'src/assets/incentives/vivan.png';
import victor from 'src/assets/incentives/victor.png';
import vibron from 'src/assets/incentives/vibron.png';
import vigour from 'src/assets/incentives/vigour.png';
import moneyBag from 'src/assets/other/dollarBag.svg';

import { useSettingsContext } from 'src/components/settings';

import IncentiveTimer from './memberIncentiveTimer';

// ----------------------------------------------------------------------

export default function IncentiveCard({
  incentiveLevelList,
  verifiedDate,
  memberIncentives,
  role,
}) {
  const isMobile = useResponsive('down', 'md');
  const settings = useSettingsContext();

  const getImageByRank = (rankName) => {
    switch (rankName) {
      case 'VICTOR':
        return victor;
      case 'VIBRON':
        return vibron;
      case 'VIGOUR':
        return vigour;
      case 'VIVIAN':
        return vivan;
      default:
        return null;
    }
  };

  const memberVerifiedDate = new Date(verifiedDate);

  return (
    <>
      <Typography variant="h6" mb={1}>
        {' '}
        {role === 'admin' ? 'Incentive Levels' : 'All Ranks'}
      </Typography>
      <Stack
        spacing={1.3}
        // sx={{ p: 2 }}
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
      >
        {(incentiveLevelList || []).map((level, index) => (
          <Card variant="outlined" sx={{ boxShadow: 'unset', p: 0.8, borderRadius: '15px' }}>
            <CardMedia
              component="img"
              src={getImageByRank(level?.rank_name)}
              alt={level?.rank_name}
              sx={{ width: '100%', height: 'auto', mb: 1 }}
            />
            <Card
              variant="outlined"
              sx={{ boxShadow: '0px 1px 2px 0px #00000017', borderRadius: '10px' }}
            >
              <Stack
                direction="row"
                gap={2}
                borderBottom={
                  settings?.themeMode === 'dark' ? '1px solid #333d48' : '1px solid #e3e7ea '
                }
                sx={{
                  px: 1,
                  py: 2,
                  flex: 1,
                  justifyContent: 'start',
                  alignItems: 'center',
                }}
              >
                <Stack
                  backgroundColor="#f0f0f0"
                  borderRadius="50%"
                  justifyContent="center"
                  alignItems="center"
                  width="32px"
                  height="32px"
                >
                  <CardMedia
                    component="img"
                    src={moneyBag}
                    alt="moneyBag"
                    style={{ width: '20px', height: '20px', objectFit: 'cover' }}
                  />
                </Stack>
                <Stack alignItems="baseline" justifyContent="center">
                  <Typography
                    sx={{
                      typography: 'caption',
                      color: '#919EAB',
                    }}
                  >
                    Incentive amount{' '}
                  </Typography>
                  <Typography variant="subtitle2">
                    {`${level?.monthly_incentive_amount || 0} USDT`}{' '}
                    {level?.is_single_time
                      ? 'Single Shot'
                      : ` x ${level?.incentive_allocation_limit} months`}
                  </Typography>
                </Stack>
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                // py={1}
              >
                <Stack>
                  <Typography pt={1} variant="body2">
                    {level?.total_refferals_count ?? 0}{' '}
                  </Typography>
                  <Typography
                    sx={{
                      typography: 'caption',
                      color: '#919EAB',
                      textAlign: 'center',
                      pb: 1,
                    }}
                  >
                    Referrals{' '}
                  </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem sx={{ mr: 1 }} />

                <Stack>
                  <Typography
                    pt={1}
                    justifyContent="center"
                    variant="body2"
                    component="span"
                    display="flex"
                    alignItems="center"
                  >
                    {level?.membership_age_limit ?? 0}&nbsp;<Typography>days</Typography>
                  </Typography>
                  <Typography
                    sx={{
                      typography: 'caption',
                      color: '#919EAB',
                      textAlign: 'center',
                      pb: 1,
                    }}
                  >
                    Period{' '}
                  </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem sx={{ mr: 1 }} />

                <Stack>
                  <Typography pt={1} variant="body2">
                    {level?.total_team_count ?? 0}{' '}
                  </Typography>
                  <Typography
                    sx={{
                      typography: 'caption',
                      color: '#919EAB',
                      textAlign: 'center',
                      pb: 1,
                    }}
                  >
                    Team{' '}
                  </Typography>
                </Stack>
              </Stack>
              <Divider orientation="horizontal" flexItem sx={{ mr: 0 }} />
              {role !== 'admin' && (
                <Stack m={1}>
                  <IncentiveTimer
                    verifiedDate={memberVerifiedDate}
                    days={level?.membership_age_limit}
                    levelId={level?._id}
                    transactionList={memberIncentives}
                  />
                </Stack>
              )}
            </Card>

            {/* <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              // flexDirection="column"
              flexDirection={isMobile ? 'row' : 'column'}
              sx={{
                position: 'relative',
              }}
            >
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  key={level.id}
                  height="120px"
                  width={isMobile ? 'auto' : '400px'}
                >
                  <Box>
                    <Typography
                      variant="h7"
                      fontWeight="bold"
                      color={
                        (level?.rank_name === 'VICTOR' && '#54B4D3') ||
                        (level?.rank_name === 'VIBRON' && '#3B71CA') ||
                        (level?.rank_name === 'VIGOUR' && '#E4A11B') ||
                        (level?.rank_name === 'VIVIAN' && '#DC4C64') ||
                        // (level?.rank_name === 'SAYAN' && 'error') ||
                        'default'
                      }
                    >
                      {level?.rank_name} <br />
                    </Typography>
                    <Typography variant="caption">
                      {`Referrals: ${level?.total_refferals_count ?? 0}`}
                      <br /> {`Team: ${level?.total_team_count ?? 0}`}
                      <br /> {`Period: ${level?.membership_age_limit ?? 0} days`}
                    </Typography>
                  </Box>
                </Box>
                <Box height="60px">
                  <Typography variant="caption">Incentive Amount:</Typography>
                  <Typography variant="subtitle2">
                    {`${level?.monthly_incentive_amount || 0} USDT`}{' '}
                    {level?.is_single_time
                      ? 'Single Shot'
                      : ` x ${level?.incentive_allocation_limit} months`}
                  </Typography>
                </Box>
              </>
            </Box> */}
          </Card>
        ))}
      </Stack>
    </>
  );
}

IncentiveCard.propTypes = {
  incentiveLevelList: PropTypes.array,
  verifiedDate: PropTypes.any,
  memberIncentives: PropTypes.any,
  role: PropTypes.any,
};

import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';

// components
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
// @mui
import {
  Stack,
  Dialog,
  Button,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
} from '@mui/material';

import WithdrawalRequest from 'src/sections/withdrawalRequest/Withdrawal-requests';

// import { createEnquiry, updateEnquiryDetails } from 'src/server/api/enquiry';

import { useDispatch } from 'react-redux';

import { useResponsive } from 'src/hooks/use-responsive';

import { ResentOtpApi } from 'src/server/api/auth';

import WithdrawalRequestSuccess from 'src/sections/withdrawalRequest/Withdrawal-success-modal';

WithdrawalRequestsConfirmDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  memberProfileDetails: PropTypes.object,
  createDialog: PropTypes.bool,
  setCreateDialog: PropTypes.func,
};

export default function WithdrawalRequestsConfirmDialog({
  open,
  setOpen,
  memberProfileDetails,
  createDialog,
  setCreateDialog,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useResponsive('down', 'md');

  const dispatch = useDispatch();
  const [openWithdrawalRequestSuccess, setOpenWithdrawalRequestSuccess] = useState(false);
  const [openWithdrawalRequest, setOpenWithdrawalRequest] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleReset = () => {
    // Assuming loginData is accessible here
    setOpen(false);
    setOpenWithdrawalRequest(true);
    if (memberProfileDetails?.user?.email) {
      const otpResetCrud = {
        state: {
          email: memberProfileDetails?.user?.email,
        },
        type: 'withdrawal_request',
      };
      dispatch(ResentOtpApi(otpResetCrud));
    }
  };

  return (
    <div>
      {openWithdrawalRequest && (
        <WithdrawalRequest
          setOpen={setOpenWithdrawalRequest}
          open={openWithdrawalRequest}
          setOpenWithdrawalRequestSuccess={setOpenWithdrawalRequestSuccess}
          tabValue={0}
        />
      )}

      {openWithdrawalRequestSuccess && (
        <WithdrawalRequestSuccess
          setOpen={setOpenWithdrawalRequestSuccess}
          open={openWithdrawalRequestSuccess}
        />
      )}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', top: 0, right: 0, m: 1 }}
        >
          <CloseIcon sx={{ fontSize: '20px' }} />
        </IconButton>
        <DialogTitle id="responsive-dialog-title" sx={{ pb: 0 }}>
          Confirmation{' '}
        </DialogTitle>
        <DialogContent sx={{ minWidth: isMobile ? '200px' : '350px', padding: '0px' }}>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="start"
            color="warning"
            padding={2}
            px={4}
          >
            <InfoRoundedIcon sx={{ fontSize: 16 }} color="warning" /> &nbsp;{' '}
            <Typography variant="body2">Are you confirm to withdrawal your wallet</Typography>
          </Stack>
          <Stack
            flexDirection="row"
            alignItems="center"
            my={2}
            pr={1}
            justifyContent={isMobile ? 'center' : 'end'}
          >
            <Button variant="outlined" color="inherit" size="small" onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button
              sx={{
                mx: 1,
                '&:hover': { backgroundColor: 'success.main' },
                backgroundColor: '#047f56',
              }}
              variant="contained"
              color="success"
              size="small"
              onClick={() => handleReset()}
            >
              Confirm
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}

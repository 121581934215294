import { useSelector } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { alpha } from '@mui/material/styles';
import ShareIcon from '@mui/icons-material/Share';
import { CardMedia, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { paths } from 'src/routes/routes/paths';

import { USDT } from 'src/hooks/use-USDT';
import { useResponsive } from 'src/hooks/use-responsive';

// import { fShortenNumber } from 'src/utils/format-number';

import { frontEndUrl } from 'src/server/api/http';
import copyWhite from 'src/assets/other/copy2.png';
import dashboard from 'src/assets/other/dashboard.webp';
import illustration from 'src/assets/other/ic-canceled.png';

import { useSnackbar } from 'src/components/snackbar';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

// import IncomePageWithActiveClub from 'src/sections/income/intex';
// ----------------------------------------------------------------------

export default function IncomeStatic() {
  const { enqueueSnackbar } = useSnackbar();
  // const dispatch = useDispatch();
  // const router = useRouter();
  // const theme = useTheme();

  // const table = useTable({ defaultOrderBy: 'createdAt' });
  const isMobile = useResponsive('down', 'md');

  const { MemberProfileDetails } = useSelector((state) => ({
    loading: state.referrals.loading,
    referralList: state.referrals.referrals,
    MemberProfileDetails: state.member.memberProfileDetails.member,
  }));
  // console.log(referralList);
  const handleCopy = () => {
    enqueueSnackbar('Link copied!', { variant: 'success' });
  };
  const handleClick = () => {
    const memberId = MemberProfileDetails?.member_id; // Extract member ID if available
    const link = `${paths.auth.register}?ref_id=${memberId}`; // Construct the link with member ID

    if (memberId) {
      window.open(link, '_blank'); // Open the link in a new tab
    } else {
      // Handle the case where member ID is not available (optional)
      console.warn('Member ID not available for referral link.');
    }
  };

  return (
    <>
      <CustomBreadcrumbs
        heading="Earnings"
        links={[
          {
            name: 'Dashboard',
            href: paths.dashboard.root,
          },
          {
            name: 'Earnings',
          },

          {
            name: 'List',
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Stack spacing={3} width={isMobile ? '100%' : '80%'}>
        <Card sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
          <Stack flex={1} p={1} spacing={4} justifyContent="center" flexDirection="row">
            <Stack justifyContent="center">
              <CardMedia component="img" src={illustration} alt="" sx={{ width: '120px' }} />
            </Stack>
            <Stack justifyContent="center">
              <Stack flexDirection="row" alignItems="center">
                <Typography variant="h4">
                  {' '}
                  {MemberProfileDetails?.wallet_balance?.toFixed(2) || 0} {USDT}
                  {/* {`${fShortenNumber(
                    MemberProfileDetails?.wallet_balance?.toFixed(2) || 0
                  )} ${USDT}`} */}
                </Typography>
              </Stack>
              <Typography variant="body2" color="#919EAB">
                Personal Wallet Balance{' '}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            flex={1}
            backgroundColor="#cdf8ea"
            p={3}
            px={2}
            flexDirection={isMobile ? 'column' : 'row'}
            gap={1.5}
            sx={{ borderLeft: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.2)}` }}
          >
            <Stack flex={2.4}>
              <Typography className="currently" fontWeight={700} color="#004B50" mb={1}>
                Currently don&apos;t have any active clubs{' '}
              </Typography>

              <Typography className="refer" color="#004B50">
                Refer 3 members to achieve Club Memberships and receive Club Incomes
              </Typography>
            </Stack>
            <Stack
              flex={1}
              gap={2}
              justifyContent="space-between"
              alignItems="center"
              flexDirection={isMobile ? 'column' : 'column'}
            >
              <CopyToClipboard
                // text={`${paths.auth.register}?ref='${MemberProfileDetails?.member_id}`}
                text={`${frontEndUrl}${paths.auth.register}?ref_id=${MemberProfileDetails?.member_id}`}
                onCopy={handleCopy}
              >
                <Button
                  size="small"
                  variant="contained"
                  startIcon={
                    <CardMedia component="img" src={copyWhite} alt="copy" sx={{ width: '15px' }} />
                  }
                  sx={{
                    width: '165px',
                    backgroundColor: '#00A76F',
                    height: '38px',
                    '&:hover': { backgroundColor: '#047f56' },
                  }}
                >
                  Copy Referral Link{' '}
                </Button>
              </CopyToClipboard>
              <Button
                size="small"
                variant="outlined"
                startIcon={<ShareIcon />}
                onClick={handleClick}
                sx={{ color: '#00A76F', width: '165px', height: '38px' }}
              >
                Share Referral Link{' '}
              </Button>
            </Stack>
          </Stack>
        </Card>
      </Stack>
      {/* <Stack mt={10} width={isMobile ? '100%' : '80%'}>
        <Stack flexDirection="row" alignItems="center" mb={2} mx={1}>
          <WarningIcon sx={{ marginRight: '5px', color: '#637381', fontSize: '20px' }} />
          <Typography variant="subtitle2" fontWeight={400} color="#637381">
            Demo image of your club dashboard shown bellow
          </Typography>
        </Stack>
        <CardMedia component="img" src={FrameImg} alt="" sx={{ width: '100%' }} />
      </Stack> */}
      <Stack spacing={3} width={isMobile ? '100%' : '80%'} mt={5}>
        <Card sx={{ p: 4, minHeight: '250px' }}>
          <Stack alignItems="center">
            <CardMedia component="img" src={dashboard} alt="dashboard" sx={{ width: '250px' }} />
          </Stack>
          <Stack alignItems="center" flexDirection="row" justifyContent="center">
            <ErrorOutlineIcon sx={{ marginRight: '5px', color: '#697077', fontSize: '15px' }} />
            <Typography
              variant="caption"
              color="#697077"
              justifyContent="center"
              alignItems="center"
              mt={0.2}
            >
              Club Dashboard will be available after achieving Club membership.{' '}
            </Typography>
          </Stack>
        </Card>
      </Stack>
    </>
  );
}

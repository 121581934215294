import { createSlice } from '@reduxjs/toolkit';

import {
  createFaq,
  deleteFaq,
  getAllFaqs,
  updateFaqDetails,
  createNotification,
  deleteNotification,
  getAllNotifications,
  updateNotificationDetails,
  getAllNotificationByMember,
} from '../api/notification';

const covertToJSON = (data) => JSON.parse(JSON.stringify(data));

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    loading: false,
    faqLoading: false,
    error: {},
    // wallet
    notificationList: {},
    faqList: {},
    memberNotification: {},
  },
  reducers: {
    clearWalletError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create wallet details
    [createNotification.pending]: (state) => {
      state.loading = true;
    },
    [createNotification.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.notificationList;
      const modifiedNotificationList = {
        ...jsonState,
        list: Array.isArray(jsonState?.list)
          ? // eslint-disable-next-line no-unsafe-optional-chaining
            [action.payload, ...jsonState?.list]
          : [action.payload],
      };
      state.loading = false;
      state.notificationList = modifiedNotificationList;
      state.error = {};
    },
    [createNotification.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all notification
    [getAllNotifications.pending]: (state) => {
      state.loading = true;
    },
    [getAllNotifications.fulfilled]: (state, action) => {
      state.loading = false;
      state.notificationList = action.payload;
      state.error = {};
    },
    [getAllNotifications.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // update notification details
    [updateNotificationDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateNotificationDetails.fulfilled]: (state, action) => {
      state.loading = false;

      const jsonState = covertToJSON(state)?.notificationList;

      const modifiedNotificationList = {
        ...jsonState,
        list: jsonState.list?.map((i) => (i?._id === action?.payload?._id ? action?.payload : i)),
      };

      console.log(action.payload);
      console.log(modifiedNotificationList);

      state.notificationList = modifiedNotificationList;
      state.error = {};
    },

    [updateNotificationDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteNotification.pending]: (state) => {
      state.loading = true;
    },
    [deleteNotification.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.notificationList;
      const modifiedList = {
        ...jsonState,
        list: jsonState.list?.filter((notification) => notification._id !== action.payload),
      };
      state.loading = false;
      state.notificationList = modifiedList;
      state.error = {};
    },
    [deleteNotification.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // faq

    [createFaq.pending]: (state) => {
      state.faqLoading = true;
    },
    [createFaq.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.faqList;
      const modifiedNotificationList = {
        ...jsonState,
        list: Array.isArray(jsonState?.list)
          ? // eslint-disable-next-line no-unsafe-optional-chaining
            [action.payload, ...jsonState?.list]
          : [action.payload],
      };
      state.faqLoading = false;
      state.faqList = modifiedNotificationList;
      state.error = {};
    },
    [createFaq.rejected]: (state, action) => {
      state.faqLoading = false;
      state.error = action.error;
    },

    // get all faq
    [getAllFaqs.pending]: (state) => {
      state.faqLoading = true;
    },
    [getAllFaqs.fulfilled]: (state, action) => {
      state.faqLoading = false;
      state.faqList = action.payload;
      state.error = {};
    },
    [getAllFaqs.rejected]: (state, action) => {
      state.faqLoading = false;
      state.error = action.error;
    },

    // update faq details
    [updateFaqDetails.pending]: (state) => {
      state.faqLoading = true;
    },
    [updateFaqDetails.fulfilled]: (state, action) => {
      state.faqLoading = false;

      const jsonState = covertToJSON(state)?.faqList;

      const modifiedFaqList = {
        ...jsonState,
        list: jsonState.list?.map((i) => (i?._id === action?.payload?._id ? action?.payload : i)),
      };

      console.log(action.payload);
      console.log(modifiedFaqList);

      state.faqList = modifiedFaqList;
      state.error = {};
    },

    [updateFaqDetails.rejected]: (state, action) => {
      state.faqLoading = false;
      state.error = action.error;
    },

    [deleteFaq.pending]: (state) => {
      state.faqLoading = true;
    },
    [deleteFaq.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.faqList;
      const modifiedList = {
        ...jsonState,
        list: jsonState.list?.filter((notification) => notification._id !== action.payload),
      };
      state.faqLoading = false;
      state.faqList = modifiedList;
      state.error = {};
    },
    [deleteFaq.rejected]: (state, action) => {
      state.faqLoading = false;
      state.error = action.error;
    },
    // notification
    [getAllNotificationByMember.pending]: (state) => {
      state.faqLoading = true;
    },
    [getAllNotificationByMember.fulfilled]: (state, action) => {
      state.faqLoading = false;
      state.memberNotification = action.payload;
      state.error = {};
    },
    [getAllNotificationByMember.rejected]: (state, action) => {
      state.faqLoading = false;
      state.error = action.error;
    },
  },
});
export const { clearWalletError } = notificationSlice.actions;

export default notificationSlice.reducer;

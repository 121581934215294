/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import { Box, CircularProgress } from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useGetRoles, useGetToken } from 'src/hooks/useHandleSessions';

import { TOKEN_PREFIX } from 'src/server/api/http';
import { getMemberProfileDetails } from 'src/server/api/member';

import { SplashScreen } from 'src/components/loading-screen';

export default function AuthGuardVerifyMember({ children }) {
  const { loading } = useSelector((state) => ({
    loading: state.member.loginLoading,
  }));

  return <>{loading ? <SplashScreen /> : <Container> {children}</Container>}</>;
}

AuthGuardVerifyMember.propTypes = {
  children: PropTypes.node,
};

// ----------------------------------------------------------------------

function Container({ children }) {
  const router = useRouter();
  const dispatch = useDispatch();

  const { token } = useGetToken(TOKEN_PREFIX);
  const [checked, setChecked] = useState(false);
  console.log(checked);
  const { role } = useGetRoles();

  const { MemberProfileDetails } = useSelector((state) => ({
    MemberProfileDetails: state.member.memberProfileDetails.member,
  }));

  useEffect(() => {
    const credential = {
      dispatch,
    };
    dispatch(getMemberProfileDetails(credential));
  }, [dispatch]);

  const check = useCallback(() => {
    if (!token) {
      router.replace(paths.public.landing);
    } else if (role === 'member' && MemberProfileDetails?.is_verified) {
      setChecked(true);

      // console.log(MemberProfileDetails);
      // console.log(role === 'member' && MemberProfileDetails?.is_verified);
    }
  }, [MemberProfileDetails, role, router, token]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MemberProfileDetails]);

  if (
    role === 'member' &&
    MemberProfileDetails?.is_verified !== undefined &&
    !MemberProfileDetails?.is_verified
  ) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '600px',
          color: 'rebeccapurple',
        }}
      >
        This feature is accessible exclusively to Verified Members
      </Box>
    );
  }
  if (role === 'member' && MemberProfileDetails?.is_verified) {
    return <>{children}</>;
  }

  return <CircularProgress color="success" />;
}

Container.propTypes = {
  children: PropTypes.node,
};

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { alpha } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import {
  Paper,
  Alert,
  Divider,
  TableRow,
  TableCell,
  CardMedia,
  Typography,
  CircularProgress,
} from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { USDT } from 'src/hooks/use-USDT';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import wallet from 'src/assets/other/wallet.png';
import { decimalLimit } from 'src/server/api/http';
import money_bag from 'src/assets/other/money_bag.svg';
import illustration from 'src/assets/other/ic-canceled.png';
import {
  memberWalletAddress,
  getAllWithdrawalRequest,
  getAllWithdrawalRequestAdmin,
} from 'src/server/api/withdrawalRequest';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import WithdrawalRequestSort from 'src/sections/withdrawalRequest/withdrawalRequestSort';
import WithdrawalRequestToolbar from 'src/sections/withdrawalRequest/withdrawalRequestToolbar';
import WithdrawalRequestTableRow from 'src/sections/withdrawalRequest/withdrawalRequestTableRow';
import WithdrawalRequestsConfirmDialog from 'src/sections/withdrawalRequest/Withdrawal-requests-confirm';

import WithdrawalSection from './WithdrawalSection';

// ----------------------------------------------------------------------

export default function WithdrawalRequestList({ memberSingle }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const role = useGetRoles()?.role;
  const router = useRouter();

  const table = useTable({
    defaultOrderBy: 'createdAt',
    defaultRowsPerPage: role === 'admin' ? 25 : 5,
  });

  const confirm = useBoolean();
  const isMobile = useResponsive('down', 'md');
  const [sort, setSort] = useState('');
  const [date, setDate] = useState('');

  const {
    loading,
    dataList,
    memberProfileDetails,
    settingsData,
    // memberWalletAddress: memberWalletAddressDetails,
    // memberWalletAddressList,
  } = useSelector((state) => ({
    loading: state.withdrawalRequest.loading,
    dataList: state.withdrawalRequest.withdrawalRequests,
    memberProfileDetails: state.member.memberProfileDetails.member,
    settingsData: state.settings.settingsDetails,
    memberWalletAddress: state.walletAddress?.walletAddresses?.memberWalletAddresses,
    memberWalletAddressList: state.withdrawalRequest?.memberWalletAddressList,
  }));

  const identifier = 'withdrawal_request';
  const storedRowsPerPage = sessionStorage.getItem(`rowsPerPage_${identifier}`);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [searchText, setSearchText] = useState();
  const [tableData, setTableData] = useState([]);
  const totalPage = dataList?.total;

  const TABLE_HEAD =
    role === 'admin'
      ? [
          { id: 'created', label: 'Date' },
          { id: 'member_id', label: 'Request ID' },
          // { id: 'name', label: 'Member' },
          ...(memberSingle ? [] : [{ id: 'name', label: 'Member' }]),
          { id: 'phone', label: 'Amount' },
          { id: 'deduction', label: 'Deduction (10%)' },
          { id: 'remarks', label: 'Remarks' },
          { id: 'status', label: 'Status' },
          { id: 'action', label: 'Action' },
        ]
      : [
          { id: 'created', label: 'Date' },
          { id: 'request_id', label: 'Request ID' },
          { id: 'amount', label: 'Amount' },
          // { id: 'phone', label: 'Remarks' },
          { id: 'status', label: 'Status' },
          { id: '', label: '' },
        ];

  const [sortOrder, setSortOrder] = useState('');

  const handleChangeSortOrder = (value) => {
    setSortOrder(value);
  };

  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      limit: memberSingle ? 5 : storedRowsPerPage || table.rowsPerPage,
      sort,
      date,
      search: searchText || '',
      dispatch,
      member: memberSingle,
      sort_order: sortOrder,
    };
    if (role === 'admin') {
      dispatch(getAllWithdrawalRequestAdmin(credentials));
    } else {
      dispatch(getAllWithdrawalRequest(credentials));
    }
  }, [
    date,
    dispatch,
    sortOrder,
    searchText,
    sort,
    role,
    storedRowsPerPage,
    table.page,
    table.rowsPerPage,
    memberSingle,
  ]);
  // console.log(searchText);
  useEffect(() => {
    setTableData(dataList?.withdrawalRequests);
  }, [dataList]);

  useEffect(() => {
    const credentials = {
      page: '',
      search: '',
      limit: 5,
      dispatch,
    };
    if (role !== 'admin') {
      dispatch(memberWalletAddress(credentials));
    }
  }, [dispatch, role]);

  const dataInPage = [];

  const canReset = false;

  const notFound = (!tableData?.length && canReset) || !tableData?.length;

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData?.filter((row) => row.id !== id);
      enqueueSnackbar('Delete success!');
      setTableData(deleteRow);
      table.onUpdatePageDeleteRow(dataInPage?.length);
    },
    [dataInPage?.length, enqueueSnackbar, table, tableData]
  );

  const handleDeleteRows = () => {};

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.edit(id));
    },
    [router]
  );

  const handleViewRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.details(id));
    },
    [router]
  );

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const getWidth = () => {
    if (memberSingle) {
      return '100%';
    }
    if (isMobile) {
      return '100%';
    }
    return '90%';
  };

  const setPage = () => {
    table.setPage(0);
  };

  return (
    <>
      <WithdrawalRequestsConfirmDialog
        open={confirmDialog}
        setOpen={() => setConfirmDialog()}
        memberProfileDetails={memberProfileDetails}
      />
      {!memberSingle && (
        <CustomBreadcrumbs
          heading="Withdrawals"
          links={[
            {
              name: 'Dashboard',
              href: paths.dashboard.root,
            },
            {
              name: 'withdrawal',
            },

            {
              name: 'List',
            },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
      )}
      <Stack
        spacing={3}
        // width={isMobile ? '100%' : '80%'}
        // direction={role !== 'admin' ? 'row' : 'column'}
        direction={role === 'admin' || (role !== 'admin' && isMobile) ? 'column' : 'row'}
        width={
          role === 'admin'
            ? getWidth()
            : '100%                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         '
        }
      >
        {role === 'admin' ? (
          !memberSingle && (
            <Card sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }} width="100%">
              <Stack flex={1} p={1} spacing={4} justifyContent="start" flexDirection="row" pl={5}>
                <Stack justifyContent="center">
                  <CardMedia component="img" src={illustration} alt="" sx={{ width: '120px' }} />
                </Stack>
                <Stack justifyContent="center">
                  <Typography variant="h4">
                    {/* {`${fShortenNumber(
                    settingsData?.company_wallets?.actual_wallet_balance?.toFixed(2) || (0).toFixed(2)
                  )} ${USDT}`} */}
                    {`${
                      settingsData?.company_wallets?.actual_wallet_balance?.toFixed(2) ||
                      (0).toFixed(2)
                    }${USDT}`}
                  </Typography>

                  <Typography variant="body2" color="#919EAB">
                    Actual Wallet Balance{' '}
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                flex={1}
                backgroundColor="#cdf8ea"
                p={3}
                flexDirection={isMobile ? 'column' : 'row'}
                gap={2}
                sx={{ border: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.2)}` }}
              >
                <Stack flex={2.2}>
                  <Typography variant="body2" color="#004B50">
                    You can view the requests made by members to withdraw funds. To approve a
                    request, simply click the `Approve` button next to the request
                  </Typography>

                  <Typography variant="body2" color="#004B50" mt={1}>
                    Conversely, if a request cannot be approved, you can reject it by clicking the
                    `Reject` button. When rejecting a request, it`s important to add remarks
                    explaining why it was rejected.
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          )
        ) : (
          <Card
            variant="outlined"
            sx={{
              display: 'flex',
              flex: 3,
              boxShadow: 'unset',
              flexDirection: isMobile ? 'column' : 'column',
            }}
          >
            <Stack pt={5.5} justifyContent="space-between">
              <Stack
                direction="column"
                gap={2}
                sx={{
                  flex: 1,
                  pb: 4,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Stack
                  p={2}
                  backgroundColor="#f0f0f0"
                  borderRadius="50%"
                  justifyContent="center"
                  alignItems="center"
                  width="60px"
                  height="60px"
                >
                  <CardMedia
                    component="img"
                    src={wallet}
                    alt="wallet"
                    style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                  />
                </Stack>
                <Stack alignItems="center" justifyContent="center">
                  <Typography variant="body2" fontWeight="bold">
                    {`${memberProfileDetails?.buks_wallet_balance?.toFixed(decimalLimit) || (0).toFixed(2)} ${USDT}`}
                  </Typography>
                  <Typography
                    sx={{
                      typography: 'caption',
                      opacity: 0.68,
                    }}
                  >
                    Buks Wallet Balance
                  </Typography>
                </Stack>
              </Stack>

              <WithdrawalSection setConfirmDialog={setConfirmDialog} />
            </Stack>
            <Divider sx={{ mx: 2 }} />
            <Stack m={2} gap={2}>
              <Stack gap={1} direction="row">
                <CardMedia
                  component="img"
                  src={money_bag}
                  alt="wallet"
                  style={{ width: '15px', height: '15px', objectFit: 'cover', marginTop: 4 }}
                />
                <Stack gap={0.5}>
                  <Typography variant="subtitle2">Withdrawal Guideline</Typography>
                  <Typography variant="caption" sx={{ opacity: 0.68 }}>
                    You can withdraw minimum of 10 USDT and a maximum amount equal to your club
                    limit.{' '}
                  </Typography>
                </Stack>
              </Stack>
              <Stack gap={1} direction="row">
                <CardMedia
                  component="img"
                  src={money_bag}
                  alt="wallet"
                  style={{ width: '15px', height: '15px', objectFit: 'cover', marginTop: 4 }}
                />
                <Stack gap={0.5}>
                  <Typography variant="subtitle2">Withdrawal Process</Typography>
                  <Typography variant="caption" sx={{ opacity: 0.68 }}>
                    The withdrawal request will be processed within the next 72 hours. You will be
                    notified via your registered email once it is processed.{' '}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Card>
        )}
        <Card variant="outlined" sx={{ boxShadow: 'unset', flex: 7 }}>
          {!memberSingle && (
            <Stack p={2}>
              <Typography variant="h6">
                Withdrawal requests ({dataList?.withdrawalRequestCounts?.allWithdrawalRequestCount})
              </Typography>
            </Stack>
          )}
          <WithdrawalRequestSort
            setPage={setPage}
            sort={sort}
            setSort={setSort}
            dataList={dataList}
          />

          <Stack mx={2} my={3}>
            <WithdrawalRequestToolbar
              setPage={setPage}
              onSearch={handleSearch}
              date={date}
              setDate={setDate}
              sortOrder={sortOrder}
              handleChangeSortOrder={handleChangeSortOrder}
            />
          </Stack>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) => {
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                );
              }}
            />
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={table.selected?.length}
                  onSort={table.onSort}
                />

                {loading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <CircularProgress color="inherit" />
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {(tableData || []).map((row, index) => (
                      <WithdrawalRequestTableRow
                        index={index}
                        page={table.page + 1}
                        rowsPerPage={storedRowsPerPage || table.rowsPerPage || 25}
                        key={row.id}
                        row={row}
                        selected={table?.selected?.includes(row.id)}
                        onSelectRow={() => table.onSelectRow(row.id)}
                        onViewRow={() => handleViewRow(row.id)}
                        onEditRow={() => handleEditRow(row.id)}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                        loading={loading}
                        colSpan={TABLE_HEAD?.length}
                        memberSingle={memberSingle}
                      />
                    ))}
                  </TableBody>
                )}

                {/* <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData?.length)}
                />  */}

                <TableNoData notFound={notFound} />
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            customRowsPerPage={role === 'admin' && !memberSingle && 25}
            count={totalPage || 0}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
            identifier={identifier}
          />
        </Card>
      </Stack>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

WithdrawalRequestList.propTypes = {
  memberSingle: PropTypes.string,
};

const WithdrawalNotification = ({ color, message }) => (
  <Stack mx={2} mb={2}>
    <Alert
      className="alert-custom"
      icon={<Iconify icon="material-symbols:info" sx={{ color: color || '#7A4100' }} />}
      sx={{
        bgcolor: '#F8F6F2',
        color: '#7A4100',
        borderRadius: '10px',
        display: 'inline-flex',
        alignItems: 'center',
        p: 0.5,
        px: 1,
        mb: 1,
        width: '100%',
      }}
    >
      <Typography variant="caption" color="#7A4100" sx={{ fontWeight: 500, lineHeight: '1px' }}>
        {message}{' '}
      </Typography>
    </Alert>
  </Stack>
);

WithdrawalNotification.propTypes = {
  color: PropTypes.string,
  message: PropTypes.string,
};

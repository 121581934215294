import React from 'react';

import { Container } from '@mui/material';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import { useSettingsContext } from 'src/components/settings';

import WithdrawalRequestList from './WithdrawalRequestsPage';

function AllMemberWithdrawalRequests() {
  const settings = useSettingsContext();

  return (
    <>
      <MetaHelmet title="Winbuks" miniDescription="Withdrawal Request" />
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <WithdrawalRequestList />
      </Container>
    </>
  );
}

export default AllMemberWithdrawalRequests;

/* eslint-disable import/no-unresolved */
import React from 'react';

import { Container } from '@mui/material';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import { useSettingsContext } from 'src/components/settings';

import Transaction from './EnquiryPage';

function Enquiry() {
  const settings = useSettingsContext();
  return (
    <>
      <MetaHelmet title="Winbuks" miniDescription="Transaction" />
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <Transaction />
      </Container>
    </>
  );
}

export default Enquiry;

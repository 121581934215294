import * as React from 'react';
import PropTypes from 'prop-types';

// components
import CloseIcon from '@mui/icons-material/Close';
// @mui
import { Stack, Dialog, IconButton, DialogContent } from '@mui/material';

// import { createEnquiry, updateEnquiryDetails } from 'src/server/api/enquiry';

import { useResponsive } from 'src/hooks/use-responsive';

import UpdateMemberEmailContent from './update-member-email-content';

UpdateMemberEmailDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setOpenUpdateMemberEmailSuccess: PropTypes.func,
  memberDetails: PropTypes.any,
  tabValue: PropTypes.any,
};

export default function UpdateMemberEmailDialog({
  open,
  setOpen,
  setOpenUpdateMemberEmailSuccess,
  memberDetails,
  tabValue,
}) {
  const isMobile = useResponsive('down', 'md');

  const handleClose = () => {
    setOpen(false);
    setActiveStep(0);
  };
  // const [activeStep, setActiveStep] = React.useState(3);
  const [activeStep, setActiveStep] = React.useState(tabValue);

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handlePreviousStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  // console.log('tabValue', tabValue);

  // console.log('activeStep', activeStep);

  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', top: 0, right: 0 }}
        >
          <CloseIcon sx={{ fontSize: '20px' }} />
        </IconButton>
        {/* <DialogTitle id="responsive-dialog-title">Verify current email</DialogTitle> */}
        <DialogContent sx={{ minWidth: isMobile ? 'auto' : '400px', padding: '0px' }}>
          {/* <OnBoardingStepper activeStep={activeStep} steps={steps} h /> */}

          <Stack>
            <UpdateMemberEmailContent
              activeStep={activeStep}
              memberDetails={memberDetails}
              setActiveStep={setActiveStep}
              handleNextStep={handleNextStep}
              handleClose={handleClose}
              handlePreviousStep={handlePreviousStep}
              setOpenUpdateMemberEmailSuccess={setOpenUpdateMemberEmailSuccess}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}

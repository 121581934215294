import React from 'react';

import { Container } from '@mui/material';

import { useSettingsContext } from 'src/components/settings';

import ClubMemberLists from './ClubMembersPage';

function AllClubMembers() {
  const settings = useSettingsContext();
  return (
    <>
      {/* <MetaHelmet title="Members" /> */}

      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <ClubMemberLists />
      </Container>
    </>
  );
}

export default AllClubMembers;

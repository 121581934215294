import { createAsyncThunk } from '@reduxjs/toolkit';

import { get } from './http';
import { activeSnack } from '../store/common';

export const getAllTransactions = createAsyncThunk(
  'transaction/list',
  async ({ page, limit, transaction_type, date, dispatch, wallet_type }) => {
    try {
      const response = await get(
        `/wallet-transaction/member/all?page=${page && page}&limit=${limit || 5}&transaction_type=${
          transaction_type || ''
        }&dateFrom=${date.from || ''}&dateTo=${date.to || ''}&wallet_type=${wallet_type || ''}`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllClubIncomeTransactions = createAsyncThunk(
  'club-income-transaction/list',
  async ({ page, limit, date, sort, dispatch }) => {
    try {
      const response = await get(
        `/club-income-transaction/member/all?page=${page && page}&limit=${limit || 5}&dateFrom=${
          date.from || ''
        }&dateTo=${date.to || ''}&sort=${sort || ''}`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllTransactionsAdmin = createAsyncThunk(
  'transaction/list',
  async ({
    page,
    limit,
    transaction_type,
    date,
    dispatch,
    member,
    wallet_type,
    selectedCategory,
  }) => {
    // console.log('member =>', member);
    try {
      const response = await get(
        `/wallet-transaction/admin/all?page=${page && page}&limit=${limit || 5}&transaction_type=${
          transaction_type || ''
        }&dateFrom=${date.from || ''}&dateTo=${date.to || ''}&member=${
          member || ''
        }&wallet_type=${wallet_type || ''}&category=${selectedCategory || ''}`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return '';
    } catch (error) {
      // dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

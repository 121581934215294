import * as React from 'react';
import PropTypes from 'prop-types';

import { LoadingButton } from '@mui/lab';
// components
import CloseIcon from '@mui/icons-material/Close';
// @mui
import { Box, Stack, Dialog, Button, IconButton, Typography, DialogContent } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import { useSettingsContext } from 'src/components/settings';

// import { createEnquiry, updateEnquiryDetails } from 'src/server/api/enquiry';

WithdrawalConfirmationModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  clickAction: PropTypes.any,
  handleClose: PropTypes.any,
  loading: PropTypes.any,
};

export default function WithdrawalConfirmationModal({
  open,
  setOpen,
  clickAction,
  handleClose,
  loading,
}) {
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useResponsive('down', 'md');
  const { themeMode } = useSettingsContext();

  // const { role } = useGetRoles();
  // console.log(isUpdate);

  // const router = useRouter();
  console.log(loading);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="sm"
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '12px',
            padding: isMobile ? '16px' : '20px',
            maxWidth: 480,
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            backgroundColor: themeMode === 'dark' ? 'transparent' : '#DFE3E8',
            '&:hover': {
              backgroundColor: themeMode === 'dark' ? 'transparent' : 'rgba(223, 227, 232, 1)',
            },
          }}
        >
          <CloseIcon sx={{ fontSize: '18px' }} />
        </IconButton>

        <DialogContent
          sx={{ maxWidth: isMobile ? '100%' : '480px', px: '24px', pt: '24px', pb: '10px' }}
        >
          <Stack spacing={2}>
            <Typography variant="h6" component="h2" textAlign="center">
              Confirm Initiate Withdrawal
            </Typography>
            <Typography variant="body2" textAlign="center">
              To process your withdrawal, refer a new member and get verified after clicking the
              <Typography component="span" variant="subtitle2" pl={0.7}>
                Initiate button.
              </Typography>
            </Typography>
          </Stack>
          <Box mt={4} display="flex" gap={2}>
            <Button
              variant="outlined"
              color="inherit"
              fullWidth
              onClick={handleClose}
              sx={{ textTransform: 'none' }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="success"
              fullWidth
              onClick={clickAction}
              loading={loading}
              sx={{ textTransform: 'none' }}
            >
              Initiate
            </LoadingButton>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import { Box, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import ListItemText from '@mui/material/ListItemText';

import { USDT } from 'src/hooks/use-USDT';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import { fDate, fTime } from 'src/utils/format-time';

import Label from 'src/components/label/label';
// import { useSettingsContext } from 'src/components/settings';
import TextMaxLine from 'src/components/text-max-line/text-max-line';

// ----------------------------------------------------------------------

export default function TransactionTableRow({ row, selected, bionium, memberSingle, type }) {
  const role = useGetRoles()?.role;
  // const settings = useSettingsContext();

  const { createdAt, amount, description, wallet_type, transaction_type, member } = row;
  // const getColorByTransactionType = () => {
  //   switch (transaction_type) {
  //     case 'CREDIT':
  //       return 'success';
  //     case 'DEBIT':
  //       return 'error';
  //     default:
  //       return 'default';
  //   }
  // };
  // console.log(settings);
  const renderContent = () => {
    if (role === 'admin' && memberSingle) {
      return null;
    }

    if (role === 'admin') {
      return (
        <ListItemText
          primary={member?.name || '---'}
          secondary={member?.member_id}
          primaryTypographyProps={{ variant: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            variant: 'caption',
          }}
        />
      );
    }
    return (
      <TextMaxLine line={2}>
        <Typography variant="body2">{description || '---'}</Typography>
      </TextMaxLine>
    );
  };

  const handleWalletType = () => {
    switch (wallet_type) {
      case 'COMPANY':
        return 'Company';
      case 'CATALYST':
        return 'Catalyst';
      case 'MEMBER':
        return 'Member';
      case 'CONNECT':
        return 'Connect';
      case 'MAINTENANCE':
        return 'Maintenance';
      case 'FUTURE_PROJECTS':
        return 'Future Projects';
      case 'ACTUAL':
        return 'Actual';
      case 'CLUB':
        return 'Club';
      case 'FOURTH':
        return 'Fourth';
      case 'INCENTIVE':
        return 'Incentive';
      case 'BUKS':
        return 'Buks';
      case 'W2W':
        return 'W2W';
      case 'DIRECT':
        return 'Direct';
      case 'LEVERAGE':
        return 'Leverage';
      default:
        return ''; // Fallback for unmatched cases
    }
  };

  return (
    <TableRow
      hover
      selected={selected}
      // sx={{ backgroundColor: transaction_type === 'CREDIT' ? '#ebf8f3' : '' }}
      // sx={{
      //   backgroundColor:
      //     transaction_type === 'CREDIT' && settings?.themeMode !== 'dark' ? '#ebf8f3' : '',
      // }}
    >
      <TableCell>
        {role !== 'admin' ? (
          <ListItemText
            primary={fDate(createdAt)}
            secondary={fTime(createdAt)}
            primaryTypographyProps={{ variant: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              variant: 'caption',
            }}
          />
        ) : (
          <ListItemText
            primary={fDate(createdAt)}
            secondary={fTime(createdAt)}
            primaryTypographyProps={{ variant: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              variant: 'caption',
            }}
          />
        )}
      </TableCell>
      {(role !== 'admin' || !memberSingle) && (
        <TableCell>
          {renderContent()}

          {/* {role === 'admin'  ? (

          <ListItemText
            primary={member?.name || '---'}
            secondary={member?.member_id}
            primaryTypographyProps={{ variant: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              variant: 'caption',
            }}
          />
          
        ) : (
          // <>{member?.name ? member?.name : '---'}</>
          <TextMaxLine line={2}>
            <Typography variant="body2">{description || '---'}</Typography>
          </TextMaxLine>
        )} */}
        </TableCell>
      )}

      {/* <TableCell>
        <Typography fontWeight="600" fontSize="14px">{`${amount?.toFixed(2)} ${USDT}`}</Typography>
      </TableCell> */}
      {/* <TableCell>{wallet}</TableCell> */}
      {!type && (
        <TableCell>
          {role === 'admin' ? (
            <TextMaxLine line={2}>
              <Typography variant="body2">{description}</Typography>
            </TextMaxLine>
          ) : (
            <Label variant="soft" color="info">
              {/* {wallet_type} */}
              {handleWalletType()}
            </Label>
          )}
        </TableCell>
      )}
      {role === 'admin' && (
        <>
          {/* <TableCell>{remarks || '---'}</TableCell> */}
          {bionium === undefined && (
            <TableCell>
              <Label variant="soft" color="info">
                {/* {wallet_type} */}
                {handleWalletType()}
              </Label>
            </TableCell>
          )}
        </>
      )}
      {type ? (
        <TableCell>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
            }}
          >
            <Typography variant="body2" color="rgba(0, 120, 103, 1)">
              {transaction_type === 'CREDIT' && `${amount?.toFixed(2)}`}
            </Typography>{' '}
            &nbsp;
            {transaction_type === 'CREDIT' && (
              <Typography variant="body2" color="text.secondary">
                {USDT}
              </Typography>
            )}
          </Box>
        </TableCell>
      ) : (
        <TableCell>
          <Typography variant="body2" color="#007867">
            {transaction_type === 'CREDIT' && `${amount?.toFixed(2)} ${USDT}`}
          </Typography>
        </TableCell>
      )}

      {type ? (
        <TableCell>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
            }}
          >
            <Typography variant="body2" color="rgba(183, 29, 24, 1)">
              {transaction_type === 'DEBIT' && `${amount?.toFixed(2)} `}
            </Typography>{' '}
            &nbsp;
            {transaction_type === 'DEBIT' && (
              <Typography variant="body2" color="text.secondary">
                {USDT}
              </Typography>
            )}
          </Box>
        </TableCell>
      ) : (
        <TableCell>
          <Typography variant="body2" color="error">
            {transaction_type === 'DEBIT' && `${amount?.toFixed(2)} ${USDT}`}
          </Typography>
        </TableCell>
      )}
    </TableRow>
  );
}

TransactionTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  bionium: PropTypes.string,
  memberSingle: PropTypes.bool,
  type: PropTypes.any,
};

/* eslint-disable import/no-unresolved */
import React from 'react';

import { Container } from '@mui/material';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import { useSettingsContext } from 'src/components/settings';

import AllLeaderboard from './LeaderboardPage';

function Leaderboard() {
  const settings = useSettingsContext();
  return (
    <>
      <MetaHelmet title="Winbuks" miniDescription="Leaderboard" />
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <AllLeaderboard />
      </Container>
    </>
  );
}

export default Leaderboard;

import * as Yup from 'yup';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
// @mui
import { Card, Stack, IconButton, Typography } from '@mui/material';

import { updateMemberPaymentAdmin } from 'src/server/api/member';

import Iconify from 'src/components/iconify';
import { RHFTextField } from 'src/components/hook-form';
import { useSettingsContext } from 'src/components/settings';
import FormProvider from 'src/components/hook-form/form-provider';

// components

ContactShow.propTypes = {
  memberDetails: PropTypes.object,
  setOpenUpdateMemberEmail: PropTypes.any,
};

export default function ContactShow({ memberDetails, setOpenUpdateMemberEmail }) {
  const dispatch = useDispatch();
  const settings = useSettingsContext();

  const { loading } = useSelector((state) => ({
    loading: state.member.loading,
  }));

  // console.log(loading);
  // console.log(error);
  const memberCreateSchema = Yup.object().shape({
    payment_transaction_id: Yup.string(),
  });
  const defaultValues = useMemo(
    () => ({
      payment_transaction_id: '',
    }),
    []
  );
  const methods = useForm({
    resolver: yupResolver(memberCreateSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
    // reset,
    // setValue,
    // watch,
  } = methods;
  // const [isPaid] = useState('');
  const onSubmit = handleSubmit(async (data) => {
    // console.log(data);
    // const credentialsCreate = {
    //   state: {
    //     ...data,
    //   },
    //   dispatch,
    //   // handleClose,
    //   // handleClear,
    //   Navigate,
    // };
    const updateCredential = {
      state: {
        // ...data,
        payment_transaction_id: data?.payment_transaction_id,
      },
      dispatch,
      memberId: memberDetails?._id,
    };
    dispatch(updateMemberPaymentAdmin(updateCredential));
    // if () {
    //   const credentialsUpdate = {
    //     state: {
    //       ...data,
    //       is_active: isActive,
    //       is_verified: isVerified,
    //       is_paid: isPaid,
    //     },
    //     dispatch,

    //     navigate,
    //   };
    // dispatch(updateMemberDetails(credentialsUpdate));
  });

  // console.log(isPaid);

  // const handleCopy = () => {
  //   enqueueSnackbar('Phone Number copied!', { variant: 'success' });
  // };

  return (
    <div>
      <Card
        sx={{
          py: 2,
          textAlign: 'start',
          typography: 'h4',
          alignItems: 'start',
          height: '230px',
          justifyContent: 'start',
        }}
      >
        <Stack
          flexDirection="column"
          alignItems="start"
          height="100%"
          justifyContent="space-between"
        >
          <Stack>
            <Stack direction="row" justifyContent="space-between" mx={2} mt={1.5}>
              <Typography
                // color=""

                sx={{ fontSize: 14, fontWeight: 700 }}
              >
                Update Payment
              </Typography>
              <IconButton
                onClick={() => setOpenUpdateMemberEmail(true)}
                sx={{
                  width: 'auto',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '8px',
                  backgroundColor: '#f0f0f0',
                  color: 'black',
                  height: '30px',
                  gap: 1,
                  '&:hover': {
                    backgroundColor: settings?.themeMode === 'dark' ? 'white' : '#f0f0f0',
                  },
                }}
                // disabled={!memberDetails?.is_primary}
              >
                <Iconify icon="tabler:mail-filled" />

                <Typography variant="caption" fontWeight="bold">
                  {' '}
                  Update Email
                </Typography>
              </IconButton>
            </Stack>
            <Typography
              variant="caption"
              mt={1}
              px={2}
              textAlign="start"
              sx={{ color: 'text.secondary' }}
            >
              Transaction hash
            </Typography>
            <Stack justifyContent="center">
              <FormProvider methods={methods} onSubmit={onSubmit}>
                <Stack px={2} mt={2.5}>
                  <RHFTextField
                    name="payment_transaction_id"
                    label="Enter Transaction hash"
                    type="text"
                    // placeholder="Enter Transaction hash"
                  />
                </Stack>
                <Stack px={2} mt={1.5}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting || loading}
                    sx={{ ml: 'auto', px: 3, width: '100%' }}
                    color="success"
                  >
                    Submit{' '}
                  </LoadingButton>
                </Stack>
              </FormProvider>
            </Stack>

            {/* <Typography
              px={2}
              variant="body2"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              To complete the payment process, please get in touch with them. If you want to reach
              out to them immediately, you can simply copy their phone number using the button
              below.
            </Typography> */}
          </Stack>
          {/* <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            px={2}
          >
            <CopyToClipboard text={memberDetails?.phone} onCopy={handleCopy}>
              <Button
                variant="outlined"
                color="success"
                width="100%"
                size="medium"
                sx={{ px: 2 }}
                startIcon={<ContentCopyIcon sx={{ fontSize: 11 }} />}
              >
                <Typography variant="subtitle2">Copy number</Typography>
              </Button>
            </CopyToClipboard>

            <Button
              variant="contained"
              color="success"
              size="medium"
              sx={{ px: 3 }}
              startIcon={<PersonAddAltRoundedIcon sx={{ fontSize: 11 }} />}
            >
              Contact
            </Button>
          </Stack> */}
        </Stack>
      </Card>
    </div>
  );
}

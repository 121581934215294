import PropTypes from 'prop-types';
import { useState, forwardRef } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import PhoneNumberInput from 'react-phone-number-input/input';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import { getCountryCode } from './utils';
import { CountryListPopover } from './list';

// ----------------------------------------------------------------------

export const PhoneInput = forwardRef(
  (
    {
      value,
      onChange,
      placeholder,
      country: inputCountryCode,
      disableSelect,
      setSelectedCode,
      ...other
    },
    ref
  ) => {
    const defaultCountryCode = getCountryCode(value, inputCountryCode);

    const [selectedCountry, setSelectedCountry] = useState(defaultCountryCode);

    const handleCode = (code) => {
      setSelectedCountry(code);
      setSelectedCode(code);
    };

    // useEffect(() => {
    //   if (defaultCountryCode) {
    //     setSelectedCode(defaultCountryCode)
    //   }
    // }, [defaultCountryCode, setSelectedCode])

    return (
      <PhoneNumberInput
        ref={ref}
        country={selectedCountry}
        inputComponent={CustomInput}
        value={value}
        onChange={onChange}
        placeholder={placeholder ?? 'Enter phone number'}
        InputProps={
          disableSelect
            ? undefined
            : {
                startAdornment: (
                  <InputAdornment position="center" sx={{ ml: 1 }}>
                    <CountryListPopover
                      countryCode={selectedCountry}
                      onClickCountry={(inputValue) => handleCode(inputValue)}
                    />
                  </InputAdornment>
                ),
              }
        }
        {...other}
      />
    );
  }
);

PhoneInput.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.any,
  placeholder: PropTypes.any,
  country: PropTypes.any,
  disableSelect: PropTypes.any,
  setSelectedCode: PropTypes.any,
};

// ----------------------------------------------------------------------

const CustomInput = forwardRef(({ ...props }, ref) => <TextField inputRef={ref} {...props} />);

/* eslint-disable import/no-unresolved */
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Card, Button, Tooltip, CardMedia, Typography } from '@mui/material';

import { useParams } from 'src/routes/hooks';
// import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { fDateTime } from 'src/utils/format-time';

import tick from 'src/assets/other/tick.png';
// import { frontEndUrl } from 'src/server/api/http';
import { memberBlock } from 'src/server/api/member';
import copyWhite from 'src/assets/other/copygreen.png';

import Iconify from 'src/components/iconify';
import TextMaxLine from 'src/components/text-max-line';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';

import FlagDialog from 'src/sections/Member/MemberSingleView/flagDialog';
import SuspendDialog from 'src/sections/Member/MemberSingleView/suspendDialog';

export default function PaymentAndVerified({ memberDetails }) {
  const settings = useSettingsContext();

  const { isBlocked, isSuspented } = useSelector((state) => ({
    flagMessage: state.member.memberDetails?.note,
    isBlocked: state.member.memberDetails?.is_blocked,
    isSuspented: state.member.memberDetails?.is_withdrawal_suspended,
  }));
  // console.log(memberDetails);
  const [openFlagDialog, setOpenFlagDialog] = useState(false);
  const [openSuspendDialog, setOpenSuspendDialog] = useState(false);

  const handleCopy = () => {
    enqueueSnackbar('Link copied!', { variant: 'success' });
  };
  const confirm = useBoolean();
  const dispatch = useDispatch();
  const params = useParams();
  const isMobile = useResponsive('down', 'md');

  // Block_Unblock_Member
  const Block_Unblock_Member = () => {
    const credentials = {
      dispatch,
      memberId: params?.id,
      state: {
        is_blocked: !isBlocked,
      },
    };
    dispatch(memberBlock(credentials));
  };

  // flagfun
  const flagfun = () => {
    setOpenFlagDialog(true);
  };
  // console.log(memberDetails);

  return (
    <>
      <Stack width="100%" direction={isMobile ? 'column' : 'row'} gap={2}>
        {memberDetails?.verified_at && (
          <Card
            variant="outlined"
            sx={{ flex: 4.3, p: 2, boxShadow: 'unset', borderRadius: '15px' }}
          >
            <Typography variant="body2">Membership Payment Details</Typography>
            <Stack>
              <Typography
                variant="caption"
                mt={1}
                textAlign="start"
                sx={{ color: 'text.secondary' }}
              >
                Transaction hash
              </Typography>
              <Stack direction="row" gap={1}>
                <Card
                  sx={{
                    px: 1,
                    py: 0.7,
                    borderRadius: '10px',
                    boxShadow: 'unset',
                    border:
                      settings?.themeMode === 'dark' ? '1px solid #616161' : '1px solid #e9ecee',
                    textAlign: 'start',
                    width: '100%',
                  }}
                >
                  <TextMaxLine line={1}>
                    <Typography
                      sx={{ wordWrap: 'break-word', overflowWrap: 'anywhere', textAlign: 'start' }}
                      variant="caption"
                    >
                      {memberDetails?.payment_transaction_id}
                    </Typography>
                  </TextMaxLine>
                </Card>
                <CopyToClipboard
                  text={`${memberDetails?.payment_transaction_id}`}
                  onCopy={handleCopy}
                >
                  <Tooltip title="Copy Transaction hash">
                    <Button
                      size="small"
                      variant="contained"
                      startIcon={
                        <CardMedia
                          component="img"
                          src={copyWhite}
                          alt="copy"
                          sx={{ width: '15px' }}
                        />
                      }
                      sx={{
                        width: '110px',
                        backgroundColor: '#e1efea',
                        height: '34px',
                        color: '#007867',

                        '&:hover': { backgroundColor: '#e1efea' },
                      }}
                    >
                      Copy
                    </Button>
                  </Tooltip>
                </CopyToClipboard>
              </Stack>
            </Stack>
          </Card>
        )}
        <Card
          variant="outlined"
          // direction={{ xs: 'column', md: 'column' }}
          // justifyContent="space-between"
          sx={{
            flex: 7.7,
            p: 2,
            height: '100%',
            boxShadow: 'unset',
            // border: settings?.themeMode === 'dark' ? '' : '1px solid #e3e7ea',
            width: '100%',
            borderRadius: '15px',
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {memberDetails?.verified_at && (
            <Stack>
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderRadius: '7px',
                  py: 0.5,
                  px: 1.5,
                  gap: 2,
                  width: isMobile ? '100%' : 'fit-content',
                  backgroundColor: '#f0f0f0',
                  mb: isMobile && 2,
                }}
              >
                <CardMedia component="img" src={tick} alt="copy" sx={{ width: '20px' }} />
                <Typography variant="body2" color="black">
                  Verified on {moment(memberDetails?.verified_at).format('DD MMM YYYY h:mm A')}
                </Typography>
              </Card>
            </Stack>
          )}

          <Stack spacing={1} direction={isMobile ? 'column' : 'row'} mt={1}>
            <Tooltip title={isSuspented ? 'Unsuspend user' : 'Suspend user'}>
              <Button
                variant="outlined"
                color="inherit"
                startIcon={<Iconify icon="material-symbols-light:lock-sharp" />}
                width="100%"
                sx={{ flex: 1 }}
                // onClick={memberSuspentfun}
                onClick={() => setOpenSuspendDialog(true)}
              >
                <>
                  <Typography variant="body2">
                    {isSuspented ? 'Unsuspend user' : 'Suspend user'}
                  </Typography>
                  {isSuspented && (
                    <Tooltip
                      placement="top"
                      title={fDateTime(memberDetails?.last_withdrawal_suspended_at)}
                    >
                      <AccessTimeIcon sx={{ ml: 1, fontSize: '20px' }} />
                    </Tooltip>
                  )}
                </>
              </Button>
            </Tooltip>

            <Tooltip title="Flag  user">
              <Button
                variant="outlined"
                color="inherit"
                width="100%"
                sx={{ flex: 1, px: 2 }}
                startIcon={<Iconify icon="material-symbols:flag-2-outline" />}
                onClick={flagfun}
              >
                <>
                  {/* {flagMessage && flagMessage.trim() ? 'Flagged' : 'Flag'} */}
                  <Typography variant="body2">
                    {memberDetails?.is_flagged ? 'Unflag user ' : 'Flag user '}
                  </Typography>
                  {memberDetails?.is_flagged && (
                    <Tooltip placement="top" title={fDateTime(memberDetails?.last_flagged_date)}>
                      <AccessTimeIcon sx={{ ml: 1, fontSize: '20px' }} />
                    </Tooltip>
                  )}
                </>
              </Button>
            </Tooltip>
            <Tooltip title={isBlocked ? 'Unblock user' : 'Block user'}>
              <Button
                variant="outlined"
                color="inherit"
                startIcon={
                  <Iconify icon="material-symbols-light:block" sx={{ color: '#B71D18' }} />
                }
                onClick={() => confirm.onTrue()}
                width="100%"
                sx={{ flex: 1 }}
              >
                <>
                  <Typography variant="body2" color="#B71D18">
                    {isBlocked ? 'Unblock user' : 'Block user'}
                  </Typography>
                  {isBlocked && (
                    <Tooltip placement="top" title={fDateTime(memberDetails?.blocked_at)}>
                      <AccessTimeIcon sx={{ ml: 1, fontSize: '20px' }} />
                    </Tooltip>
                  )}
                </>
              </Button>
            </Tooltip>
          </Stack>
        </Card>
      </Stack>

      <ConfirmDialog
        open={confirm.value}
        // open='true'
        onClose={confirm.onFalse}
        title={isBlocked ? 'Unblock Member' : 'Block Member'}
        content={<>Are you sure want to {isBlocked ? 'unblock member' : 'block member'}?</>}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              Block_Unblock_Member();
              confirm.onFalse();
            }}
          >
            {isBlocked ? 'Unblock' : 'Block'}
          </Button>
        }
      />

      <FlagDialog
        open={openFlagDialog}
        setOpen={setOpenFlagDialog}
        flagMessage={memberDetails?.note}
        is_flagged={memberDetails?.is_flagged}
      />
      <SuspendDialog
        open={openSuspendDialog}
        setOpen={setOpenSuspendDialog}
        isSuspented={isSuspented}
      />
    </>
  );
}

PaymentAndVerified.propTypes = {
  memberDetails: PropTypes.string,
};

import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

import { Tab, Tabs, alpha } from '@mui/material';

import { useGetRoles } from 'src/hooks/useHandleSessions';

import Label from 'src/components/label';

// ----------------------------------------------------------------------

export default function ClubIncomeTransactionSort({
  sort,
  setSort,
  counts,
  clubIncomeTransactionList,
  table,
  setPage,
}) {
  const theme = useTheme();
  // console.log(clubIncomeTransactionList);
  const role = useGetRoles()?.role;
  const TABS =
    role === 'admin'
      ? [
          {
            value: '',
            label: 'All',
            color: 'default',
            count: counts?.AllClubIncomeTransactionCount,
          },
          {
            value: 'CLUB',
            label: 'Company Club',
            color: 'default',
            count: counts?.ClubClubIncomeTransactionCount,
          },
          {
            value: 'MEMBER_CLUB',
            label: 'Member Club',
            color: 'default',
            count: counts?.MemberClubIncomeTransactionCount,
          },
        ]
      : [
          {
            value: '',
            label: 'All',
            color: 'default',
            count:
              clubIncomeTransactionList?.clubIncomeTransactionCounts?.AllClubIncomeTransactionCount,
          },
          {
            value: 'CREDIT',
            label: 'Credit',
            color: 'error',
            count:
              clubIncomeTransactionList?.clubIncomeTransactionCounts
                ?.CreditClubIncomeTransactionCount,
          },
          {
            value: 'DEBIT',
            label: 'Debit',
            color: 'default',
            count:
              clubIncomeTransactionList?.clubIncomeTransactionCounts
                ?.DebitClubIncomeTransactionCount,
          },
        ];

  return (
    <Tabs
      value={sort}
      // onChange={(e, newValue) => {
      //   setSort(newValue);
      // }}
      onChange={(e, newValue) => {
        setSort(newValue);
        table.setPage(0);
      }}
      sx={{
        px: 2.5,
        boxShadow: `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
      }}
    >
      {TABS?.map((tab) => (
        <Tab
          key={tab.value}
          value={tab.value}
          label={tab.label}
          iconPosition="end"
          icon={
            <Label
              variant={((tab.value === 'all' || tab.value === sort) && 'filled') || 'soft'}
              color={tab.color}
            >
              {tab.count}
            </Label>
          }
        />
      ))}
    </Tabs>
  );
}

ClubIncomeTransactionSort.propTypes = {
  setSort: PropTypes.func,
  sort: PropTypes.string,
  counts: PropTypes.object,
  clubIncomeTransactionList: PropTypes.any,
  table: PropTypes.any,
  setPage: PropTypes.any,
};

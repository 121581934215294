import React from 'react';

import { Stack, Typography } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import MainLayout from 'src/layouts/website/index';

// import { SplashScreen } from 'src/components/loading-screen';

export default function PrivacyPolicy() {
  const isMobile = useResponsive('down', 'md');

  return (
    <MainLayout>
      <Stack flexDirection={isMobile ? 'column' : 'row'}>
        <Stack flex={9} p={isMobile ? 5 : 12} mb={isMobile ? 10 : 0}>
          <Typography variant="h3" color="#FEDFA1" mb={3}>
            Privacy Policy for Winbuks
          </Typography>
          <Stack pb={3}>
            <Typography variant="body1" color="#BFA879">
              At Winbuks, we are committed to protecting the privacy and security of our members`
              personal information. This privacy policy outlines how we collect, use, disclose, and
              protect the personal information provided by our members.
            </Typography>
          </Stack>
          <Stack mb={2}>
            <Typography variant="body1" color="#BFA879">
              1.Information We Collect:
            </Typography>
            <Stack>
              <Typography component="ul" variant="body1" color="#BFA879">
                <li>
                  Personal Information: When members sign up for Winbuks, we collect personal
                  information such as name, email address, contact number, and payment details.
                </li>
                <li>
                  {' '}
                  Referral Information: We may collect information about referrals made by members,
                  including the names and contact details of referred individuals.
                </li>
                <li>
                  {' '}
                  Log Data: We automatically collect log data when members access our platform,
                  including IP addresses, browser type, and pages visited.
                </li>
              </Typography>
            </Stack>
          </Stack>
          <Stack mb={2}>
            <Typography variant="body1" color="#BFA879">
              2. Use of Information:
            </Typography>
            <Stack>
              <Typography component="ul" variant="body1" color="#BFA879">
                <li>
                  {' '}
                  We use members` personal information to provide and maintain their membership
                  accounts, process subscription payments, and facilitate participation in the
                  referral program.
                </li>
                <li>
                  Referral information is used to track and credit referral commissions to members`
                  accounts.
                </li>
                <li>
                  Log data is used for analytics purposes to improve the functionality and user
                  experience of the Winbuks platform.
                </li>
              </Typography>
            </Stack>
          </Stack>
          <Stack mb={2}>
            <Typography variant="body1" color="#BFA879">
              3. Disclosure of Information:
            </Typography>
            <Stack>
              <Typography component="ul" variant="body1" color="#BFA879">
                <li>
                  We may disclose members` personal information to trusted third-party service
                  providers who assist us in operating our platform, processing payments, and
                  delivering services.
                </li>
                <li>
                  Referral information may be shared with the referred individuals to notify them
                  about the referral and encourage them to join Winbuks.
                </li>
                <li>
                  We may disclose personal information when required by law or to protect the
                  rights, property, or safety of Winbuks, its members, or others.
                </li>
              </Typography>
            </Stack>
          </Stack>
          <Stack mb={2}>
            <Typography variant="body1" color="#BFA879">
              4. Data Security:
            </Typography>
            <Stack>
              <Typography component="ul" variant="body1" color="#BFA879">
                <li>
                  We implement industry-standard security measures to protect members` personal
                  information from unauthorized access, disclosure, alteration, or destruction.
                </li>
                <li>
                  All payment transactions are encrypted using secure socket layer (SSL) technology.
                </li>
              </Typography>
            </Stack>
          </Stack>
          <Stack mb={2}>
            <Typography variant="body1" color="#BFA879">
              5. Data Retention:
            </Typography>
            <Stack>
              <Typography component="ul" variant="body1" color="#BFA879">
                <li>
                  We retain members` personal information for as long as necessary to fulfill the
                  purposes outlined in this privacy policy, unless a longer retention period is
                  required or permitted by law.
                </li>
              </Typography>
            </Stack>
          </Stack>
          <Stack mb={2}>
            <Typography variant="body1" color="#BFA879">
              6. Your Rights:
            </Typography>
            <Stack>
              <Typography component="ul" variant="body1" color="#BFA879">
                <li>
                  Members have the right to access, update, or delete their personal information by
                  contacting Winbuks` customer support.
                </li>
                <li>
                  Members may opt out of receiving promotional communications from Winbuks by
                  following the unsubscribe instructions provided in such communications.
                </li>
              </Typography>
            </Stack>
          </Stack>
          <Stack mb={2}>
            <Typography variant="body1" color="#BFA879">
              7. Changes to Privacy Policy:
            </Typography>
            <Stack>
              <Typography component="ul" variant="body1" color="#BFA879">
                <li>
                  Winbuks reserves the right to update or modify this privacy policy at any time.
                  Any changes will be effective immediately upon posting the revised policy on the
                  Winbuks platform.
                </li>
                <li>
                  Members are encouraged to review the privacy policy periodically for any changes.
                </li>
              </Typography>
            </Stack>
          </Stack>

          <Typography component="body1" color="#BFA879" pt={3}>
            By using the Winbuks platform and providing personal information, you consent to the
            collection, use, and disclosure of your information as described in this privacy policy.
          </Typography>
        </Stack>
        <Stack flex={3} />
      </Stack>
    </MainLayout>
  );
}

/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import { Grid, Card, Button, CardMedia, Typography } from '@mui/material';

import { useParams } from 'src/routes/hooks';

import { USDT } from 'src/hooks/use-USDT';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import wallet from 'src/assets/other/wallet.png';
import { memberBlock } from 'src/server/api/member';

import { ConfirmDialog } from 'src/components/custom-dialog';

import FlagDialog from 'src/sections/Member/MemberSingleView/flagDialog';
import SuspendDialog from 'src/sections/Member/MemberSingleView/suspendDialog';

export default function EarningsCard({ memberDetails }) {
  const { isBlocked, isSuspented } = useSelector((state) => ({
    flagMessage: state.member.memberDetails?.note,
    isBlocked: state.member.memberDetails?.is_blocked,
    isSuspented: state.member.memberDetails?.is_withdrawal_suspended,
  }));
  // console.log(memberDetails?.earnings_statistics);
  const [openFlagDialog, setOpenFlagDialog] = useState(false);
  const [openSuspendDialog, setOpenSuspendDialog] = useState(false);

  const confirm = useBoolean();
  const dispatch = useDispatch();
  const params = useParams();
  const isMobile = useResponsive('down', 'md');

  // Block_Unblock_Member
  const Block_Unblock_Member = () => {
    const credentials = {
      dispatch,
      memberId: params?.id,
      state: {
        is_blocked: !isBlocked,
      },
    };
    dispatch(memberBlock(credentials));
  };

  const earnings = [
    { label: 'Catalyst Earnings', value: memberDetails?.earnings_statistics?.catalyst_total },
    { label: 'Connect Earnings', value: memberDetails?.earnings_statistics?.line_total },
    { label: 'Fourth Earnings', value: memberDetails?.earnings_statistics?.fourth_total },
    { label: 'Club Earnings', value: memberDetails?.earnings_statistics?.club_total },
    { label: 'Incentive Earnings', value: memberDetails?.earnings_statistics?.incentive_total },
    { label: 'Bionium Earnings', value: memberDetails?.bionium_coin },
  ];
  return (
    <>
      <Stack mt={4} mb={2}>
        <Typography variant="h6">Member Earnings</Typography>
      </Stack>
      <Stack width="100%" direction={isMobile ? 'column' : 'row'} gap={2}>
        <Card variant="outlined" sx={{ flex: 3, boxShadow: 'unset', borderRadius: '15px', p: 2 }}>
          <Stack
            direction="column"
            gap={2}
            sx={{
              p: 3,
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Stack
              p={2}
              backgroundColor="#f0f0f0"
              borderRadius="50%"
              justifyContent="center"
              alignItems="center"
              width="60px"
              height="60px"
            >
              <CardMedia
                component="img"
                src={wallet}
                alt="wallet"
                style={{ width: '40px', height: '40px', objectFit: 'cover' }}
              />
            </Stack>
            <Stack alignItems="baseline" justifyContent="center">
              <Typography variant="body2" fontWeight="bold">
                {memberDetails?.earnings_statistics?.net_earnings_total?.toFixed(2) || 0} {USDT}
              </Typography>
              <Typography
                sx={{
                  typography: 'caption',
                  opacity: 0.68,
                }}
              >
                Net Earnings{' '}
              </Typography>
            </Stack>
          </Stack>
        </Card>
        <Card
          variant="outlined"
          sx={{
            flex: 8,
            p: 2,
            height: '100%',
            boxShadow: 'unset',
            width: '100%',
            borderRadius: '15px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Grid container spacing={2}>
            {earnings.map((item, index) => (
              <Grid display="flex" justifyContent="center" item xs={6} sm={6} md={4} key={index}>
                <Stack alignItems="center" justifyContent="center">
                  {/* <Typography variant="body2" fontWeight="bold">
                    {item.value?.toFixed(2) || 0} {USDT}
                  </Typography> */}
                  {item.label === 'Bionium Earnings' ? (
                    <Typography variant="body2" fontWeight="bold">
                      {item.value || 0} Tokens
                    </Typography>
                  ) : (
                    <Typography variant="body2" fontWeight="bold">
                      {item.value?.toFixed(2) || 0} {USDT}
                    </Typography>
                  )}
                  <Typography sx={{ typography: 'caption', opacity: 0.68 }}>
                    {item.label}
                  </Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Card>
      </Stack>

      <ConfirmDialog
        open={confirm.value}
        // open='true'
        onClose={confirm.onFalse}
        title={isBlocked ? 'Unblock Member' : 'Block Member'}
        content={<>Are you sure want to {isBlocked ? 'unblock member' : 'block member'}?</>}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              Block_Unblock_Member();
              confirm.onFalse();
            }}
          >
            {isBlocked ? 'Unblock' : 'Block'}
          </Button>
        }
      />

      <FlagDialog
        open={openFlagDialog}
        setOpen={setOpenFlagDialog}
        flagMessage={memberDetails?.note}
        is_flagged={memberDetails?.is_flagged}
      />
      <SuspendDialog
        open={openSuspendDialog}
        setOpen={setOpenSuspendDialog}
        isSuspented={isSuspented}
      />
    </>
  );
}

EarningsCard.propTypes = {
  memberDetails: PropTypes.string,
};

/* eslint-disable import/no-unresolved */
import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
// import { createEnquiry, updateEnquiryDetails } from 'src/server/api/enquiry';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useState, useEffect, useCallback } from 'react';

import { LoadingButton } from '@mui/lab';
// components
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// @mui
import { Stack, Dialog, Button, Divider, DialogTitle, DialogContent } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import { createNotification, updateNotificationDetails } from 'src/server/api/notification';

import FormProvider from 'src/components/hook-form/form-provider';
import { RHFEditor, RHFTextField } from 'src/components/hook-form';
import { FileUploadCustom } from 'src/components/CustomFields/ImageUpload';

// import CrudModalContent from './CrudModalContent';

NotificationModalDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  isUpdate: PropTypes.func,
};

export default function NotificationModalDialog({ open, setOpen, isUpdate }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useResponsive('down', 'md');
  const [imagePreview, setImagePreview] = useState([]);
  // const { role } = useGetRoles();
  const { loading } = useSelector((state) => ({
    loading: state.notification.loading,
  }));
  // console.log(loading);

  const handleClose = () => {
    setOpen(false);
  };

  const notificationSchema = Yup.object().shape({
    title: Yup.string()?.required('title is required'),
    description: Yup.string(),
  });

  const defaultValues = useMemo(
    () => ({
      title: isUpdate?.title || '',
      description: isUpdate?.description || '',
    }),
    [isUpdate?.title, isUpdate?.description]
  );

  const methods = useForm({
    resolver: yupResolver(notificationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    setValue,
    // watch,
  } = methods;

  useEffect(() => {
    if (isUpdate?._id) {
      reset(defaultValues);
    }
  }, [defaultValues, reset, isUpdate?._id]);

  useEffect(() => {
    if (isUpdate?.image) {
      setImagePreview([isUpdate?.image]);
    }
  }, [isUpdate?.image]);

  const onSubmit = handleSubmit(async (data) => {
    const credentials = {
      state: {
        ...data,
        image: imagePreview[0],
      },
      dispatch,
      handleClose,
      reset,
    };
    const updateCredentials = {
      state: {
        ...data,
        ...(imagePreview[0] ? { image: imagePreview[0] } : { image: null }),
      },
      dispatch,
      handleClose,
      reset,
      notificationId: isUpdate?._id,
    };
    if (isUpdate?._id) {
      dispatch(updateNotificationDetails(updateCredentials));
    } else {
      dispatch(createNotification(credentials));
    }
  });

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('image', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  console.log(imagePreview);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" sx={{ pb: 0 }}>
          {isUpdate?._id ? 'Edit' : 'Create'} Notification
        </DialogTitle>
        <DialogContent
          className="crud-modal"
          sx={{ minWidth: isMobile ? '200px' : '450px', padding: '0px' }}
        >
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack p={3}>
              <Stack gap={3}>
                <RHFTextField
                  name="title"
                  size="medium"
                  label="Title"
                  InputLabelProps={{ shrink: true }}
                  placeholder="Enter title"
                  required
                />
              </Stack>
              <Stack pt={2}>
                <RHFEditor name="description" placeholder="Message" />
              </Stack>
              <Stack pt={0.5}>
                <FileUploadCustom
                  uploadUrl="notification"
                  folder="notification"
                  setImagesPreview={setImagePreview}
                  imagesPreview={imagePreview}
                  title="Select Image"
                  imageSize={4}
                  theme={{
                    type: 'notification-normal',
                    name: 'image',
                    handleDrop,
                  }}
                />
              </Stack>
            </Stack>
            <Divider
              sx={{
                width: '100%',
                borderStyle: 'dashed',
              }}
            />
            <Stack p={2} direction="row" alignItems="center" justifyContent="end" spacing={1}>
              <LoadingButton
                loading={loading}
                variant="contained"
                sx={{
                  backgroundColor: '#007867',
                  color: 'white',
                }}
                type="submit"
              >
                {isUpdate?._id ? 'Save' : 'Create'}
              </LoadingButton>
              <Button color="inherit" variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}

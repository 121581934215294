// import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
// @mui
import { Box, Card, Stack, Tooltip, IconButton, Typography } from '@mui/material';

// components
import { updateMemberStatus } from 'src/server/api/member';

import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';

PaymentShow.propTypes = {
  memberDetails: PropTypes.object,
  setOpenUpdateMemberEmail: PropTypes.any,
};

export default function PaymentShow({ memberDetails, setOpenUpdateMemberEmail }) {
  const dispatch = useDispatch();
  const settings = useSettingsContext();

  const { processed } = useSelector((state) => ({
    loading: state.member.loading,
    processed: state.member.processed,
  }));

  // console.log(loading);
  // console.log(processed);

  // const [isPaid] = useState('');

  // console.log(isPaid);

  const handleChangeVerify = () => {
    const updateCredential = {
      dispatch,
      memberId: memberDetails?._id,
    };
    dispatch(updateMemberStatus(updateCredential));
  };

  return (
    <Card sx={{ px: 1, gap: 2, height: '100%' }}>
      <Stack direction="row" justifyContent="space-between" mx={2} mt={2}>
        <Typography sx={{ fontSize: 14, fontWeight: 700 }}>Payment Details</Typography>
        <IconButton
          onClick={() => setOpenUpdateMemberEmail(true)}
          sx={{
            width: 'auto',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '8px',
            backgroundColor: '#f0f0f0',
            color: 'black',
            height: '30px',
            gap: 1,
            '&:hover': {
              backgroundColor: settings?.themeMode === 'dark' ? 'white' : '#f0f0f0',
            },
          }}
          // disabled={!memberDetails?.is_primary}
        >
          <Iconify icon="tabler:mail-filled" />

          <Typography variant="caption" fontWeight="bold">
            {' '}
            Update Email
          </Typography>
        </IconButton>
      </Stack>
      <Stack>
        <Typography
          variant="caption"
          mt={1}
          px={2}
          textAlign="start"
          sx={{ color: 'text.secondary' }}
        >
          Transaction hash
        </Typography>
        <Card
          sx={{
            mt: 2,
            p: 1,
            mx: 2,
            borderRadius: '10px',
            boxShadow: 'unset',
            border: '1px solid #e9ecee',
            textAlign: 'start',
          }}
        >
          <Typography
            sx={{ wordWrap: 'break-word', overflowWrap: 'anywhere', textAlign: 'start' }}
            variant="caption"
          >
            {memberDetails?.payment_transaction_id}
          </Typography>
        </Card>
      </Stack>
      {/* <Stack direction="row" alignItems="center" justifyContent="center" px={2}>
        <CardMedia
          component="img"
          sx={{
            width: '350px',
            height: '105px',
            lineHeight: 0,
            borderRadius: 1,
            objectFit: 'cover',
          }}
          src={memberDetails?.payment_screenshot?.url}
        />
      </Stack> */}

      <Stack flexDirection="row" alignItems="center" justifyContent="center" pb={0} py={2}>
        {/* <Stack pt={2}>
          <Button variant="outlined" color="success" size="small" sx={{ px: 3 }}>
            <Typography fontSize={12} fontWeight={700}>
              Download
            </Typography>
            &nbsp; &nbsp;
            <CloudDownloadIcon fontSize="small" />
          </Button>
        </Stack> */}
        <Stack pt={2} width="100%" mx={2}>
          {/* <Button
            sx={{ px: 3 }}
            variant="contained"
            color="success"
            size="small"
            width="100%"
            onClick={() => handleChangeVerify()}
          >
            <Typography fontSize={12} fontWeight={700}>
              Verify
            </Typography>{' '}
            &nbsp; &nbsp;
            <VerifiedRoundedIcon fontSize="small" />
          </Button> */}
          {processed?.is_verified_processed === true ? (
            <>
              <Tooltip title="Verification in progress">
                <Box>
                  <Button
                    sx={{ px: 3, width: '100%' }}
                    variant="contained"
                    color="success"
                    size="small"
                    width="100%"
                    disabled
                    // onClick={() => handleChangeVerify()}
                  >
                    <Typography fontSize={12} fontWeight={700}>
                      Verify
                    </Typography>{' '}
                    &nbsp; &nbsp;
                    <VerifiedRoundedIcon fontSize="small" />
                  </Button>
                </Box>
              </Tooltip>
              <Typography variant="caption" mt={0.5} sx={{ color: 'text.secondary' }}>
                Refresh after some time.{' '}
              </Typography>
            </>
          ) : (
            <Button
              sx={{ px: 3, width: '100%' }}
              variant="contained"
              color="success"
              size="small"
              width="100%"
              onClick={() => handleChangeVerify()}
            >
              <Typography fontSize={12} fontWeight={700}>
                Verify
              </Typography>{' '}
              &nbsp; &nbsp;
              <VerifiedRoundedIcon fontSize="small" />
            </Button>
          )}
        </Stack>
      </Stack>
    </Card>
  );
}

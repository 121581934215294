/* eslint-disable import/no-unresolved */
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import {
  Stack,
  Dialog,
  Button,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
} from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import { flagMember } from 'src/server/api/member';

import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';

FlagDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  flagMessage: PropTypes.string,
  is_flagged: PropTypes.bool,
};

export default function FlagDialog({ open, setOpen, flagMessage, is_flagged }) {
  const dispatch = useDispatch();

  const isMobile = useResponsive('down', 'md');
  const params = useParams();

  const { loading } = useSelector((state) => ({
    loading: state.member.loading,
  }));

  const flagSchema = Yup.object().shape({
    flagMsg: Yup.string().required('Note is required'),
  });

  const defaultValues = React.useMemo(
    () => ({
      flagMsg: flagMessage || '',
    }),
    [flagMessage]
  );

  const methods = useForm({
    resolver: yupResolver(flagSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    formState: { isSubmitting },
    reset,
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const handleClose = () => {
    setOpen(false);
  };
  const fladMessage = watch('flagMsg');

  // console.log(flagMessage);
  const onSubmit = handleSubmit(async (data) => {
    const credentialsCreate = {
      state: {
        note: data?.flagMsg,
        is_flagged: !is_flagged,
      },
      memberId: params?.id,
      dispatch,
      handleClose,
      flag: 'flag',
    };

    dispatch(flagMember(credentialsCreate));
  });

  const handleFlag = () => {
    const credentialsCreate = {
      state: {
        is_flagged,
        note: fladMessage,
      },
      memberId: params?.id,
      dispatch,

      handleClose,
      note: 'note',
    };

    dispatch(flagMember(credentialsCreate));
  };
  // console.log(is_flagged);

  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', top: 0, right: 0, m: 1 }}
        >
          <CloseIcon sx={{ fontSize: '20px' }} />
        </IconButton>
        <DialogTitle id="responsive-dialog-title" sx={{ pb: 2 }}>
          {is_flagged ? 'Unflag Member' : 'Flag Member'}
        </DialogTitle>

        <DialogContent
          className="crud-modal"
          sx={{ minWidth: isMobile ? '300px' : '450px', padding: '0px' }}
        >
          <Typography variant="body2" sx={{ px: 2.4, color: 'error' }}>
            Are you sure to {is_flagged ? 'unflag' : 'flag'}?
          </Typography>
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack p={2} pl={3}>
              <RHFTextField
                name="flagMsg"
                label="Note"
                type="text"
                multiline
                rows={3}
                required
                placeholder="Enter the reason"
                InputLabelProps={{ shrink: true }}
              />
            </Stack>
            <Stack alignItems="end">
              <Stack
                mx={2}
                py={2}
                mb=""
                direction="row"
                alignItems="center"
                width="fit-content"
                gap={1}
              >
                {/* <Button variant="outlined" color="inherit" onClick={handleClose}>
                  Cancel
                </Button> */}
                {is_flagged && (
                  <Button variant="outlined" color="inherit" onClick={handleFlag}>
                    Update Note
                  </Button>
                )}
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting || loading}
                  sx={{ ml: 'auto' }}
                  color="success"
                  size="medium"
                >
                  {is_flagged ? 'Unflag Member' : 'Flag Member'}
                </LoadingButton>
              </Stack>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}

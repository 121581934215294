import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import SignUpOTPView from 'src/sections/auth/sign-up-view-OTP';

// ----------------------------------------------------------------------

export default function SignUpPageOTP() {
  return (
    <>
      <MetaHelmet title="Auth" miniDescription="Sign Up OTP Form" />

      <SignUpOTPView />
    </>
  );
}

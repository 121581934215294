import React from 'react';

import { Container } from '@mui/material';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import { useSettingsContext } from 'src/components/settings';

import MemberLists from './MembersPage';

function AllMembers() {
  const settings = useSettingsContext();
  return (
    <>
      {/* <MetaHelmet title="Members" /> */}
      <MetaHelmet title="Winbuks" miniDescription="Members" />

      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <MemberLists />
      </Container>
    </>
  );
}

export default AllMembers;

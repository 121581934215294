import { createSlice } from '@reduxjs/toolkit';

import {
  getAllTransactions,
  getAllTransactionsAdmin,
  getAllClubIncomeTransactions,
} from '../api/transaction';

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState: {
    loading: false,
    error: {},
    transactions: {},
    transactionDetails: {},
    latestTransactions: {},
    ClubIncomeTransactions: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // get all transactions
    [getAllTransactions.pending]: (state) => {
      state.loading = true;
    },
    [getAllTransactions.fulfilled]: (state, action) => {
      state.loading = false;
      state.transactions = action.payload;
      state.error = {};
    },
    [getAllTransactions.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllTransactionsAdmin.pending]: (state) => {
      state.loading = true;
    },
    [getAllTransactionsAdmin.fulfilled]: (state, action) => {
      state.loading = false;
      state.transactions = action.payload;
      state.error = {};
    },
    [getAllTransactionsAdmin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // get all club income transactions
    [getAllClubIncomeTransactions.pending]: (state) => {
      state.loading = true;
    },
    [getAllClubIncomeTransactions.fulfilled]: (state, action) => {
      state.loading = false;
      state.ClubIncomeTransactions = action.payload;
      state.error = {};
    },
    [getAllClubIncomeTransactions.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = transactionSlice.actions;

export default transactionSlice.reducer;

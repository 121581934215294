/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';

import { Container } from '@mui/material';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import { useSettingsContext } from 'src/components/settings';

import Transaction from './TransactionPage';

function AllTransactions({ type, disabled, earingPage }) {
  const settings = useSettingsContext();

  return (
    <>
      <MetaHelmet title="Winbuks" miniDescription="Transaction" />
      {type ? (
        <Transaction type={type} earnings={earingPage} />
      ) : (
        <Container maxWidth={settings.themeStretch ? false : 'xl'}>
          <Transaction disable={disabled} />
        </Container>
      )}
    </>
  );
}

export default AllTransactions;

AllTransactions.propTypes = {
  type: PropTypes.any,
  disabled: PropTypes.any,
  earingPage: PropTypes.any,
};

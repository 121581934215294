// import { useDispatch } from 'react-redux';
// import { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

// @mui
import { styled } from '@mui/material/styles';
import { Container, CircularProgress } from '@mui/material';

// import { paths } from 'src/routes/routes/paths';

// import { useGetToken } from 'src/hooks/useHandleSessions';

// import { logoutApi } from 'src/server/api/auth';
// import { TOKEN_PREFIX } from 'src/server/api/http';
import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LogoutPage() {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const { isSuccess, token } = useGetToken(TOKEN_PREFIX);

  // const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   setLoading(true);
  //   const timer = setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  //   return () => clearTimeout(timer);
  // }, []);

  // useEffect(() => {
  //   const credentials = {
  //     dispatch,
  //     navigate,
  //   };
  //   dispatch(logoutApi(credentials));
  // }, [dispatch, navigate]);

  // useEffect(() => {
  //   if (isSuccess && !token) {
  //     navigate(paths.auth.login);
  //     sessionStorage.clear();
  //   }
  // }, [isSuccess, token, navigate]);

  return (
    <>
      <MetaHelmet title="Auth" miniDescription="Logout" />

      <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <CircularProgress color="warning" />
        </StyledContent>
      </Container>
    </>
  );
}

import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { Container } from '@mui/material';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import { useSettingsContext } from 'src/components/settings';

import NewIncomePage from './WalletPage';
import IncomePage from '../Income/IncomePage';
import IncomeStatic from '../Income/IncomeStaticPage';

function MyWalletPage() {
  const { MemberProfileDetails } = useSelector((state) => ({
    MemberProfileDetails: state.member.memberProfileDetails.member,
  }));
  const settings = useSettingsContext();
  const location = useLocation();

  function getValueFromSearch(searchString, parameterName) {
    const searchParams = new URLSearchParams(searchString);
    return searchParams.get(parameterName);
  }
  const searchValue = getValueFromSearch(location.search, 'search');
  return (
    <>
      <MetaHelmet title="Winbuks" miniDescription="Wallet" />
      <Container
        maxWidth={
          // eslint-disable-next-line no-nested-ternary
          settings.themeStretch ? false : searchValue === 'my_wallet' ? 'lg' : 'lg'
        }
      >
        {searchValue === 'my_wallet' && <NewIncomePage />}
        {searchValue === 'archived' && (
          <>
            {MemberProfileDetails && MemberProfileDetails.is_club_member === true ? (
              <IncomePage />
            ) : (
              <IncomeStatic />
            )}
          </>
        )}
      </Container>
    </>
  );
}

export default MyWalletPage;

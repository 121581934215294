import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import { Box, Card, Grid, CardMedia, Typography } from '@mui/material';

import { USDT } from 'src/hooks/use-USDT';

import { useSettingsContext } from 'src/components/settings';

// ----------------------------------------------------------------------

export default function MiniCards({ title, total, icon, color = 'primary', chart, sx, ...other }) {
  const { themeMode } = useSettingsContext();
  return (
    <Card
      variant="outlined"
      sx={{
        p: 1,
        borderRadius: '6px',
        textAlign: 'center',
        boxShadow: 'none',
      }}
    >
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          px: 2,
          py: 0.6,
          overflow: 'hidden',
          position: 'relative',
          color: 'common.white',
          bgcolor: color,
          height: '76px',
          borderRadius: '2px',
        }}
      >
        <Grid display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h5">{total?.toFixed(2)}</Typography>
          &nbsp;
          <Typography sx={{ fontWeight: 400, fontSize: '14px', pt: 0.5 }}>{USDT}</Typography>
        </Grid>
      </Stack>
      <Box mt={0.5} display="flex" alignItems="center" justifyContent="start">
        <Box>
          <CardMedia
            component="img"
            src={icon}
            sx={{
              objectFit: 'contain',
              width: '16px',
              height: '16px',
            }}
          />
        </Box>
        <Box pl={1}>
          <Typography
            color={themeMode === 'dark' ? 'white' : 'rgba(0, 0, 0, 1)'}
            variant="caption"
            fontSize={12}
            fontWeight={600}
            lineHeight="18px"
            mt={0.5}
          >
            {title}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}

MiniCards.propTypes = {
  chart: PropTypes.object,
  color: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  sx: PropTypes.object,
  title: PropTypes.string,
  total: PropTypes.number,
};

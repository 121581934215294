import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ListItemText from '@mui/material/ListItemText';
import { Stack, Select, Tooltip, MenuItem, Typography, FormControl } from '@mui/material';

import { fDate, fTime } from 'src/utils/format-time';

import { updateEnquiryStatus } from 'src/server/api/referrals';

import EnquiryView from './enquiryView';

export default function EnquiryTableRow({ row, selected }) {
  const { createdAt, first_name, last_name, status, _id, email } = row;
  const dispatch = useDispatch();
  const [statusValue, setStatusValue] = useState('');
  const [openModalDialog, setOpenModalDialog] = useState(false);
  // console.log(message)
  // console.log(subject)

  const statusOptions = [
    { label: 'New', value: 'NEW' },
    { label: 'In Progress', value: 'IN_PROGRESS' },
    { label: 'Closed', value: 'CLOSED' },
    // { label: 'Opened', value: 'OPENED' },
    // { label: 'Confirmed', value: 'CONFIRMED' },
  ];
  const handleTypeChange = (event, id) => {
    const newStatus = event.target.value;
    setStatusValue(newStatus);
    // console.log(newStatus);
    // console.log('id =>', id);
    const updateCredentials = {
      state: {
        status: newStatus,
        id: _id,
      },
      dispatch,
    };

    dispatch(updateEnquiryStatus(updateCredentials));
  };

  useEffect(() => {
    const getStatusValue = () => (status === 'OPENED' ? 'NEW' : status);
    if (status) {
      setStatusValue(getStatusValue());
    }
  }, [setStatusValue, status]);

  return (
    <>
      {openModalDialog && (
        <EnquiryView
          setOpen={setOpenModalDialog}
          open={openModalDialog}
          isUpdate={row}
          // setIsUpdate={setInvestorData}
        />
      )}
      <TableRow hover selected={selected}>
        <TableCell>
          <ListItemText
            primary={fDate(createdAt)}
            secondary={fTime(createdAt)}
            primaryTypographyProps={{ variant: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              variant: 'caption',
            }}
          />
        </TableCell>
        <TableCell onClick={() => setOpenModalDialog(true)}>
          <Tooltip title="View Details ">
            <Typography variant="body2" color="#2e436a" sx={{ cursor: 'pointer' }}>
              {first_name} {last_name}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{email}</Typography>
        </TableCell>
        {/* <TableCell>
        <TextMaxLine line={2}>
          <Typography variant="body2">{message}</Typography>
        </TextMaxLine>
      </TableCell>
      <TableCell>
        <TextMaxLine line={2}>
          <Typography variant="body2">{subject}</Typography>
        </TextMaxLine>
      </TableCell> */}
        <TableCell>
          <Stack className="all-list-select-ui">
            <FormControl width="150px" name="status">
              <Select
                value={statusValue}
                onChange={handleTypeChange}
                sx={{
                  backgroundColor: statusOptions.find((option) => option.value === statusValue)
                    ?.color,
                  color: 'black',
                }}
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option.value} value={option?.value} sx={{ color: 'black' }}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
}

EnquiryTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
};

// import { useState } from 'react';
import { useState } from 'react';
import { pdfjs } from 'react-pdf';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@react-pdf-viewer/core/lib/styles/index.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';

import Stack from '@mui/material/Stack';

import { paths } from 'src/routes/routes/paths';

// eslint-disable-next-line import/no-unresolved
import samplePdf from 'src/assets/pdf/BLOCKCHAIN_TECHNOLOGY.pdf';

import Scrollbar from 'src/components/scrollbar';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

// ----------------------------------------------------------------------

export default function LearningPage() {
  const [numPages, setNumPages] = useState(null);
  console.log(numPages); // do not remove

  const onDocumentLoadSuccess = ({ Pages }) => {
    setNumPages(Pages);
  };

  return (
    <>
      <CustomBreadcrumbs
        heading="Learnings"
        links={[
          {
            name: 'Dashboard',
            href: paths.dashboard.root,
          },
          {
            name: 'Learnings',
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Stack spacing={3} width="100%">
        <Scrollbar>
          <Worker
            workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
          >
            <Viewer
              fileUrl={samplePdf}
              defaultScale={SpecialZoomLevel.PageWidth}
              onDocumentLoad={onDocumentLoadSuccess}
            />
          </Worker>
        </Scrollbar>
      </Stack>
    </>
  );
}

import * as React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import parser from 'html-react-parser';
import { useNavigate } from 'react-router';

import { Box } from '@mui/system';
import { useTheme } from '@mui/styles';
// @mui
import { Stack, Paper, Dialog, CardMedia, Typography, DialogContent } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { fDateTime } from 'src/utils/format-time';

import { paper } from 'src/theme/css';

NotificationModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  data: PropTypes.any,
};

export default function NotificationModal({ open, setOpen, data }) {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleClose = () => {
    setOpen(false);
  };

  const handleRoute = () => {
    navigate(paths?.dashboard?.memberNotification);
    handleClose();
  };

  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className="custom-notification-modal"
      >
        <DialogContent
          sx={{
            ...paper({ theme }),
            backgroundColor: '#ECECEC',
            borderRadius: 0,
            height: 650,
            width: 380,
            padding: 1.5,
            backdropFilter: 'inherit',
          }}
        >
          <Stack spacing={2} direction="column" justifyContent="space-between" height="100%">
            {/* Header */}
            <Box>
              <Typography variant="h6" color="#212B36" px={2} pb={2}>
                Notifications
              </Typography>
              <Stack
                sx={{
                  maxHeight: 520,
                  overflowY: 'auto',
                  scrollbarWidth: 'thin',
                  scrollbarColor: '#ECECEC transparent',
                  '&::-webkit-scrollbar': {
                    width: '8px',
                    borderRadius: 5,
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#888',
                    borderRadius: 4,
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent',
                  },
                  '&::-webkit-scrollbar-button': {
                    display: 'none',
                  },
                }}
              >
                {data?.map((i, index) => (
                  <Paper
                    key={index}
                    sx={{
                      borderRadius: 2,
                      // boxShadow: 2,
                      padding: 2,
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 2,
                      borderBottom: '0.5px dashed #e2e2e2',
                      mb: 1,
                    }}
                  >
                    <Stack spacing={1}>
                      {i?.image && (
                        <Stack>
                          <CardMedia
                            src={i?.image?.url}
                            component="img"
                            height="100%"
                            sx={{
                              width: 200,
                              objectFit: 'contain',
                              borderRadius: 2,
                            }}
                            width={80}
                          />
                        </Stack>
                      )}
                      <Typography variant="subtitle2" fontWeight="bold" pt={0.5} pl={0.3}>
                        {i?.title}
                      </Typography>
                      <Typography
                        pl={0.3}
                        variant="caption"
                        sx={{ alignSelf: 'flex-start', color: 'text.secondary', marginTop: -0.5 }}
                      >
                        {fDateTime(i?.createdAt)}
                      </Typography>

                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          backgroundColor: '#F4F6F8',
                          px: 1,
                          borderRadius: 1,
                          width: 285,
                        }}
                      >
                        {parser(i?.description)}
                      </Typography>
                    </Stack>
                  </Paper>
                ))}
              </Stack>
            </Box>

            {data?.length <= 0 && (
              <Typography variant="h6" textAlign="center">
                No Notifications Yet !
              </Typography>
            )}

            {/* Footer */}
            <Typography
              variant="subtitle2"
              pb={2}
              // position="absolute"
              // bottom={20}
              sx={{
                color: 'black',
                cursor: 'pointer',
                textAlign: 'center',
                mt: 1,
              }}
              onClick={() => handleRoute()}
            >
              Read more
            </Typography>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Stack } from '@mui/system';
// @mui
import { CardMedia, Container, Typography, CircularProgress } from '@mui/material';

import { paths } from 'src/routes/routes/paths';
import { useParams, useRouter } from 'src/routes/hooks';

import dashboard from 'src/assets/other/dashboard.webp';
import { getMemberProfileDetails } from 'src/server/api/member';

// components

// action

// sections

// ----------------------------------------------------------------------

export default function DashboardLoadingPage() {
  const params = useParams();
  console.log(params);
  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    if (params?.id) {
      sessionStorage.setItem('memberId', params.id);
    }
  }, [params.id]);

  useEffect(() => {
    const memberId = sessionStorage.getItem('memberId');

    if (memberId === params?.id) {
      // profile
      const credential = {
        dispatch,
      };
      dispatch(getMemberProfileDetails(credential));

      // dashboard

      setTimeout(() => {
        router.push(paths.dashboard.root);
      }, 2000);
    }
  }, [dispatch, params?.id, router]);

  return (
    <Container
      maxWidth="xl"
      sx={{
        mt: 4,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Stack alignItems="center" gap={2}>
        <CardMedia component="img" src={dashboard} alt="dashboard" sx={{ width: '250px' }} />
        <CircularProgress color="success" />
        <Box>
          <Typography variant="h6" color="black">
            Syncing member data...{' '}
          </Typography>
        </Box>{' '}
      </Stack>
    </Container>
  );
}

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// xx
// @mui
import {
  Box,
  Grid,
  Card,
  Stack,
  TextField,
  Typography,
  CardHeader,
  IconButton,
  Autocomplete,
  InputAdornment,
} from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { CheckUserName } from 'src/server/api/auth';
// components
import { createMember } from 'src/server/api/member';
import { countryCodes } from 'src/assets/data/countryCodes';

import Iconify from 'src/components/iconify';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

export default function MemberCrud() {
  const navigate = useNavigate();
  const password = useBoolean();
  const isMobile = useResponsive('down', 'md');
  const dispatch = useDispatch();

  const { loading, userNamesCheck } = useSelector((state) => ({
    loading: state.member.loading,
    userNamesCheck: state.auth.CheckUserNames,

    error: state.member.error,
  }));
  // console.log(error);

  // const [imagePreview, setImagePreview] = useState([]);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [selectedCountryCode, setSelectedCountryCode] = useState({ label: 'Select', value: '' });
  // console.log(selectedCountryCode);
  // console.log(setImagePreview);
  const mdUp = useResponsive('up', 'md');

  const memberCreateSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    name: Yup.string(),
    email: Yup.string().required('email is required'),
    phone: Yup.string(),
    // .min(10, 'Phone must be at least 10 characters')
    // .max(10, 'Phone must not exceed 10 characters'),
    date_of_birth: Yup.string(),
    company: Yup.string(),
    referral_member: Yup.string(),
    referral_id: Yup.string(),
    payment_transaction_id: Yup.string(),
  });
  const defaultValues = useMemo(
    () => ({
      name: '',
      username: '',
      email: '',
      referral_id: '',
      phone: '',
      date_of_birth: '',
      company: '',
      password: '',
      payment_transaction_id: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(memberCreateSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
    // reset,
    // setValue,
    watch,
  } = methods;
  const watchedUserName = watch('username');

  // const handleClear = () => {
  //   reset(defaultValues);
  // };

  // const handleDrop = useCallback(
  //   (acceptedFiles) => {
  //     const file = acceptedFiles[0];

  //     const newFile = Object.assign(file, {
  //       preview: URL.createObjectURL(file),
  //     });

  //     if (file) {
  //       setValue('image', newFile, { shouldValidate: true });
  //     }
  //   },
  //   [setValue]
  // );

  // const handleUserNameChange = (event) => {
  //   const { value } = event.target;
  //   console.log('User Name Value:', value);
  //   setUserNameCheck(value);
  // };

  useEffect(() => {
    if (watchedUserName) {
      const credential = {
        state: { username: watchedUserName },
      };
      dispatch(CheckUserName(credential));
    }
  }, [dispatch, watchedUserName]);
  const handleAutocompleteChangeCountry = (e, newValue) => {
    // setSelectedCountryCode(newValue?.value || '');
    setSelectedCountryCode({
      label: newValue?.label || 'Select',
      value: newValue?.value || '',
    });
  };
  const optionCountry = [
    ...(countryCodes || []).map((results) => ({
      label: `${results?.code} ${results?.name}`,
      value: results?.code,
    })),
  ];
  // useEffect(() => {

  //   setSelectedCountryCode({
  //     label:
  //       (updateCoutryCode?.code &&
  //         updateCoutryCode?.name &&
  //         ` ${updateCoutryCode?.code} ${updateCoutryCode?.name}`) ||
  //       'Select',
  //     value: updateCoutryCode?.code || '',
  //   });
  // }, [
  //   isUpdate?.country_code,
  //   isUpdate?.district,
  //   isUpdate?.district_name,
  //   isUpdate?.state,
  //   isUpdate?.state_name,
  //   updateCoutryCode?.code,
  //   updateCoutryCode?.name,
  // ]);
  const basicDetails = (
    <Grid container p={2}>
      {mdUp && (
        <Grid item md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Personal Info
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Enter required information such as name, email, phone, etc.
          </Typography>
          <br />
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Fields marked with an asterisk (
            <Typography color="error" variant="caption">
              *
            </Typography>
            ) are mandatory and must be completed before submitting.
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8} px={2}>
        <Card>
          {!mdUp && <CardHeader title="Details" />}
          <Stack spacing={3} sx={{ p: 1.5 }}>
            <Grid container my={2}>
              <Grid md={6} sx={{ mb: 2, px: 1 }}>
                <RHFTextField
                  name="name"
                  label="Name"
                  type="text"
                  required
                  placeholder="Enter Name"
                  pattern="^[A-Za-z\s]"
                />
              </Grid>
              <Grid
                md={6}
                marginBottom={2}
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
                sx={{ px: 1 }}
              >
                <TextField
                  sx={{ width: '100%' }}
                  name="username"
                  label="Username"
                  InputLabelProps={{ shrink: true }}
                  type="string"
                  placeholder="Enter username"
                  required
                  {...methods.register('username')}
                  helperText={
                    <Typography variant="body2">
                      {watchedUserName && (
                        <>
                          {userNamesCheck === false && (
                            <span style={{ color: '#FF5630' }}>
                              <WarningIcon
                                style={{
                                  fontSize: '14px',
                                  marginRight: '4px',
                                  verticalAlign: 'middle',
                                }}
                              />{' '}
                              This username is taken.
                            </span>
                          )}
                          {userNamesCheck === true && (
                            <span style={{ color: '#008000' }}>
                              <CheckCircleIcon
                                style={{
                                  fontSize: '14px',
                                  marginRight: '4px',
                                  verticalAlign: 'middle',
                                }}
                              />{' '}
                              Username available.
                            </span>
                          )}
                        </>
                      )}
                    </Typography>
                  }
                />

                {/* {watchedUserName && ( // Watch for changes to 'username' field
                  <>
                    {userNamesCheck === false && (
                      <Typography
                        color="#FF5630"
                        fontSize={12}
                        fontWeight={400}
                        display="flex"
                        alignItems="center"
                        ml={2}
                      >
                        <WarningIcon
                          sx={{ fontSize: '14px', marginRight: '4px', color: '#FF5630' }}
                        />{' '}
                        username already taken
                      </Typography>
                    )}
                    {userNamesCheck === true && (
                      <Typography
                        sx={{
                          color: 'success.main',
                          fontSize: '10px',
                          fontWeight: '600',
                          display: 'flex',
                          alignItems: 'center',
                          mx: 2,
                        }}
                      >
                        Verified <CheckCircleIcon sx={{ fontSize: '14px', marginLeft: '4px' }} />
                      </Typography>
                    )}
                  </>
                )} */}
              </Grid>
              <Grid md={6} sx={{ mb: 2, px: 1 }}>
                <RHFTextField
                  name="date_of_birth"
                  label="Date of Birth"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid md={6} sx={{ mb: 2, px: 1 }}>
                <RHFTextField
                  name="email"
                  label="Email"
                  type="string"
                  required
                  placeholder="Enter email address"
                />
              </Grid>

              <Grid md={6} sx={{ mt: 2, px: 1 }}>
                <Autocomplete
                  id="country-select-demo"
                  sx={{ width: '100%' }}
                  options={optionCountry}
                  autoHighlight
                  size="medium"
                  // className="small-select"
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  value={selectedCountryCode}
                  onChange={handleAutocompleteChangeCountry}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country Code"
                      // required
                      // placeholder={isUpdate?.country_code ? `+${isUpdate?.country_code}` : 'Select'}
                      InputLabelProps={{ shrink: true }}
                      // onChange={handleTextFieldChangeCountry}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid md={6} sx={{ mt: 2, px: 1 }}>
                <RHFTextField
                  sx={{ width: '100%' }}
                  name="phone"
                  type="number"
                  label="Phone"
                  placeholder="Enter phone number"
                  // onInput={(e) => {
                  //   const input = e.target.value.replace(/\D/g, '');
                  //   const limitedInput = input.slice(0, 10);
                  //   e.target.value = limitedInput;
                  // }}
                />
              </Grid>
            </Grid>
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );

  const accessCredentials = (
    <Grid container p={2}>
      {mdUp && (
        <Grid item md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Access Credentials
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            These credentials grant authorized individuals access to member panel, They can login
            with username and password combination
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8} px={2}>
        <Card>
          {!mdUp && <CardHeader title="Details" />}
          <Stack spacing={3} sx={{ p: 1.5 }}>
            <Grid container>
              <Grid md={12} sx={{ mb: 0, mt: 1, px: 1 }}>
                <RHFTextField
                  name="referral_id"
                  label="Referral ID"
                  type="string"
                  placeholder="Referral ID"
                />
              </Grid>
            </Grid>
            <Grid container pb={1.5}>
              <Grid item md={6} px={1.5}>
                <RHFTextField
                  name="password"
                  label="Password"
                  placeholder="Password"
                  required
                  InputLabelProps={{ shrink: true }}
                  type={password.value ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={password.onToggle} edge="end">
                          <Iconify
                            icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={6} px={1}>
                <RHFTextField
                  required
                  name="confirm_password"
                  label="Confirm"
                  InputLabelProps={{ shrink: true }}
                  placeholder="Confirm Password"
                  type={password.value ? 'text' : 'password'}
                  onChange={(e) => {
                    const confirmPasswordValue = e.target.value;
                    const passwordValue = methods.getValues('password');
                    setPasswordMatch(passwordValue === confirmPasswordValue);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={password.onToggle} edge="end">
                          <Iconify
                            icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box>
                  {!passwordMatch && (
                    <Stack textAlign="right">
                      <Typography
                        variant="body2"
                        color="error"
                        display="flex"
                        alignItems="center"
                        justifyContent="start"
                        mr={2}
                      >
                        <WarningIcon
                          sx={{ fontSize: '14px', marginRight: '4px', color: '#FF5630' }}
                        />{' '}
                        Password does not match
                      </Typography>
                    </Stack>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );

  // const walletAddress = (
  //   <Grid container p={2}>
  //     {mdUp && (
  //       <Grid item md={4}>
  //         <Typography variant="h6" sx={{ mb: 0.5 }}>
  //           Wallet Address
  //         </Typography>
  //         <Typography variant="body2" sx={{ color: 'text.secondary' }}>
  //           Users can securely upload their crypto wallet address. This allows them to receive
  //           transfers of their personal wallet balance directly into their crypto wallet.
  //         </Typography>
  //       </Grid>
  //     )}

  //     <Grid xs={12} md={8} px={2}>
  //       <Card>
  //         {!mdUp && <CardHeader title="Details" />}

  //         <WalletAddressDetailsAdmin />
  //       </Card>
  //     </Grid>
  //   </Grid>
  // );

  const payment = (
    <Grid container p={2}>
      {mdUp && (
        <Grid item md={4} lg={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Payment Details{' '}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {/* If your payment has been successfully processed, please kindly provide us with a
            screenshot of the payment. */}
            Enter the Transaction hash
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8} px={2}>
        <Card sx={{ paddingBottom: 5 }}>
          {!mdUp && <CardHeader title="Details" />}

          <Stack spacing={3} sx={{ p: isMobile ? 1 : 3 }}>
            <Grid container>
              {/* <Grid item md={6} xs={12} pb={isMobile && 2}>
                <FileUploadCustom
                  uploadUrl="member"
                  folder="logo"
                  setImagesPreview={setImagePreview}
                  imagesPreview={imagePreview}
                  title="Upload Image"
                  isMultiple
                  imageSize={4}
                  theme={{
                    type: 'simple-square',
                    name: 'image',
                    handleDrop,
                  }}
                />
              </Grid> */}
              <Grid item md={6} lg={6} px={isMobile ? 0 : 0}>
                <RHFTextField
                  name="payment_transaction_id"
                  label="Enter Transaction hash"
                  type="text"
                  // placeholder="eg: TID3872XG9"
                />
              </Grid>
              <Grid item lg={6} px={isMobile ? 0 : 0} />
            </Grid>
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
  const onSubmit = handleSubmit(async (data) => {
    // console.log(data);
    const credentialsCreate = {
      state: {
        ...data,
        // payment_screenshot: imagePreview,
        country_code: selectedCountryCode?.value || '',
        // username: userNameCheck,
      },
      dispatch,
      // handleClose,
      // handleClear,
      navigate,
    };
    // if () {
    //   const credentialsUpdate = {
    //     state: {
    //       ...data,
    //       is_active: isActive,
    //       is_verified: isVerified,
    //       is_paid: isPaid,
    //     },
    //     dispatch,

    //     navigate,
    //   };
    // dispatch(updateMemberDetails(credentialsUpdate));
    dispatch(createMember(credentialsCreate));
  });

  return (
    <Grid>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Stack direction="column">
          <CustomBreadcrumbs
            heading="Create new member"
            links={[
              { name: 'Dashboard', href: paths.dashboard.root },
              { name: 'Member', href: paths.dashboard.member },
              { name: 'New Member' },
            ]}
            sx={{
              mb: { xs: 3, md: 5 },
            }}
          />
          {basicDetails}
          {accessCredentials}
          {/* {walletAddress} */}
          {payment}
        </Stack>
        <Stack px={2}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting || loading}
            sx={{ ml: 'auto', px: 3 }}
            color="success"
          >
            Create Member
          </LoadingButton>
        </Stack>
      </FormProvider>
    </Grid>
  );
}

import moment from 'moment';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import React, { useState, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Card,
  Alert,
  Popover,
  MenuItem,
  Accordion,
  CardMedia,
  IconButton,
  Typography,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { fDateTime } from 'src/utils/format-time';

import empty from 'src/assets/other/empty.png';
import timer from 'src/assets/other/timer.png';
import { deleteWalletAddressAdmin, getAllWalletAddressAdmin } from 'src/server/api/walletAddress';

import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';

import WalletAddressDialog from 'src/sections/walletAddress/walletAdressModal';

import TimerComponent from './timerComponent';
// ----------------------------------------------------------------------

export default function WalletAddressDetailsAdmin({ memberDetails }) {
  const dispatch = useDispatch();
  const confirm = useBoolean();
  const params = useParams();
  // const role = useGetRoles()?.role;
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useResponsive('down', 'md');
  const [openWalletAddressId, setOpenWalletAddressId] = useState({});
  const [openWalletAddressDialog, setOpenWalletAddressDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [dateCheck, setDateCheck] = useState(false);
  // console.log('dateCheck',dateCheck)
  const { memberWalletAddress } = useSelector((state) => ({
    loading: state.referrals.loading,
    memberProfileDetails: state.member.memberProfileDetails,
    memberClubForMember: state.memberClub.memberClubsForMember?.memberClubs,
    // memberWalletAddress: state.walletAddress.walletAddresses.walletAddresses,
    memberWalletAddress: state.walletAddress?.walletAddresses?.memberWalletAddresses,
  }));
  console.log(openWalletAddressId);

  // console.log(role);

  useEffect(() => {
    const credentials = {
      page: '',
      search: '',
      id: params?.id,
      limit: '',
      dispatch,
    };
    if (params?.id) {
      dispatch(getAllWalletAddressAdmin(credentials));
    }
  }, [dispatch, params?.id]);
  const handleDeleteConfirmClick = (id) => {
    const credentials = {
      dispatch,
      walletId: id,
      id: params?.id,
    };
    // console.log(id);

    dispatch(deleteWalletAddressAdmin(credentials));
    confirm.onFalse();
  };

  const isDisabled = memberWalletAddress && memberWalletAddress.length > 2;

  const handleDelete = (item) => {
    setSelectedItem(item);
    confirm.onTrue();
  };
  const handleCopy = () => {
    enqueueSnackbar('Link copied!', { variant: 'success' });
  };
  const [expanded, setExpanded] = useState(false);

  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };
  const [popover, setPopover] = useState({ open: false, index: null });
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event, index) => {
    setAnchorEl(event.currentTarget);
    setPopover({ open: true, index });
  };

  const handlePopoverClose = () => {
    setPopover({ open: false, index: null });
    setAnchorEl(null);
  };
  // const handlePopoverClose = () => {
  //   setPopover({ open: false, index: null, mouseX: 0, mouseY: 0 });
  // };
  // console.log('memberProfileDetails', memberDetails);
  // console.log('gggg------>>', formatRemainingTime(memberDetails?.wallet_address_last_updated_at));
  return (
    <>
      {openWalletAddressDialog && (
        <WalletAddressDialog
          memberId={memberDetails?._id}
          isUpdate={openWalletAddressId}
          setOpenWalletAddressId={setOpenWalletAddressId}
          setOpen={setOpenWalletAddressDialog}
          open={openWalletAddressDialog}
        />
      )}
      <Stack
        spacing={3}
        mb={2}
        direction={{ xs: 'row', md: 'row' }}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        justifyContent="space-between"
      >
        <Typography variant="h6">Wallet Address</Typography>
        <Tooltip title={isDisabled ? 'Only 3 wallet addresses are allowed' : ''}>
          <Button
            type="submit"
            size="small"
            disabled={isDisabled}
            startIcon={<Iconify icon="mingcute:add-line" sx={{ width: '15px' }} />}
            onClick={() => {
              setOpenWalletAddressDialog(true);
            }}
            sx={{ flexShrink: 0, backgroundColor: '#f0f0f0', color: 'black' }}
          >
            Add
          </Button>
        </Tooltip>
      </Stack>
      <Card
        variant="outlined"
        sx={{
          py: 0,
          // minHeight: 300,
          // border: settings?.themeMode === 'dark' ? '' : '1px solid #e3e7ea',
          boxShadow: 'unset',
          height: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        {memberWalletAddress && memberWalletAddress?.length > 0 ? (
          <Stack className="wallet-address" spacing={0} mb={2}>
            {(memberWalletAddress || []).map((item, index) => (
              <>
                <Accordion
                  key={index}
                  expanded={expanded === index}
                  // onChange={handleChange(index)}
                  className="custom-accordion"
                >
                  <AccordionSummary
                    sx={{
                      borderRadius: '8px',
                      px: 2,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box display="flex" alignItems="center" flexGrow={1}>
                      <IconButton
                        size="small"
                        onClick={() => setExpanded(expanded === index ? false : index)}
                        sx={{ mr: 1 }}
                      >
                        <ArrowRightIcon
                          sx={{
                            transform: expanded === index ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.2s',
                            color: 'black',
                          }}
                        />
                      </IconButton>
                      <Typography variant="body2" sx={{ flexGrow: 1 }}>
                        {item?.title ? item?.title : 'No Title'}
                      </Typography>
                    </Box>
                    <CopyToClipboard text={item?.wallet_id} onCopy={handleCopy}>
                      <Tooltip title="Copy Wallet">
                        <IconButton
                          size="small"
                          sx={{ justifyContent: 'center', borderRadius: '8px' }}
                        >
                          <Typography variant="caption" color="#00A76F">
                            Copy
                          </Typography>
                        </IconButton>
                      </Tooltip>
                    </CopyToClipboard>

                    <IconButton
                      size="small"
                      onClick={(e) => handlePopoverOpen(e, index)}
                      sx={{ mr: 1 }}
                    >
                      <MoreVertIcon
                        sx={{
                          transform: expanded === index ? 'rotate(180deg)' : 'rotate(0deg)',
                          transition: 'transform 0.2s',
                        }}
                      />
                    </IconButton>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      sx={{
                        px: 5,
                        maxWidth: isMobile ? '300px' : '550px',
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="#637381"
                        gutterBottom
                        style={{
                          whiteSpace: 'normal',
                          overflowWrap: 'break-word',
                        }}
                      >
                        {item?.wallet_id ? item?.wallet_id : 'No Wallet ID'}
                      </Typography>
                      {item?.withdraw_message && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Typography variant="body2" sx={{ color: '#637381' }}>
                            {item?.withdraw_message}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion>

                <Popover
                  open={popover.open && popover.index === index}
                  onClose={handlePopoverClose}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  sx={{ width: 140 }}
                >
                  <Tooltip title="Remove Wallet">
                    <MenuItem
                      onClick={() => {
                        handleDelete(item);
                        handlePopoverClose();
                      }}
                      sx={{ color: 'error.main' }}
                    >
                      <Iconify icon="solar:trash-bin-trash-bold" sx={{ mr: 2 }} />
                      Delete
                    </MenuItem>
                  </Tooltip>

                  <Tooltip title="Edit Wallet">
                    <MenuItem
                      onClick={() => {
                        setOpenWalletAddressId(item);
                        setOpenWalletAddressDialog(true);
                        handlePopoverClose();
                      }}
                    >
                      <Iconify icon="solar:pen-bold" sx={{ mr: 2 }} />
                      Edit
                    </MenuItem>
                  </Tooltip>
                </Popover>
              </>
            ))}
          </Stack>
        ) : (
          <Stack p={4} justifyContent="center" alignItems="center" gap={1} py={7}>
            <CardMedia component="img" src={empty} alt="copy" sx={{ width: '120px' }} />
            <Typography variant="caption" fontWeight="bold">
              No wallet address yet!
            </Typography>
            <Typography variant="caption" color="#637381">
              Click ‘+Add’ to set up your wallet address and begin making transactions!
            </Typography>
          </Stack>
        )}
        {/* <Box
          sx={{
            pt: 2,
            px: 2,
            mx: 1,
            height: 230,
            overflowY: 'auto',
            WebkitOverflowScrolling: 'touch',
            '&::-webkit-scrollbar': {
              width: '8px', // Width of the scrollbar
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'grey', // Color of the scrollbar thumb
              borderRadius: '15px', // Border radius of the scrollbar thumb
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#F5F5F5', // Color of the scrollbar track
              borderRadius: '15px', // Border radius of the scrollbar track
            },
          }}
        >
          {memberWalletAddress && memberWalletAddress.length > 0 ? (
            <Stack spacing={1} mb={2}>
              {(memberWalletAddress || []).map((item, index) => (
                <Card
                  key={index}
                  sx={{
                    border: '1px solid #00B8D9 ',
                    borderRadius: '10px',
                    // boxShadow: 'box-shadow: 4px 4px 4px 0px #00000040',
                    boxShadow:
                      '6px 6px 2px 0 rgb(91 94 98 / 20%), 14px 16px 24px -4px rgb(120 127 133 / 12%)',
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    padding={2}
                    overflow={isMobile && 'scroll'}
                    width={isMobile ? 300 : 'auto'}
                  >
                    <Typography>
                      {item?.title ? `${item?.title} : ` : ''}
                      {item?.wallet_id}
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={isMobile ? 0 : 1}
                      padding={isMobile && 1.5}
                      px={isMobile && 1}
                      sx={{
                        position: isMobile && 'absolute',
                        right: isMobile && 0,
                        bgcolor: isMobile && '#f3f2f2',
                      }}
                    >
                      <CopyToClipboard text={item?.wallet_id} onCopy={handleCopy}>
                        <Tooltip title="Copy Wallet">
                          <Button size="small" color="info" sx={{ minWidth: '27px' }}>
                            <CardMedia
                              component="img"
                              src={copy}
                              alt="copy"
                              sx={{ width: '20px' }}
                            />
                          </Button>
                        </Tooltip>
                      </CopyToClipboard>
                      <Tooltip title="Edit Wallet">
                        <Button
                          size="small"
                          color="info"
                          sx={{ minWidth: '27px' }}
                          onClick={() => {
                            setOpenWalletAddressId(item);
                            setOpenWalletAddressDialog(true);
                          }}
                        >
                          <Iconify icon="heroicons-outline:pencil-alt" sx={{ color: 'grey' }} />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Remove Wallet">
                        <Button
                          size="small"
                          color="info"
                          sx={{ minWidth: '27px' }}
                          onClick={() => {
                            handleDelete(item);
                          }}
                        >
                          <Iconify icon="heroicons-outline:trash" sx={{ color: 'grey' }} />
                        </Button>
                      </Tooltip>
                    </Stack>
                  </Stack>
                </Card>
              ))}
            </Stack>
          ) : (
            <Stack flexDirection="row" spacing={2} alignItems="center">
              <Stack alignItems="center" flex={6}>
                <CardMedia component="img" src={wallet} alt="dashboard" sx={{ width: '210px' }} />
              </Stack>
              <Stack alignItems="left" flexDirection="row" justifyContent="center" flex={6}>
                <ErrorOutlineIcon
                  sx={{ marginRight: '5px', color: '#697077', fontSize: '15px', mt: 0.5 }}
                />
                <Typography
                  variant="caption"
                  color="#697077"
                  justifyContent="center"
                  alignItems="center"
                >
                  You can only withdraw funds from your Winbuks wallet 24 hours after updating your
                  wallet address.{' '}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Box> */}
        {memberWalletAddress && memberWalletAddress?.length > 0 && (
          <Stack
            mt={1.5}
            mx={4}
            mb={2}
            flexDirection={isMobile ? 'column' : 'column'}
            justifyContent="space-between"
            // sx={{ position: 'absolute', bottom: 16, right: 16 }}
          >
            {/* {memberWalletAddress && memberWalletAddress?.length > 0 && (
              <Alert
                className="alert-custom"
                icon={<Iconify icon="material-symbols:info" sx={{ color: '#B76E00' }} />}
                sx={{
                  bgcolor: '#F8F6F2',
                  color: '#00A76F',
                  borderRadius: '10px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  p: 0.5,
                  px: 1,
                  mb: 1,
                  width: '100%',
                }}
              >
                <Typography variant="caption" color="#B76E00" sx={{ fontWeight: 500 }}>
                  You can withdraw your funds from your Winbuks wallet in{' '}
                  <Typography variant="caption" component="span" fontWeight="bold">
                    {formatRemainingTime(memberDetails?.wallet_address_last_updated_at)}
                  </Typography>
                </Typography>
              </Alert>
            )} */}
            {memberWalletAddress && memberWalletAddress?.length > 0 && !dateCheck && (
              <Alert
                className="alert-custom"
                icon={<Iconify icon="material-symbols:info" sx={{ color: '#B76E00' }} />}
                sx={{
                  bgcolor: '#F8F6F2',
                  color: '#00A76F',
                  borderRadius: '10px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  py: 1.5,
                  px: 1,
                  mb: 1,
                }}
              >
                <Typography
                  variant="caption"
                  color="#B76E00"
                  sx={{ fontWeight: 500, display: 'flex', gap: 0.5, alignItems: 'center' }}
                >
                  You can withdraw your funds from your Winbuks wallet in{' '}
                  <TimerComponent
                    setDateCheck={setDateCheck}
                    updatedAt={moment(memberDetails?.wallet_address_last_updated_at).toISOString()}
                  />
                </Typography>
              </Alert>
            )}
            {memberDetails?.wallet_address_last_updated_at && (
              <Stack alignItems="center" flexDirection="row" justifyContent="center" flex={7}>
                <CardMedia
                  component="img"
                  src={timer}
                  alt="copy"
                  sx={{ width: '18px', marginRight: '5px' }}
                />

                {isMobile ? (
                  <Typography
                    variant="caption"
                    color="#697077"
                    justifyContent="center"
                    alignItems="center"
                    mt={0.2}
                  >
                    Last updated {fDateTime(memberDetails?.wallet_address_last_updated_at)}
                  </Typography>
                ) : (
                  <Typography
                    variant="caption"
                    color="#697077"
                    justifyContent="center"
                    alignItems="center"
                    mt={0.2}
                  >
                    Last updated {fDateTime(memberDetails?.wallet_address_last_updated_at)}
                  </Typography>
                )}
              </Stack>
            )}
          </Stack>
        )}
      </Card>
      <ConfirmDialog
        title={`Are you sure want to delete  '${selectedItem ? selectedItem?.title : ''}' ? `}
        content="Are you sure you want to delete this Wallet Address? This action cannot be undone."
        action={
          <Button
            variant="contained"
            sx={{
              color: 'white',
              backgroundColor: '#106E58',
              '&:hover': {
                backgroundColor: '#0b4e3e',
                boxShadow: 'unset',
              },
            }}
            onClick={() => handleDeleteConfirmClick(selectedItem?._id)}
          >
            Delete
          </Button>
        }
        open={confirm.value}
        onClose={confirm.onFalse}
      />
    </>
  );
}

WalletAddressDetailsAdmin.propTypes = {
  memberDetails: PropTypes.any,
};

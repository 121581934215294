import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, Stack } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import { getAllClubs } from 'src/server/api/club';
import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import { getAllTransactionsAdmin } from 'src/server/api/transaction';
import { getAllMemberClubsForMember } from 'src/server/api/memberClub';

import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import WalletTransferDialog from 'src/sections/income/wallet-Transfer';
import WalletTransferSuccess from 'src/sections/income/WalletTransfer-success-modal';

import AllClubTransactions from '../Club Transactions';
import AllClub from '../../../sections/clubIncomeTransaction/editClub';

// ----------------------------------------------------------------------

export default function AllClubPage() {
  // const { enqueueSnackbar } = useSnackbar();
  // const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useResponsive('down', 'md');
  const role = useGetRoles();

  const { clubList } = useSelector((state) => ({
    loading: state.referrals.loading,
    MemberProfileDetails: state.member.memberProfileDetails,
    memberClubForMember: state.memberClub.memberClubsForMember?.memberClubs,
    clubList: state.club.clubs?.clubs,
    settingsData: state.settings.settingsDetails,
    // transactionList: state.transaction.transactions,
  }));
  // const ClubName = MemberProfileDetails?.member?.current_club?.club?.title;
  const [openWalletTransferDialog, setOpenWalletTransferDialog] = useState(false);
  const [openWalletTransferSuccess, setOpenWalletTransferSuccess] = useState(false);
  const [openClubId, setOpenClubId] = useState('');
  // console.log(settingsData);
  console.log(setOpenClubId);
  // ..
  // console.log(MemberProfileDetails);
  // console.log(memberClubForMember);
  useEffect(() => {
    if (role === 'admin') {
      const state = {
        page: '',
        search: '',
        dispatch,
        limit: '',
      };
      dispatch(getAllMemberClubsForMember(state));
    }
  }, [dispatch, role]);

  useEffect(() => {
    dispatch(getAllClubs(dispatch));
  }, [dispatch]);

  useEffect(() => {
    const credentials = {
      page: '',
      limit: '',
      transaction_type: '',
      date: '',
      dispatch,
      member: '',
      wallet_type: '',
      selectedCategory: '',
    };
    dispatch(getAllTransactionsAdmin(credentials));
  }, [dispatch]);

  return (
    <>
      {openWalletTransferDialog && (
        <WalletTransferDialog
          setOpen={setOpenWalletTransferDialog}
          open={openWalletTransferDialog}
          setOpenWalletTransferSuccess={setOpenWalletTransferSuccess}
          openClubId={openClubId}
        />
      )}
      {openWalletTransferSuccess && (
        <WalletTransferSuccess
          setOpen={setOpenWalletTransferSuccess}
          open={openWalletTransferSuccess}
        />
      )}
      <MetaHelmet title="Winbuks" miniDescription="Club" />

      <CustomBreadcrumbs
        heading="Clubs"
        links={[
          {
            name: 'Dashboard',
            href: paths.dashboard.root,
          },
          {
            name: 'Clubs',
          },

          {
            name: 'List',
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      {/* <Stack mt={3} spacing={3} flexDirection={isMobile ? 'column' : 'row'}>
        <Stack flex={2}>
          <IncomeWidgetSummary
            title="Actual Wallet"
            total={settingsData?.company_wallets?.actual_wallet_balance || 0}
            icon="solar:double-alt-arrow-up-bold-duotone"
            text="company wallet serves as the central financial repository for the organization"
          />
        </Stack>
        <Stack flex={2}>
          <IncomeWidgetSummary
            title="Company Wallet"
            total={settingsData?.company_wallets?.company_wallet_amount || 0}
            icon="solar:double-alt-arrow-up-bold-duotone"
            text="company wallet serves as the central financial repository for the organization"
          />
        </Stack>

        <Stack flex={2}>
          <IncomeWidgetSummary
            title="Maintenance Wallet"
            total={settingsData?.company_wallets?.maintenance_wallet_amount || 0}
            icon="solar:sort-by-time-bold-duotone"
            text="wallet is for covering maintenance costs associated with systems"
          />
        </Stack>
        <Stack flex={2}>
          <IncomeWidgetSummary
            title="Future Projects Wallet"
            total={settingsData?.company_wallets?.future_project_wallet_amount || 0}
            icon="solar:double-alt-arrow-up-bold-duotone"
            text="Funds allocated for future projects, innovations, and expansions."
          />
        </Stack>
      </Stack>
      <Stack mt={3} flexDirection="row" gap={3}>
        <Stack flex={4} flexDirection="row" gap={3}>
          <MiniCards
            icon="ph:lightning-fill"
            sx={{ backgroundColor: '#637381' }}
            total={transactionList?.incomeTotalAmount?.catalystTotal}
            title="Catalyst income distributed"
          />
          <MiniCards
            icon="solar:share-bold"
            color="info"
            total={transactionList?.incomeTotalAmount?.lineTotal}
            title="Line income distributed"
          />
        </Stack>
        <Stack flex={8}>
          <Card>
            {' '}
            <AllClub clubList={clubList} dashboard={false} />
          </Card>
        </Stack>
      </Stack> */}
      <Stack
        mt={3}
        flexDirection={isMobile ? 'column' : 'column'}
        spacing={3}
        width={isMobile ? '100%' : '75%'}
      >
        {/* <Grid container>
          <Grid item md={8} px={!isMobile && 1} xs={12}>
            <Box flex={2.1}>
            </Box>
          </Grid> */}
        {/* <Grid item md={4} pl={!isMobile && 2} pt={isMobile && 1}>
            <Box>
              <AllClub clubList={clubList} dashboard={false} />
              <Box py={3}>
                <MiniCards
                  icon="ph:lightning-fill"
                  sx={{ backgroundColor: '#637381' }}
                  total={transactionList?.incomeTotalAmount?.catalystTotal}
                  title="Catalyst income total"
                />
              </Box>
              <Box>
                <MiniCards
                  icon="solar:share-bold"
                  color="info"
                  total={transactionList?.incomeTotalAmount?.lineTotal}
                  title="Line income total"
                />
              </Box>
            </Box>
          </Grid> */}
        {/* </Grid> */}
        <Card>
          {' '}
          <AllClub clubList={clubList} dashboard={false} />
        </Card>
        <AllClubTransactions disable="disable" />
      </Stack>
    </>
  );
}

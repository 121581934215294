import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';

import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { Stack, Button, CardMedia } from '@mui/material';

import { usePathname } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import bar from 'src/assets/icons/navMobile.png';
import cross from 'src/assets/other/Frame 46.png';

import Logo from 'src/components/logo';
import Scrollbar from 'src/components/scrollbar';

import NavList from './nav-list';
// ----------------------------------------------------------------------

export default function NavMobile({ data }) {
  const pathname = usePathname();

  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    if (openMenu) {
      handleCloseMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpenMenu = useCallback(() => {
    setOpenMenu(true);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setOpenMenu(false);
  }, []);

  return (
    <>
      <IconButton onClick={handleOpenMenu} sx={{ ml: 1 }}>
        {/* <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" /> */}
        {/* <SvgColor src="src/assets/logo/logo_sm_white.png" /> */}
        <CardMedia component="img" src={bar} alt="copy" sx={{ width: 18 }} />
      </IconButton>

      <Drawer
        open={openMenu}
        onClose={handleCloseMenu}
        className="drawer"
        PaperProps={{
          sx: {
            pb: 5,
            width: '100%',
          },
        }}
      >
        <Scrollbar sx={{ backgroundColor: 'black' }}>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
            <Stack>
              <Logo sx={{ mx: 2.5, my: 3, height: '28px', width: '28px' }} type="smallGolden" />
            </Stack>
            <Stack alignItems="flex-end" mr={2}>
              <CardMedia
                component="img"
                src={cross}
                alt="copy"
                sx={{ width: '28px' }}
                onClick={handleCloseMenu}
              />
            </Stack>
          </Stack>
          <Stack justifyContent="center">
            <Link href={paths.auth.login_member} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                sx={{
                  // backgroundColor: '#252729',
                  color: 'white',
                  fontWeight: '400',
                  borderRadius: '20px',
                  '&:hover': {
                    // backgroundColor: 'rgb(30 31 32)',
                  },
                }}
              >
                Log in
              </Button>
            </Link>{' '}
          </Stack>
          <Stack justifyContent="center" my={3}>
            <Link
              href={paths.auth.register}
              sx={{ display: 'flex', justifyContent: 'center', mx: 3 }}
            >
              <Button
                variant="contained"
                target="_blank"
                rel="noopener"
                sx={{
                  backgroundColor: '#966e11',
                  fontWeight: '400',
                  borderRadius: '20px',
                  // fontSize: '20px',
                  width: '100%',
                  '&:hover': {
                    backgroundColor: 'rgb(92 68 10)',
                  },
                }}
                // href={paths.minimalUI}
              >
                Get Started
              </Button>
            </Link>
          </Stack>
          {data.map((list) => (
            <NavList key={list.title} data={list} />
          ))}
        </Scrollbar>
      </Drawer>
    </>
  );
}

NavMobile.propTypes = {
  data: PropTypes.array,
};

import React from 'react';

// import { Container } from '@mui/material';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

// import { useSettingsContext } from 'src/components/settings';

import PropTypes from 'prop-types';

import ClubIncomeTransaction from './ClubIncomeTransactionPage';

function AllClubIncomeTransactions({ disabled }) {
  // const settings = useSettingsContext();
  console.log(disabled);

  return (
    <>
      <MetaHelmet title="Winbuks" miniDescription="Connect Earning Transactions" />
      {/* <Container maxWidth={settings.themeStretch ? false : 'xl'} sx={{paddingX: '0px'}}> */}
      <ClubIncomeTransaction disabled={disabled} />
      {/* </Container> */}
    </>
  );
}

export default AllClubIncomeTransactions;

AllClubIncomeTransactions.propTypes = {
  disabled: PropTypes.any,
};

import * as Yup from 'yup';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useMemo, useState, useEffect, useCallback } from 'react';

// import { createEnquiry, updateEnquiryDetails } from 'src/server/api/enquiry';
import { LoadingButton } from '@mui/lab';
// components
import { alpha } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
// @mui
import { Box, Card, Stack, Dialog, IconButton, DialogTitle, DialogContent } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import { updateMemberProfileDetails } from 'src/server/api/member';

import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { FileUploadCustom } from 'src/components/CustomFields/ImageUpload';

// import CrudModalContent from './CrudModalContent';

UpdateMemberModalDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  isUpdate: PropTypes.object,
  setIsUpdate: PropTypes.any,
  setTab: PropTypes.any,
  MemberProfileDetails: PropTypes.object,
};

export default function UpdateMemberModalDialog({
  open,
  setOpen,
  isUpdate,
  MemberProfileDetails,
  setIsUpdate,
  setTab,
}) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  // const theme = useTheme();

  // console.log(isUpdate);
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useResponsive('down', 'md');

  // const { error } = useSelector((state) => ({
  //   loading: state.member.loading,
  // }));
  // console.log(error);

  const [imagePreview, setImagePreview] = useState([]);

  const UpdateUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    date_of_birth: Yup.string(),
  });

  // console.log(MemberProfileDetails);
  const formattedDob = MemberProfileDetails?.date_of_birth
    ? moment(MemberProfileDetails?.date_of_birth).format('YYYY-MM-DD')
    : '';
  // console.log(formattedDob);
  const defaultValues = useMemo(
    () => ({
      name: MemberProfileDetails?.name || '',

      date_of_birth: formattedDob,
    }),
    [MemberProfileDetails?.name, formattedDob]
  );

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    setValue,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const memberDataCredential = {
      state: {
        // ...data,
        name: data?.name,
        date_of_birth: data?.date_of_birth,
        profile_photo: imagePreview[0],
      },
      dispatch,
      enqueueSnackbar,
      handleClose,
    };

    dispatch(updateMemberProfileDetails(memberDataCredential));

    // dispatch(updateMemberPassword(credentialsPasswordChange));
    // if (MemberProfileDetails?._id) {
    //   dispatch(updateMemberProfileDetails(memberDataCredential));
    // }
  });
  // console.log(imagePreview);
  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        url: URL.createObjectURL(file),
      });

      if (file) {
        setValue('profile_photo', newFile, { shouldValidate: true });
        setImagePreview(newFile);
        // handleSubmit(onSubmit)();
      }
    },
    [setValue]
  );

  useEffect(() => {
    reset({
      name: MemberProfileDetails?.name,
      date_of_birth: formattedDob,
    });
  }, [reset, MemberProfileDetails, formattedDob]);

  // useEffect(() => {
  //   if (MemberProfileDetails?._id) {
  //     setImagePreview(MemberProfileDetails?.profile_photo);
  //   }
  // }, [MemberProfileDetails?._id, MemberProfileDetails?.profile_photo]);
  // useEffect(() => {
  //   if (MemberProfileDetails?._id) {
  //     setImagePreview(
  //       [
  //         {
  //           public_id: MemberProfileDetails?.profile_photo?.public_id,
  //           url: MemberProfileDetails?.profile_photo?.url,
  //         },
  //       ] || []
  //     );
  //   }
  // }, [
  //   MemberProfileDetails?._id,
  //   MemberProfileDetails?.profile_photo?.public_id,
  //   MemberProfileDetails?.profile_photo?.url,
  // ]);
  useEffect(() => {
    if (MemberProfileDetails?.profile_photo) {
      setImagePreview([
        {
          public_id: MemberProfileDetails.profile_photo.public_id,
          url: MemberProfileDetails.profile_photo.url,
        },
      ]);
    } else {
      // Handle the case where profile_photo is undefined
      setImagePreview([]);
    }
  }, [MemberProfileDetails?._id, MemberProfileDetails?.profile_photo]);
  // console.log(imagePreview);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', top: 0, right: 0, m: 1 }}
        >
          <CloseIcon sx={{ fontSize: '20px' }} />
        </IconButton>
        <DialogTitle id="responsive-dialog-title" sx={{ pb: 0 }}>
          {/* {isUpdate?._id ? 'Update your payment' : 'Complete your payment'} */}
          Update Info
        </DialogTitle>
        <DialogContent
          className="crud-modal"
          sx={{ minWidth: isMobile ? '200px' : '450px', padding: '0px' }}
        >
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack flexDirection={isMobile ? 'column' : 'row'} spacing={3}>
              <Stack>
                <Card sx={{ p: 3, display: 'flex', spacing: 2, flexDirection: 'column' }}>
                  <Stack flexDirection="column">
                    <Stack flexDirection={isMobile ? 'column' : 'row'} spacing={3}>
                      <Stack flex={2.3}>
                        <Card
                          sx={{
                            flex: 1.7,
                            p: 2,
                            mb: 3,
                            border: (them) => `1px dashed ${alpha(them.palette.grey[500], 0.2)}`,
                            opacity: 0.72,
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <FileUploadCustom
                            uploadUrl="profile"
                            setImagesPreview={setImagePreview}
                            imagesPreview={imagePreview}
                            title="Upload Image"
                            isMultiple
                            imageSize={4}
                            theme={{
                              type: 'standard',
                              name: 'profile_photo',
                              handleDrop,
                            }}
                            sx={{ marginBottom: '1rem' }}
                          />
                        </Card>
                        <Box
                          rowGap={3}
                          columnGap={2}
                          display="grid"
                          gridTemplateColumns={{
                            xs: 'repeat(1, 1fr)',
                            sm: 'repeat(1, 1fr)',
                          }}
                        >
                          <RHFTextField
                            name="name"
                            label="Name"
                            required
                            InputLabelProps={{ shrink: true }}
                          />
                          <RHFTextField
                            name="date_of_birth"
                            label="Date of Birth"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            defaultValue={defaultValues.date_of_birth}
                          />
                        </Box>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                      sx={{
                        color: 'white',
                        width: 'fit-content',
                        marginLeft: 'auto',
                        backgroundColor: '#22C55E',
                        '&:hover': {
                          backgroundColor: '#0b4e3e',
                        },
                      }}
                    >
                      Update Changes{' '}
                    </LoadingButton>
                  </Stack>
                </Card>
              </Stack>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}

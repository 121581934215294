import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/system';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Card, Chip, Avatar, Button, CardMedia, Typography } from '@mui/material';

import empty from 'src/assets/other/empty.png';
import { getAllMemberAccountMember } from 'src/server/api/member';

import { useSettingsContext } from 'src/components/settings';

import CreateMemberAccountModalDialog from './MemberSingleView/createMemberAccount';

// ----------------------------------------------------------------------

export default function MemberAccountsMember({ memberDetails }) {
  const { memberAccounts, account } = useSelector((state) => ({
    memberAccounts: state.member.memberAccounts,
    account: state.member.memberProfileDetails?.member,
  }));
  const dispatch = useDispatch();

  // console.log(memberAccounts);
  const [openModalDialog, setOpenModalDialog] = useState(false);

  useEffect(() => {
    const credential = {
      dispatch,
    };
    dispatch(getAllMemberAccountMember(credential));
  }, [dispatch]);

  const handleOpenDialog = () => {
    setOpenModalDialog(true);
  };

  const getInitials = (name) => {
    const names = name?.split(' ');
    return names?.map((n) => n.charAt(0).toUpperCase()).join('');
  };
  const accounts = Array.isArray(memberAccounts) ? memberAccounts : [];
  const settings = useSettingsContext();
  // console.log(settings);
  return (
    <>
      {openModalDialog && (
        <CreateMemberAccountModalDialog setOpen={setOpenModalDialog} open={openModalDialog} />
      )}
      <Stack
        spacing={3}
        mb={2}
        direction={{ xs: 'row', md: 'row' }}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        justifyContent="space-between"
      >
        <Typography variant="h6">My Accounts</Typography>
      </Stack>
      <Card
        variant="outlined"
        sx={{
          py: 0,
          p: 2,

          boxShadow: 'unset',
          height: '100%',
        }}
      >
        {accounts && accounts?.length > 0 ? (
          <Stack
            sx={{
              height: 290,
              p: 1,
              overflowY: 'auto',
              WebkitOverflowScrolling: 'touch',
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'grey',
                borderRadius: '15px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#F5F5F5',
                borderRadius: '15px',
              },
            }}
          >
            {accounts?.map((item, index) => (
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                }}
                key={index}
              >
                <Card
                  className="account-card"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: 2,
                    gap: 2,
                    mb: 1.5,
                    backgroundColor: item?._id === account?._id ? '#f0f5f1' : 'white',
                    width: '100%',
                  }}
                >
                  <Avatar
                    sx={{
                      backgroundColor: item?.is_primary ? '#8431AD' : '#31AD4C',
                      width: '50px',
                      height: '50px',
                      borderRadius: '5px',
                    }}
                    src={
                      item?.profile_photo ? item?.profile_photo?.url : 'default-profile_photo-url'
                    }
                  >
                    <Typography variant="body2"> {getInitials(item?.name)}</Typography>
                  </Avatar>
                  <Stack>
                    <Typography
                      variant="caption"
                      color={settings?.themeMode === 'dark' ? 'black' : 'black'}
                    >
                      {item?.name}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        wordWrap: 'break-word',
                        color: settings?.themeMode === 'dark' ? 'black' : 'black',
                      }}
                    >
                      ID: {item?.member_id}
                    </Typography>
                  </Stack>
                  <Stack alignItems="flex-end">
                    {item?.is_primary && (
                      <Chip
                        label="Primary"
                        size="small"
                        sx={{
                          backgroundColor: '#cccccc',
                          color: '#4F1D68',
                          width: '70px',
                          fontSize: '12px',
                        }}
                      />
                    )}
                  </Stack>
                </Card>

                {account?._id === item?._id && (
                  <CheckCircleIcon
                    sx={{
                      color: '#31AD4C',
                      position: 'absolute',
                      top: -7,
                      right: -7,
                      fontSize: '20px',
                    }}
                  />
                )}
              </Box>
            ))}
          </Stack>
        ) : (
          <Stack p={4} justifyContent="center" alignItems="center" gap={1} py={7}>
            <CardMedia component="img" src={empty} alt="copy" sx={{ width: '120px' }} />
            <Typography variant="caption" fontWeight="bold">
              No account added yet!
            </Typography>
            <Typography variant="caption" color="#637381">
              Click ‘+Add new Member’ to add new account
            </Typography>
          </Stack>
        )}
        <Button
          onClick={handleOpenDialog}
          variant="contained"
          className="add-new-btn"
          size="medium"
          sx={{
            px: 1,
            py: 1,
            width: '100%',
            backgroundColor: '#f6f7f8',
            color: 'black',
            '&:hover': {
              backgroundColor: settings?.themeMode === 'dark' ? '#f6f7f8' : '#f6f7f8',
            },
          }}
          startIcon={<AddIcon />}
        >
          Add New Member
        </Button>
      </Card>
    </>
  );
}

MemberAccountsMember.propTypes = {
  memberDetails: PropTypes.any,
};

// eslint-disable-next-line import/no-unresolved
import { createAsyncThunk } from '@reduxjs/toolkit';

import { get } from './http';

export const getSidebarItemCountData = createAsyncThunk(
  'static/sidebar/count',
  async ({ enqueueSnackbar, userType }) => {
    try {
      const response = await get(`/static/dashboard/counts?user_type=${userType && userType}`);
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const getDashboardData = createAsyncThunk(
  'static/dashboard',
  async ({ enqueueSnackbar }) => {
    try {
      const response = await get('/static/admin/dashboard/data');
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const syncDashboardData = createAsyncThunk(
  'static/dashboard/sync',
  async ({ enqueueSnackbar }) => {
    try {
      const response = await get('/static/admin/calculate/dashboard/data');
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

// referral charts
export const getReferralChartData = createAsyncThunk(
  'referral-chart/member',
  async ({ enqueueSnackbar, year }) => {
    try {
      const response = await get(`/member/refferal/graph`);
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const getIncomeChartData = createAsyncThunk(
  'income-chart/member',
  async ({ enqueueSnackbar, year }) => {
    try {
      const response = await get(`/wallet-transaction/member/income/graph`);
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);
export const getPartnerBalanceChartData = createAsyncThunk(
  'earning/admin/increment',
  async ({ enqueueSnackbar, year }) => {
    try {
      const response = await get(`/earning/admin/increment/graph/monthly?&year=${year || ''}`);
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

// school course progression graph
export const getProgressionGraphData = createAsyncThunk(
  'school-course/school-user/graph/data',
  async ({ enqueueSnackbar }) => {
    try {
      const response = await get('/school-course/school-user/graph/data');
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

// get all chain node
export const getAllChainNodeData = createAsyncThunk(
  'static/chain/list/',
  async ({ enqueueSnackbar, limit, page }) => {
    try {
      const response = await get(`/chain-node/admin/all?page=${page || 1}&limit=${limit || 50}`);
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

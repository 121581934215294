import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Box, Divider } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

// ----------------------------------------------------------------------

export default function ConfirmDialog({
  title,
  content,
  action,
  open,
  alertMsg,
  onClose,
  icon,
  ...other
}) {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ pb: 2, textAlign: 'start', fontWeight: 'bold' }}>{title}</DialogTitle>

      {content && (
        <DialogContent
          sx={{
            typography: 'body2',
            color: 'text.secondary',
            textAlign: 'start',
            mb: icon && alertMsg ? 0 : 2,
          }}
        >
          {content}
        </DialogContent>
      )}
      {icon && alertMsg && (
        <DialogContent
          sx={{
            typography: 'caption',
            fontSize: 15,
            color: '#7A0916',
            backgroundColor: 'rgba(255, 233, 213, 1)',
            minHeight: 80,
            borderRadius: 2,
            px: 3,
            py: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            mx: 2,
            my: 2,
          }}
        >
          {icon && <Box sx={{ mr: 1 }}>{icon}</Box>}
          <Box sx={{ flex: 1 }}>{alertMsg}</Box>
        </DialogContent>
      )}
      <Divider
        sx={{
          width: '100%',
          borderStyle: 'dashed',
        }}
      />
      <DialogActions sx={{ justifyContent: 'space-between', mt: 0, px: 2 }}>
        {action}
        <Button variant="outlined" color="inherit" fullWidth onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  action: PropTypes.node,
  content: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  alertMsg: PropTypes.string,
  icon: PropTypes.any,
};

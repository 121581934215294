/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-else-return */
/* eslint-disable no-unsafe-optional-chaining */
import { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import {
  Box,
  Grid,
  Stack,
  Paper,
  Button,
  Divider,
  Skeleton,
  CardMedia,
  Typography,
  LinearProgress,
} from '@mui/material';

import { paths } from 'src/routes/routes/paths';

// import { fShortenNumber } from 'src/utils/format-number';

import { USDT } from 'src/hooks/use-USDT';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import like from 'src/assets/other/like.png';
import fire from 'src/assets/other/fire.png';
import gift from 'src/assets/other/gift.png';
import miniImg1 from 'src/assets/other/1.png';
import miniImg2 from 'src/assets/other/2.png';
import miniImg3 from 'src/assets/other/3.png';
import miniImg4 from 'src/assets/other/4.png';
import miniImg5 from 'src/assets/other/5.png';
import miniImg6 from 'src/assets/other/6.png';
import tripIcon from 'src/assets/other/trip.png';
import pointer from 'src/assets/other/pointer.png';
import dollar from 'src/assets/other/dollar-img.png';
import userPlus from 'src/assets/other/user-plus.png';
import fileIcon from 'src/assets/other/folder-icon.png';
import fileKeyIcon from 'src/assets/other/file-key.png';
import successIcon from 'src/assets/other/check_circle.png';
import mintIcon from 'src/assets/other/mint-wallet-icon.png';
import keyTimerImage from 'src/assets/other/timerkeyimg.png';
import { getAllMemberClubsWallet, initiateTripWalletWithdrawal } from 'src/server/api/memberClub';

import Label from 'src/components/label';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import ReferralModal from 'src/sections/income/RefferalsModal';
// import WalletTransferDialog from 'src/sections/income/wallet-Transfer';
// import WalletTransferSuccess from 'src/sections/income/WalletTransfer-success-modal';
// import WalletRequestsConfirmDialog from 'src/sections/income/Wallet-requests-confirm';

// import { NavHashLink } from 'react-router-hash-link';

import { fDateTime } from 'src/utils/format-time';

import { decimalLimit } from 'src/server/api/http';

import WithdrawalConfirmationModal from 'src/sections/income/widthdrawalInitiateConfirmationModal';

import AllTransactions from '../Transaction';

// ----------------------------------------------------------------------

export default function NewIncomePage() {
  // const { enqueueSnackbar } = useSnackbar();
  // const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useResponsive('down', 'md');
  const tableSection = useRef(null);
  const { themeMode } = useSettingsContext();

  const { MemberProfileDetails, memberClubWallets, initiateLoading } = useSelector((state) => ({
    loading: state.referrals.loading,
    initiateLoading: state.memberClub.initiatedLoading,
    profileLoading: state.member.loading,
    MemberProfileDetails: state.member.memberProfileDetails,
    memberClubForMember: state.memberClub.memberClubsForMember?.memberClubs,
    transactionList: state.transaction.transactions,
    memberClubWallets: state?.memberClub?.memberClubWallets?.memberClubWallets,
    memberClubWalletLoading: state?.memberClub?.loading,
  }));

  const memberClubWalletsObj = memberClubWallets
    ? memberClubWallets.reduce((acc, item, index) => {
        acc[`wallet_${index}`] = item?.limit_amount || 0;
        return acc;
      }, {})
    : {};
  const memberClubWalletsObj2 = memberClubWallets
    ? memberClubWallets.reduce((acc, item, index) => {
        acc[`wallet__balance_${index}`] = item?.wallet_balance || 0;
        return acc;
      }, {})
    : {};
  const memberClubWalletsObj2TotalAmount = memberClubWallets
    ? memberClubWallets.reduce((acc, item, index) => {
        acc[`total_amount_${index}`] = item?.total_amount || 0;
        return acc;
      }, {})
    : {};

  const handleCalculatePercentage = () => {
    if (!MemberProfileDetails || !memberClubWalletsObj) {
      return;
    }

    const mintWalletTotal = memberClubWalletsObj2TotalAmount?.total_amount_0;
    const tripWalletTotal = memberClubWalletsObj2TotalAmount?.total_amount_1;
    const mintWalletLimit = memberClubWalletsObj?.wallet_0 || 1; // Default to 1 to avoid division by 0
    const tripWalletLimit = memberClubWalletsObj?.wallet_1 || 1;

    const percentageMint = (mintWalletTotal / mintWalletLimit) * 100;
    const percentageTrip = (tripWalletTotal / tripWalletLimit) * 100;

    // eslint-disable-next-line consistent-return
    return { percentageMint, percentageTrip };
  };

  const [openReferralModal, setOpenReferralModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  useEffect(() => {
    const state = {
      dispatch,
    };
    dispatch(getAllMemberClubsWallet(state));
  }, [dispatch]);

  const handleCatalystCount = () => {
    const count =
      (MemberProfileDetails?.member?.earnings_statistics?.catalyst_total || 0) +
      (MemberProfileDetails?.member?.earnings_statistics?.direct_total || 0) +
      (MemberProfileDetails?.member?.earnings_statistics?.leverage_total || 0);

    return count;
  };

  const miniCardDatas = [
    {
      icon: miniImg1,
      title: 'Connect Earnings',
      value: MemberProfileDetails?.member?.earnings_statistics?.line_total,
      walletType: 'CONNECT',
    },
    {
      icon: miniImg2,
      title: 'Club Earnings',
      value: MemberProfileDetails?.member?.earnings_statistics?.club_total,
      walletType: 'CLUB',
      isTrue: MemberProfileDetails?.member?.is_club_member === true,
    },
    {
      icon: miniImg3,
      title: 'Fourth Earnings',
      value: MemberProfileDetails?.member?.earnings_statistics?.fourth_total,
      walletType: 'FOURTH',
    },
    {
      icon: miniImg4,
      title: 'Catalyst Earning',
      walletType: 'CATALYST',
      value: handleCatalystCount(),
      directValue: MemberProfileDetails?.member?.earnings_statistics?.direct_total,
      directValueWalletType: 'DIRECT',
      leverageValue: MemberProfileDetails?.member?.earnings_statistics?.leverage_total,
      leverageValueWalletType: 'LEVERAGE',
    },
    {
      icon: miniImg5,
      title: 'Incentive Earnings',
      value: MemberProfileDetails?.member?.earnings_statistics?.incentive_total,
      badge: MemberProfileDetails?.member?.current_member_incentive?.incentive_level?.rank_name,
      walletType: 'INCENTIVE',
    },
    {
      icon: miniImg6,
      title: 'Bionium Earnings',
      value: MemberProfileDetails?.member?.bionium_coin,
      walletType: 'BIONIUM',
    },
  ];

  const howItWorks = [
    {
      title: 'Refer 3 members',
      image: userPlus,
      description: 'Invite your 3 friends to join winbuks using your referral link.',
    },
    {
      title: 'Earn your reward',
      image: dollar,
      description: 'Become the club member and earn up to 32100 UST',
    },
    {
      title: 'Claim your reward',
      image: gift,
      description: 'We will let you know once your reward is ready to claim',
    },
  ];

  const isInitiated = useBoolean();

  const handleInitiateTripWithdrawal = () => {
    const state = {
      dispatch,
      tripId: memberClubWallets[1]?._id,
      isInitiated,
      handleClose,
    };
    dispatch(initiateTripWalletWithdrawal(state));
  };

  const [sortValue, setSortValue] = useState({
    label: '',
    value: '',
  });
  const handleCatalystSort = (value) => {
    let sortOption = null;

    if (value === 'DIRECT') {
      sortOption = {
        label: 'Direct',
        value: 'DIRECT',
      };
    } else if (value === 'LEVERAGE') {
      sortOption = {
        label: 'Leverage',
        value: 'LEVERAGE',
      };
    }

    setSortValue(sortOption);
  };

  const handleSortTable = (value) => {
    if (value?.isTrue === false) {
      setOpenReferralModal(true);
    }
    let sortOption = null;
    switch (value?.walletType || value?.leverageValueWalletType || value?.directValueWalletType) {
      case 'CONNECT':
        sortOption = {
          label: 'Connect',
          value: 'CONNECT',
        };
        break;

      case 'CLUB':
        sortOption = {
          label: 'Club',
          value: 'CLUB',
        };
        break;
      case 'FOURTH':
        sortOption = {
          label: 'Fourth',
          value: 'FOURTH',
        };
        break;

      case 'CATALYST':
        sortOption = {
          label: 'Catalyst',
          value: 'CATALYST',
        };
        break;

      case 'INCENTIVE':
        sortOption = {
          label: 'Incentive',
          value: 'INCENTIVE',
        };
        break;

      case 'BIONIUM':
        sortOption = {
          label: 'Bionium',
          value: 'BIONIUM',
        };
        break;

      case 'BUKS':
        sortOption = {
          label: 'Buks Wallet',
          value: 'BUKS',
        };
        break;

      default:
        '';
    }

    setSortValue(sortOption);
    return sortOption;
  };

  const handleClose = () => {
    setOpenConfirmationModal(false);
  };

  const handleDisableButton = () =>
    memberClubWallets
      ? memberClubWallets.reduce((acc, item, index) => {
          acc[`is_withdrawal_initiated${index}`] = item?.is_withdrawal_initiated || '';
          return acc;
        }, {})
      : {};

  const initiatedAtDate = () =>
    memberClubWallets
      ? memberClubWallets.reduce((acc, item, index) => {
          acc[`is_withdrawal_initiated_at_${index}`] = item?.last_withdrawal_initiated_at || '';
          return acc;
        }, {})
      : {};

  const walletDetails = (
    <Box minWidth={309}>
      <Card
        variant="outlined"
        sx={{
          background:
            themeMode === 'dark'
              ? ''
              : 'linear-gradient(128deg, #FFF -4.67%, #E2FFF4 36.27%, #FFF 96.4%)',
          p: '10px 20px 20px 20px',
          borderRadius: '12px',
          boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.09)',
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1.5} mb={1}>
          <CardMedia
            component="img"
            src={mintIcon}
            sx={{
              width: 'unset',
              height: 72,
            }}
          />
          <Typography fontSize={12} fontWeight={400} lineHeight="23.5px">
            Mint Club Wallet
            <Typography fontSize={18} fontWeight={600}>
              {memberClubWalletsObj2?.wallet__balance_0?.toFixed(decimalLimit) || 0} USDT
            </Typography>
          </Typography>
        </Stack>
        <Card
          variant="outlined"
          sx={{
            borderRadius: '9px',
            p: '12px 8px',
          }}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography
              variant="body2"
              color={themeMode === 'dark' ? '' : 'rgba(33, 43, 54, 1)'}
              fontSize={14}
              fontWeight={500}
            >
              Mint Wallet
            </Typography>
            <Typography variant="caption" color="text.disabled" fontSize={13}>
              {memberClubWalletsObj2TotalAmount?.total_amount_0?.toFixed(decimalLimit) || 0}/
              <Typography component="span" variant="caption" fontSize={13} color="text.disabled">
                {memberClubWalletsObj?.wallet_0?.toFixed(decimalLimit) || 0}
              </Typography>
            </Typography>
          </Stack>

          <LinearProgress
            variant="determinate"
            value={handleCalculatePercentage()?.percentageMint}
            sx={{
              width: '100%',
              height: 4,
              borderRadius: 5,
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#00A76F',
              },
              backgroundColor: '#e0e0e0',
              mt: 1,
            }}
          />
        </Card>
      </Card>
      <Card
        variant="outlined"
        sx={{
          background:
            themeMode === 'dark'
              ? ''
              : 'linear-gradient(128deg, #FFF -4.67%, #E2F7FF 36.27%, #FFF 96.4%)',
          p: '10px 20px 20px 20px',
          boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.09)',
          mt: 2.5,
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1.5} mb={1}>
          <CardMedia
            component="img"
            src={tripIcon}
            sx={{
              width: 'unset',
              height: 72,
            }}
          />
          <Typography fontSize={12} fontWeight={400} lineHeight="23.5px">
            Trip Club Wallet
            <Typography fontSize={18} fontWeight={600}>
              {memberClubWalletsObj2?.wallet__balance_1?.toFixed(decimalLimit) || 0} {USDT}
            </Typography>
          </Typography>
        </Stack>
        <Card
          variant="outlined"
          sx={{
            borderRadius: '9px',
            p: '12px 8px',
          }}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography
              variant="body2"
              color={themeMode === 'dark' ? '' : 'rgba(33, 43, 54, 1)'}
              fontSize={14}
              fontWeight={500}
            >
              Trip Wallet
            </Typography>
            <Typography variant="caption" color="text.disabled" fontSize={13}>
              {memberClubWalletsObj2TotalAmount?.total_amount_1?.toFixed(decimalLimit) || 0}/
              <Typography component="span" variant="caption" fontSize={13} color="text.disabled">
                {memberClubWalletsObj?.wallet_1?.toFixed(decimalLimit) || 0}
              </Typography>
            </Typography>
          </Stack>

          <LinearProgress
            variant="determinate"
            value={handleCalculatePercentage()?.percentageTrip}
            sx={{
              width: '100%',
              height: 4,
              borderRadius: 5,
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#00A76F',
              },
              backgroundColor: '#e0e0e0',
              mt: 1,
            }}
          />
        </Card>
        <Stack mt={2}>
          {handleDisableButton()?.is_withdrawal_initiated1 === true ? (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              sx={{
                backgroundColor: 'white',
                borderRadius: '8px',
                border: '1px solid #DFE3E8',
                height: 48,
              }}
            >
              <CardMedia
                component="img"
                src={successIcon}
                sx={{
                  width: 20,
                  height: 20,
                }}
              />{' '}
              <Typography
                fontSize="14px"
                // lineHeight="24px"
                fontWeight={400}
                color="rgba(33, 43, 54, 1)"
              >
                Withdrawal Initiated
                <Typography fontSize={8.5} color="text.disabled">
                  at {fDateTime(initiatedAtDate()?.is_withdrawal_initiated_at_1)}
                </Typography>
              </Typography>
            </Stack>
          ) : (
            <Button
              onClick={() => setOpenConfirmationModal(true)}
              variant="contained"
              size="large"
              disabled={
                handleDisableButton()?.is_withdrawal_initiated1 === true ||
                memberClubWalletsObj2?.wallet__balance_1 < 500
              }
              sx={{
                backgroundColor: '#007867',
                '&:hover': {
                  backgroundColor: '#007867',
                },
              }}
            >
              Initiate Withdrawal
            </Button>
          )}
          <Typography color="#637381" variant="body2" pt={1.5} fontSize={13}>
            To process withdrawal refer a new member and get verified after clicking initiate
            withdrawal button.
          </Typography>
        </Stack>
      </Card>
    </Box>
  );

  const referralContent = (
    <Box minWidth={309}>
      <Card
        variant="outlined"
        sx={{
          // backgroundColor: themeMode === 'dark' ? '' : 'rgba(255, 255, 255, 1)',
          p: 3,
          boxShadow: 'none',
          height: '100%',
        }}
      >
        <Typography variant="subtitle2" fontWeight={700} pb={1}>
          Refer friends to become club member
        </Typography>
        <Typography variant="body2" fontSize={12} color="#637381" pb={2.5}>
          Refer{' '}
          <Typography
            component="span"
            variant="body2"
            fontSize={12}
            color={themeMode === 'dark' ? 'white' : 'black'}
          >
            3
          </Typography>{' '}
          members to become club member and earn up to{' '}
          <Typography
            component="span"
            variant="body2"
            fontSize={12}
            color={themeMode === 'dark' ? 'white' : 'black'}
          >
            32100
          </Typography>{' '}
          USDT.
        </Typography>
        <Button
          onClick={() => setOpenReferralModal(true)}
          variant="contained"
          size="medium"
          sx={{
            backgroundColor: '#007867',
            borderRadius: '50px',
            color: 'white',
            px: 2,
            '&:hover': {
              backgroundColor: '#007867',
            },
          }}
        >
          Get my referral link
        </Button>
        <Divider
          sx={{
            pt: 2.5,
          }}
        />
        <Box mt={3}>
          <Typography fontWeight={500} fontSize={14}>
            How it works?
          </Typography>
          {howItWorks?.map((item, key) => (
            <Box key={key} display="flex" gap={2} mt={2}>
              <CardMedia
                component="img"
                src={item?.image}
                sx={{
                  height: 36,
                  width: 36,
                }}
              />
              <Typography color="text.secondary" fontSize={12.5} fontWeight={700} pt={1}>
                {item?.title}
                <Typography color="text.secondary" fontSize={12} variant="body2">
                  {item?.description}
                </Typography>
              </Typography>
            </Box>
          ))}
        </Box>
      </Card>
    </Box>
  );

  const growSection = (
    <Grid container mt={2}>
      <Grid item lg={8.6} md={9} sm={12} xs={12}>
        <Box mt={2} maxWidth={821} minWidth={821} ref={tableSection}>
          <AllTransactions type={sortValue} earingPage disabled member />
        </Box>
      </Grid>
    </Grid>
  );

  const personalAndBuksWalletSection = (
    <Box minWidth={240}>
      <Card
        onClick={() => {
          handleSortTable({
            walletType: 'BUKS',
          });
          handleControlScroll();
        }}
        sx={{
          p: 1.5,
          backgroundColor: themeMode === 'dark' ? '' : '#05060F',
          borderRadius: '11px',
          cursor: 'pointer',
        }}
      >
        <Card
          variant="outlined"
          sx={{
            backgroundColor: themeMode === 'dark' ? '' : '#202020',
            borderRadius: '5px',
            height: 168,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CardMedia
            src={fileIcon}
            component="img"
            alt="pointer"
            sx={{
              height: 130,
              width: 135,
            }}
          />
        </Card>
        <Card
          variant="outlined"
          sx={{
            backgroundColor: themeMode === 'dark' ? '' : 'rgba(32, 32, 32, 1)',
            borderRadius: '5px',
            p: '12px',
            mt: 1.5,
          }}
        >
          <Typography fontSize={13} fontWeight={700} color="rgba(196, 205, 213, 1)">
            Buks Wallet
          </Typography>
          <Typography fontSize={19} fontWeight={600} color="rgba(255, 255, 255, 1)">
            {MemberProfileDetails?.member?.buks_wallet_balance?.toFixed(2)} USDT
          </Typography>
        </Card>
      </Card>

      <Paper
        sx={{
          backgroundColor: themeMode === 'dark' ? '' : 'rgba(255, 255, 255, 1)',
          border: themeMode === 'dark' ? '' : '1px solid rgba(223, 227, 232, 1)',
          borderRadius: '11px',
          p: 1.5,
          mt: 2,
          // height: 82,
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="start">
          <Grid>
            <CardMedia
              src={fileKeyIcon}
              component="img"
              alt="pointer"
              height={56}
              width={56}
              sx={{
                objectFit: 'contain',
              }}
            />
          </Grid>
          <Grid pl={1}>
            <Typography
              color={themeMode === 'dark' ? '' : 'rgba(33, 43, 54, 1)'}
              fontSize={14}
              fontWeight={700}
              lineHeight="22px"
            >
              Personal Wallet
            </Typography>
            <Typography
              color="rgba(99, 115, 129, 1)"
              fontSize={12}
              fontWeight={400}
              lineHeight="18px"
            >
              Balance : {MemberProfileDetails?.member?.wallet_balance?.toFixed(2)} USDT
            </Typography>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );

  const handleControlScroll = () => {
    tableSection?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const earningsSection = (
    <Box>
      <Card
        sx={{
          borderRadius: '11px',
          border: '1px solid var(--grey-300, #DFE3E8)',
          background: 'linear-gradient(78deg, #F1D8B1 -7.55%, #F4B6A9 25.02%, #DAE1F1 51.44%)',
          p: 3,
          pb: 7,
          height: '100%',
        }}
      >
        <Box
          display="grid"
          gap={2}
          gridTemplateColumns={{
            xs: 'repeat(2, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
            lg: 'repeat(3, 1fr)',
          }}
        >
          {miniCardDatas?.map((i) => (
            <Stack>
              <Card
                onClick={() => {
                  handleSortTable(i);
                  handleControlScroll();
                }}
                sx={{
                  width: 137,
                  height: 121,
                  borderRadius: '9px',
                  borderBottomLeftRadius: i?.directValue ? '0px' : '9px',
                  borderBottomRightRadius: i?.directValue ? '0px' : '9px',
                  cursor: 'pointer',
                }}
              >
                {i?.badge && (
                  <Label
                    color="success"
                    variant="soft"
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      borderRadius: '8px 0px 8px 0px',
                      fontSize: 10,
                      fontWeight: 400,
                      height: 16,
                      p: '8px',
                      color: themeMode === 'dark' ? '' : '#007867',
                    }}
                  >
                    {i?.badge}
                  </Label>
                )}
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1.3}
                  height="100%"
                >
                  <CardMedia
                    component="img"
                    src={i?.icon}
                    sx={{
                      objectFit: 'contain',
                      height: 38,
                      width: 38,
                    }}
                  />
                  <Typography
                    color={themeMode === 'dark' ? 'white' : '#454F5B'}
                    fontSize={12}
                    fontWeight={400}
                    textAlign="center"
                  >
                    {i?.title}
                    <Typography
                      fontSize={13}
                      fontWeight={600}
                      color={themeMode === 'dark' ? 'white' : '#212B36'}
                    >
                      {i?.value?.toFixed(2) || 0} USDT
                    </Typography>
                  </Typography>
                </Stack>
              </Card>

              {i?.directValue ? (
                <Card
                  sx={{
                    backgroundColor: 'rgba(230, 232, 245, 1)',
                    borderRadius: '0px 0px 9px 9px',
                    width: 137,
                    p: 1,
                  }}
                >
                  {/* Direct Value Card */}
                  <Card
                    sx={{
                      borderRadius: '3px',
                      p: 1.5,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      handleControlScroll();
                      handleCatalystSort(i?.directValueWalletType);
                    }}
                  >
                    <Stack direction="row" justifyContent="space-between">
                      <Typography fontSize={11} fontWeight={600}>
                        {i?.directValue?.toFixed(2)} USDT
                        <Typography color="#454F5B" fontSize={11} fontWeight={400}>
                          Direct
                        </Typography>
                      </Typography>
                      <CardMedia
                        component="img"
                        src={like}
                        sx={{
                          objectFit: 'contain',
                          height: 20,
                          width: 20,
                        }}
                      />
                    </Stack>
                  </Card>

                  {/* Leverage Value Card */}
                  {i?.leverageValue ? (
                    <Card
                      sx={{
                        borderRadius: '3px',
                        p: 1.5,
                        mt: 1,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        handleControlScroll();
                        handleCatalystSort(i?.leverageValueWalletType);
                      }}
                    >
                      <Stack direction="row" justifyContent="space-between">
                        <Typography fontSize={11} fontWeight={600}>
                          {i?.leverageValue?.toFixed(2)} USDT
                          <Typography color="#454F5B" fontSize={11} fontWeight={400}>
                            Leverage
                          </Typography>
                        </Typography>
                        <CardMedia
                          component="img"
                          src={fire}
                          sx={{
                            objectFit: 'contain',
                            height: 20,
                            width: 20,
                          }}
                        />
                      </Stack>
                    </Card>
                  ) : (
                    ''
                  )}
                </Card>
              ) : (
                ''
              )}
            </Stack>
          ))}
        </Box>
      </Card>
    </Box>
  );

  const startDate = new Date(MemberProfileDetails?.member?.club_member_achieved_at);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const endDate = new Date(startDate.getTime() + 2 * 24 * 60 * 60 * 1000); // Add 2 days

  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const [isTimerEnded, setIsTimerEnded] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const difference = endDate - now;

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
          minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((difference % (1000 * 60)) / 1000),
        });
        setLoading(false);
      } else {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        setIsTimerEnded(true);
        setLoading(false);
      }
    };
    const timer = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(timer);
  }, [endDate]);

  const clubMemberTimer = (
    <Box minWidth={309}>
      <Paper
        variant="outlined"
        sx={{
          boxShadow: 'none',
          borderRadius: '11px',
          height: '100%',
        }}
      >
        <CardMedia
          component="img"
          src={keyTimerImage}
          width="auto"
          height="auto"
          sx={{
            borderTopRightRadius: '11px',
            borderTopLeftRadius: '11px',
          }}
        />
        <Box pt={6} textAlign="center">
          <Typography color="rgba(0, 120, 103, 1)" fontSize="11px" fontWeight={600}>
            CONGRATS
          </Typography>
          <Typography
            pt={0}
            pb={3}
            color={themeMode === 'dark' ? '#fff' : 'rgba(33, 43, 54, 1)'}
            lineHeight="28px"
            fontSize="18px"
            fontWeight={600}
          >
            {' '}
            You’re a club member now!
          </Typography>
          <Typography
            px={2.5}
            variant="body2"
            color="text.secondary"
            fontSize={12.5}
            textAlign="center"
          >
            You`ve officially achieved the club! Your benefits will start in just 2 days. Check the
            countdown timer below to see how much time is left before you can enjoy your rewards.
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          textAlign="center"
          pt={4}
          px={2}
          pb={5}
        >
          <Stack>
            <Typography variant="subtitle2" color="#007867">
              {timeLeft.days}
            </Typography>
            <Typography pt={1} color="text.secondary" variant="caption" fontSize="12px">
              Days
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle2" color="#007867">
              {timeLeft.hours}
            </Typography>
            <Typography pt={1} color="text.secondary" variant="caption" fontSize="12px">
              Hours
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle2" color="#007867">
              {timeLeft.minutes}
            </Typography>
            <Typography pt={1} color="text.secondary" variant="caption" fontSize="12px">
              Minutes
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle2" color="#007867">
              {timeLeft.seconds}
            </Typography>
            <Typography pt={1} color="text.secondary" variant="caption" fontSize="12px">
              Seconds
            </Typography>
          </Stack>
        </Box>
      </Paper>
    </Box>
  );

  const renderContent = () => {
    if (MemberProfileDetails?.member?.is_club_member === false) {
      return referralContent;
    }
    if (isTimerEnded) {
      return walletDetails;
    }
    if (MemberProfileDetails?.member?.is_club_member === true && !isTimerEnded) {
      return clubMemberTimer;
    }
    return '';
  };

  return (
    <>
      {openReferralModal && (
        <ReferralModal
          setOpen={setOpenReferralModal}
          open={openReferralModal}
          member={MemberProfileDetails?.member?.member_id}
        />
      )}
      {openConfirmationModal && (
        <WithdrawalConfirmationModal
          setOpen={setOpenConfirmationModal}
          open={openConfirmationModal}
          clickAction={handleInitiateTripWithdrawal}
          handleClose={handleClose}
          loading={initiateLoading}
        />
      )}
      <CustomBreadcrumbs
        heading="My Wallets"
        links={[
          {
            name: 'Dashboard',
            href: paths.dashboard.root,
          },
          {
            name: 'Earnings',
          },

          {
            name: 'My Wallets',
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Card
        sx={{
          backgroundColor: themeMode === 'dark' ? '' : 'rgba(243, 242, 248, 1)',
          borderRadius: '4px',
          p: 0.6,
          boxShadow: 'none',
        }}
      >
        <Grid display="flex" alignItems="center" justifyContent="flex-start">
          <Grid px={1}>
            <CardMedia src={pointer} component="img" alt="pointer" height={18} width={15.05} />
          </Grid>
          <Grid>
            <Typography
              variant="body2"
              color={themeMode === 'dark' ? '' : '#003768'}
              fontSize={isMobile ? 11 : 13}
              lineHeight="20px"
            >
              Click on a wallet or earnings to view detailed data related to it in the table below.{' '}
            </Typography>
          </Grid>
        </Grid>
      </Card>

      <Box
        display="grid"
        gap={3}
        mt={3}
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
          lg: 'repeat(3, 1fr)',
        }}
      >
        <>
          {loading ? (
            <>
              <Skeleton variant="rectangular" width="100%" height={468} sx={{ borderRadius: 2 }} />
              <Skeleton variant="rectangular" width="100%" height={468} sx={{ borderRadius: 2 }} />
              <Skeleton variant="rectangular" width="100%" height={468} sx={{ borderRadius: 2 }} />
            </>
          ) : (
            <>
              {renderContent()}
              {earningsSection}
              {personalAndBuksWalletSection}
            </>
          )}
        </>
      </Box>
      {growSection}
    </>
  );
}

/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Stack, Button, Tooltip, Typography, IconButton } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { deleteFaq } from 'src/server/api/notification';

import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
// import TextTruncate from 'src/components/Typography/TextTruncate';

export default function FaqTableRow({ row, selected, onEditRow }) {
  const { question, answer, priority } = row;
  const dispatch = useDispatch();
  const confirm = useBoolean();
  const isShow = useBoolean();
  const { themeMode } = useSettingsContext();
  // console.log(message)
  // console.log(subject)

  // const handleTypeChange = (event, id) => {
  //   const newStatus = event.target.value;
  //   setStatusValue(newStatus);
  //   // console.log(newStatus);
  //   // console.log('id =>', id);
  //   const updateCredentials = {
  //     state: {
  //       status: newStatus,
  //       id: _id,
  //     },
  //     dispatch,
  //   };

  //   dispatch(updateEnquiryStatus(updateCredentials));
  // };
  const handleClose = () => {
    confirm.onFalse();
  };

  const handleDelete = () => {
    confirm.onTrue();
  };

  const handleDeleteFaqConfirmClick = () => {
    const credentials = {
      dispatch,
      faqId: row?._id,
      handleCloseDeleteDialog: handleClose,
    };
    dispatch(deleteFaq(credentials));
  };

  console.log(isShow);

  return (
    <>
      {/* {openModalDialog && (
        <EnquiryView
          setOpen={setOpenModalDialog}
          open={openModalDialog}
          isUpdate={row}
          // setIsUpdate={setInvestorData}
        />
      )} */}
      <TableRow hover selected={selected}>
        <TableCell
          sx={{
            width: 350,
          }}
        >
          <Stack>
            <Typography
              variant="body2"
              color={themeMode === 'dark' ? '#FFFFFF' : '#212B36'}
              sx={{ cursor: 'pointer' }}
            >
              {question || '--'}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell>
          {answer?.length > 100 ? (
            isShow.value ? (
              <Typography variant="body2">
                {answer}
                <Typography
                  component="span"
                  sx={{
                    cursor: 'pointer',
                    paddingLeft: '8px',
                  }}
                  onClick={() => isShow.onFalse()}
                  variant="caption"
                  color="#007867"
                >
                  Show less
                </Typography>
              </Typography>
            ) : (
              <Typography variant="body2">
                {answer.slice(0, 100)}...
                <Typography
                  component="span"
                  sx={{
                    cursor: 'pointer',
                    color: '#007867',
                    paddingLeft: '4px',
                  }}
                  onClick={() => isShow.onTrue()}
                  variant="caption"
                >
                  Show more
                </Typography>
              </Typography>
            )
          ) : (
            // Display full text if it's 110 characters or less
            <Typography variant="body2">{answer || '--'}</Typography>
          )}
        </TableCell>

        <TableCell>
          <Stack>
            <Typography
              variant="body2"
              color={themeMode === 'dark' ? '#FFFFFF' : '#2e436a'}
              sx={{ cursor: 'pointer' }}
            >
              {priority || '--'}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell>
          <Stack direction="row" alignItems="center">
            <>
              <Tooltip title="Edit ">
                <Stack direction="row" alignItems="center">
                  <IconButton
                    color="text.secondary"
                    onClick={() => onEditRow(row)}
                    sx={{
                      borderRadius: '50%',
                    }}
                  >
                    <Iconify icon="solar:pen-bold" />
                  </IconButton>
                </Stack>
              </Tooltip>
              <Tooltip title="Delete ">
                <IconButton
                  sx={{
                    borderRadius: '50%',
                    color: '#B71D18',
                  }}
                  onClick={handleDelete}
                >
                  <Iconify icon="solar:trash-bin-trash-bold" />
                </IconButton>
              </Tooltip>
            </>
          </Stack>
        </TableCell>
      </TableRow>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete FAQ"
        icon={<WarningRoundedIcon color="error" fontSize="large" />}
        content="Are you sure you want to delete this FAQ? This action cannot be undone."
        alertMsg="Deleting this FAQ will remove it from the Winbuks website."
        action={
          <Button
            fullWidth
            variant="contained"
            sx={{
              backgroundColor: '#7A0916',
              '&:hover': {
                backgroundColor: '#9B1120',
              },
            }}
            onClick={handleDeleteFaqConfirmClick}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

FaqTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.any,
};

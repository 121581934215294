import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Divider, CardMedia } from '@mui/material';

// import { fCurrency } from 'src/utils/format-number';
import { USDT } from 'src/hooks/use-USDT';
import { useResponsive } from 'src/hooks/use-responsive';

import club1 from 'src/assets/club/CLUB.png';
import club2 from 'src/assets/club/UJIN.png';
import club3 from 'src/assets/club/CIAN.png';
import club4 from 'src/assets/club/KIAH.png';
import club5 from 'src/assets/club/SAYAN.png';
import nullImage from 'src/assets/other/noImage.png';
import money_bag from 'src/assets/other/money_bag.svg';

// ----------------------------------------------------------------------

export default function IncomeCurrentBalance({
  sentAmount,
  ActiveClubBalance,
  currentBalance,
  setOpenWalletTransferDialog,
  sx,
  MemberProfileDetails,
  setOpenClubId,
  setConfirmDialog,
  disabled,
  ...other
}) {
  // const totalAmount = currentBalance - sentAmount;
  const isMobile = useResponsive('down', 'md');

  const clubImages = {
    BENE: club1,
    UJIN: club2,
    CIAN: club3,
    KIAH: club4,
    SAYAN: club5,
  };
  const currentClubTitle = MemberProfileDetails?.member?.current_club?.club?.title;
  console.log(MemberProfileDetails?.current_club?.wallet_balance);
  return (
    <Card variant="outlined" sx={{ p: 3, boxShadow: 'unset', ...sx }} {...other}>
      <Stack
        direction="column"
        alignItems="center"
        gap={2}
        sx={{
          flex: 1,
          py: isMobile ? 1 : 0,
          px: 2,
          mt: 1,
          // borderBottom:
          //   settings?.themeMode === 'dark' ? '1px solid #333d48' : '1px solid #e3e7ea ',
        }}
      >
        <Stack
          p={2}
          backgroundColor="#f0f0f0"
          borderRadius="50%"
          justifyContent="center"
          alignItems="center"
          width="92px"
          height="92px"
        >
          <CardMedia
            component="img"
            // src={rankBadge}
            src={clubImages[currentClubTitle] || nullImage}
            alt={currentClubTitle || 'club badge'}
            style={{ width: '52px', objectFit: 'cover' }}
          />
        </Stack>
        <Stack alignItems="center" justifyContent="center">
          <Typography
            sx={{
              typography: 'h6',
              fontWeight: 'bold',
            }}
          >
            {MemberProfileDetails?.member?.current_club?.club?.title || 'No active club'}
          </Typography>
          <Typography variant="caption"> Current Club</Typography>
        </Stack>
        <Stack alignItems="center" justifyContent="center">
          <Typography
            sx={{
              typography: 'h6',
              fontWeight: 'bold',
            }}
          >
            {`${ActiveClubBalance?.toFixed(2) || '0.00'} ${USDT}`}
          </Typography>
          <Typography variant="caption">Current Club Balance</Typography>
        </Stack>
      </Stack>
      {/* <Typography variant="subtitle2" gutterBottom>
        {title}
      </Typography> */}
      <Divider sx={{ mb: 2, mt: 3 }} />
      <Stack gap={1} direction="row">
        {/* <Stack mt={.5}> */}

        <CardMedia
          component="img"
          src={money_bag}
          alt="wallet"
          style={{ width: '15px', height: '15px', objectFit: 'cover', marginTop: 4 }}
        />
        {/* </Stack> */}
        <Stack gap={0.5}>
          <Typography variant="subtitle2">Transfer Guideline</Typography>
          <Typography variant="caption" sx={{ opacity: 0.68 }}>
            {` You can transfer your balance from your club wallet to your personal wallet. Please note that you can only transfer up to $${MemberProfileDetails?.member?.current_club?.club?.limit_amount}  from your current club.`}
          </Typography>
        </Stack>
      </Stack>
      <Stack mt={3}>
        <Button
          variant="contained"
          size="large"
          disabled={!ActiveClubBalance >= 10 || disabled}
          sx={{
            backgroundColor: '#007867',
            '&:hover': { backgroundColor: '#044f45' },
          }}
          onClick={() => {
            setConfirmDialog(true);

            setOpenClubId(MemberProfileDetails?.member?.current_club?._id);
          }}
        >
          Transfer to Wallet{' '}
        </Button>
      </Stack>
    </Card>
  );
}

IncomeCurrentBalance.propTypes = {
  currentBalance: PropTypes.number,
  sentAmount: PropTypes.number,
  sx: PropTypes.object,
  ActiveClubBalance: PropTypes.number,
  setOpenWalletTransferDialog: PropTypes.func,
  setOpenClubId: PropTypes.any,
  setConfirmDialog: PropTypes.any,
  MemberProfileDetails: PropTypes.any,
  disabled: PropTypes.any,
};

import { createSlice } from '@reduxjs/toolkit';

import { getFaq } from '../api/faq';

export const faq = createSlice({
  name: 'faq',
  initialState: {
    loading: false,
    error: {},
    faqList: [],
  },
  reducers: {},
  extraReducers: {
    [getFaq.pending]: (state) => {
      state.loading = true;
    },
    [getFaq.fulfilled]: (state, action) => {
      state.loading = false;
      state.faqList = action.payload;
      state.error = {};
    },
    [getFaq.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export default faq.reducer;

/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ListItemText from '@mui/material/ListItemText';

import { useGetRoles } from 'src/hooks/useHandleSessions';

import { fDate, fTime } from 'src/utils/format-time';

import TextMaxLine from 'src/components/text-max-line/text-max-line';

// ----------------------------------------------------------------------

export default function BioniumTableRow({ row, selected }) {
  const role = useGetRoles()?.role;
  const { createdAt, amount, description, member } = row;

  return (
    <TableRow hover selected={selected}>
      <TableCell>
        <ListItemText
          primary={fDate(createdAt)}
          secondary={fTime(createdAt)}
          primaryTypographyProps={{ variant: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            variant: 'caption',
          }}
        />
      </TableCell>
      <TableCell>
        {role === 'admin' ? (
          <ListItemText
            primary={member?.name || '---'}
            secondary={member?.member_id}
            primaryTypographyProps={{ variant: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              variant: 'caption',
            }}
          />
        ) : (
          <TextMaxLine line={2}>
            <Typography variant="body2">{description || '---'}</Typography>
          </TextMaxLine>
        )}
      </TableCell>
      {role === 'admin' && (
        <TableCell>
          <TextMaxLine line={2}>
            <Typography variant="body2">{description}</Typography>
          </TextMaxLine>
        </TableCell>
      )}

      <TableCell>
        <Typography variant="body2" color="#007867">
          {`${amount?.toFixed(2)} `}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

BioniumTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
};

import React from 'react';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import ClubTransactions from './ClubIncomeTransactionPage';

function AllClubTransactions() {
  return (
    <>
      <MetaHelmet title="Winbuks" miniDescription="Club Income Transaction" />
      <ClubTransactions />
    </>
  );
}

export default AllClubTransactions;

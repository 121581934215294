// import { Link } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// import { Stack, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';

// import { paths } from 'src/routes/routes/paths';

import { Link } from 'react-router-dom';

import { Stack, Button } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';

import { bgBlur } from 'src/theme/css';
// import NavMobile from 'src/layouts/main/nav/mobile';
import HeaderShadow from 'src/layouts/common/header-shadow';

import Logo from 'src/components/logo';

import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
import { HEADER } from './config-layout';
import { navConfig } from './config-navigation';

// ----------------------------------------------------------------------

export default function Header() {
  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  return (
    <AppBar className="website">
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
            }),
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
            },
          }),
        }}
      >
        <Container
          sx={{ height: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          {/* <Badge
            sx={{
              [`& .${badgeClasses.badge}`]: {
                top: 8,
                right: -16,
              },
            }}
            badgeContent={
              <Link
                // href={paths.changelog}
                target="_blank"
                rel="noopener"
                underline="none"
                sx={{ ml: 1 }}
              >
                <Label color="info" sx={{ textTransform: 'unset', height: 22, px: 0.5 }}>
                  v5.7.0
                </Label>
              </Link>
            }
          > */}
          {mdUp && <Logo color="golden" />}
          {!mdUp && <Logo type="smallGolden" sx={{ height: '28px', width: '28px' }} />}
          {/* </Badge> */}

          {/* <Box sx={{ flexGrow: .5 }} /> */}

          {mdUp && <NavDesktop data={navConfig} />}

          <Stack alignItems="center" direction={{ xs: 'row', md: 'row-reverse' }} gap={2}>
            {mdUp && (
              <Link to={paths.auth.register}>
                <Button
                  variant="contained"
                  target="_blank"
                  rel="noopener"
                  sx={{
                    backgroundColor: '#966e11',
                    fontWeight: '400',
                    borderRadius: '20px',
                    '&:hover': {
                      backgroundColor: 'rgb(92 68 10)',
                    },
                  }}
                  // href={paths.minimalUI}
                >
                  Sign Up{' '}
                </Button>
              </Link>
            )}

            <Link to={paths.auth.login_member}>
              <Button
                sx={{
                  backgroundColor: '#252729',
                  color: 'white',
                  fontWeight: '400',
                  borderRadius: '20px',
                  '&:hover': {
                    backgroundColor: 'rgb(30 31 32)',
                  },
                }}
              >
                Login
              </Button>
            </Link>
          </Stack>
          {!mdUp && <NavMobile data={navConfig} />}
        </Container>
      </Toolbar>

      {offsetTop && <HeaderShadow />}
    </AppBar>
  );
}

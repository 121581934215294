import { createAsyncThunk } from '@reduxjs/toolkit';

import { get } from './http';
import { activeSnack } from '../store/common';

// export const createClub = createAsyncThunk(
//   'club/create',
//   async ({ state, dispatch, handleClose, handleClear }) => {
//     try {
//       const URL = `/club/admin/new`;
//       const response = await post(URL, state);
//       if (response) {
//         handleClose();
//         handleClear();
//         dispatch(activeSnack({ type: 'success', message: 'Club created successfully' }));
//         return response;
//       }
//       return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
//     } catch (error) {
//       dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
//       throw error?.response?.data?.message;
//     }
//   }
// );

export const getAllIncentiveLevels = createAsyncThunk(
  'incentive-level/list',
  async ({ dispatch }) => {
    try {
      const response = await get(`/incentive-level/admin/all`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllMemberIncentives = createAsyncThunk(
  'member-incentive/admin/list',
  async ({ dispatch, page, limit, selectedMember, selectedIncentiveLevel, date }) => {
    try {
      // console.log('date: ', date);

      const response = await get(
        `/member-incentive/admin/all?page=${page && page}&limit=${limit || 10}&dateFrom=${
          date?.start_date || ''
        }&dateTo=${date?.end_date || ''}&member=${selectedMember || ''}&incentive_level=${
          selectedIncentiveLevel || ''
        }`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// export const getClubDetails = createAsyncThunk('club/single', async ({ clubId, dispatch }) => {
//   try {
//     const response = await get(`/club/admin/${clubId}`);
//     if (response) {
//       return response;
//     }
//     return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
//   } catch (error) {
//     dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
//     throw error?.response?.data?.message;
//   }
// });

// export const updateClubDetails = createAsyncThunk(
//   'club/update',
//   async ({ state, clubId, dispatch, handleClose, handleClear }) => {
//     try {
//       const URL = `/club/admin/${clubId}`;

//       const response = await put(URL, state);
//       if (response) {
//         if (handleClose) {
//           handleClose();
//         }
//         dispatch(activeSnack({ type: 'success', message: 'Club updated Successfully' }));
//         return response;
//       }
//       return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
//     } catch (error) {
//       dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
//       throw error?.response?.data?.message;
//     }
//   }
// );

// export const deleteClub = createAsyncThunk(
//   'club/delete',
//   async ({ clubId, dispatch, handleCloseDeleteDialog }) => {
//     try {
//       const response = await del(`/club/admin/${clubId}/`);
//       if (response) {
//         handleCloseDeleteDialog();
//         dispatch(activeSnack({ type: 'success', message: 'Club deleted Successfully' }));
//         return response?._id;
//       }
//       return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
//     } catch (error) {
//       dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
//       throw error?.response?.data?.message;
//     }
//   }
// );

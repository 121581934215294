/* eslint-disable import/no-unresolved */
import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import { AuthRoles } from 'src/routes/routes/paths';

import FAQ from 'src/pages/Common/FAQ';
import AllClub from 'src/pages/Common/Club';
import Bionium from 'src/pages/Common/Bionium';
import AllMembers from 'src/pages/Common/Member';
import Incentive from 'src/pages/Common/Incentive';
import Enquiry from 'src/pages/Common/EnquiryPage';
import SettingsPage from 'src/pages/Admin/settings';
import ChainNodesView from 'src/pages/Common/Chain';
import SignUpPage from 'src/pages/Common/Auth/SignUp';
import LoginAdmin from 'src/assets/other/Frame 55.png';
import Leaderboard from 'src/pages/Common/Leaderboard';
import AccountView from 'src/pages/Common/Auth/profile';
import AllClubMembers from 'src/pages/Common/ClubMember';
import AllLearningPage from 'src/pages/Common/learnings';
import Notification from 'src/pages/Common/Notification';
import RegisterPage from 'src/pages/Common/Auth/Register';
import LogoutPage from 'src/pages/Common/Auth/LogoutPage';
import MemberView from 'src/pages/Common/Member/SingleView';
import SignUpPageOTP from 'src/pages/Common/Auth/SignUpOTP';
import PrivacyPolicy from 'src/pages/Common/website/privacy&policy';
import IncentivePage from 'src/pages/Common/Incentive/IncentivePage';
import AuthGuardVerifyMember from 'src/auth/auth-guard-verify-member';
import DashboardLoadingPage from 'src/pages/Common/Dashboard/DashboardLoading';
import MemberNotification from 'src/pages/Common/Notification/member/memberNotification';
// import AllWithdrawalReports from 'src/pages/Common/withdrawalReport';
// import AuthGuardVerifyMember from 'src/auth/auth-guard-verify-member';
import MyWalletPage from 'src/pages/Common/Wallet';
// import DashboardLoadingPage from 'src/pages/Common/Dashboard/DashboardLoading';
import AllMemberWithdrawalRequests from 'src/pages/Common/withdrawalRequest/withdrawalRequestMember';

import { SplashScreen } from 'src/components/loading-screen';

import MemberCrud from 'src/sections/Member/MemberCrud';

const AuthLayout = lazy(() => import('src/layouts/auth/authLayout'));
const LoginAdminPage = lazy(() => import('src/pages/Common/Auth/Login'));
const LoginMemberPage = lazy(() => import('src/pages/Common/Auth/MemberLogin'));
const LoginMemberOTPPage = lazy(() => import('src/pages/Common/Auth/MemberOTPLogin'));

const NewPasswordPage = lazy(() => import('src/pages/Common/Auth/NewPassword'));
const ForgotPasswordPage = lazy(() => import('src/pages/Common/Auth/ForgotPassword'));
const AuthModernCompactLayout = lazy(() => import('src/layouts/auth/modern-compact'));
const RegisterLayout = lazy(() => import('src/layouts/auth/registerLayoutNew'));

const CreatePassword = lazy(() => import('src/sections/auth/new-password-view'));

const CompactLayout = lazy(() => import('src/layouts/compact'));
const ReferralPage = lazy(() => import('src/pages/Common/Referrals/index'));
const TransactionPage = lazy(() => import('src/pages/Common/Transaction/index'));
const IncomePage = lazy(() => import('src/pages/Common/Income/index'));
const WithdrawalRequestPage = lazy(() => import('src/pages/Common/withdrawalRequest/index'));
const PlanPage = lazy(() => import('src/pages/Common/website/plan'));
const AboutPage = lazy(() => import('src/pages/Common/website/about'));
const ContactPage = lazy(() => import('src/pages/Common/website/contact'));
const TermsAndConditionPage = lazy(() => import('src/pages/Common/website/terms&conditions'));

const Page500 = lazy(() => import('src/pages/Common/Static/500'));
const Page403 = lazy(() => import('src/pages/Common/Static/403'));
const Page404 = lazy(() => import('src/pages/Common/Static/404'));

const publicRoutes = [
  {
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      // { path: 'landing', element: <LandingPage /> },
      { path: 'plan', element: <PlanPage /> },
      { path: 'home', element: <AboutPage /> },
      { path: 'contact', element: <ContactPage /> },
      { path: 'terms', element: <TermsAndConditionPage /> },
      { path: 'privacy', element: <PrivacyPolicy /> },
      { path: 'dashboard_loading/:id', element: <DashboardLoadingPage /> },
    ],
  },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
    ],
  },
];

const authRoutes = [
  {
    path: '',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),

    children: [
      {
        path: 'login',
        element: <Outlet />,
        children: [
          {
            path: 'admin',
            element: (
              <AuthLayout
                welcomeText="Hi, Welcome back"
                image={LoginAdmin}
                title="Authenticate as an administrator"
                userRole="admin"
              >
                <LoginAdminPage role={AuthRoles.admin} />
              </AuthLayout>
            ),
          },
          {
            path: '',
            element: (
              <AuthLayout
                image={LoginAdmin}
                welcomeText="Hi, Welcome back"
                title="Authenticate as an Member"
                userRole="member"
              >
                <LoginMemberPage role={AuthRoles.member} />
              </AuthLayout>
            ),
          },
          {
            path: 'otp',
            element: (
              <AuthLayout image={LoginAdmin} title="Authenticate as an Member" userRole="member">
                <LoginMemberOTPPage role={AuthRoles.member} />
              </AuthLayout>
            ),
          },
        ],
      },

      {
        path: 'logout',
        element: (
          <AuthModernCompactLayout>
            <LogoutPage />
          </AuthModernCompactLayout>
        ),
      },
      {
        path: 'forgot/password',
        element: (
          <AuthModernCompactLayout>
            <ForgotPasswordPage />
          </AuthModernCompactLayout>
        ),
      },

      {
        path: 'create/password/:type/:token',
        element: (
          <AuthModernCompactLayout>
            <NewPasswordPage type="create" />
          </AuthModernCompactLayout>
        ),
      },

      {
        path: 'password/reset/:userId/:token',
        element: (
          <AuthModernCompactLayout>
            <NewPasswordPage type="reset" />
          </AuthModernCompactLayout>
        ),
      },

      {
        path: 'create/password/:id',
        element: (
          <AuthModernCompactLayout>
            <CreatePassword />
          </AuthModernCompactLayout>
        ),
      },
      {
        path: 'sign-up',
        element: (
          <RegisterLayout
            welcomeText="Welcome To Winbuks </br>
          Higher Profit And Enjoy The Profit"
          >
            <RegisterPage />
          </RegisterLayout>
        ),
      },
      {
        path: 'register',
        element: (
          <RegisterLayout
            welcomeText="Welcome To Winbuks </br>
          Higher Profit And Enjoy The Profit"
          >
            <SignUpPage />
          </RegisterLayout>
        ),
      },
      {
        path: 'sign-up-otp',
        element: (
          <RegisterLayout
            welcomeText="Welcome To Winbuks </br>
          Higher Profit And Enjoy The Profit"
          >
            <SignUpPageOTP />
          </RegisterLayout>
        ),
      },
      { path: '404', element: <Page404 isInner /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
];

// super admin
const adminRoutes = [
  // {
  //   path: 'dashboard',
  //   element: <AdminWidgetSummary />,
  // },
  { path: 'profile', element: <AccountView /> },

  {
    path: 'chain-node',
    element: <ChainNodesView />,
  },
  {
    path: 'member',
    element: <AllMembers />,
  },
  {
    path: 'club/members',
    element: <AllClubMembers />,
  },
  {
    path: 'enquiry',
    element: <Enquiry />,
  },
  {
    path: 'notifications',
    element: <Notification />,
  },
  {
    path: 'faq',
    element: <FAQ />,
  },
  {
    path: 'member/:id',
    element: <MemberView />,
  },
  {
    path: 'member/create',
    element: <MemberCrud />,
  },
  {
    path: 'withdrawal/requests',
    element: <WithdrawalRequestPage />,
  },
  // {
  //   path: 'withdrawal/reports',
  //   element: <AllWithdrawalReports />,
  // },
  {
    path: 'clubs',
    element: <AllClub />,
  },
  {
    path: 'incentive',
    element: <Incentive />,
  },

  {
    path: 'bionium',
    element: <Bionium />,
  },
  {
    path: 'transactions',
    element: <TransactionPage />,
  },
  {
    path: 'settings',
    element: <SettingsPage />,
  },
];
// member
const memberRoutes = [
  {
    path: 'profile',
    element: (
      <AuthGuardVerifyMember>
        <AccountView />
      </AuthGuardVerifyMember>
    ),
  },

  {
    path: 'referral',
    element: (
      <AuthGuardVerifyMember>
        <ReferralPage />
      </AuthGuardVerifyMember>
    ),
  },
  {
    path: 'income',
    element: (
      <AuthGuardVerifyMember>
        <IncomePage />
      </AuthGuardVerifyMember>
    ),
  },
  {
    path: 'learning',
    element: (
      <AuthGuardVerifyMember>
        <AllLearningPage />
      </AuthGuardVerifyMember>
    ),
  },

  {
    path: 'withdrawal/requests',
    element: (
      <AuthGuardVerifyMember>
        <AllMemberWithdrawalRequests />
      </AuthGuardVerifyMember>
    ),
  },
  {
    path: 'transactions',
    element: (
      <AuthGuardVerifyMember>
        <TransactionPage />
      </AuthGuardVerifyMember>
    ),
  },
  // {
  //   path: 'dashboard-loading/:id',
  //   element: (
  //     <AuthGuardVerifyMember>
  //       <dashboardLaoding />
  //     </AuthGuardVerifyMember>
  //   ),
  // },
  {
    path: 'incentive',
    element: (
      <AuthGuardVerifyMember>
        <IncentivePage />
      </AuthGuardVerifyMember>
    ),
  },
  {
    path: 'my-wallet',
    element: (
      <AuthGuardVerifyMember>
        <MyWalletPage />
      </AuthGuardVerifyMember>
    ),
  },
  {
    path: 'bionium',
    element: (
      <AuthGuardVerifyMember>
        <Bionium />
      </AuthGuardVerifyMember>
    ),
  },
  {
    path: 'leaderboard',
    element: (
      <AuthGuardVerifyMember>
        <Leaderboard />
      </AuthGuardVerifyMember>
    ),
  },
  {
    path: 'member/notifications',
    element: (
      <AuthGuardVerifyMember>
        <MemberNotification />
      </AuthGuardVerifyMember>
    ),
  },
];

export { authRoutes, adminRoutes, publicRoutes, memberRoutes };

/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import { Card, Button, CardMedia, Typography, IconButton } from '@mui/material';
import MarkEmailUnreadRoundedIcon from '@mui/icons-material/MarkEmailUnreadRounded';

import { useParams } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { USDT } from 'src/hooks/use-USDT';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import { bgGradient } from 'src/theme/css';
import club1 from 'src/assets/club/CLUB.png';
import club2 from 'src/assets/club/UJIN.png';
import club3 from 'src/assets/club/CIAN.png';
import club4 from 'src/assets/club/KIAH.png';
import club5 from 'src/assets/club/SAYAN.png';
import gmail from 'src/assets/other/gmail.png';
import refer from 'src/assets/other/reffer.png';
import wallet from 'src/assets/other/wallet.png';
import { memberBlock } from 'src/server/api/member';
import nullImage from 'src/assets/other/noImage.png';
import passwordLock from 'src/assets/other/passwordLock.png';
import { frontEndUrl, decimalLimit } from 'src/server/api/http';

import Iconify from 'src/components/iconify';
import TextMaxLine from 'src/components/text-max-line';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';

import FlagDialog from 'src/sections/Member/MemberSingleView/flagDialog';
import SuspendDialog from 'src/sections/Member/MemberSingleView/suspendDialog';

export default function MemberRefiredCardNew({
  memberDetails,
  setOpenModalPasswordDialog,
  setOpenModalDialog,
  data,
  memberID,
  setOpenUpdateMemberEmail,
}) {
  const { isBlocked, isSuspented } = useSelector((state) => ({
    flagMessage: state.member.memberDetails?.note,
    isBlocked: state.member.memberDetails?.is_blocked,
    isSuspented: state.member.memberDetails?.is_withdrawal_suspended,
  }));
  // console.log(memberDetails?.member_id);
  const [openFlagDialog, setOpenFlagDialog] = useState(false);
  const [openSuspendDialog, setOpenSuspendDialog] = useState(false);

  const role = useGetRoles()?.role;
  // const clubImages = [club1, club2, club3, club4, club5];
  const clubImages = {
    BENE: club1,
    UJIN: club2,
    CIAN: club3,
    KIAH: club4,
    SAYAN: club5,
  };
  const currentClubTitle = memberDetails?.current_club?.club?.title;

  const handleCopy = () => {
    enqueueSnackbar('Link copied!', { variant: 'success' });
  };
  const settings = useSettingsContext();

  const confirm = useBoolean();
  const dispatch = useDispatch();
  const params = useParams();
  const isMobile = useResponsive('down', 'md');

  // Block_Unblock_Member
  const Block_Unblock_Member = () => {
    const credentials = {
      dispatch,
      memberId: params?.id,
      state: {
        is_blocked: !isBlocked,
      },
    };
    dispatch(memberBlock(credentials));
  };

  const handleClick = () => {
    const memberId = memberDetails?.member_id;
    const link = `${paths.auth.register}?ref_id=${memberId}`;
    if (memberId) {
      window.open(link, '_blank');
    } else {
      console.warn('Member ID not available for referral link.');
    }
  };
  return (
    <>
      {role === 'member' && (
        // <Stack
        //   direction={{ xs: 'column', md: 'row' }}
        //   sx={{
        //     ...bgGradient({
        //       color: alpha('#0a0909cc', 0.7),
        //       imgUrl: coverUrl,
        //     }),
        //     // backgroundColor: '#FFE0A3',
        //     // height: '201px',
        //     // height: isMobile ? '458px' : '100%',
        //     height: '100%',
        //     width: '100%',
        //     color: 'common.white',
        //     // border: '2px solid orange',
        //     borderRadius: '15px',
        //   }}
        //   justifyContent="space-between"
        // >
        //   <Stack
        //     // border='2px solid orange'
        //     justifyContent="space-between"
        //     p={2}
        //   >
        //     <Stack
        //       sx={{
        //         // position: 'absolute',
        //         // top: 60,
        //         // left: 16,
        //         color: 'common.white',
        //       }}
        //       // direction='row'
        //     >
        //       <Typography variant="body2">Current Club</Typography>
        //       <Typography
        //         variant="body2"
        //         sx={{
        //           opacity: 0.48,
        //         }}
        //       >
        //         {/* CIAN (Active Member) */}
        //         {memberDetails?.current_club?.club?.title || ''}
        //       </Typography>
        //       {/* <Typography
        //       sx={{
        //         typography: 'caption',
        //         opacity: 0.48,
        //       }}
        //     >
        //       You can only withdraw
        //       {memberDetails?.current_club?.club?.limit_amount || ''} {USDT}
        //     </Typography> */}
        //     </Stack>

        //     <Stack
        //       sx={{
        //         // position: 'absolute',
        //         // bottom: 57,
        //         // left: 16,
        //         color: 'common.white',
        //       }}
        //       // direction='row'
        //     >
        //       <Typography variant="body2">Wallet Balance</Typography>
        //       <Typography
        //         sx={{
        //           typography: 'caption',
        //           opacity: 0.68,
        //         }}
        //       >
        //         {memberDetails?.wallet_balance?.toFixed(2) || 0} {USDT}
        //       </Typography>
        //     </Stack>

        //     <Stack direction="row">
        //       <IconButton
        //         onClick={() => setOpenModalDialog(true)}
        //         sx={{
        //           // position: 'absolute',
        //           // top: 16,
        //           // left: 16,
        //           color: 'common.white',
        //           width: '40px',
        //           // border: '2px solid orange'
        //         }}
        //       >
        //         <Tooltip title="Update Info">
        //           <EditRoundedIcon sx={{ fontSize: '20px' }} />
        //         </Tooltip>
        //       </IconButton>
        //       <IconButton
        //         onClick={() => setOpenModalPasswordDialog(true)}
        //         sx={{
        //           color: 'common.white',
        //           width: '40px',
        //         }}
        //       >
        //         <Tooltip title="Update Password">
        //           <KeyIcon />
        //         </Tooltip>
        //       </IconButton>
        //     </Stack>
        //   </Stack>

        //   <Stack
        //     // border='2px solid orange'
        //     justifyContent="center"
        //     flexDirection=""
        //     // alignItems="start"
        //     // justifyContent="start"
        //     sx={{
        //       // position: 'absolute',
        //       // bottom: 57,
        //       // right: 66,
        //       color: 'common.white',
        //     }}
        //     p={2}
        //   >
        //     {data?.referral_member ? (
        //       <>
        //         <Typography sx={{ color: '#919EAB', fontSize: 13, pb: 1 }}>Referred by</Typography>

        //         <Stack gap={1} ml={1}>
        //           <Stack flexDirection="row" alignItems="center">
        //             <DeviceHubIcon sx={{ fontSize: 14, color: 'white' }} fontSize="small" /> &nbsp;
        //             <Typography variant="body2" sx={{ color: 'white' }}>
        //               {data?.referral_member?.member_id}
        //             </Typography>
        //           </Stack>
        //           <Stack flexDirection="row" alignItems="center">
        //             <PersonIcon sx={{ fontSize: 14, color: 'white' }} fontSize="small" /> &nbsp;
        //             <Typography variant="body2" sx={{ color: 'white' }}>
        //               {data?.referral_member?.name}
        //             </Typography>
        //           </Stack>
        //           <Stack flexDirection="row" alignItems="center">
        //             <MarkEmailUnreadRoundedIcon
        //               sx={{ fontSize: 14, color: 'white' }}
        //               fontSize="small"
        //             />{' '}
        //             &nbsp;
        //             <Typography variant="body2" sx={{ color: 'white' }}>
        //               {' '}
        //               {data?.referral_member?.email}
        //             </Typography>
        //           </Stack>
        //           <Stack flexDirection="row" alignItems="center">
        //             <LocalPhoneRoundedIcon sx={{ fontSize: 14, color: 'white' }} fontSize="small" />{' '}
        //             &nbsp;
        //             <Typography variant="body2" sx={{ color: 'white' }}>
        //               {data?.referral_member?.phone}
        //             </Typography>
        //           </Stack>
        //         </Stack>
        //       </>
        //     ) : (
        //       <Stack
        //         // border='2px solid orange'
        //         mr={3}
        //       >
        //         <Typography sx={{ color: '#919EAB', fontSize: 13, pb: 1 }}>Referred by</Typography>

        //         <Typography
        //           mt={8}
        //           sx={{ color: '#919EAB', fontSize: 13, pb: 1, textAlign: 'center' }}
        //         >
        //           Nil
        //         </Typography>
        //       </Stack>
        //     )}
        //   </Stack>
        // </Stack>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          sx={{
            ...bgGradient({
              // color: alpha('#0a0909cc', 0.7),
              // imgUrl: coverUrl,
            }),

            height: '100%',

            width: '100%',
            // color: 'common.white',
            borderRadius: '15px',
          }}
        >
          {/* {memberDetails?.current_club?.club?.title ? ( */}
          <Stack
            flex={5}
            justifyContent="space-between"
            // p={2}
          >
            <Stack
              direction="row"
              alignItems="center"
              gap={2}
              sx={{
                py: isMobile ? 1 : 0,
                px: 2,
                flex: 1,
              }}
            >
              <Stack
                p={2}
                backgroundColor="#f0f0f0"
                borderRadius="50%"
                justifyContent="center"
                alignItems="center"
                width="60px"
                height="60px"
              >
                <CardMedia
                  component="img"
                  src={wallet}
                  alt="wallet"
                  style={{ width: '30px', height: '30px', objectFit: 'cover' }}
                />
              </Stack>
              <Stack alignItems="baseline" justifyContent="center">
                <Typography
                  sx={{
                    typography: 'body2',
                    fontWeight: 'bold',
                  }}
                >
                  {memberDetails?.buks_wallet_balance?.toFixed(decimalLimit) || 0} {USDT}
                </Typography>
                <Typography variant="caption" sx={{ opacity: 0.68 }}>
                  Buks Wallet Balance
                </Typography>
              </Stack>
            </Stack>

            {/* <Stack
                direction="row"
                alignItems="center"
                gap={2}
                sx={{
                  flex: 1,
                  py: isMobile ? 1 : 0,
                  px: 2,
                  borderTop:
                    settings?.themeMode === 'dark' ? '1px solid #333d48' : '1px solid #e3e7ea ',
                }}
              >
                <Stack
                  p={2}
                  backgroundColor="#f0f0f0"
                  borderRadius="50%"
                  justifyContent="center"
                  alignItems="center"
                  width="60px"
                  height="60px"
                >
                  <CardMedia
                    component="img"
                    // src={rankBadge}
                    src={clubImages[currentClubTitle] || nullImage}
                    alt={currentClubTitle || 'club badge'}
                    style={{ width: '30px', height: '30px', objectFit: 'cover' }}
                  />
                </Stack>
                <Stack alignItems="baseline" justifyContent="center">
                  <Typography
                    sx={{
                      typography: 'body2',
                      fontWeight: 'bold',
                    }}
                  >
                    {memberDetails?.current_club?.club?.title || 'No active club'}
                  </Typography>
                  <Typography variant="caption" sx={{ opacity: 0.68 }}>
                    {' '}
                    Current Club
                  </Typography>
                </Stack>
              </Stack> */}
          </Stack>
          {/* ) : (
            <Stack
              flex={5}
              justifyContent="space-between"
              // p={2}
            >
              <Stack
                direction="column"
                gap={2}
                sx={{
                  p: 3,
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Stack
                  p={2}
                  backgroundColor="#f0f0f0"
                  borderRadius="50%"
                  justifyContent="center"
                  alignItems="center"
                  width="60px"
                  height="60px"
                >
                  <CardMedia
                    component="img"
                    src={wallet}
                    alt="wallet"
                    style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                  />
                </Stack>
                <Stack alignItems="baseline" justifyContent="center">
                  <Typography variant="body2" fontWeight="bold">
                    {memberDetails?.wallet_balance?.toFixed(2) || 0} {USDT}
                  </Typography>
                  <Typography
                    sx={{
                      typography: 'caption',
                      opacity: 0.68,
                    }}
                  >
                    Wallet Balance
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          )} */}
          <Stack
            flex={7}
            sx={{
              borderLeft:
                settings?.themeMode === 'dark' ? '1px solid #333d48' : '1px solid #e3e7ea ',
              p: 1.5,
              gap: 1.5,
            }}
          >
            <Stack
              flexDirection=""
              sx={{
                color: 'common.white',
              }}
            >
              {memberDetails?.referral_member ? (
                <Card
                  sx={{
                    boxShadow: 'unset',
                    border: '1px solid #e3e7ea',
                    backgroundColor: '#f0f0f0',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 1.5,
                  }}
                >
                  <Stack direction="row" gap={1}>
                    <Stack direction="row" flex={8} gap={1.5}>
                      <Stack
                        p={1}
                        borderRadius="50%"
                        justifyContent="center"
                        alignItems="center"
                        border="1px solid #e3e7ea"
                        width="50px"
                      >
                        <CardMedia
                          component="img"
                          src={refer}
                          alt="wallet"
                          style={{ width: '30px', height: '20px', objectFit: 'cover' }}
                        />
                      </Stack>

                      <Stack>
                        <Typography fontSize="14px" color="#75838f">
                          Referred by
                        </Typography>
                        <Typography fontSize="14px" fontWeight="bold">
                          {data?.referral_member?.name}{' '}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack flex={4}>
                      <Card
                        boxShadow="unset"
                        sx={{
                          borderRadius: '2px',
                          borderLeft: '2px solid #004b50',
                          px: 1,
                          backgroundColor: '#d8d9f5',
                          width: 'fit-content',
                        }}
                      >
                        <Typography variant="caption" fontWeight="bold">
                          ID : {data?.referral_member?.member_id}
                        </Typography>
                      </Card>
                    </Stack>
                  </Stack>
                  <Stack ml={6.5} mt={1}>
                    <Stack direction="row" alignItems="center">
                      <MarkEmailUnreadRoundedIcon
                        sx={{ fontSize: 14, color: '#919eab' }}
                        fontSize="small"
                      />{' '}
                      &nbsp;
                      <Typography variant="caption"> {data?.referral_member?.email}</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <LocalPhoneRoundedIcon
                        sx={{ fontSize: 14, color: '#919eab' }}
                        fontSize="small"
                      />
                      &nbsp;
                      <Typography variant="caption">{data?.referral_member?.phone}</Typography>
                    </Stack>
                  </Stack>
                </Card>
              ) : (
                <Card
                  sx={{
                    boxShadow: 'unset',
                    border: '1px solid #e3e7ea',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 1.5,
                    backgroundColor: '#f0f0f0',
                  }}
                >
                  <Stack
                    direction="row"
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack gap={2} flex={1}>
                      <Typography variant="h6" fontWeight="bold" color="black">
                        Member referral <br />
                        link
                      </Typography>
                    </Stack>
                    <Stack width="auto">
                      <CardMedia
                        component="img"
                        src={gmail}
                        alt="wallet"
                        style={{ width: 70, height: 70, objectFit: 'cover' }}
                      />
                    </Stack>
                  </Stack>
                  {/* .. */}
                  <Typography
                    variant="caption"
                    sx={{ mb: 0.5, color: settings?.themeMode === 'dark' ? 'black' : '#637381' }}
                  >
                    Share link
                  </Typography>
                  <Stack direction="row" gap={1} justifyContent="center" alignItems="center">
                    <Card
                      sx={{
                        px: 1,
                        py: 0.7,
                        borderRadius: '10px',
                        boxShadow: 'unset',
                        border: '1px solid #e3e7ea',
                        textAlign: 'start',
                        backgroundColor: '#f0f0f0',
                        gap: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <TextMaxLine line={1}>
                        <Typography
                          sx={{
                            color: settings?.themeMode === 'dark' ? 'black' : '#637381',
                            wordWrap: 'break-word',
                            overflowWrap: 'anywhere',
                            opacity: 0.7,
                            textAlign: 'start',
                          }}
                          variant="caption"
                        >
                          {`${frontEndUrl}${paths.auth.register}?ref_id=${memberDetails?.member_id}`}
                        </Typography>
                      </TextMaxLine>
                      <CopyToClipboard
                        // text={`${paths.auth.register}?ref='${MemberProfileDetails?.member_id}`}
                        text={`${frontEndUrl}${paths.auth.register}?ref_id=${memberDetails?.member_id}`}
                        onCopy={handleCopy}
                        sx={
                          {
                            // cursor: 'pointer',
                            // position: 'absolute',
                            // bottom: 16,
                            // left: 16,
                            // '&:hover': {
                            //   color: 'primary.main',
                            // },
                          }
                        }
                      >
                        <Button
                          size="small"
                          variant="contained"
                          className="custom-copy-button"
                          backgroundColor="#007867"
                          borderRadius="25px"
                          sx={{
                            position: 'absolute',
                            bottom: 16,
                            right: 16,
                            color: 'white',
                            p: 1,
                            '&:hover': { backgroundColor: '#03433a' },
                          }}
                        >
                          Copy
                        </Button>
                      </CopyToClipboard>
                    </Card>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={handleClick}
                      sx={{
                        border: '1px solid #e3e7ea',
                        color: '#007867',
                        width: 'auto',
                        height: '40px',
                        borderRadius: '20px',
                        backgroundColor: '#e1efea',
                      }}
                    >
                      Share
                    </Button>
                  </Stack>
                </Card>
              )}
            </Stack>
            <Stack width="100%" alignItems="end">
              <Stack direction="row" alignItems="center" gap={2}>
                <IconButton
                  onClick={() => setOpenModalDialog(true)}
                  sx={{
                    width: 'auto',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '8px',
                    backgroundColor: '#f0f0f0',
                    color: 'black',
                    gap: 1,
                    '&:hover': {
                      backgroundColor: settings?.themeMode === 'dark' ? 'white' : '#f0f0f0',
                    },
                  }}
                >
                  <Iconify icon="fluent:edit-12-filled" />
                  <Typography variant="caption" fontWeight="bold">
                    {' '}
                    Edit Member
                  </Typography>
                </IconButton>
                <IconButton
                  onClick={() => setOpenModalPasswordDialog(true)}
                  sx={{
                    width: 'auto',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '8px',
                    backgroundColor: '#f0f0f0',
                    color: 'black',
                    gap: 1,
                    '&:hover': {
                      backgroundColor: settings?.themeMode === 'dark' ? 'white' : '#f0f0f0',
                    },
                  }}
                >
                  <CardMedia component="img" src={passwordLock} alt="copy" sx={{ width: '16px' }} />

                  <Typography variant="caption" fontWeight="bold">
                    {' '}
                    Change Password
                  </Typography>
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      )}

      {role === 'admin' && (
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          sx={{
            ...bgGradient({
              // color: alpha('#0a0909cc', 0.7),
              // imgUrl: coverUrl,
            }),

            height: '100%',

            width: '100%',
            // color: 'common.white',
            borderRadius: '15px',
          }}
        >
          {memberDetails?.current_club?.club?.title ? (
            <Stack
              flex={5}
              justifyContent="space-between"
              // p={2}
            >
              <Stack
                direction="row"
                alignItems="center"
                gap={2}
                sx={{
                  py: isMobile ? 1 : 0,
                  px: 2,
                  flex: 1,
                }}
              >
                <Stack
                  p={2}
                  backgroundColor="#f0f0f0"
                  borderRadius="50%"
                  justifyContent="center"
                  alignItems="center"
                  width="60px"
                  height="60px"
                >
                  <CardMedia
                    component="img"
                    src={wallet}
                    alt="wallet"
                    style={{ width: '30px', height: '30px', objectFit: 'cover' }}
                  />
                </Stack>
                <Stack alignItems="baseline" justifyContent="center">
                  <Typography
                    sx={{
                      typography: 'body2',
                      fontWeight: 'bold',
                    }}
                  >
                    {memberDetails?.wallet_balance?.toFixed(2) || 0} {USDT}
                  </Typography>
                  <Typography variant="caption" sx={{ opacity: 0.68 }}>
                    Wallet Balance
                  </Typography>
                </Stack>
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                gap={2}
                sx={{
                  flex: 1,
                  py: isMobile ? 1 : 0,
                  px: 2,
                  borderTop:
                    settings?.themeMode === 'dark' ? '1px solid #333d48' : '1px solid #e3e7ea ',
                }}
              >
                <Stack
                  p={2}
                  backgroundColor="#f0f0f0"
                  borderRadius="50%"
                  justifyContent="center"
                  alignItems="center"
                  width="60px"
                  height="60px"
                >
                  <CardMedia
                    component="img"
                    src={clubImages[currentClubTitle] || nullImage}
                    alt={currentClubTitle || 'club badge'}
                    style={{ width: '30px', height: '30px', objectFit: 'cover' }}
                  />
                </Stack>
                <Stack alignItems="baseline" justifyContent="center">
                  <Typography
                    sx={{
                      typography: 'body2',
                      fontWeight: 'bold',
                    }}
                  >
                    {memberDetails?.current_club?.club?.title || 'No active club'}
                  </Typography>
                  <Typography variant="caption" sx={{ opacity: 0.68 }}>
                    {' '}
                    Current Club
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <Stack
              flex={5}
              justifyContent="space-between"
              // p={2}
            >
              <Stack
                direction="column"
                gap={2}
                sx={{
                  p: 3,
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Stack
                  p={2}
                  backgroundColor="#f0f0f0"
                  borderRadius="50%"
                  justifyContent="center"
                  alignItems="center"
                  width="60px"
                  height="60px"
                >
                  <CardMedia
                    component="img"
                    src={wallet}
                    alt="wallet"
                    style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                  />
                </Stack>
                <Stack alignItems="baseline" justifyContent="center">
                  <Typography variant="body2" fontWeight="bold">
                    {memberDetails?.wallet_balance?.toFixed(2) || 0} {USDT}
                  </Typography>
                  <Typography
                    sx={{
                      typography: 'caption',
                      opacity: 0.68,
                    }}
                  >
                    Wallet Balance
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          )}
          <Stack
            flex={7}
            sx={{
              borderLeft:
                settings?.themeMode === 'dark' ? '1px solid #333d48' : '1px solid #e3e7ea ',
              p: 1.5,
              gap: 1.5,
            }}
          >
            <Stack
              flexDirection=""
              sx={{
                color: 'common.white',
              }}
            >
              {memberDetails?.referral_member ? (
                <Card
                  sx={{
                    boxShadow: 'unset',
                    border: '1px solid #e3e7ea',
                    backgroundColor: '#f0f0f0',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 1.5,
                  }}
                >
                  <Stack direction="row" gap={1}>
                    <Stack direction="row" flex={8} gap={1.5}>
                      <Stack
                        p={1}
                        borderRadius="50%"
                        justifyContent="center"
                        alignItems="center"
                        border={
                          settings?.themeMode === 'dark' ? '1px solid #616161' : '1px solid #e3e7ea'
                        }
                        width="50px"
                      >
                        <img
                          src={refer}
                          alt="wallet"
                          style={{ width: '30px', height: '20px', objectFit: 'cover' }}
                        />
                      </Stack>

                      <Stack>
                        <Typography fontSize="14px" color="#75838f">
                          Referred by
                        </Typography>
                        <Typography
                          fontSize="14px"
                          fontWeight="bold"
                          sx={{ color: settings?.themeMode === 'dark' ? 'black' : 'black' }}
                        >
                          {data?.referral_member?.name}{' '}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack flex={4}>
                      <Card
                        boxShadow="unset"
                        sx={{
                          borderRadius: '2px',
                          borderLeft: '2px solid #004b50',
                          px: 1,
                          backgroundColor: '#d8d9f5',
                          width: 'fit-content',
                        }}
                      >
                        <Typography
                          variant="caption"
                          fontWeight="bold"
                          sx={{ color: settings?.themeMode === 'dark' ? 'black' : 'black' }}
                        >
                          ID : {data?.referral_member?.member_id}
                        </Typography>
                      </Card>
                    </Stack>
                  </Stack>
                  <Stack ml={6.5} mt={1}>
                    <Stack direction="row" alignItems="center">
                      <MarkEmailUnreadRoundedIcon
                        sx={{ fontSize: 14, color: '#919eab' }}
                        fontSize="small"
                      />{' '}
                      &nbsp;
                      <Typography
                        variant="caption"
                        sx={{ color: settings?.themeMode === 'dark' ? 'black' : 'black' }}
                      >
                        {' '}
                        {data?.referral_member?.email}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <LocalPhoneRoundedIcon
                        sx={{ fontSize: 14, color: '#919eab' }}
                        fontSize="small"
                      />
                      &nbsp;
                      <Typography
                        variant="caption"
                        sx={{ color: settings?.themeMode === 'dark' ? 'black' : 'black' }}
                      >
                        {data?.referral_member?.phone}
                      </Typography>
                    </Stack>
                  </Stack>
                </Card>
              ) : (
                <Card
                  sx={{
                    boxShadow: 'unset',
                    border: '1px solid #e3e7ea',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 1.5,
                    backgroundColor: '#f0f0f0',
                  }}
                >
                  <Stack
                    direction="row"
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack gap={2} flex={1}>
                      <Typography variant="h6" fontWeight="bold" color="black">
                        Member referral <br />
                        link
                      </Typography>
                    </Stack>
                    <Stack width="auto">
                      <CardMedia
                        component="img"
                        src={gmail}
                        alt="wallet"
                        style={{ width: 70, height: 70, objectFit: 'cover' }}
                      />
                    </Stack>
                  </Stack>
                  <Typography
                    variant="caption"
                    sx={{ mb: 0.5, color: settings?.themeMode === 'dark' ? 'black' : '#637381' }}
                  >
                    Share link
                  </Typography>
                  <Stack direction="row" gap={1} justifyContent="center" alignItems="center">
                    <Card
                      sx={{
                        px: 1,
                        py: 0.7,
                        borderRadius: '10px',
                        boxShadow: 'unset',
                        border: '1px solid #e3e7ea',
                        textAlign: 'start',
                        backgroundColor: '#f0f0f0',
                        gap: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <TextMaxLine line={1}>
                        <Typography
                          sx={{
                            color: settings?.themeMode === 'dark' ? 'black' : '#637381',
                            wordWrap: 'break-word',
                            overflowWrap: 'anywhere',
                            opacity: 0.7,
                            textAlign: 'start',
                          }}
                          variant="caption"
                        >
                          {`${frontEndUrl}${paths.auth.register}?ref_id=${memberID}`}
                        </Typography>
                      </TextMaxLine>
                      <CopyToClipboard
                        // text={`${paths.auth.register}?ref='${MemberProfileDetails?.member_id}`}
                        text={`${frontEndUrl}${paths.auth.register}?ref_id=${memberID}`}
                        onCopy={handleCopy}
                        sx={
                          {
                            // cursor: 'pointer',
                            // position: 'absolute',
                            // bottom: 16,
                            // left: 16,
                            // '&:hover': {
                            //   color: 'primary.main',
                            // },
                          }
                        }
                      >
                        <Button
                          size="small"
                          variant="contained"
                          className="custom-copy-button"
                          backgroundColor="#007867"
                          borderRadius="25px"
                          sx={{
                            position: 'absolute',
                            bottom: 16,
                            right: 16,
                            color: 'white',
                            p: 1,
                            '&:hover': { backgroundColor: '#03433a' },
                          }}
                        >
                          Copy
                        </Button>
                      </CopyToClipboard>
                    </Card>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={handleClick}
                      sx={{
                        border: '1px solid #e3e7ea',
                        color: '#007867',
                        width: 'auto',
                        height: '40px',
                        borderRadius: '20px',
                        backgroundColor: '#e1efea',
                      }}
                    >
                      Share
                    </Button>
                  </Stack>
                </Card>
              )}
            </Stack>
            <Stack width="100%" alignItems="end">
              <Stack direction="row" alignItems="center" gap={2}>
                <IconButton
                  onClick={() => setOpenModalDialog(true)}
                  sx={{
                    width: 'auto',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '8px',
                    backgroundColor: '#f0f0f0',
                    color: 'black',
                    gap: 1,
                    '&:hover': {
                      backgroundColor: settings?.themeMode === 'dark' ? 'white' : '#f0f0f0',
                    },
                  }}
                >
                  <Iconify icon="fluent:edit-12-filled" />
                  <Typography variant="caption" fontWeight="bold">
                    {' '}
                    Edit Member
                  </Typography>
                </IconButton>
                {/* {memberDetails?.is_primary && ( */}
                <IconButton
                  onClick={() => setOpenUpdateMemberEmail(true)}
                  sx={{
                    width: 'auto',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '8px',
                    backgroundColor: '#f0f0f0',
                    color: 'black',
                    gap: 1,
                    '&:hover': {
                      backgroundColor: settings?.themeMode === 'dark' ? 'white' : '#f0f0f0',
                    },
                  }}
                  // disabled={!memberDetails?.is_primary}
                >
                  <Iconify icon="tabler:mail-filled" />

                  <Typography variant="caption" fontWeight="bold">
                    {' '}
                    Update Email
                  </Typography>
                </IconButton>
                {/* )} */}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      )}

      <ConfirmDialog
        open={confirm.value}
        // open='true'
        onClose={confirm.onFalse}
        title={isBlocked ? 'Unblock Member' : 'Block Member'}
        content={<>Are you sure want to {isBlocked ? 'unblock member' : 'block member'}?</>}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              Block_Unblock_Member();
              confirm.onFalse();
            }}
          >
            {isBlocked ? 'Unblock' : 'Block'}
          </Button>
        }
      />

      <FlagDialog
        open={openFlagDialog}
        setOpen={setOpenFlagDialog}
        flagMessage={memberDetails?.note}
        is_flagged={memberDetails?.is_flagged}
      />
      <SuspendDialog
        open={openSuspendDialog}
        setOpen={setOpenSuspendDialog}
        isSuspented={isSuspented}
      />
    </>
  );
}

MemberRefiredCardNew.propTypes = {
  memberDetails: PropTypes.string,
  setOpenModalPasswordDialog: PropTypes.func,
  setOpenModalDialog: PropTypes.func,
  data: PropTypes.object,
  memberID: PropTypes.string,
  setOpenUpdateMemberEmail: PropTypes.any,
};

import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from 'src/utils/stateToJson';

import {
  memberWalletAddress,
  getAllWithdrawalRequest,
  createWithdrawalRequest,
  getAllWithdrawalRequestAdmin,
  updateWithdrawalRequestAdmin,
} from '../api/withdrawalRequest';

export const withdrawalRequestReducerSlice = createSlice({
  name: 'withdrawalRequestReducer',
  initialState: {
    loading: false,
    error: {},
    withdrawalRequests: {},
    adminWithdrawalRequests: {},
    memberWalletAddressList: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [getAllWithdrawalRequest.pending]: (state) => {
      state.loading = true;
    },
    [getAllWithdrawalRequest.fulfilled]: (state, action) => {
      state.loading = false;
      state.withdrawalRequests = action.payload;
      state.error = {};
    },
    [getAllWithdrawalRequest.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [createWithdrawalRequest.pending]: (state) => {
      state.loading = true;
    },
    [createWithdrawalRequest.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.withdrawalRequests;
      const newList = jsonState?.withdrawalRequests;
      const modifiedList = {
        ...jsonState,
        withdrawalRequests: Array.isArray(newList)
          ? [action.payload, ...newList]
          : [action.payload],
      };
      state.loading = false;
      state.withdrawalRequests = modifiedList;
      state.error = {};
    },
    [createWithdrawalRequest.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // admin withdrawal request

    [getAllWithdrawalRequestAdmin.pending]: (state) => {
      state.loading = true;
    },
    [getAllWithdrawalRequestAdmin.fulfilled]: (state, action) => {
      state.loading = false;
      state.withdrawalRequests = action.payload;
      state.error = {};
    },
    [getAllWithdrawalRequestAdmin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateWithdrawalRequestAdmin.pending]: (state) => {
      state.loading = true;
    },
    [updateWithdrawalRequestAdmin.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.withdrawalRequests;
      const modifiedList = {
        ...jsonState,
        withdrawalRequests: jsonState.withdrawalRequests?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      state.withdrawalRequests = modifiedList;
      state.error = {};
    },
    [updateWithdrawalRequestAdmin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // memberwalletaddress
    [memberWalletAddress.pending]: (state) => {
      state.loading = true;
    },
    [memberWalletAddress.fulfilled]: (state, action) => {
      state.loading = false;
      state.memberWalletAddressList = action.payload;
      state.error = {};
    },
    [memberWalletAddress.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = withdrawalRequestReducerSlice.actions;

export default withdrawalRequestReducerSlice.reducer;

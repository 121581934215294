import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createClub,
  deleteClub,
  getAllClubs,
  getClubDetails,
  getAllClubsAdmin,
  updateClubDetails,
} from '../api/club';

export const clubSlice = createSlice({
  name: 'club',
  initialState: {
    loading: false,
    error: {},
    clubsAdmin: {},
    // staff
    clubs: {},
    clubDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [createClub.pending]: (state) => {
      state.loading = true;
    },
    [createClub.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.clubs;
      const newList = jsonState?.clubs;
      const modifiedList = {
        ...jsonState,
        clubs: Array.isArray(newList) ? [action.payload, ...newList] : [action.payload],
      };
      state.loading = false;
      state.clubs = modifiedList;
      state.error = {};
    },
    [createClub.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllClubs.pending]: (state) => {
      state.loading = true;
    },
    [getAllClubs.fulfilled]: (state, action) => {
      state.loading = false;
      state.clubs = action.payload;
      state.error = {};
    },
    [getAllClubs.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [getAllClubsAdmin.pending]: (state) => {
      state.loading = true;
    },
    [getAllClubsAdmin.fulfilled]: (state, action) => {
      state.loading = false;
      state.clubsAdmin = action.payload;
      state.error = {};
    },
    [getAllClubsAdmin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [getClubDetails.pending]: (state) => {
      state.loading = true;
    },
    [getClubDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.clubDetails = action.payload;
      state.error = {};
    },
    [getClubDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateClubDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateClubDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.clubs;
      const modifiedList = {
        ...jsonState,
        clubs: jsonState.clubs?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
      };

      state.loading = false;
      state.clubs = modifiedList;
      state.error = {};
    },
    [updateClubDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteClub.pending]: (state) => {
      state.loading = true;
    },
    [deleteClub.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.clubs;
      const modifiedList = {
        ...jsonState,
        clubs: jsonState.clubs?.filter((member) => member._id !== action.payload),
      };
      state.loading = false;
      state.clubs = modifiedList;
      state.error = {};
    },
    [deleteClub.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = clubSlice.actions;

export default clubSlice.reducer;

// eslint-disable-next-line import/no-extraneous-dependencies
import parser from 'html-react-parser';
import { React, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Paper, Stack, Container, CardMedia, Typography } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { fDateTime } from 'src/utils/format-time';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import { getAllNotificationByMember } from 'src/server/api/notification';

import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

function MemberNotification() {
  const settings = useSettingsContext();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllNotificationByMember({ dispatch }));
  }, [dispatch]);
  const { allNotification } = useSelector((state) => ({
    allNotification: state.notification.memberNotification?.list,
  }));
  return (
    <>
      <MetaHelmet title="Winbuks" miniDescription="Notifications" />
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        {/* <AllNotification /> */}
        <CustomBreadcrumbs
          heading="Notifications"
          links={[
            {
              name: 'Dashboard',
              href: paths.dashboard.root,
            },
            {
              name: ' All Notifications',
            },
            // {
            //   name: 'List',
            // },
          ]}
          sx={{
            mb: { xs: 3, md: 2 },
            mt: 4,
            width: '80%',
          }}
        />

        <Paper
          sx={{
            backgroundColor: '',
            borderRadius: 1,
            // height: 550,
            width: 442,
            padding: 1.5,
            boxShadow: 'none',
          }}
        >
          <Stack spacing={2}>
            {/* Header */}
            <Stack sx={{}}>
              {allNotification?.map((i, index) => (
                <Paper
                  key={index}
                  sx={{
                    borderRadius: 2,
                    boxShadow: 'none',
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: '#F7F7F7',
                    gap: 2,
                    borderBottom: `${0.5}px dashed #e2e2e2`,
                    mb: 1.5,
                  }}
                >
                  <Stack spacing={1}>
                    {i?.image && (
                      <Stack>
                        <CardMedia
                          sx={{
                            borderRadius: '12px',
                            height: 308,
                            width: 308,
                            objectFit: 'contain',
                          }}
                          src={i?.image?.url}
                          component="img"
                        />
                      </Stack>
                    )}
                    <Typography
                      variant="subtitle2"
                      fontWeight="bold"
                      sx={{
                        color: '#212B36',
                      }}
                    >
                      {i?.title}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ alignSelf: 'flex-start', color: 'text.secondary', marginTop: -0.5 }}
                    >
                      {fDateTime(i?.createdAt)}
                    </Typography>

                    <Typography
                      variant="body2"
                      color="#637381"
                      sx={{
                        backgroundColor: '#FFFFFF',
                        p: 1.5,
                        borderRadius: 1,
                        minWidth: 390,
                      }}
                    >
                      {parser(i?.description)}
                    </Typography>
                  </Stack>
                </Paper>
              ))}
            </Stack>
          </Stack>
        </Paper>
      </Container>
    </>
  );
}

export default MemberNotification;

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import SignUpView from 'src/sections/auth/sign-up-view';

// ----------------------------------------------------------------------

export default function SignUpPage() {
  return (
    <>
      <MetaHelmet title="Auth" miniDescription="Sign Up Form" />

      <SignUpView />
    </>
  );
}

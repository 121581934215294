import React from 'react';
import ContentLoader from 'react-content-loader';

import { Stack } from '@mui/material';

const Loader = (props) => (
  <ContentLoader
    speed={2}
    width={470}
    height={160}
    viewBox="0 0 470 160"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="10" cy="20" r="8" />
    <circle cx="164" cy="20" r="8" />
    <rect x="22" y="19" rx="5" ry="5" width="130" height="3" />
    <rect x="178" y="19" rx="5" ry="5" width="130" height="3" />
    <rect x="7" y="36" rx="0" ry="0" width="96" height="44" />
    <rect x="161" y="37" rx="0" ry="0" width="96" height="44" />
    <circle cx="326" cy="20" r="8" />
    <rect x="340" y="19" rx="5" ry="5" width="130" height="3" />
    <rect x="324" y="36" rx="0" ry="0" width="96" height="44" />
    <rect x="497" y="18" rx="5" ry="5" width="130" height="3" />
    <circle cx="10" cy="94" r="8" />
    <circle cx="164" cy="94" r="8" />
    <rect x="22" y="93" rx="5" ry="5" width="130" height="3" />
    <rect x="178" y="93" rx="5" ry="5" width="130" height="3" />
    <rect x="7" y="110" rx="0" ry="0" width="96" height="44" />
    <rect x="161" y="111" rx="0" ry="0" width="96" height="44" />
    <rect x="340" y="93" rx="5" ry="5" width="130" height="3" />
    <rect x="324" y="110" rx="0" ry="0" width="96" height="44" />
    <rect x="497" y="92" rx="5" ry="5" width="130" height="3" />
    <circle cx="325" cy="97" r="8" />
  </ContentLoader>
);

const ChainLoader = () => (
  <Stack direction="row" flexWrap="wrap">
    <Loader />
    <Loader />
    <Loader />
    <Loader />
    <Loader />
    <Loader />
    <Loader />
    <Loader />
  </Stack>
);

export default ChainLoader;

import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

import TextField from '@mui/material/TextField';

// ----------------------------------------------------------------------

export default function RHFTextField({
  name,
  helperText,
  multiline,
  rows,
  label,
  placeholder,
  InputLabelProps,
  type,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div style={{ position: 'relative' }}>
          <TextField
            {...field}
            fullWidth
            type={type}
            variant="outlined"
            label={label}
            placeholder={placeholder}
            multiline={multiline} // Allow multiline
            rows={rows}
            autoComplete="off"
            InputLabelProps={InputLabelProps && { shrink: true }} // Apply InputLabelProps only if it's provided
            value={type === 'number' && field.value === 0 ? '' : field.value}
            onChange={(event) => {
              if (type === 'number') {
                field.onChange(Number(event.target.value));
              } else {
                field.onChange(event.target.value);
              }
            }}
            error={!!error}
            helperText={error ? error?.message : helperText}
            {...other}
          />
        </div>
      )}
    />
  );
}

RHFTextField.propTypes = {
  helperText: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  multiline: PropTypes.bool, // Add prop types for multiline and rows
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  InputLabelProps: PropTypes.object,
};

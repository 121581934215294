/* eslint-disable import/no-unresolved */
import { useRef } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import { toPng } from 'html-to-image';

import { Box } from '@mui/system';
import Card from '@mui/material/Card';
import Timeline from '@mui/lab/Timeline';
import Tooltip from '@mui/material/Tooltip';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import SouthIcon from '@mui/icons-material/South';
import TimelineContent from '@mui/lab/TimelineContent';
// import DownloadIcon from '@mui/icons-material/Download';
import CallMadeIcon from '@mui/icons-material/CallMade';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { Stack, Button, CardMedia, LinearProgress } from '@mui/material';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';

import { USDT } from 'src/hooks/use-USDT';
import { useResponsive } from 'src/hooks/use-responsive';

import club1 from 'src/assets/club/CLUB.png';
import club2 from 'src/assets/club/UJIN.png';
import club3 from 'src/assets/club/CIAN.png';
import club4 from 'src/assets/club/KIAH.png';
import club5 from 'src/assets/club/SAYAN.png';
import trophy from 'src/assets/other/trophy.svg';
// import copy from 'src/assets/other/copygreen2.png';
import nullImage from 'src/assets/other/noImage.png';
import club1lock from 'src/assets/club/CLUB-BLACK.png';
import club2lock from 'src/assets/club/UJIN-BLACK.png';
import club3lock from 'src/assets/club/CIAN-BLACK.png';
import club4lock from 'src/assets/club/KIAH-BLACK.png';
import club5lock from 'src/assets/club/SAYAN-BLACK.png';

import { useSettingsContext } from 'src/components/settings';
// import imageCopy from 'src/assets/other/copygrey.png';

// ----------------------------------------------------------------------

export default function AnalyticsIncomeTimeline({
  title,
  clubs,
  subheader,
  list,
  ClubName,
  MemberProfileDetails,
  setOpenWalletTransferDialog,
  setOpenClubId,
  setConfirmDialog,
  disabled,
  ...other
}) {
  // console.log(clubs);
  const settings = useSettingsContext();

  const clubImages = [club1, club2, club3, club4, club5];
  const clubImagesLock = [club1lock, club2lock, club3lock, club4lock, club5lock];

  const combinedArray = clubImages.map((image, index) => ({
    image,
    imageLock: clubImagesLock && clubImagesLock[index] ? clubImagesLock[index] : null,

    club: clubs && clubs[index] ? clubs[index] : null,
  }));
  const isMobile = useResponsive('down', 'md');

  const CurrentClub = MemberProfileDetails?.member?.current_club?.club?.title;
  // console.log(MemberProfileDetails?.member?.member_id);
  // console.log(combinedArray);
  const clubImagArray = {
    BENE: club1,
    UJIN: club2,
    CIAN: club3,
    KIAH: club4,
    SAYAN: club5,
  };

  const currentClubTitle = MemberProfileDetails?.member?.current_club?.club?.title;
  const contentRef = useRef(null);
  const downloadButtonRef = useRef(null);

  const handleDownload = () => {
    if (contentRef.current === null) {
      return;
    }

    toPng(contentRef.current, {
      cacheBust: true,
      filter: (node) => node !== downloadButtonRef.current,
    })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = `${MemberProfileDetails?.member?.member_id}-Winbuks Achievements.png`;
        link.click();
      })
      .catch((err) => {
        console.error('Could not download image', err);
      });
  };
  return (
    <div ref={contentRef}>
      <Card variant="outlined" {...other} sx={{ justifyContent: 'center', boxShadow: 'unset' }}>
        <Stack justifyContent="space-between" direction="row" p={3} alignItems="center">
          <Stack gap={1} direction="row">
            <CardMedia
              component="img"
              src={trophy}
              alt="wallet"
              style={{ width: '20px', height: '20px', objectFit: 'cover' }}
            />
            <Typography variant="body2">Your Achievements</Typography>
          </Stack>
          <Stack width="fit-content">
            <Button
              ref={downloadButtonRef}
              onClick={handleDownload}
              disabled={disabled && disabled}
              size="small"
              startIcon={<SouthIcon sx={{ width: '15px' }} />}
              sx={{
                borderRadius: '5px',
                width: 'fit-content',
                backgroundColor: '#007867',
                px: 1.5,
                color: 'white',
                '&:hover': { backgroundColor: '#044f45' },
                '&:active': { backgroundColor: '#044f45' },
                '&:focus': { outline: 'none', backgroundColor: '#007867' },
              }}
            >
              Download
            </Button>
          </Stack>
        </Stack>
        <Card
          variant="outlined"
          sx={{
            mx: 2,
            p: 2,
            // boxShadow: '0px 1px 2px 0px #00000040'
            borderRadius: '7px',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            gap={2}
            sx={{
              flex: 1,
              py: isMobile ? 1 : 0,
              mb: 1,
            }}
          >
            <Stack
              p={1}
              backgroundColor="#f0f0f0"
              borderRadius="50%"
              justifyContent="center"
              alignItems="center"
              width="56px"
              height="56px"
            >
              <CardMedia
                component="img"
                src={clubImagArray[currentClubTitle] || nullImage}
                alt={currentClubTitle || 'club badge'}
                style={{ width: '30px', objectFit: 'cover' }}
              />
            </Stack>
            <Stack alignItems="baseline" justifyContent="center">
              <Typography
                sx={{
                  typography: 'body2',
                  fontWeight: 'bold',
                }}
              >
                {MemberProfileDetails?.member?.current_club?.club?.title || 'No active club'}
              </Typography>
              <Typography variant="caption" sx={{ opacity: 0.68 }}>
                {' '}
                Current Club
              </Typography>
            </Stack>
          </Stack>
          <Stack
            borderRadius="3px"
            backgroundColor={
              settings?.themeMode === 'dark' ? 'rgba(145, 158, 171, 0.08)' : '#f6f7f8'
            }
            width="fit-content"
            px={1}
            py={0.5}
          >
            <Typography variant="caption">
              {' '}
              Total Club Balance :
              <Typography
                component="span"
                variant="caption"
                color="#007867"
                fontWeight="bold"
                pl={0.2}
              >
                {`${MemberProfileDetails?.memberClubTotal?.toFixed(2) || 0} ${USDT}`}
              </Typography>
            </Typography>
          </Stack>
        </Card>
        {/* <CardHeader subheader={subheader} /> */}

        <Timeline
          sx={{
            m: 0,
            mx: 'auto',
            alignItems: 'center',
            p: 3,
            pb: 0,
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {combinedArray &&
            combinedArray.map((item, index) => (
              <ClubItem
                key={item.id}
                item={item}
                lastTimeline={index === combinedArray.length - 1}
                ClubName={ClubName}
                CurrentClub={CurrentClub}
                index={index}
                setOpenWalletTransferDialog={setOpenWalletTransferDialog}
                MemberProfileDetails={MemberProfileDetails}
                setOpenClubId={setOpenClubId}
                setConfirmDialog={setConfirmDialog}
                disabled={disabled}
              />
            ))}
        </Timeline>
      </Card>
    </div>
  );
}

AnalyticsIncomeTimeline.propTypes = {
  list: PropTypes.array,
  subheader: PropTypes.string,
  title: PropTypes.string,
  clubs: PropTypes.array,
  ClubName: PropTypes.any,
  MemberProfileDetails: PropTypes.any,
  setOpenWalletTransferDialog: PropTypes.func,
  setOpenClubId: PropTypes.any,
  setConfirmDialog: PropTypes.any,
  disabled: PropTypes.any,
};

// ----------------------------------------------------------------------

function ClubItem({
  item,
  lastTimeline,
  ClubName,
  CurrentClub,
  index,
  setOpenWalletTransferDialog,
  MemberProfileDetails,
  setOpenClubId,
  setConfirmDialog,
  disabled,
}) {
  const { club, image, imageLock } = item;

  const handleCheckCurrentClub = () => {
    switch (CurrentClub) {
      case 'BENE':
        return [0];
      case 'UJIN':
        return [0, 1];
      case 'CIAN':
        return [0, 1, 2];
      case 'KIAH':
        return [0, 1, 2, 3];
      case 'SAYAN':
        return [0, 1, 2, 3, 4];

      default:
        return [];
    }
  };
  const settings = useSettingsContext();

  const totalAmount = club?.total_amount ?? 0;
  const limitAmount = club?.club?.limit_amount ?? 1;
  const progress = (totalAmount / limitAmount) * 100;
  // console.log(progress)
  let icon;

  if (handleCheckCurrentClub()?.includes(index)) {
    if (progress > 1 && progress !== 100) {
      icon = <PanoramaFishEyeIcon sx={{ color: '#00A76F', width: '20px' }} />;
    } else {
      icon = <CheckCircleIcon sx={{ color: '#00A76F', width: '20px' }} />;
    }
  } else {
    icon = <PanoramaFishEyeIcon sx={{ color: '#dfe3e8', width: '20px' }} />;
  }
  // console.log(CurrentClub);
  // console.log(club?.club?.title);

  // const buttonTitle = () => {
  //   if (CurrentClub === club?.club?.title) {
  //     return club?.wallet_balance >= 10
  //       ? 'Transfer once this club is active'
  //       : 'Minimum 10 USDT balance required to transfer from current club';
  //   }
  //   return club?.wallet_balance > 0
  //     ? 'Transfer once this club is active'
  //     : 'Minimum 10 USDT balance required to transfer from current club';
  // };

  return (
    <TimelineItem className="custom-dot">
      <TimelineSeparator>
        {/* <TimelineDot
          color={(handleCheckCurrentClub()?.includes(index) === true && 'success') || 'grey'}
        /> */}
        <TimelineDot
          sx={
            {
              // backgroundColor: handleCheckCurrentClub()?.includes(index) ? 'success.main' : 'grey.500',
            }
          }
        >
          {icon}
        </TimelineDot>

        {lastTimeline ? null : (
          <TimelineConnector
            sx={{ width: '7px', backgroundColor: settings?.themeMode === 'dark' ? '' : '#F4F6F8' }}
            // sx={{
            //   backgroundColor:
            //     (handleCheckCurrentClub()?.includes(index) === true && 'green') || 'grey',
            // }}
          />
        )}
      </TimelineSeparator>

      <TimelineContent>
        <Stack flexDirection="row" width="auto">
          <Stack width="auto" mr={1}>
            {handleCheckCurrentClub()?.includes(index) === true && club?.wallet_balance > 0 ? (
              <CardMedia
                component="img"
                src={image}
                alt="club-img"
                sx={{ width: '40px', objectFit: 'cover' }}
              />
            ) : (
              <CardMedia
                component="img"
                src={imageLock}
                alt="club-img"
                sx={{ width: '40px', objectFit: 'cover' }}
              />
            )}
          </Stack>
          <Stack width="auto" sx={{ minWidth: '140px', width: '100%' }}>
            <Stack mb={1.5}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="caption" fontWeight="bold">
                  {club?.club?.title}
                </Typography>
                <Box display="flex" direction="row">
                  <Typography fontSize="10px" sx={{ opacity: 0.68 }}>
                    {club?.total_amount}/
                  </Typography>

                  <Typography fontSize="10px" sx={{ opacity: 0.68 }}>
                    {club?.club?.limit_amount}
                  </Typography>
                </Box>
              </Stack>
              {handleCheckCurrentClub()?.includes(index) === true && club?.wallet_balance > 0 ? (
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  sx={{
                    width: '100%',
                    height: 3.5,
                    borderRadius: 5,
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#00A76F',
                    },
                    backgroundColor: '#e0e0e0',
                  }}
                />
              ) : (
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  sx={{
                    width: '100%',
                    height: 3.5,
                    borderRadius: 5,
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#949cac',
                    },
                    backgroundColor: '#e0e0e0',
                  }}
                />
              )}

              <Stack flexDirection="row">
                <Typography
                  pt={0.5}
                  pb={1.3}
                  fontSize="11px"
                  sx={{
                    color:
                      // eslint-disable-next-line no-nested-ternary
                      handleCheckCurrentClub()?.includes(index) === true && club?.wallet_balance > 0
                        ? settings?.themeMode === 'dark'
                          ? 'white'
                          : 'black'
                        : '#637381',
                  }}
                >
                  {`Club balance:  ${club?.wallet_balance.toFixed(2) || 0} ${USDT}`}
                </Typography>
              </Stack>
              {handleCheckCurrentClub()?.includes(index) === true &&
              (CurrentClub === club?.club?.title
                ? club?.wallet_balance >= 10
                : club?.wallet_balance > 0) ? (
                //  club?.wallet_balance > 0 ? (

                <Tooltip title="Transfer to personal wallet">
                  <Button
                    size="small"
                    onClick={() => {
                      // setOpenWalletTransferDialog(true);
                      // handleReset();
                      setConfirmDialog(true);
                      setOpenClubId(club?._id);
                    }}
                    disabled={disabled && disabled}
                    sx={{
                      // backgroundColor: '#f6f7f8' rgba(145, 158, 171, 0.08),
                      backgroundColor:
                        settings?.themeMode === 'dark' ? 'rgba(145, 158, 171, 0.08)' : '#f6f7f8',

                      p: 1,
                      borderRadius: '2px',
                      fontSize: '11px',
                      fontWeight: 600,
                      height: '25px',
                    }}
                    startIcon={<CallMadeIcon sx={{ color: '#00A76F', width: '12px' }} />}
                  >
                    Transfer to Wallet
                  </Button>
                </Tooltip>
              ) : (
                // <Tooltip
                //   title={buttonTitle()}
                // >
                <Stack>
                  <Button
                    className="transfer-to-wallet"
                    size="medium"
                    disabled
                    sx={{
                      mt: 1,
                      backgroundColor:
                        settings?.themeMode === 'dark' ? 'rgba(145, 158, 171, 0.08)' : '#f6f7f8',
                      borderRadius: '2px',
                      fontSize: '11px',
                      fontWeight: 600,
                      height: '25px',
                    }}
                    startIcon={<CallMadeIcon sx={{ width: '12px' }} />}
                  >
                    Transfer to Wallet
                  </Button>
                </Stack>
                // </Tooltip>
              )}
            </Stack>
          </Stack>
        </Stack>
      </TimelineContent>
    </TimelineItem>
  );
}

ClubItem.propTypes = {
  item: PropTypes.object,
  lastTimeline: PropTypes.bool,
  ClubName: PropTypes.any,
  CurrentClub: PropTypes.any,
  index: PropTypes.any,
  setOpenWalletTransferDialog: PropTypes.func,
  MemberProfileDetails: PropTypes.any,
  setOpenClubId: PropTypes.any,
  setConfirmDialog: PropTypes.any,
  disabled: PropTypes.any,
};

import { createSlice } from '@reduxjs/toolkit';

// import { covertToJSON } from '../../utils/stateToJson';
import { getPublicSettings, getSettingsDetails, updateSettingsDetails } from '../api/settings';

export const settings = createSlice({
  name: 'settings',
  initialState: {
    loading: false,
    error: {},
    staffDropList: [],
    settingsDetails: {},
    publicSettings: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // get settings details
    [getPublicSettings.pending]: (state) => {
      state.loading = true;
    },
    [getPublicSettings.fulfilled]: (state, action) => {
      state.loading = false;
      state.publicSettings = action.payload;
      state.error = {};
    },
    [getPublicSettings.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get settings details
    [getSettingsDetails.pending]: (state) => {
      state.loading = true;
    },
    [getSettingsDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.settingsDetails = action.payload;
      state.error = {};
    },
    [getSettingsDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update settings details
    [updateSettingsDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateSettingsDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.settingsDetails = action.payload;
      state.error = {};
    },
    [updateSettingsDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export const { clearStaffError } = settings.actions;

export default settings.reducer;

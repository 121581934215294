import React from 'react';

import { Container } from '@mui/material';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import { useSettingsContext } from 'src/components/settings';

import LearningPage from './learningPage';

function AllLearningPage() {
  // console.log(MemberProfileDetails);
  const settings = useSettingsContext();
  return (
    <>
      <MetaHelmet title="Winbuks" miniDescription="Learnings" />
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <LearningPage />
      </Container>
    </>
  );
}

export default AllLearningPage;
